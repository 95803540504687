import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConnectionProfile} from '../models/connection-profile';

@Injectable({
  providedIn: 'root'
})
export class IPService {

  apiUrl: string;

  constructor(private http: HttpClient) { }


  getJWTToken(adapterEndpoint: string, connectionProfile: ConnectionProfile, isNewCredential:Boolean){
    this.apiUrl = adapterEndpoint + '/api/admin/requesttoken?purpose=' + connectionProfile.purpose + '&aud=' + adapterEndpoint + '&env=' + connectionProfile.ipUrl;
    console.log('API URL: ' + this.apiUrl);
    let body = JSON.parse(JSON.stringify({'newCredentials': isNewCredential}));
    let authorizationData = 'Basic ' + btoa(connectionProfile.userName + ':' + connectionProfile.authKey);
    return this.http.post(this.apiUrl, body,
      { observe: 'response',
                headers: {'Content-Type' : 'application/json; charset=utf-8', Authorization: authorizationData, Accept: '*/*'},
                responseType: 'json'
              });
  }
}

<div style="position: relative; padding: 20px;" >

  <div style="color: red; padding-bottom: 10px;" [hidden]="errorMessage === null || errorMessage === ''">{{errorMessage}}</div>

  <h2 mat-dialog-title>{{title}}</h2>

  <ngx-spinner [name]="'connProfile'" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium" >
    <p class="loading" style="color: white; font-weight: bold; padding-top: 30%;">Verifying Connection ...</p>
  </ngx-spinner>

  <mat-dialog-content [formGroup]="form">

    <div style="height:10px;"></div>

    <label id="example-radio-group-label">Type:</label>
    <mat-radio-group name="radioOpt1" [(ngModel)]="selectedLoginType" style="padding-left: 20px; padding-right: 20px;"
                     [ngModelOptions]="{standalone: true}" (change)="radioChange()">
      <mat-radio-button value="cp" name="own" style="padding-right: 10px;">Connection Profile</mat-radio-button>
      <mat-radio-button value="jwt" name="own" style="padding-left: 10px;">JWT</mat-radio-button>
    </mat-radio-group>

    <div style="height:20px;"></div>

    <div [hidden]="selectedLoginType == 'cp'">

      <mat-form-field style="width: 350px;" appearance="outline">
        <textarea matInput rows="5" cols="40" placeholder="Jwt" formControlName="jwt"></textarea>
      </mat-form-field>

    </div>

    <div [hidden]="selectedLoginType == 'jwt'">

      <mat-form-field [style.width.px]="350">
        <mat-label>Select / Create Connection Profile</mat-label>
        <mat-select (selectionChange)="applyFilter($event)" formControlName="selectedCp" >
          <mat-option value="New">New</mat-option>
          <mat-option *ngFor="let cp of cpList" [value]="cp" >{{cp.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div [hidden]="!showCreate">

        <mat-form-field [style.width.px]="350">
          <mat-label>Profile Name</mat-label>
          <input matInput
                 formControlName="name" [attr.disabled]="allowEdit? true : null">
        </mat-form-field>

        <div style="height: 1px;"></div>

        <mat-form-field [style.width.px]="350">
          <mat-label>Identity Provider</mat-label>
          <mat-select formControlName="ipUrl" >
            <mat-option *ngFor="let env of envs | async" [value]="env.url">
              {{ env.url }} ({{ env.name }})
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div style="height: 1px;"></div>

        <mat-form-field [style.width.px]="350">
          <mat-label>User</mat-label>
          <input matInput formControlName="user" [attr.disabled]="allowEdit">
        </mat-form-field>

        <div style="height: 1px;"></div>

        <mat-form-field [style.width.px]="350">
          <mat-label>API Access Key</mat-label>
          <input matInput type ="password"
                 formControlName="authKey" [attr.disabled]="allowEdit? true : null">
        </mat-form-field>

      </div>

    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button class="mat-dialog-button" [hidden]="!showCreate || selectedLoginType == 'jwt' || isNewConnectionProfile" (click)="deleteConnectionProfile('Delete', connProf)">
      Delete
    </button>
    <button mat-button class="mat-dialog-button" (click)="onNoClick()">
      Cancel
    </button>
    <button mat-button class="mat-dialog-button" [hidden]="showCreate || selectedLoginType == 'jwt' || isInitForm" (click)="editForm()">
      Edit
    </button>
    <div style="width: 10px;"></div>
    <button mat-button class="mat-dialog-button" (click)="authAdapter()" [hidden]="selectedLoginType != 'jwt'" >
      Ok
    </button>
    <button mat-button class="mat-dialog-button" (click)="authAdapter()" [disabled]="!form.valid" [hidden]="selectedLoginType == 'jwt'" >
      Ok
    </button>
  </mat-dialog-actions>

</div>

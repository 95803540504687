import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SchemaService} from '../../../../services/schema.service';
import {IntegrationDetails} from '../../../../models/integration-details';
import {GlobalUiStateQuery} from '../../../../state/global-ui-state.query';
import {UntypedFormBuilder} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-raw-schema-entity-dialog-body',
  templateUrl: './raw-schema-entity-dialog-body.component.html',
  styleUrls: ['./raw-schema-entity-dialog-body.component.scss']
})
export class RawSchemaEntityDialogBodyComponent implements OnInit {

  isEdit = false;
  jsondata = new Object();
  titleBase = 'Json View for Entity: ';
  title = '';
  beenModified = false;
  validJson = true;

  public editData: any;
  public originalData: any;

  selectedAdapterDetails$: Object;
  selectedIntegrationDetails$: IntegrationDetails = new IntegrationDetails(null);

  constructor(public dialogRef: MatDialogRef<RawSchemaEntityDialogBodyComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private service: SchemaService,
              private globalStateQuery: GlobalUiStateQuery,
              public fb: UntypedFormBuilder,
              private spinner: NgxSpinnerService,) {

  }

  ngOnInit() {
    this.jsondata = this.data;
    this.title = [this.titleBase, this.data.EntityName].join(' ');
    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();
  }

  close() {
    this.dialogRef.close(this.beenModified);
  }

  entityChanged(event) {
    this.editData = JSON.parse(event.target.value);
  }

  async edit() {
    let response = await this.service.getSingleSchemaEntity(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, this.jsondata['Name']).toPromise();
    this.originalData = response.body["RawSchema"]["EntityDefinitions"][0];
    this.editData = this.originalData;
    this.isEdit = true;
  }

  async cancel() {
    this.isEdit = false;
  }

  async save() {
    await this.spinner.show("processing2");
    const result = await this.service.updateSingleSchemaEntity(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId,
      JSON.stringify(this.editData)).toPromise();
    this.beenModified = true;
    await this.spinner.hide("processing2");
    this.isEdit = false;
    this.dialogRef.close(this.beenModified);
  }
}

import { Injectable } from '@angular/core';
import {EntityState, EntityStore, EntityUIStore, StoreConfig} from '@datorama/akita';
import { AdminUiUserModel, AdminUiUsersState } from './admin-ui-user.model';

export type UsersUI = {
};

export interface AdminUiUsersUIState extends EntityState<UsersUI> {}

const initialState = {
  ui: {
  }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'admin-ui-users' })
export class AdminUiUsersStore extends EntityStore<AdminUiUsersState, AdminUiUserModel> {

  ui: EntityUIStore<AdminUiUsersUIState>;

  constructor() {
    super(initialState);
  }

  updateUI( ui: AdminUiUsersUIState['ui'] ) {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui
      }
    }));
  }

}

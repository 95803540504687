
export class QueueDetails {

  subscriptionArn: string;
  queueArn: string;
  epochCreationTs: number;
  status: string;
  guid: string;

  public constructor(
    fields?: {
      subscriptionArn?: string;
      queueArn?: string;
      epochCreationTs?: number;
      status?: string;
      guid?: string;

    }) {
    if (fields) { Object.assign(this, fields); }
  }

}

<div style="position: relative; padding: 20px;  width: 525px;">

  <h2 mat-dialog-title>Query Builder</h2>

  <ngx-spinner [name]="'loadingEntity'" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium" >
    <p class="loading" style="color: white; font-weight: bold;">Loading Entity ...</p>
  </ngx-spinner>

  <mat-dialog-content [formGroup]="queryForm">

      <form class="form-vertical" [formGroup]="queryForm">

        <mat-form-field appearance="fill">
          <mat-label>From:</mat-label>
          <mat-select name="fromEntity" [(ngModel)]="selectedEntity" (selectionChange)="onEntityChanged()" formControlName="entityControlName">
            <mat-option *ngFor="let entity of entities" [value]="entity.Name"  >
              {{entity.Name}}
            </mat-option>
            <mat-hint>You can pick up your favorite entity here</mat-hint>
          </mat-select>

        </mat-form-field>

        <div style="height: 1px;"></div>

        <div [hidden]="!entitySelected">
          <mat-form-field appearance="fill">
            <mat-select  [formControl]="fieldControl"  formControlName="fieldControlName" multiple name="fieldName" ngDefaultControl placeholder="Fields">
              <mat-select-trigger>
                {{fieldControl.value ? fieldControl.value[0] : ''}}
                <span *ngIf="fieldControl.value?.length > 1" class="example-additional-selection">
                  (+{{fieldControl.value.length - 1}} {{fieldControl.value?.length === 2 ? 'other' : 'others'}})
                </span>
              </mat-select-trigger>
              <mat-option value="All">All</mat-option>
              <mat-option *ngFor="let field of fields" [value]="field.Name">{{field.Name}}</mat-option>
            </mat-select>
            <mat-label>
              Fields
            </mat-label>
          </mat-form-field>

          <div style="height: 5px;"></div>

          Conditions
          <mat-icon title="Add Condition" (click)="add()" disabled="!entitySelected"
                    style="color: darkgreen; cursor: pointer; padding: 0;">add_circle_outline</mat-icon>

          <div style="height: 5px;"></div>

          <div>

            <div class="container text-center" style="border: solid 1px gray; position: relative; padding: 0px 10px 10px 10px; max-height: 150px; overflow: auto;">

              <table mat-table [dataSource]="dataSource" class="my-table">
                &lt;!&ndash; Andor Column &ndash;&gt;
                <ng-container matColumnDef="andor">
                  <th mat-header-cell *matHeaderCellDef class="mat-header" style="width: 15%; text-align: left;"> And/Or </th>
                  <td mat-cell *matCellDef="let element"> {{element.andor}} </td>
                </ng-container>
                &lt;!&ndash; Field Column &ndash;&gt;
                <ng-container matColumnDef="field" >
                  <th mat-header-cell *matHeaderCellDef class="mat-header" style="width: 25%; text-align: left;"> Field </th>
                  <td mat-cell *matCellDef="let element"> {{element.field}} </td>
                </ng-container>
                &lt;!&ndash; Name Column &ndash;&gt;
                <ng-container matColumnDef="operator">
                  <th mat-header-cell *matHeaderCellDef class="mat-header" style="width: 25%; text-align: left;"> Operator </th>
                  <td mat-cell *matCellDef="let element"> {{element.operator}} </td>
                </ng-container>
                &lt;!&ndash; Value Column &ndash;&gt;
                <ng-container matColumnDef="value">
                  <th mat-header-cell *matHeaderCellDef class="mat-header" style="width: 25%; text-align: left;"> Value </th>
                  <td mat-cell *matCellDef="let element"> {{element.value}} </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="mat-header" style="width: 10%; text-align: center;"></th>
                  <td mat-cell *matCellDef="let element;  let i = index;">
                    <a (click)="deleteCondition(i)" style="color: blue; cursor: pointer;">
                      <mat-icon matTooltip="Delete" style="color: red;">delete</mat-icon>
                    </a>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>

            </div>

            <div style="height: 15px;"></div>

            <mat-form-field appearance="fill" >
              <mat-label>Limit</mat-label>
              <input type="number" matInput placeholder="" value="" [(ngModel)]="limitValue"  formControlName="limitControlName" >
            </mat-form-field>

          </div>

        </div>

    </form>

  </mat-dialog-content>

  <mat-dialog-actions  style="float:right;margin:20px;">
    <button mat-button (click)="close()">Cancel</button>
    <button mat-button (click)="save()" [disabled]="!queryForm.valid">Save</button>
  </mat-dialog-actions>

</div>



export class JWTInfo {
  public ipUserName: string = '';
  public ipRegisterAud: string = '';
  public ipAuthKey: string = '';
  public ipPurpose = 'toolkit';
  public ipUrl: string = '';
  public isRegistered  = false;
  public jwtToken: string;

  public constructor(
    fields?: {
      ipUserName?: string,
      ipRegisterAud?: string,
      ipAuthKey?: string,
      ipPurpose?: string,
      ipUrl?: string,
      isRegistered?: boolean,
      jwtToken: string;
    }) {
    if (fields) { Object.assign(this, fields); }
  }

}

<div id="fab-dismiss"
     *ngIf="fabTogglerState==='active'"
     (click)="onToggleFab()">
</div>

<main style="background-color: white; height: 100%; position: relative;">

  <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading" style="color: white; font-weight: bold;">Loading Integration Details ...</p>
  </ngx-spinner>

  <mat-toolbar>
    <div>Integration Management <span [hidden]="this.globalStateQuery.getSelectedAdapterIntegration() != null && this.globalStateQuery.getSelectedAdapterIntegration().integrationId"> - Onboard Integration</span></div>
    <span class="spacer"></span>
    <div style="float:right; position: fixed; bottom: 30px; right: 2%; z-index: 100; display: flex; flex-direction: column-reverse; align-items: center;">
      <mat-fab-menu color="primary" [fabButtons]="buttons" icon="menu" (onFabMenuItemSelected)="processFabButtonClick($event)">
      </mat-fab-menu>
    </div>
  </mat-toolbar>

  <div style="background-color: white; margin: auto; width: 98%; position: relative;">

    <form class="form-vertical" [formGroup]="selectedIntegrationDetails$Form">

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" style="padding-top: 10px">

        <mat-card style="margin-top: 10px; width: 96%; padding: 10px;">

          <mat-card-header>
            <mat-card-title>Account Details</mat-card-title>
          </mat-card-header>

          <div style="padding-left: 30px; display: flex;">

            <span style="display: flex; align-items: start;" [hidden]="isEdit && !selectedIntegrationDetails$.integrationId"
                  style="margin-top: 15px; font-weight: bold;"><span style="font-weight: normal;">Account Id:</span> {{selectedIntegrationDetails$.accountId}}</span>

            <mat-form-field style="width: 120px !important;" [hidden]="!isEdit || (isEdit && selectedIntegrationDetails$.integrationId)">
              <mat-label>Account Id</mat-label>
              <input matInput type="text" formControlName="accountId" required>
              <mat-error *ngIf="selectedIntegrationDetails$Form.controls['accountId'].invalid">Account Id Required
              </mat-error>
            </mat-form-field>

            <div style="width: 40px;"></div>

            <span style="display: flex; align-items: start;" [hidden]="isEdit && !selectedIntegrationDetails$.integrationId"
                  style="margin-top: 15px; font-weight: bold;"><span style="font-weight: normal;">Environment:</span> {{selectedIntegrationDetails$.environment}}</span>

            <mat-form-field style="width:300px !important" [hidden]="!isEdit || (isEdit && selectedIntegrationDetails$.integrationId)">
              <mat-label>Environment</mat-label>
              <input matInput type="text" formControlName="environment" required>
              <mat-error *ngIf="selectedIntegrationDetails$Form.controls['environment'].invalid">Environment Required
              </mat-error>
            </mat-form-field>

          </div>

        </mat-card>

        <mat-card style="margin-top: 10px; width: 96%; padding: 10px;">
          <mat-card-header>
            <mat-card-title>Integration Details</mat-card-title>
          </mat-card-header>

          <div style="padding-left: 30px;">

            <ng-container *ngFor="let form_elem of addedIntegrationFields">
              <div [ngSwitch]="form_elem.type" style="width: 100% !important;">
                <div *ngSwitchCase="'TEXT'">
                  <mat-form-field style="width: 100% !important;" [hidden]="!isEdit">
                    <mat-label>{{form_elem.label}}</mat-label>
                    <input matInput type="text" formControlName="{{form_elem.fieldName}}" [required]=form_elem.required>
                  </mat-form-field>
                  <span style="display: flex; align-items: start;" [hidden]="isEdit" style="margin-top: 15px; font-weight: bold;">
                    <span style="font-weight: normal;">{{form_elem.label}}:</span> {{form_elem.defaultValue}}
                  </span>
                </div>
                <div *ngSwitchCase="'NUMBER'" style="width: 100%;">
                  <mat-form-field style="width: 100% !important;" [hidden]="!isEdit">
                    <mat-label>{{form_elem.label}}</mat-label>
                    <input matInput type="text" formControlName="{{form_elem.fieldName}}" [required]=form_elem.required>
                    <mat-error
                      *ngIf="selectedIntegrationDetails$Form.controls[form_elem.fieldName].invalid">{{form_elem.label}} must
                      either be a number or blank
                    </mat-error>
                  </mat-form-field>
                  <span style="display: flex; align-items: center; justify-content: center"
                        [hidden]="isEdit" style="margin-top: 15px; font-weight: bold;"><span style="font-weight: normal;">{{form_elem.label}}:</span> {{form_elem.defaultValue}}</span>
                </div>
                <div *ngSwitchCase="'URI'" style="width: 100% !important;">
                  <mat-form-field style="width: 100% !important" [hidden]="!isEdit">
                    <mat-label>{{form_elem.label}}</mat-label>
                    <input matInput type="text" formControlName="{{form_elem.fieldName}}" [required]=form_elem.required>
                    <mat-error
                      *ngIf="selectedIntegrationDetails$Form.controls[form_elem.fieldName].invalid">{{form_elem.label}} must
                      be a valid URL
                    </mat-error>
                  </mat-form-field>
                  <span style="display: flex; align-items: center; justify-content: center"
                        [hidden]="isEdit" style="margin-top: 15px; font-weight: bold;"><span style="font-weight: normal;">{{form_elem.label}}:</span> {{form_elem.defaultValue}}</span>
                </div>
              </div>
              <!--  </template>-->

            </ng-container>

            <div style="width: 100% !important; padding-top: 10px;">
              <span style="display: flex; align-items: start;" [hidden]="isEdit" style="margin-top: 15px; font-weight: bold;">
                <span style="font-weight: normal;">
                  Integration State:
                </span>
                <span style="font-weight: bold; color: green;" [hidden]="this.selectedIntegrationDetails$.integrationStatus['state'] != 'healthy'">
                  {{this.selectedIntegrationDetails$.integrationStatus["state"]}}
                </span>
                <span style="font-weight: bold; color: red;" [hidden]="this.selectedIntegrationDetails$.integrationStatus['state'] == 'healthy'">
                  {{this.selectedIntegrationDetails$.integrationStatus["state"]}}
                </span>
                ,
                <span style="font-weight: normal;">StatusCode</span>
                :
                <span style="font-weight: bold; color: green;" [hidden]="this.selectedIntegrationDetails$.integrationStatus['state'] != 'healthy'">
                  {{this.selectedIntegrationDetails$.integrationStatus["statusCode"]}}
                </span>
                <span style="font-weight: bold; color: red;" [hidden]="this.selectedIntegrationDetails$.integrationStatus['state'] == 'healthy'">
                  {{this.selectedIntegrationDetails$.integrationStatus["statusCode"]}}
                </span>
              </span>
            </div>

            <div style="width:350px; padding-top: 20px;">
              <mat-checkbox formControlName="enabled"  [disabled]="!isEdit"  style="width:300px !important" (change)="isEnabled($event)" [checked] = this.selectedIntegrationDetails$.enabled >Integration Enabled </mat-checkbox>
            </div>

            <div style="width:350px; padding-top: 20px;">
              <mat-checkbox formControlName="enableBatch"  [disabled]="!isEdit"  style="width:300px !important" (change)="isBatchEnabled($event)" [checked] = this.selectedIntegrationDetails$.enableBatch >Enable Batch Messaging </mat-checkbox><br>
              <span style="display: flex; align-items: start;" [hidden]="isEdit || !this.selectedIntegrationDetails$.enableBatch"
                    style="margin-top: 15px; font-weight: bold;"><span style="font-weight: normal;">Batch Message Size:</span> {{selectedIntegrationDetails$.maxPublishedMessageSize}}</span>
              <mat-form-field style="width: 160px !important;" [hidden]="!isEdit || (isEdit && !selectedIntegrationDetails$.enableBatch)">
                <mat-label>Batch Message Size:</mat-label>
                <input matInput type="text" formControlName="batchSize">
              </mat-form-field>
            </div>

            <div class="individual-contributor">
              <mat-checkbox class=".individual-contributor-checkbox " formControlName="individualContributor"  [disabled]="!isEdit"  style="width:300px !important" (change)="indivContrChanged($event)" [checked] = this.selectedIntegrationDetails$.individualContributor >Individual Contributor </mat-checkbox>
            </div>
            <div class="shared-creds" [hidden]="(_env == 'production')">
              <mat-checkbox class="shared-creds-checkbox" formControlName="sharedCreds"  [disabled]="!isEdit"  style="width:300px !important" (change)="sharedCredsChanged($event)" [checked] = this.selectedIntegrationDetails$.isSharedCreds >Shared Credentials</mat-checkbox>
            </div>

            <div style="padding-top: 20px;">

              <strong>Integration Information</strong>

              <div style="height: 10px;"></div>

              <div [hidden]="integrationInformation !== {} && integrationInformation !== undefined">
                N/A
              </div>

              <div style="height: 400px; width: 1000px; padding-top: 20px; border: solid 1px gray;" [hidden]="integrationInformation === {} || integrationInformation === undefined">
                <ngx-json-viewer [json]="integrationInformation" [expanded]="false"></ngx-json-viewer>
              </div>

            </div>

          </div>

        </mat-card>

        <mat-card style="margin-top: 10px; width: 96%; padding: 10px;">

          <mat-card-header>
            <mat-card-title>Fetch Limits</mat-card-title>
          </mat-card-header>

          <div style="padding-left: 30px;">

            <span style="display: flex; align-items: start; margin-top: 15px; font-weight: bold;" [hidden]="isEdit">
              <span style="font-weight: normal; padding-right: 5px;">API Limit:</span>{{selectedIntegrationDetails$.apiLimits.overallLimit}}
            </span>

            <div>
              <mat-form-field [hidden]="!isEdit">
                <mat-label>API Limit</mat-label>
                <input matInput type="number" formControlName="overallLimit" >
              </mat-form-field>
            </div>

            <span style="display: flex; align-items: start; margin-top: 15px; font-weight: bold;" [hidden]="!liveApiLimitLoaded">
              <span style="font-weight: normal; padding-right: 5px;">Live API Limit:</span>{{liveApiLimit}}
            </span>

            <span style="display: flex; align-items: start; margin-top: 15px; font-weight: bold;" [hidden]="liveApiLimitLoaded">
              <span style="font-weight: normal; padding-right: 5px;">Live API Limit: N/A</span>
            </span>

            <div style="height: 20px;" [hidden]="!isEdit"></div>

            <span style="display: flex; align-items: start; margin-top: 15px; font-weight: bold;" [hidden]="isEdit">
              <span style="font-weight: normal; padding-right: 5px;">Search Limit %:</span>{{selectedIntegrationDetails$.apiLimits.searchLimitPercentage}}
            </span>

            <div>
              <mat-form-field [hidden]="!isEdit">
                <mat-label>Search Limit %</mat-label>
                <input matInput type="number" formControlName="searchLimitPercentage" >
              </mat-form-field>
            </div>

            <span style="display: flex; align-items: start; margin-top: 15px; font-weight: bold;" [hidden]="isEdit">
              <span style="font-weight: normal; padding-right: 5px;">CRUD Limit %:</span>{{selectedIntegrationDetails$.apiLimits.crudLimitPercentage}}
            </span>

            <div>
              <mat-form-field [hidden]="!isEdit">
                <mat-label>CRUD Limit %</mat-label>
                <input matInput type="number" formControlName="crudLimitPercentage" >
              </mat-form-field>
            </div>

            <span style="display: flex; align-items: start; margin-top: 15px; font-weight: bold;" [hidden]="isEdit">
              <span style="font-weight: normal; padding-right: 5px;">Entity Sync Limit %:</span>{{selectedIntegrationDetails$.apiLimits.entitySyncLimitPercentage}}
            </span>

            <div>
              <mat-form-field [hidden]="!isEdit">
                <mat-label>Entity Sync Limit %</mat-label>
                <input matInput type="number" formControlName="entitySyncLimitPercentage" >
              </mat-form-field>
            </div>

            <span style="display: flex; align-items: start; margin-top: 15px; font-weight: bold;" [hidden]="isEdit">
              <span style="font-weight: normal; padding-right: 5px;">Delete Job Limit %:</span>{{selectedIntegrationDetails$.apiLimits.deleteJobLimitPercentage}}
            </span>

            <div>
              <mat-form-field [hidden]="!isEdit">
                <mat-label>Delete Job Limit %</mat-label>
                <input matInput type="number" formControlName="deleteJobLimitPercentage" >
              </mat-form-field>
            </div>


          </div>

        </mat-card>

        <mat-card style="margin-top: 10px; width: 96%; padding: 10px;">

          <mat-card-header>
            <mat-card-title>Relay Details</mat-card-title>
          </mat-card-header>

          <div style="padding-left: 30px;">

            <div style="height: 30px; margin-top: auto; margin-bottom: auto; padding-right: 30px;">
              <mat-checkbox formControlName="enableRelay" (change)="isRelayChanged($event)" [disabled]="!isEdit" >Enable Relay</mat-checkbox>
            </div>

            <div style="height: 10px;"></div>

            <div>
              <mat-form-field [hidden]="!displayRelayFields || isEdit">
                <mat-label>Encryption Password</mat-label>
                <input matInput type="text" formControlName="relayEncPassword" [readonly]="true" [disabled]="true">
              </mat-form-field>
            </div>

            <div>
              <mat-form-field [hidden]="!displayRelayFields || !isEdit">
                <mat-label>Encryption Password</mat-label>
                <input matInput type="text" formControlName="relayEncPassword" >
              </mat-form-field>
            </div>

            <div>
              <mat-form-field [hidden]="!displayRelayFields || !selectedIntegrationDetails$.relayQueueName" style="width: 100% !important">
                <mat-label>Reply Queue Name</mat-label>
                <input matInput type="text" formControlName="relayResponseQueueName" [readonly]="true" [disabled]="true">
              </mat-form-field>
            </div>

            <div>
              <mat-form-field [hidden]="!displayRelayFields || !selectedIntegrationDetails$.relayResponseQueueName" style="width: 100% !important">
                <mat-label>Queue Name</mat-label>
                <input matInput type="text" formControlName="relayQueueName"  [readonly]="true" [disabled]="true">
              </mat-form-field>
            </div>

            <div>
              <mat-form-field [hidden]="!displayRelayFields || !selectedIntegrationDetails$.pingQueueName" style="width: 100% !important">
                <mat-label>Ping Queue Name</mat-label>
                <input matInput type="text" formControlName="pingQueueName"  [readonly]="true" [disabled]="true">
              </mat-form-field>
            </div>

          </div>

        </mat-card>

      </div>

      <mat-card style="margin-top: 10px; width: 96%; padding: 10px;" [hidden]="!selectedIntegrationDetails$.userRegistered">

        <mat-card-header>
          <mat-card-title>Authentication Details</mat-card-title>
        </mat-card-header>

        <div style="padding-left: 30px;">

          <div style="height: 10px;"></div>

          <div style="font-size: 12pt; width: 70%; overflow-wrap: break-word; margin: 4px">
            Supported Auth Types:
            <span><b>{{selectedIntegrationDetails$.supportedAuthTypes}}</b></span></div>
          <div style="height: 15px"></div>

          <div>
            <h3>Selected Auth Type:</h3>
            <span style="font-weight: bold;padding-left: 25px">{{selectedIntegrationDetails$.authType === 1 ? "BasicAuth" : selectedIntegrationDetails$.authType === 2 ? "OAuth2Authorization" : selectedIntegrationDetails$.authType === 3 ? "OAuth2UsernamePassword" : selectedIntegrationDetails$.authType === 4 ? "CustomAuth" : "N/A"}}</span>
          </div>
          <br/>
          <div>
            <h3>Auth Attributes:</h3>
            <ul *ngFor="let authAttribute of selectedIntegrationDetails$.authSettings | keyvalue">{{authAttribute.key}}: <b>{{authAttribute.value}}</b></ul>
          </div>

        </div>

      </mat-card>

      <div style="height: 10px"></div>

    </form>


  </div>

</main>

export class IntegrationDetails {

  constructor(mapValues)
  {
    if (mapValues != null)
    {
      this.supportedAuthTypes = mapValues['supportedAuthTypes'] ? mapValues['supportedAuthTypes'] : [];
      this.connectionState = mapValues['connectionState'] ? mapValues['connectionState'] : "unknown";
      this.runningConnectionTest = mapValues['runningConnectionTest'] ? mapValues['runningConnectionTest'] : false;
      this.connectionMessage = mapValues['connectionMessage'] ? mapValues['connectionMessage'] : "";

      this.integrationId = mapValues['IntegrationId'];
      this.type = mapValues['Type'];
      this.name = mapValues['Name'];
      this.environment = mapValues['Environment'];
      this.environmentOverride = mapValues['EnvironmentOverride'];
      this.accountId = mapValues['AccountId'];
      this.enabled = mapValues['Enabled'];
      this.endPoint = mapValues['EndPoint'];
      this.deepLinkURL = mapValues['DeepLinkURL'];
      this.authType = mapValues['AuthType'];
      this.supportedAuthTypes = mapValues['SupportedAuthTypes'];
      this.config = new IntegrationDetailsConfig(mapValues['Config']);
      this.apiLimits = new ApiLimits(mapValues['ApiLimits']);
      this.oAuthSettings = mapValues['OAuthSettings'];
      this.customAuthSettings = mapValues['CustomAuthSettings'];
      this.isRelay =  mapValues['IsRelay'];
      this.relayQueueName =  mapValues['RelayQueueName'];
      this.relayEncryptionPassword =  mapValues['RelayEncryptionPassword'];
      this.relayResponseQueueName =  mapValues['RelayResponseQueueName'];
      this.pingQueueName =  mapValues['PingQueueName'];
      this.metaData =  mapValues['MetaDataElements'];
      this.enableBatch = mapValues["BatchPublishing"];
      this.maxPublishedMessageSize = mapValues["MaxPublishedMessageSize"];
      this.authSettings = mapValues["AuthSettings"];
      this.userRegistered = mapValues["UserRegistered"];
      this.individualContributor = mapValues["IsIndividualContributor"];
      this.integrationStatus = mapValues["IntegrationStatus"];
      this.isSharedCreds =  mapValues["IsSharedCreds"];
    }
    else
    {
      this.supportedAuthTypes = [];
      this.connectionState = "unknown";
      this.runningConnectionTest = false;
      this.connectionMessage = "";
      this.config = new IntegrationDetailsConfig(null);
      this.apiLimits = new ApiLimits(null);
      this.oAuthSettings = new Map<string,string>();
      this.authSettings = new Map<string,string>();
      this.individualContributor = false;
      this.integrationStatus = new IntegrationStatus(null);
      this.isSharedCreds = false;
    }
  }

  integrationId: number;
  type: string;
  name: string = "";
  environment: string;
  accountId: number;
  enabled: boolean;
  enableBatch: boolean;
  maxPublishedMessageSize: number;
  endPoint: string = "";
  deepLinkURL: string = "";
  authType: number;
  supportedAuthTypes: string[];
  config: IntegrationDetailsConfig;
  apiLimits: ApiLimits;
  oAuthSettings: Map<string,string>;
  customAuthSettings: Object;
  isRelay: boolean;
  relayQueueName: string;
  relayEncryptionPassword: string;
  relayResponseQueueName: string;
  pingQueueName: string;
  connectionState: string;
  runningConnectionTest: boolean;
  connectionMessage: string;
  metaData: {};
  environmentOverride: string ="";
  authSettings: Map<string,string>;
  userRegistered: boolean;
  individualContributor: boolean;
  integrationStatus: IntegrationStatus;
  isSharedCreds: boolean

}

export class IntegrationDetailsConfig
{
  constructor(mapValues)
  {
    if (mapValues != null)
    {
      this.integrationId = mapValues['integrationId'];
      this.dateTimeFormat = mapValues['dateTimeFormat'];
      this.maxPageSize = mapValues['maxPageSize'];
      this.defaultPageSize = mapValues["defaultPageSize"];
      this.connectionTimeout = mapValues["connectionTimeout"];
      this.responseTimeout = mapValues["responseTimeout"];
      this.customTableName = mapValues["customTableName"];
      this.initialImportDefaultReachBackDays = mapValues["initialImportDefaultReachBackDays"];
      this.callThrottleValuePerSec = mapValues["callThrottleValuePerSec"];
      this.revokingEndpoint = mapValues["revokingEndpoint"];

    }
  }

  integrationId: number;
  dateTimeFormat: string;
  maxPageSize: number;
  defaultPageSize: number;
  connectionTimeout: number;
  responseTimeout: number;
  initialImportDefaultReachBackDays: number;
  customTableName: string;
  callThrottleValuePerSec: number;
  revokingEndpoint: string;

}

export class ApiLimits
{
  constructor(mapValues)
  {
    if (mapValues != null)
    {
      this.overallLimit = mapValues["overallLimit"];
      this.searchLimitPercentage = mapValues["searchLimitPercentage"];
      this.crudLimitPercentage = mapValues["crudLimitPercentage"];
      this.deleteJobLimitPercentage = mapValues["deleteJobLimitPercentage"];
      this.entitySyncLimitPercentage = mapValues["entitySyncLimitPercentage"];
    }
  }

  overallLimit: number;
  searchLimitPercentage: number;
  crudLimitPercentage: number;
  deleteJobLimitPercentage: number;
  entitySyncLimitPercentage: number;
}

export class IntegrationStatus
{
  constructor(mapValues)
  {
    if (mapValues != null)
    {
      this.state = mapValues["state"];
      this.statusCode = mapValues["statusCode"];
    }
  }

  state: string;
  statusCode: string;

}

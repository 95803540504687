export class BatchResults {
  succeeded: number = 0;
  failed: number = 0;
  notProcessed: number = 0;
  message : string[];
  errorMessages: string[];



  public constructor(
    fields?: {
      succeeded?: number;
      failed?: number;
      notProcessed?: number;
      message? : string[];
      errorMessages?: string[];
    }) {
    if (fields) { Object.assign(this, fields); }
  }

}

import {JWTInfo} from './jwtinfo';

export class AdapterUser {
  type: string;
  email: string;
  publicKeys: [];

  public constructor(
    fields?: {
      type?: string,
      email?: string,
      publicKeys?: string
    }) {
    if (fields) { Object.assign(this, fields); }
  }
}


<div style="width: 100%; position: relative;">

  <app-pager (onFilterChange)="filterChanged($event)"
             [fields]="[{'key':'Error ID','value':'errorId'},{'key':'GUID','value':'guid'}]"
             [operators]="[{'key':'Equal','value':'eg'}]">
  </app-pager>

  <ngx-datatable
    #adapterLogTable
    id="adapterLogTable"
    class="material server-scroller"
    [columnMode]="ColumnMode.flex"
    [headerHeight]="headerHeight"
    [rowHeight]="rowHeight"
    [rows]="logs$"
    [loadingIndicator]="isLoading"
    [scrollbarV]="true"
    (scroll)="onScroll($event.offsetY)">

    <ngx-datatable-column name="Time (UTC)" [sortable]="false" [flexGrow]="6">
      <ng-template let-log="row" ngx-datatable-cell-template >
        {{ log.createTime*1000 | date:'short':'+0000' }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="IntId" [sortable]="false" [flexGrow]="2">
      <ng-template let-log="row" ngx-datatable-cell-template >
        {{ log.integrationId }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="GUID" [sortable]="false" [flexGrow]="8">
      <ng-template let-log="row" ngx-datatable-cell-template >
        {{ log.guid }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Error Message" [sortable]="false" [canAutoResize]="true"  [flexGrow]="10">
      <ng-template let-log="row" ngx-datatable-cell-template >
        {{ log.errorMessage }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Description" [sortable]="false" [flexGrow]="10">
      <ng-template let-log="row" ngx-datatable-cell-template >
        {{ log.errorDescription }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Ex." [sortable]="false" [flexGrow]="2">
      <ng-template let-log="row" ngx-datatable-cell-template >
        <a (click)="openException(log)" [ngClass]="{'enabled': (log.errorException != undefined && log.errorException.length > 0) ,'disabled': (log.errorException == undefined || log.errorException <=0 ) }" matTooltip="View error"><mat-icon  style="color:red">warning</mat-icon></a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Payload" [sortable]="false" [flexGrow]="3">
      <ng-template let-log="row" ngx-datatable-cell-template >
        <a (click)="openPayload(log)" [ngClass]="{'enabled': log.payload != undefined ,'disabled': log.payload === undefined }" matTooltip="View payload"> <mat-icon *ngIf="log.payload != undefined" matTooltip="View Payload" style="color:royalblue">visibility</mat-icon></a>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>

</div>


import {Component, ElementRef, OnInit} from '@angular/core';
import {AuditLogService} from './state/audit-log.service';
import {AuditLogModel} from './state/audit-log.model';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AuditlogdialogComponent} from '../../../components/auditlogdialog/auditlogdialog.component';
import { ColumnMode } from '@swimlane/ngx-datatable';
import {PagingDetails} from '../../../models/paging-details';

@Component({
  selector: 'app-security-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss']
})
export class AuditLogsComponent implements OnInit {

  logs$?: AuditLogModel[] = [];
  defaultPageSize = 10;
  pagingDetails = new PagingDetails();
  filter: string = null;
  readonly headerHeight = 50;
  readonly rowHeight = 50;
  readonly pageLimit = 10;
  startTime:  number = 0;
  selectedAdapterDetails$: Object;
  isLoading: boolean;
  searchComplete: boolean = false;

  constructor(
    private auditLogService: AuditLogService,
    public matDialog: MatDialog,
    private el: ElementRef
  ) { }

  ColumnMode = ColumnMode;

  async ngOnInit(): Promise<void> {
    // this.tokens = [];
    // const x = await this.auditLogService.getAuditLogs(this.defaultPageSize, null, null);
    // this.logs$ = x.theList;
    // this.tokens.push(x.nextToken);
    // this.currentPosition = 0;
    // setTimeout(() => {
    //   this.loading = false;
    // }, 1500);

    await this.onScroll(0);
  }

  async onScroll(offsetY: number) {

    // total height of all rows in the viewport
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;

    // check if we scrolled to the end of the viewport
    if (!this.isLoading && offsetY + viewHeight >= this.logs$.length * this.rowHeight) {

      // check if we haven't fetched any results yet
      if (this.logs$.length === 0) {
        // calculate the number of rows that fit within viewport
        const pageSize = Math.ceil(viewHeight / this.rowHeight);

        // change the limit to pageSize such that we fill the first page entirely
        // (otherwise, we won't be able to scroll past it)
        this.pagingDetails.size = Math.max(pageSize, this.pageLimit);
      }
      await this.loadPage();
    }
  }

  private async loadPage() {

    if (this.searchComplete)  {
      return;
    }

    // set the loading flag, which serves two purposes:
    // 1) it prevents the same page from being loaded twice
    // 2) it enables display of the loading indicator
    this.isLoading = true;
    const x = await this.auditLogService.getAuditLogs(this.pagingDetails.size, this.startTime);

    const results = x ? x.theList.sort(function compareFn(a, b) {
      if (a['createTime'] > b['createTime']) {
        return -1;
      }
      if (a['createTime'] < b['createTime']) {
        return 1
      }
      return 0;
    }) : [];

    if (results.length > 0) {
      this.startTime = results[results.length-1]["createTime"];
      const logs  = [...this.logs$, ...results];
      this.logs$ = logs;
    } else  {
      this.searchComplete = true;
    }

    this.isLoading = false;

  }

  async filterChanged(event) {
    if( event == this.filter)
      return;

    this.filter = event;
    this.startTime = 0;
    this.logs$ = [];
    this.searchComplete = false;
    await this.onScroll(0);

  }

  openPayload(log: AuditLogModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data =
      {
        "json":JSON.parse(log.payload)
      };
    this.matDialog.open(AuditlogdialogComponent, dialogConfig);
  }


}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {APIService} from '../../API.service';
import {Router} from '@angular/router';
import {NgFormsManager} from '@ngneat/forms-manager';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AdaptersService} from '../state/adapters.service';
import {Auth} from 'aws-amplify';
import {GlobalUiStateQuery} from '../../state/global-ui-state.query';
import {Order} from '@datorama/akita';
import {Observable} from 'rxjs';
import {IntegrationTypeModel} from '../../config/config-integration-types/state/integration-type.model';
import {IntegrationTypesQuery} from '../../config/config-integration-types/state/integration-types.query';
import {IntegrationTypesService} from '../../config/config-integration-types/state/integration-types.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-adapter-maintenance',
  templateUrl: './adapter-maintenance.component.html',
  styleUrls: ['./adapter-maintenance.component.scss']
})
export class AdapterMaintenanceComponent implements OnInit, OnDestroy {

  adapterForm: UntypedFormGroup;
  selectedEnvironment: String;
  integrationTypes$?: Observable<IntegrationTypeModel[]>;
  adapterIntegrationType: String;

  constructor(
    private spinner: NgxSpinnerService,
    private api: APIService,
    private router: Router,
    private formsManager: NgFormsManager,
    private adapterService: AdaptersService,
    private builder: UntypedFormBuilder,
    private globalStateQuery: GlobalUiStateQuery,
    private integrationTypesQuery: IntegrationTypesQuery,
    private integrationTypesService: IntegrationTypesService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {

    this.selectedEnvironment = this.globalStateQuery.getSelectedEnvironment();
    this.adapterForm = this.builder.group({
      id: [null],
      name: [null, Validators.required],
      adapterType: [null, Validators.required],
      endpoint: [null, Validators.required],
      localPortForwardPort: [null],
      enabled: [null],
    });

    this.formsManager.upsert('adapter', this.adapterForm, {
      persistState: true
    });

    const adapter = this.formsManager.getControl('adapter').value;
    this.adapterIntegrationType = adapter.adapterType;

    this.integrationTypesService.getIntegrationTypes().then(() =>
      {
        this.integrationTypes$ = this.integrationTypesQuery.selectAll({ sortBy: 'displayName', sortByOrder: Order.ASC });
      }
    );


  }

  ngOnDestroy() {
    this.formsManager.unsubscribe('user');
  }

  async onCancel(): Promise<void> {
    this.formsManager.clear('adapter');
    await this.router.navigate(['/adapters']);
  }

  async upsertAdapter(): Promise<void> {

    await this.spinner.show();

    const adapter = this.formsManager.getControl('adapter').value;
    adapter.name = adapter.name.trim();
    adapter.endpoint = adapter.endpoint.trim();

    if (!adapter.enabled) {
      adapter.enabled = false;
    }

    this.setLogoDetails(adapter);

    // if (this.selectedEnvironment !== "dev")
    //   adapter.envPrefix = this.selectedEnvironment;
    // else
    // {
    //   const user = await Auth.currentAuthenticatedUser({ bypassCache: false });
    //   const emailAddress = user.attributes.email;
    //   const userList = await this.api.ListUsers({ emailAddress: { eq: emailAddress}});
    //   if (userList) {
    //     const fullUser = await this.api.GetUser(userList.items[0].id);
    //     adapter.envPrefix = fullUser.userDetail.envPrefix;
    //   }
    // }

    adapter.envPrefix = this.globalStateQuery.getSelectedEnvPrefix();

    if (adapter.id)
    {
      try {
        await this.api.UpdateAdapter(adapter);
      } catch (e) {
        console.log(e.errors[0].message);
        await this.spinner.hide();
        if (e.errors[0].message.indexOf("Status Code: 400") > -1) {
          this.showToaster('Update Adapter call failed. User does not have permission to perform this operation', 1);
        } else {
          this.showToaster('Update Adapter call failed. Status code: ' + e.errors[0].message, 1);
        }
        return;
      }
    }
    else
    {
      adapter.id = null;
      await this.api.CreateAdapter(adapter);
    }

    this.formsManager.clear('adapter');

    await this.spinner.hide();

    await this.adapterService.getAdapters();

    await this.router.navigate(['/adapters']);

  }

  showToaster(message, statusCode) {
    statusCode === 0 ? this.toastr.success(message) : this.toastr.error(message);
  }

  setLogoDetails(adapter)  {
    this.integrationTypes$.subscribe(list =>
      list.forEach(integrationType => {
        if (integrationType["code"] === adapter.adapterType) {
          adapter.logoFilename = integrationType["logoFilename"];
          adapter.logoWidth = integrationType["logoWidth"];
          adapter.logoHeight = integrationType["logoHeight"];
        }
      })
    );
  }

}

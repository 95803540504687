import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IntegrationTypesStore } from './integration-types.store';
import { APIService } from '../../../API.service';
import { IntegrationTypeModel } from './integration-type.model';

@Injectable({ providedIn: 'root' })
export class IntegrationTypesService {

  constructor(private api: APIService,
              private integrationTypesStore: IntegrationTypesStore,
              private http: HttpClient) {
  }

  async getIntegrationTypes(): Promise<void> {
    const itList: IntegrationTypeModel[] = [];
    const integrationTypesList = await this.api.ListIntegrationTypes();
    integrationTypesList.items.forEach(integrationType => {
      itList.push(
        {
          id: integrationType.id,
          code: integrationType.code,
          displayName: integrationType.displayName,
          logoFilename: integrationType.logoFilename,
          logoWidth: integrationType.logoWidth,
          logoHeight: integrationType.logoHeight
        });
    });
    this.integrationTypesStore.set(itList);
  }

  async updateSelectedIntegrationType(integrationType: IntegrationTypeModel)
  {
    await this.api.UpdateIntegrationType(integrationType);
  }
}

import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AdaptersStore } from './adapters.store';
import {AdapterModel, AdaptersState} from './adapter.model';

@Injectable({ providedIn: 'root' })
export class AdaptersQuery extends QueryEntity<AdaptersState, AdapterModel> {

  constructor(protected store: AdaptersStore) {
    super(store);
  }

  getShowScheduledJobSearchResults() {
    return this.getValue().ui.showScheduledJobSearchResults;
  }

  getShowScheduledJobDiv() {
    return this.getValue().ui.showScheduledJobDiv;
  }

  getScheduledJobIsEdit() {
    return this.getValue().ui.scheduledJobIsEdit;
  }

  getScheduledJobSearchResultList() {
    return this.getValue().ui.scheduledJobSearchResultList;
  }

  getScheduleJob() {
    return this.getValue().ui.scheduleJob;
  }

}

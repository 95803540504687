import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adapter-integration-stats',
  templateUrl: './adapter-integration-stats.component.html',
  styleUrls: ['./adapter-integration-stats.component.scss']
})
export class AdapterIntegrationStatsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

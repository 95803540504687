<nav>

  <div (click)="adapterIntegrationDetailsClicked()" [style.background-color]="getSelectedNavBackgroundColor('adapter-integration-details')" style="cursor: pointer;">
      <div style="text-align: center; padding-top:15px;"><img src="assets/details.png" style="height: 24px;"></div>
      <div style="text-align: center; padding-top:5px; padding-bottom:10px; color: black; font-size: 8pt;">Details</div>
  </div>
  <div (click)="adapterIntegrationUserJobsClicked()"  [hidden]="!selectedIntegrationDetails$ || !selectedIntegrationDetails$['integrationId']" [style.background-color]="getSelectedNavBackgroundColor('app-adapter-integration-users')" style="cursor: pointer;">
    <div style="text-align: center; padding-top:15px;"><img src="assets/users.png" style="height: 24px;"></div>
    <div style="text-align: center; padding-top:5px; padding-bottom:10px; color: black; font-size: 8pt;">Users</div>
  </div>
  <div  [hidden]="selectedIntegrationDetails$ && selectedIntegrationDetails$['connectionState'] !== 'connected'">
    <div (click)="adapterIntegrationJobsClicked()"  [hidden]="!selectedIntegrationDetails$ || !selectedIntegrationDetails$['integrationId']" [style.background-color]="getSelectedNavBackgroundColor('app-adapter-integration-jobs')" style="cursor: pointer;">
      <div style="text-align: center; padding-top:15px;"><img src="assets/jobs.png" style="height: 24px;"></div>
      <div style="text-align: center; padding-top:5px; padding-bottom:10px; color: black; font-size: 8pt;">Jobs</div>
    </div>
  </div>
  <div [hidden]="selectedIntegrationDetails$ && selectedIntegrationDetails$['connectionState'] === 'connected'">
    <div [hidden]="!selectedIntegrationDetails$ || !selectedIntegrationDetails$['integrationId']" style="position: relative; background: radial-gradient(gray 1px, gray 1%, transparent 95%);">
      <div style="text-align: center; padding-top:15px; height: 30px;"></div>
      <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); padding-bottom: 20px;" [hidden]="selectedIntegrationDetails$['connectionState'] === 'connected' || selectedIntegrationDetails$['connectionState'] === 'disconnected'">
        <fa-icon [icon]="spinnerIcon" style="margin: auto; color:green;" size="lg" [spin]="true"></fa-icon>
      </div>
      <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); padding-bottom: 20px;" [hidden]="selectedIntegrationDetails$['connectionState'] === 'unknown'">
        <mat-icon style="color:red">cloud_off</mat-icon>
      </div>
      <div style="text-align: center; padding-top:5px; padding-bottom:10px; color: black; font-size: 8pt;">Jobs</div>
    </div>
  </div>
  <div (click)="adapterIntegrationSchemasClicked()"  [hidden]="!selectedIntegrationDetails$ || !selectedIntegrationDetails$['integrationId']" [style.background-color]="getSelectedNavBackgroundColor('adapter-integration-schemas')" style="cursor: pointer;">
      <div style="text-align: center; padding-top:15px;"><img src="assets/schema.png" style="height: 36px;"></div>
      <div style="text-align: center; padding-top:5px; padding-bottom:10px; color: black; font-size: 8pt;">Schemas</div>
  </div>
  <div  [hidden]="selectedIntegrationDetails$ && selectedIntegrationDetails$['connectionState'] !== 'connected'">
    <div (click)="adapterIntegrationQueryToolClicked()"  [hidden]="!selectedIntegrationDetails$ || !selectedIntegrationDetails$['integrationId']" [style.background-color]="getSelectedNavBackgroundColor('adapter-integration-querytool')" style="cursor: pointer;">
      <div style="text-align: center; padding-top:15px;"><img src="assets/hql.png" style="height: 30px;"></div>
      <div style="text-align: center; padding-top:5px; padding-bottom:10px; color: black; font-size: 8pt;">Query Tool</div>
    </div>
  </div>
  <div [hidden]="selectedIntegrationDetails$ && selectedIntegrationDetails$['connectionState'] === 'connected'">
    <div [hidden]="!selectedIntegrationDetails$ || !selectedIntegrationDetails$['integrationId']" style="position: relative; background: radial-gradient(gray 1px, gray 1%, transparent 95%);">
      <div style="text-align: center; padding-top:15px; height: 30px;"></div>
      <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); padding-bottom: 20px;" [hidden]="selectedIntegrationDetails$['connectionState'] === 'connected' || selectedIntegrationDetails$['connectionState'] === 'disconnected'">
        <fa-icon [icon]="spinnerIcon" style="margin: auto; color:green;" size="lg" [spin]="true"></fa-icon>
      </div>
      <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); padding-bottom: 20px;" [hidden]="selectedIntegrationDetails$['connectionState'] === 'unknown'">
        <mat-icon style="color:red">cloud_off</mat-icon>
      </div>
      <div style="text-align: center; padding-top:5px; padding-bottom:10px; color: black; font-size: 8pt;">Query Tool</div>
    </div>
  </div>
  <div (click)="adapterAuditLogClicked()"  [hidden]="!selectedIntegrationDetails$ || !selectedIntegrationDetails$['integrationId']" [style.background-color]="getSelectedNavBackgroundColor('adapter-integration-audit-logs')" style="cursor: pointer;">
    <div style="text-align: center; padding-top:15px;"><img src="assets/logs.png" style="height: 32px;"></div>
    <div style="text-align: center; padding-top:5px; padding-bottom:15px; color: black; font-size: 8pt;">Audit Logs</div>
  </div>

  <div (click)="adapterErrorLogClicked()"  [hidden]="!selectedIntegrationDetails$ || !selectedIntegrationDetails$['integrationId']" [style.background-color]="getSelectedNavBackgroundColor('adapter-integration-logs')" style="cursor: pointer;">
    <div style="text-align: center; padding-top:15px;"><img src="assets/error-logs.png" style="height: 32px;"></div>
    <div style="text-align: center; padding-top:5px; padding-bottom:15px; color: black; font-size: 8pt;">Error Logs</div>
  </div>

  <div (click)="adapterQueueListenerClicked()"  [hidden]="!selectedIntegrationDetails$ || !selectedIntegrationDetails$['integrationId']" [style.background-color]="getSelectedNavBackgroundColor('adapter-integration-queue-listener')" style="cursor: pointer;">
    <div style="text-align: center; padding-top:15px;"><img src="assets/queue_message.png" style="height: 32px;"></div>
    <div style="text-align: center; padding-top:5px; padding-bottom:15px; color: black; font-size: 8pt;">Entity Feed</div>
  </div>
</nav>

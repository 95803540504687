<script src="https://apis.google.com/js/platform.js"></script>

<mat-toolbar>
  <div>Out of The Box Filter Management</div>
  <span class="spacer"></span>
  <div style="float:right; position: fixed; bottom: 50px; right: 2%; z-index: 100; display: flex; flex-direction: column-reverse; align-items: center;">
    <!--<button mat-fab color="primary" aria-label="Add User" (click)="addFilter()">
      <mat-icon>add</mat-icon>
    </button>-->
  </div>
</mat-toolbar>

<div style="width: 100%; position: relative;">

  <div [hidden]="!this.isConnected">

    <ngx-datatable
      #filtersTable
      class="material"
      [loadingIndicator]=loading
      [columnMode]="ColumnMode.flex"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="50"
      [rows]="filters$ | async"
      [limit]="10">

      <ngx-datatable-column name="Integration Type" [sortable]="false" [flexGrow]="5">
        <ng-template let-filter="row" ngx-datatable-cell-template>
          <strong>{{ filter.integrationType }}</strong>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Actions" [sortable]="false" [flexGrow]="1">
        <ng-template let-filter="row" ngx-datatable-cell-template>
          <a (click)="$event.stopPropagation(); filterClicked(filter)" style="color: blue; cursor: pointer;">
            <mat-icon matTooltip="View" style="color:royalblue">visibility</mat-icon>
          </a>
          <mat-icon style="color:gray">more_vert</mat-icon>
          <a (click)="$event.stopPropagation(); resetFilterClicked(filter)" style="color: blue; cursor: pointer;">
            <mat-icon matTooltip="Reload From Discovery Service" style="color:black">undo</mat-icon>
          </a>
        </ng-template>
      </ngx-datatable-column>

    </ngx-datatable>
  </div>

  <div [hidden]="this.isConnected" style="padding: 20px 20px 20px 20px; color: red; font-weight: bold; font-size: 13pt;">
    No connected Adapter detected - Page cannot be loaded...
  </div>

</div>




<ol class="breadcrumb">
    <li *ngFor="let breadcrumb of breadcrumbs">

          <span *ngIf="breadcrumb.isClickable" [routerLink]="breadcrumb.url" routerLinkActive="router-link-active"  >
            {{ breadcrumb.label }}
          </span>

      <span *ngIf="!breadcrumb.isClickable" style="color: lightgrey; cursor: unset;">
            {{ breadcrumb.label }}
          </span>

    </li>
</ol>

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalUiStateQuery} from '../../state/global-ui-state.query';

@Component({
  selector: 'app-adapter-details',
  templateUrl: './adapter-details.component.html',
  styleUrls: ['./adapter-details.component.scss']
})
export class AdapterDetailsComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalStateQuery: GlobalUiStateQuery
  ) { }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(async queryParams => {
      await this.parentReloadPage();
    });

  }

  async parentReloadPage(): Promise<void> {
    const selectedAdapterDetailsNavPage = this.globalStateQuery.getSelectedAdapterDetailsNavPage() ?? 'adapter-details-health';
    if (selectedAdapterDetailsNavPage === "adapter-details-health") {
      await this.router.navigate(['/adapters/adapter-details/adapter-details-health']);
    } else if (selectedAdapterDetailsNavPage === "adapter-details-config") {
      await this.router.navigate(['/adapters/adapter-details/adapter-details-config']);
    } else if (selectedAdapterDetailsNavPage === "adapter-audit-log") {
      await this.router.navigate(['/adapters/adapter-details/adapter-audit-log']);
    }else if (selectedAdapterDetailsNavPage === "adapter-error-logs") {
      await this.router.navigate(['/adapters/adapter-details/adapter-details-logs']);
    }

    else {
      await this.router.navigate(['/adapters/adapter-details/adapter-details-integrations']);
    }

  }

}

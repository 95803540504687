import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {APIService} from '../../../../API.service';
import {AdaptersQuery} from '../../../state/adapters.query';
import {IPService} from '../../../../services/ip.service';
import {ToastrService} from 'ngx-toastr';
import {GlobalUiStateService} from '../../../../state/global-ui-state.service';
import {GlobalUiStateQuery} from '../../../../state/global-ui-state.query';
import {NgxSpinnerService} from 'ngx-spinner';
import {AdapterService} from '../../../../services/adapter.service';

@Component({
  selector: 'app-loglevel-dialog',
  templateUrl: './loglevel-dialog.component.html',
  styleUrls: ['./loglevel-dialog.component.scss']
})
export class LoglevelDialogComponent implements OnInit {

  form: UntypedFormGroup;
  adapterEndpoint: string;
  title: string;
  logLevel: string;
  duration: string;
  errorMessage: string;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<LoglevelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private api: APIService,
    private adaptersQuery: AdaptersQuery,
    private ipService: IPService,
    private toastr: ToastrService,
    private globalStateService: GlobalUiStateService,
    public globalStateQuery: GlobalUiStateQuery,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private adapterService: AdapterService)
  {
    this.adapterEndpoint = data.adapterEndpoint;
    this.errorMessage = data.errorMessage;
    this.title = data.title;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      duration: [this.duration, Validators.required],
      logLevel: [this.logLevel, [Validators.required]],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showToaster(message, statusCode){
    statusCode === 0 ? this.toastr.success(message) : this.toastr.error(message);
  }

  async setLogLevel(): Promise<void> {
    await this.spinner.show();
    this.adapterService.setLogLevel(this.adapterEndpoint, this.form.controls["logLevel"].value, this.form.controls["duration"].value).subscribe(
      result => {
        this.showToaster("Set Log Level call succeeded", 0);
        this.dialogRef.close(this.form.controls["logLevel"].value);
      },
      error => {
        console.log(error);
        this.errorMessage = 'Error calling setLogLevel on the Adapter Integration API Endpoint';
        this.showToaster("Set Log Level call failed. Status code: " + error.status, 1);
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  get f(){
    return this.form.controls;
  }
}

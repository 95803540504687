import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { AdapterModel } from '../../state/adapter.model';
import { Auth } from 'aws-amplify';
import { JWTInfoDialogComponent } from '../jwtinfo-dialog/jwtinfo-dialog.component';
import { ConnectionProfile } from '../../../models/connection-profile';
import jwt_decode from 'jwt-decode';
import { GlobalUiStateQuery } from '../../../state/global-ui-state.query';
import { GlobalUiStateService } from '../../../state/global-ui-state.service';
import { IPService } from '../../../services/ip.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { AdaptersQuery } from '../../state/adapters.query';
import { ToastrService } from 'ngx-toastr';
import { APIService } from '../../../API.service';
import { AdaptersService } from '../../state/adapters.service';
import { AdapterService as AdapterSvc } from '../../../services/adapter.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgFormsManager } from '@ngneat/forms-manager';
import {DialogBoxComponent} from '../../../dialog-box/dialog-box.component';
import {interval, Subscription} from 'rxjs';
import {AlertDialogBoxComponent} from '../../../alert-dialog-box/alert-dialog-box.component';

@Component({
  selector: 'app-adapter-card',
  templateUrl: './adapter-card.component.html',
  styleUrls: ['./adapter-card.component.scss']
})

export class AdapterCardComponent implements OnInit, OnDestroy {

  @Input() adapter: AdapterModel;
  @Output() parentReloadPage: EventEmitter<any> = new EventEmitter();
  isConnected: boolean;
  isWorkerConnected: boolean;
  isLoggedIn: boolean;
  adapterEndpointForDisplay: String;
  adapterNameForDisplay: String;
  isDev: boolean;
  jwtDialogShown: boolean;
  adapterConnectionDetails:any

  private subscription: Subscription;

  constructor(
    private formsManager: NgFormsManager,
    private builder: UntypedFormBuilder,
    private api: APIService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private adaptersQuery: AdaptersQuery,
    private adapterService: AdaptersService,
    private adapterSvc: AdapterSvc,
    private spinner: NgxSpinnerService,
    public globalStateQuery: GlobalUiStateQuery,
    private globalStateService: GlobalUiStateService,
    private ipService: IPService,
    public matDialog: MatDialog,
    private ref: ChangeDetectorRef
  ) { }

  async ngOnInit(): Promise<void> {

    this.adapterConnectionDetails = this.globalStateQuery.getCachedAdapterConnectionDetails()[this.adapter.id];
    this.isDev = this.globalStateQuery.getSelectedEnvironment() == 'dev';

    if (this.adapterConnectionDetails?.jwtToken) {
      this.isLoggedIn = true;
    }

    await this.spinner.show(this.adapter.id);

    this.adapterEndpointForDisplay = this.adapter.endpoint.length > 25 ? this.adapter.endpoint.substring(0,25) + " ..." : this.adapter.endpoint
    this.adapterNameForDisplay = this.adapter.name.length > 20 ? this.adapter.name.substring(0,20) + " ..." : this.adapter.name

    this.startTimer();

    //document.documentElement.style.setProperty('--width', '70%');

  }

  ngOnDestroy() {
    this.stopTimer();
  }

  async adapterClicked(adapter: AdapterModel,endPoint:string): Promise<void> {

    if (this.jwtDialogShown)
      return;

    this.jwtDialogShown = true;

    var data= {};
    var ep = endPoint == undefined ?adapter.endpoint:endPoint;

    try {
      if (!this.isConnected)  {
        await this.spinner.show(this.adapter.id);
      }

      const result = await this.adapterSvc.getAdapterMetadata(ep).toPromise();

      data = JSON.parse(JSON.stringify(result.body));
      // console.log(result);
      if (data['adapterType'] && data['adapterType'].toLowerCase() === this.adapter.adapterType.toLowerCase()) {
        if (!this.isConnected)  {
          await this.spinner.hide(this.adapter.id);
        }
        this.isConnected = true;
      }
      else {
        await this.spinner.show(this.adapter.id);
        this.isConnected = false;
        this.isLoggedIn = false;
        await this.delay(1500);
        await this.spinner.hide(this.adapter.id);
        this.ref.detectChanges();
        this.showToaster('Adapter connection failed.', 1);
        return;
      }
    } catch(e)  {
      this.isConnected = false;
      this.isLoggedIn = false;
      await this.delay(1500);
      await this.spinner.hide(this.adapter.id);
      this.showToaster('Adapter connection failed.', 1);
      this.ref.detectChanges();
      return;
    }

    this.ref.detectChanges();

    this.globalStateService.updateSelectedAdapterDetails(
      { adapterId: adapter.id, adapterType: adapter.adapterType, name: adapter.name, endpoint: ep, auditTtl:data["auditLogTtl"] });

    const adapterConnectionDetails = this.globalStateQuery.getCachedAdapterConnectionDetails()[adapter.id];
    if (adapterConnectionDetails?.jwtToken) {
      if (!this.checkValidToken(adapterConnectionDetails.jwtToken)) {
        try
        {
          if (adapterConnectionDetails.connectionProfileId == "")
          {
            this.isLoggedIn = false;
            this.globalStateService.updateCachedAdapterConnectionDetails(adapter.id, '', '');
            await this.displayLoginDialog("The JWT used to login to this adapter is either invalid or expired ...", adapter);
            return;
          } else {
            const connectionProfile = await this.api.GetConnectionProfile(adapterConnectionDetails.connectionProfileId);

            const jwtToken = await this.getJWTToken(adapter.id, ep, connectionProfile as ConnectionProfile, false);
            if (!jwtToken){
              console.log("Unable to retrieve a jwt from Toolkit")
              this.isLoggedIn = false;
              await this.displayLoginDialog("The JWT used to login to this adapter is either invalid or expired ...", adapter);
              return;
            }

            this.globalStateService.updateCachedAdapterConnectionDetails(adapter.id, jwtToken, connectionProfile.id);
          }
        } catch (e) {
          console.log(e.message);
          this.isLoggedIn = false;
          await this.displayLoginDialog("The JWT used to login to this adapter is either invalid or expired ...", adapter);
          return;
        }
      }
      this.isLoggedIn = true;
      this.globalStateService.updateSelectedAdapterDetailsNavPage('');
      this.router.navigate(['adapter-details'], {relativeTo: this.activatedRoute}).then( status => {
          this.spinner.hide();
        }
      );
    } else {
      await this.displayLoginDialog("", adapter);
    }
  }

  async displayLoginDialog(errorMessage: string, adapter: AdapterModel)  {
    Auth.currentAuthenticatedUser({
      bypassCache: false
    }).then(async user => {

      let userEnvPrefix = "";
      const userList = await this.api.ListUsers({ emailAddress: { eq: user.attributes.email}});
      if (userList) {
        const fullUser = await this.api.GetUser(userList.items[0].id);
        userEnvPrefix = fullUser.userDetail.envPrefix;
        const connectionProfileList = [];
        fullUser.userDetail.connectionProfiles.items.forEach(profile => {
          connectionProfileList.push({id: profile.id, name: profile.name, envPrefix:
              this.globalStateQuery.getSelectedImpersonatedEnvPrefix() ? this.globalStateQuery.getSelectedImpersonatedEnvPrefix() :
                profile.envPrefix, adapterId: profile.adapterId});
        });
        this.globalStateService.updateConnectionProfiles(connectionProfileList.sort((a, b) => {
          return a.name.toUpperCase().localeCompare(b.name.toUpperCase())
        }));
      }
      const emailAddress = user.attributes.email;
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      console.log(this.globalStateQuery.getConnectionProfiles());

      dialogConfig.data = {
        adapter: adapter,
        title: 'Login With A Connection Profile / JWT',
        authKey: '',
        userEmailAddress: emailAddress,
        userEnvPrefix: userEnvPrefix,
        ipUrl: '',
        cpList: this.globalStateQuery.getConnectionProfiles(),
        errorMessage: errorMessage
      };

      const confirmDialog = this.matDialog.open(JWTInfoDialogComponent, dialogConfig);

      confirmDialog.afterClosed().subscribe(async data => {

        this.jwtDialogShown = false;

        if (data === undefined) {
          return;
        }

        await this.spinner.show();

        this.isLoggedIn = true;

        this.router.navigate(['adapter-details'], {relativeTo: this.activatedRoute}).then(async status => {
            await this.spinner.hide();
          }
        );

      });

    });
  }

  async updateConnectionProfile(adapterId: string, connectionProfile: ConnectionProfile): Promise<void>
  {
    Auth.currentAuthenticatedUser({
      bypassCache: false
    }).then(async user => {
      const emailAddress = user.attributes.email;
      const userList = await this.api.ListUsers({ emailAddress: { eq: emailAddress}});

      await this.api.UpdateConnectionProfile({
        id: connectionProfile.id,
        accountId: connectionProfile.accountId,
        audience: connectionProfile.audience,
        authKey: connectionProfile.authKey,
        connectionProfileUserDetailId: userList.items[0].userDetail.id,
        environment: connectionProfile.environment,
        adapterId: adapterId,
        envPrefix: this.globalStateQuery.getSelectedEnvironment(),
        ipUrl: connectionProfile.ipUrl,
        isRegistered: connectionProfile.isRegistered,
        jwtToken: connectionProfile.jwtToken,
        purpose: connectionProfile.purpose,
        userName: connectionProfile.userName
      });
    })
      .catch(err => console.log(err));
  }


  async getJWTToken(adapterId: string, endpoint: string, connectionProfile: ConnectionProfile, isNewCredential: Boolean): Promise<string> {

    let jwtToken = '';
    await this.ipService.getJWTToken(endpoint, connectionProfile, isNewCredential).toPromise().then(result => {
      jwtToken = result.body['Token']
    }).catch(error => {
      console.log(error);
      this.toastr.error('An error occurred getting a access token. ' + error['status'] + ' : ' + error['error'], 'Error!', {
        closeButton: true
      });
    });
    return jwtToken;
  }

  checkValidToken(token: string){
    console.log('checking token for expiry');
    if (!token) {
      return false;
    }

    const decodedToken = jwt_decode(token);
    // @ts-ignore
    if (decodedToken.exp * 1000 < Date.now())
    {
      console.log('Token has Expired.');
      return false;
    }
    return true;
  }

  async initializeAdapter(adapterId: string, endpoint: string) {

    console.log(`Initializing Adapter: ${adapterId}`);
    try {

      const result = await this.adapterSvc.getAdapterMetadata(endpoint).toPromise();

      if (result.body['adapterType'] && result.body['adapterType'].toLowerCase() === this.adapter.adapterType.toLowerCase()) {
        if (this.isConnected == false) {
          await this.spinner.show(this.adapter.id);
          await this.delay(1500);
        }
        this.isConnected = true;
      } else {
        if (this.isConnected == true) {
          await this.spinner.show(this.adapter.id);
          await this.delay(1500);
        }
        this.isConnected = false;
        this.isLoggedIn = false;
        this.globalStateService.updateCachedAdapterConnectionDetails(adapterId, '', '');
        this.ref.detectChanges();
      }
      await this.spinner.hide(this.adapter.id);
    } catch (ex) {
      if (this.isConnected == true) {
        await this.spinner.show(this.adapter.id);
        await this.delay(1500);
      }
      this.isConnected = false;
      this.isLoggedIn = false;
      this.globalStateService.updateCachedAdapterConnectionDetails(adapterId, '', '');
      await this.spinner.hide(this.adapter.id);
      this.ref.detectChanges();
    }

    try{
      this.isWorkerConnected = false;
      if( this.adapter.localPortForwardPort != null &&  this.adapter.localPortForwardPort > 0){
        const workerResult = await this.adapterSvc.getAdapterMetadata("http://localhost:" +  this.adapter.localPortForwardPort ).toPromise();
        if(workerResult.status == 200 )
        {
          if (workerResult.body['adapterType'] && workerResult.body['adapterType'].toLowerCase() === this.adapter.adapterType.toLowerCase()) {
            this.isWorkerConnected = true;
          }
        }
      }


    }catch (e) {

    }

  }

  private delay(ms: number)
  {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async editAdapter(adapter: AdapterModel): Promise<void> {

    console.log(JSON.stringify(adapter));

    this.formsManager.clear('adapter');

    const adapterForm = this.builder.group({
      id: [adapter.id],
      name: [adapter.name, Validators.required],
      adapterType: [adapter.adapterType, Validators.required],
      envPrefix: [adapter.envPrefix, Validators.required],
      endpoint: [adapter.endpoint],
      enabled: [adapter.enabled],
      lastSuccessfulPing: [adapter.lastSuccessfulPing],
      localPortForwardPort: [adapter.localPortForwardPort]
    });

    this.formsManager.upsert('adapter', adapterForm, {
      persistState: true
    });

    await this.router.navigate(['/adapters/adapter-maintenance']);

  }

  LogoutOfAdapter(adapter) {
    this.isLoggedIn = false;
    this.globalStateService.updateCachedAdapterConnectionDetails(adapter.id, '', '');
  }

  deleteAdapter(action, obj) {

    const adapter = { ...obj };
    adapter.action = action;

    if (this.globalStateQuery.getSelectedEnvironment() !== 'dev' && (!this.globalStateQuery.getCachedUser() || (this.globalStateQuery.getCachedUser() && this.globalStateQuery.getCachedUser().role !== 'admin'))) {
      const adminDialogRef = this.matDialog.open(AlertDialogBoxComponent, {
        width: '250px',
        data:{
          message: 'Users can only delete their own Dev Adapters. All other Adapters can only be deleted by a Toolkit UI administrator.',
          buttonText: {
            cancel: 'Ok'
          },
          title: 'Adapter'
        },
      });

      return;
    }

    const dialogRef = this.matDialog.open(DialogBoxComponent, {
      width: '250px',
      data: {
        dataObj: adapter,
        title: 'Adapter',
        message: 'Are you sure you want to delete Adapter  ' + adapter.name
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result.event === 'Delete') {
        await this.spinner.show();

        this.api.DeleteAdapter({id: adapter.id}).then(async value => {
          await this.adapterService.getAdapters();
          await this.spinner.hide();
          this.showToaster('Adapter ' + adapter.name + ' deleted.', 0);
          this.parentReloadPage.emit();
        }).catch(async error => {

          console.log(error.errors[0].message);
          await this.spinner.hide();
          if (error.errors[0].message.indexOf("Status Code: 400") > -1) {
            this.showToaster('Delete call failed. User does not have permission to perform this operation', 1);
          } else {
            this.showToaster('Delete call failed. Message: ' + error.errors[0].message, 1);
          }
          await this.spinner.hide();
        });
      }
    });
  }

  showToaster(message, statusCode) {
    statusCode === 0 ? this.toastr.success(message) : this.toastr.error(message);
  }

  async startTimer() {

    await this.initializeAdapter(this.adapter.id, this.adapter.endpoint);
    this.ref.detectChanges();
    this.subscription = interval(30000)
      .subscribe(async x => {
        await this.initializeAdapter(this.adapter.id, this.adapter.endpoint);
        this.ref.detectChanges();
      });

  }

  stopTimer()
  {
    if (this.subscription)  {
      this.subscription.unsubscribe();
    }
  }


  async connectWorkerAdapter(adapter: AdapterModel): Promise<void> {
    var test = "http://localhost:" + (adapter.localPortForwardPort == undefined ? 9090 : adapter.localPortForwardPort );
    console.log(test);
    await this.adapterClicked(adapter, "http://localhost:" + (adapter.localPortForwardPort == undefined ? 9090 : adapter.localPortForwardPort ) );
  }
}

import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AdminUiUsersStore } from './admin-ui-users.store';
import { AdminUiUserModel, AdminUiUsersState } from './admin-ui-user.model';

@Injectable({ providedIn: 'root' })
export class AdminUiUsersQuery extends QueryEntity<AdminUiUsersState, AdminUiUserModel> {

  constructor(protected store: AdminUiUsersStore) {
    super(store);
  }

}

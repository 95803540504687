import { Injectable } from '@angular/core';
import {EntityState, EntityStore, EntityUIStore, StoreConfig} from '@datorama/akita';
import {AuditLogModel, AuditLogState} from './audit-log.model';

export type AuditLogUI = {
};

export interface AuditLogUiState extends EntityState<AuditLogUI> {}

const initialState = {
  ui: {
  }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'audit-log' })
export class AuditLogStore extends EntityStore<AuditLogState, AuditLogModel> {

  ui: EntityUIStore<AuditLogUiState>;

  constructor() {
    super(initialState);
  }

  updateUI( ui: AuditLogUiState['ui'] ) {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui
      }
    }));
  }

}

import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {SplitAreaDirective, SplitComponent} from 'angular-split';
import {QueryService} from '../../../services/query-service';
import {IntegrationDetails} from '../../../models/integration-details';
import {GlobalUiStateQuery} from '../../../state/global-ui-state.query';
import {NgxSpinnerService} from 'ngx-spinner';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {QueryBuilderDialogComponent} from './query-builder-dialog/query-builder-dialog.component';
import {IntegrationSchema} from '../../../models/integration-schema';
import {SchemaService} from '../../../services/schema.service';
import {ToastrService} from 'ngx-toastr';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-adapter-integration-querytool',
  templateUrl: './adapter-integration-querytool.component.html',
  styleUrls: ['./adapter-integration-querytool.component.scss']
})
export class AdapterIntegrationQuerytoolComponent implements OnInit {

  public editData: any;
  public data: any;
  private schemaLoaded: boolean;

  entities: any[] = [];
  sizes = {
    percent: {
      area1: 30,
      area2: 70,
    },
    pixel: {
      area1: 120,
      area2: '*',
      area3: 160,
    },
  }
  selectedAdapterDetails$: Object;
  selectedIntegrationDetails$: IntegrationDetails;
  form: UntypedFormGroup;
  selectedEntities: any[] = [];
  filteredSelectedEntities: any[] = [];
  selectedEntitiesFilterValue: string;

  query: object;
  queryResult: object;

  @ViewChild('split') split: SplitComponent
  @ViewChild('area1') area1: SplitAreaDirective
  @ViewChild('area2') area2: SplitAreaDirective

  constructor( private formBuilder: UntypedFormBuilder,
               private queryService:QueryService,
               private globalStateQuery: GlobalUiStateQuery,
               private spinner: NgxSpinnerService,
               private dialog: MatDialog,
               private service: SchemaService,
               private toastr: ToastrService,) {

    this.data = {};
    this.queryResult = {};
    this.query = {};

  }

  ngOnInit(): void {
    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();

    const group: any = {};
    group['environment'] =  new UntypedFormControl('', Validators.required);
    group['accountId'] =  new UntypedFormControl('', Validators.required);
    this.schemaLoaded = false;

    this.loadShallowSchemaEntityList(true).then(async schemaData => {
      this.selectedEntities = JSON.parse(schemaData);
    });

    this.form = this.formBuilder.group({
      rawQuery: [JSON.stringify(this.query, undefined, 2), []]
    });
  }

  async performQuery() {

    if (this.form.controls["rawQuery"].value == null || this.form.controls["rawQuery"].value == "{}" || this.form.controls["rawQuery"].value == "") {
      return;
    }

    try {
      this.query = JSON.parse(this.form.controls["rawQuery"].value);
    } catch (e) {
      this.showToaster('Query parsing failed - invalid json!', 1);
      return;
    }

    await this.spinner.show();

    console.log('Query ' + JSON.stringify(this.query));
    this.queryService.executeQuery(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, JSON.stringify(this.query)).subscribe(
      async result => {
        console.log(JSON.parse(JSON.stringify(result.body)));
        this.queryResult = JSON.parse(JSON.stringify(result.body));
        await this.spinner.hide();
      },
      async error => {
        if (error.name === "TimeoutError") {
          this.showToaster('Update Integration call failed. Call Timed out!', 1);
        } else {
          this.queryResult = JSON.parse(JSON.stringify(error.error["errors"]));
        }
        await this.spinner.hide();
      },
      async () => {
        await this.spinner.hide();
      }
    )

  }

  showToaster(message, statusCode){
    statusCode === 0 ? this.toastr.success(message) : this.toastr.error(message);
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  clearQuery() {
    this.query = {};
    this.queryResult = {};
    this.form = this.formBuilder.group({
      rawQuery: [JSON.stringify(this.query, undefined, 2), []]
    });
  }

  dragEnd(unit, { sizes }) {
    if (unit === 'percent') {
      this.sizes.percent.area1 = sizes[0]
      this.sizes.percent.area2 = sizes[1]
    } else if (unit === 'pixel') {
      this.sizes.pixel.area1 = sizes[0]
      this.sizes.pixel.area2 = sizes[1]
      this.sizes.pixel.area3 = sizes[2]
    }
  }

  buildQuery() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      entities: this.selectedEntities,
      query: this.query
    }

    this.dialog.open(QueryBuilderDialogComponent, dialogConfig).afterClosed()
      .subscribe(
        data => {
          if (data === undefined) {
            return;
          }
          this.query = JSON.parse(data);
          this.form = this.formBuilder.group({
            rawQuery: [JSON.stringify(this.query, undefined, 2), []]
          });
        }
      );

  }

  async loadShallowSchemaEntityList(selected): Promise<string> {

    let data = '{"entities": []}';
    try {
      const result = await this.service.getShallowSchemaEntitiesAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, selected).toPromise();
      data = JSON.stringify(result.body, undefined, 4);
    } catch(ex) {

    } finally {
      if(selected)  {
        await this.spinner.hide("selectedEntities");
      } else {
        await this.spinner.hide("deselectedEntities");
      }

    }

    return data;
  }

}

<script src="https://apis.google.com/js/platform.js"></script>

<mat-toolbar>
  <span [hidden]="environmentForm.value.id"> Add Rails Environment</span>
  <span [hidden]="!environmentForm.value.id"> Edit Rails Environment</span>
  <span class="spacer"></span>
  <div style="float:right; position: fixed; bottom: 50px; right: 2%; z-index: 100; display: flex; flex-direction: column-reverse; align-items: center;">
    <button mat-fab color="primary" aria-label="Cancel" (click)="onCancel()">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</mat-toolbar>

<div style="padding: 20px; width: 100%;">

  <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading" style="color: white; font-weight: bold;">Saving Environment ...</p>
  </ngx-spinner>

  <form [formGroup]="environmentForm">

    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput
             formControlName="name">
    </mat-form-field>

    <div style="height: 10px"></div>

    <mat-form-field>
      <mat-label>Url</mat-label>
      <input matInput
             formControlName="url">
    </mat-form-field>

    <div style="height: 10px"></div>

    <mat-form-field>
      <mat-label>Reference</mat-label>
      <input matInput
             formControlName="reference">
    </mat-form-field>

    <div style="height: 10px"></div>

  </form>

  <div style="text-align: left; margin: auto">
    <button mat-button (click)="upsertEnvironment()" [disabled]="!environmentForm.valid" style="margin-right: 10px;" class="actionButton">Save</button>
    <button mat-button (click)="onCancel()" class="actionButton">Cancel</button>
  </div>

</div>






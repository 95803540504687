<div class="flex-grid" style="justify-content: center; height: 100%;">
  <div style="display: flex; flex-direction: column; justify-content: center;text-align: center;">
    <img mat-card-image src="assets/full_logo_small.png" style="height: 70px; position: absolute; top: 42%; left: 52%; z-index: 1000;">
    <img mat-card-image src="assets/bg3b.png" style="background-size: cover; background-position:center; height: 300px;">

    <div style="font-size: 14pt; width: 150px; position: absolute; top: 48%; left: 59%; font-weight: bold;">Toolkit Admin</div>

    <div style="font-size: 14pt; padding-top: 15px; padding-bottom: 15px;">Please login with your Introhive Google account.</div>

    <div [hidden]="!statusMessage" style="color: red; font-weight: bold; padding-top: 15px;">{{statusMessage}}</div>

    <div style="text-align: center;">
      <button mat-button (click)="doFederatedLogin()" class="loginButton">Login With Google</button>
    </div>
  </div>
</div>

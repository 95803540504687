import { Injectable } from '@angular/core';
import {EntityState, EntityStore, EntityUIStore, StoreConfig} from '@datorama/akita';
import { IntegrationTypeModel, IntegrationTypesState } from './integration-type.model';

export type IntegrationTypesUI = {
};

export interface IntegrationTypesUIState extends EntityState<IntegrationTypesUI> {}

const initialState = {
  ui: {
  }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'integrationTypes' })
export class IntegrationTypesStore extends EntityStore<IntegrationTypesState, IntegrationTypeModel> {

  ui: EntityUIStore<IntegrationTypesUIState>;

  constructor() {
    super(initialState);
  }

  updateUI( ui: IntegrationTypesUIState['ui'] ) {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui
      }
    }));
  }

}

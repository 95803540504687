<div style="position: relative; padding: 20px;" >

  <div style="color: red; padding-bottom: 10px; padding-right: 10px; padding-left: 10px; width: 250px;" [hidden]="errorMessage === null || errorMessage === ''">{{errorMessage}}</div>

  <h2 mat-dialog-title>{{title}}</h2>

  <mat-dialog-content [formGroup]="form">

    <div style="height:10px;"></div>

    {{message}}<br/>

    <div style="height:10px;"></div>

    <mat-checkbox formControlName="preserveNextRunDate" [hidden]="jobType === 'HealthCheck'">Preserve Next Fetch Start Date</mat-checkbox>

    <div style="height:10px;"></div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">
      Cancel
    </button>
    <div style="width: 10px;"></div>
    <button mat-button class="mat-dialog-button" [disabled]="!form.valid" (click)="onYesClick()">
      Reset
    </button>
  </mat-dialog-actions>

</div>

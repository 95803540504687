import {Component, HostListener, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {APIService} from '../../API.service';
import {NgFormsManager} from '@ngneat/forms-manager';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Order} from '@datorama/akita';
import {DialogBoxComponent} from '../../dialog-box/dialog-box.component';
import {FilterModel} from './state/filter.model';
import {FiltersQuery} from './state/filters.query';
import {FiltersService} from './state/filters.service';
import {AdminUiUserModel} from '../../security/admin-ui-users/state/admin-ui-user.model';
import {GlobalUiStateQuery} from '../../state/global-ui-state.query';
import {AdapterService as AdapterSvc} from '../../services/adapter.service';
import {GlobalUiStateService} from '../../state/global-ui-state.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import {FiltersStore} from './state/filters.store';
import {AdaptersService} from '../../adapters/state/adapters.service';
import {HealthCheckService} from '../../services/health-check.service';

@Component({
  selector: 'app-config-filters',
  templateUrl: './config-filters.component.html',
  styleUrls: ['./config-filters.component.scss']
})

export class ConfigFiltersComponent implements OnInit {

  filters$?: Observable<FilterModel[]>;
  isEdit = false;
  environment: AdminUiUserModel = new AdminUiUserModel();
  isConnected = true;
  loading = true;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private api: APIService,
    private adaptersService: AdaptersService,
    private formsManager: NgFormsManager,
    private builder: UntypedFormBuilder,
    private filtersQuery: FiltersQuery,
    private filtersService: FiltersService,
    private adapterSvc: AdapterSvc,
    public globalStateQuery: GlobalUiStateQuery,
    private globalStateService: GlobalUiStateService,
    private dialog: MatDialog,
    private filtersStore: FiltersStore,
    private healthCheckService: HealthCheckService,
    private spinner: NgxSpinnerService
  ) { }

  ColumnMode = ColumnMode;

  async ngOnInit(): Promise<void> {
    this.filtersStore.set([]);
    await this.loadAdapterAndJwt().then(
      async r => {
        if (r == true) {
          this.filters$ = this.filtersQuery.selectAll({sortBy: 'integrationType', sortByOrder: Order.ASC});
          this.isConnected = true;
          await this.filtersService.getFilters(this.globalStateQuery.getCachedDirectCallAdapter().endpoint);
        } else {
          this.isConnected = false;
        }
        setTimeout(() => {
          this.loading = false;
        }, 1500);
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

  }

  async addFilter(): Promise<void> {
    this.formsManager.clear('filter');
    await this.router.navigate(['/config/add-edit-filter']);
  }

  cancelEdit(): void {

  }

  async loadAdapterAndJwt(): Promise<boolean> {

    if (this.globalStateQuery.getCachedDirectCallAdapter()) {
      if ((await this.testConnection(this.globalStateQuery.getCachedDirectCallAdapter())) == true)
      {
        return true;
      }
    }

    let adapters = await this.adaptersService.getAdapters();

    for (const adapter of adapters) {
      // now check to see if there is a jwt available for one of the adapters
      const connDetails = this.globalStateQuery.getCachedAdapterConnectionDetails()[adapter.id];
      if (connDetails != null && connDetails.jwtToken) {
        // now check the jwtToken against the adapter to see if it's valid
        if ((await this.testConnection({ id: adapter.id, adapterType: adapter.adapterType, endpoint: adapter.endpoint, jwtToken: connDetails.jwtToken })) == true)
        {
          return true;
        }
      }
    }

    this.toastr.error(`For this page to actively retrieve data, at least one Adapter must be connected with valid/unexpired credentials.`, 'Error!', {
      closeButton: false,
      timeOut: 5000
    });
    return false;

  }

  async testConnection(adapter) : Promise<boolean> {
    try {
      if (this.globalStateQuery.getCachedAdapterConnectionDetails()[adapter.id] == null)  {
        return false;
      }
      this.globalStateService.updateCachedDirectCallAdapter({adapterType: adapter['adapterType'], endpoint: adapter['endpoint'], jwtToken: adapter['jwtToken']});
      const result = await this.healthCheckService.checkHealthForDirectCall({endpoint: adapter['endpoint']}).toPromise();
      if (result.status == 200) {
          return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e.message);
      return false;
    }
  }

  async filterClicked(filter: FilterModel): Promise<void> {

    this.formsManager.clear('filter');

    const filterForm = this.builder.group({
      integrationType: [filter.integrationType, Validators.required],
      rawFilteredEntities: [filter.filteredEntities, Validators.required]
    });

    this.formsManager.upsert('filter', filterForm, {
      persistState: true
    });

    await this.router.navigate(['/config/add-edit-filter']);

  }

  async resetFilterClicked(obj): Promise<void> {

    const filter = { ...obj };
    filter.action = "Reset";

    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '250px',
      data: {
        dataObj: filter,
        btn1Action: "Reset",
        title: 'Filter',
        message: 'Are you sure you want to reset the default out of the box filter from Discovery Service for integration:  ' + filter.integrationType
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result.event === 'Reset') {
        this.loading = true;
        console.log('Reset ' + JSON.stringify(result.data));
        this.filtersStore.set([]);
        await this.filtersService.resetSelectedFilter(result.data.dataObj.integrationType, this.globalStateQuery.getCachedDirectCallAdapter().endpoint);
        await this.filtersService.getFilters(this.globalStateQuery.getCachedDirectCallAdapter().endpoint);
        this.loading = false;
      }
    });

  }

  showToaster(message, statusCode) {
    statusCode === 0 ? this.toastr.success(message) : this.toastr.error(message);
  }

}


import {HostListener, Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdapterModel } from '../state/adapter.model';
import { AdaptersQuery } from '../state/adapters.query';
import { AdaptersService } from '../state/adapters.service';
import {GlobalUiStateQuery} from '../../state/global-ui-state.query';
import { NgFormsManager } from '@ngneat/forms-manager';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {APIService} from '../../API.service';
import {GlobalConfig, ToastrService} from 'ngx-toastr';
import {CustomToastComponent} from '../../components/custom-toast/custom-toast.component';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'app-adapters',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './adapters.component.html',
  styleUrls: ['./adapters.component.scss']
})
export class AdaptersComponent implements OnInit {

  constructor(
    private api: APIService,
    private formsManager: NgFormsManager,
    private builder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private adaptersQuery: AdaptersQuery,
    private adapterService: AdaptersService,
    public globalStateQuery: GlobalUiStateQuery,
    private chRef: ChangeDetectorRef,
    private toastr: ToastrService) {
    this.options = this.toastr.toastrConfig;
  }

  numCols = 0;
  colPadding = '10px';
  width = 0;
  remainder = 0;
  emailAddress: string;
  loading: boolean;

  adapters$?: AdapterModel[];
  filteredResultList$?: AdapterModel[];
  searchCriteria: string;
  options: GlobalConfig;

  async ngOnInit(): Promise<void> {
    this.calcSizes();
    this.searchCriteria = "";

    await this.loadAdapters();

    this.activatedRoute.queryParams.subscribe(async queryParams => {
      await this.parentReloadPage();
    });

  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.calcSizes();
  }

  async loadAdapters(): Promise<void> {

    this.loading = true;

    const opt = cloneDeep(this.options);
    opt.toastComponent = CustomToastComponent;
    opt.toastClass = 'info';
    opt.closeButton = false,
    opt.positionClass = 'toast-center-center',
    opt.iconClasses = 'info'
    this.toastr.show("Loading Adapters ...", "", opt);

    this.adapters$ = await this.adapterService.getAdapters();
    this.filteredResultList$ = this.adapters$.sort((a, b) => {
      return a.name.toUpperCase().localeCompare(b.name.toUpperCase())
    });

    this.toastr.clear();

    this.loading = false;
  }
  calcSizes(): void
  {
    this.numCols = Math.floor((window.innerWidth - 100) / 165);
    this.remainder = window.innerWidth - 100 - (this.numCols * 165);
    if (this.remainder < this.numCols * 10)
    {
      this.numCols = this.numCols - 1;
      this.remainder = window.innerWidth - 100 - (this.numCols * 165);
    }
    this.colPadding = (Math.ceil(this.remainder / (this.numCols - 1))).toString() + "px";
    this.width = window.innerWidth;
  }

  onSearchChange(searchValue: string): void {
    this.searchCriteria = searchValue;
    if ( searchValue.length === 0 ) {
      this.filteredResultList$ =  this.adapters$.sort((a, b) => {
        return a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      });
    }
    else {
      this.filteredResultList$ = this.adapters$.filter(x => { console.log(x.name); return (x.name.toUpperCase().includes(searchValue.toUpperCase()) ||
        x.id === searchValue || x.adapterType ===  searchValue); }).sort((a, b) => {
        return a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      });
    }
  }

  clearSearch(): void {
    this.filteredResultList$ =  this.adapters$.sort((a, b) => {
      return a.name.toUpperCase().localeCompare(b.name.toUpperCase())
    });
    this.searchCriteria = "";
  }

  async addAdapter(): Promise<void> {

    this.formsManager.clear('adapter');

    const adapterForm = this.builder.group({
      id: [''],
      name: [null, Validators.required],
      adapterType: [null, Validators.required],
      endpoint: [null],
      enabled: [null, false]
    });

    this.formsManager.upsert('adapter', adapterForm, {
      persistState: true
    });

    await this.router.navigate(['/adapters/adapter-maintenance']);

  }

  async parentReloadPage(): Promise<void> {
    await this.adapterService.getAdapters();
    this.adapters$ = this.adaptersQuery.getAll();
    this.filteredResultList$ = this.adapters$.sort((a, b) => {
      return a.name.toUpperCase().localeCompare(b.name.toUpperCase())
    });
    this.chRef.detectChanges();
  }

}

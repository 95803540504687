import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {EnvironmentsStore, EnvironmentsUIState} from './environments.store';
import { APIService } from '../../../API.service';
import { EnvironmentModel } from './environment.model';

@Injectable({ providedIn: 'root' })
export class EnvironmentsService {

  constructor(private api: APIService,
              private environmentsStore: EnvironmentsStore,
              private http: HttpClient) {
  }

  async getEnvironments(): Promise<void> {
    const eList: EnvironmentModel[] = [];
    const environmentsList = await this.api.ListEnvironments();
    environmentsList.items.forEach(environment => {
      eList.push(
        {
          id: environment.id,
          name: environment.name,
          url: environment.url,
          reference: environment.reference
        });
    });
    this.environmentsStore.set(eList);
  }

  async updateSelectedEnvironment(environment: EnvironmentModel)
  {
    await this.api.UpdateEnvironment(environment);
  }
}

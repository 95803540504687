<form [formGroup]= "pagerForm" >

  <div>

    <mat-toolbar style="font-size: 12px;">

      <span class="spacer"></span>

      <mat-form-field style="width: 125px; margin-top: 15px;">
        <mat-label>Field</mat-label>
        <mat-select style="width: 75px;"  formControlName="filterField" (valueChange)="filterChanged()">
          <mat-option *ngFor="let f of fields" [value]="f.value">
            {{f.key}}
          </mat-option>
        </mat-select>
      </mat-form-field >

      <span class="small-spacer"></span>

      <mat-form-field style="width: 125px; margin-top: 15px;">
        <mat-label>Op</mat-label>
        <mat-select style="width: 75px;" formControlName="filterOperator"  (valueChange)="filterChanged()">
          <mat-option *ngFor="let o of operators" [value]="o.value">
            {{o.key}}
          </mat-option>
        </mat-select>
      </mat-form-field >

      <span class="small-spacer"></span>

      <mat-form-field style="width: 145px; margin-top: 15px;">
        <mat-label>Filter value</mat-label>
        <input matInput formControlName="filterValue" (input)="filterValueChanged($event.target.value)">
      </mat-form-field>

      <span class="small-spacer"></span>

      <button type="button" mat-icon-button (click)="updateFilter()" [disabled]="!pagerForm.valid">
        <mat-icon matTooltip="Apply Filter" [ngClass]="{'disable':pagerForm.valid !== true, 'green-mat-icon-button':pagerForm.valid === true}" color="green">filter_list</mat-icon>
      </button>

      <span class="tiny-spacer"></span>

      <button type="button" mat-icon-button (click)="clearFilter()" [disabled]="!pagerForm.valid">
        <mat-icon matTooltip="Clear Filter" [ngClass]="{'disable':pagerForm.valid !== true, 'red-mat-icon-button':pagerForm.valid === true}">delete_sweep</mat-icon>
      </button>

      <span class="medium-spacer"></span>

    </mat-toolbar>

  </div>

</form>



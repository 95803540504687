<h2 mat-dialog-title> {{title}} </h2>

<mat-dialog-content *ngIf="jsonData != null">
  <ngx-json-viewer [json]="jsonData" [expanded]="true"></ngx-json-viewer>
</mat-dialog-content>
<mat-dialog-content *ngIf="stackTrace != null">
  <div>
    {{stackTrace}}
  </div>
</mat-dialog-content>

<mat-dialog-actions align="start">
  <button mat-button (click)="close()" class="closeButton">Close</button>
</mat-dialog-actions>

<div style="position: relative; padding: 20px;" >

  <div style="color: red; padding-bottom: 10px; padding-right: 10px; padding-left: 10px; width: 250px;" [hidden]="errorMessage === null || errorMessage === ''">{{errorMessage}}</div>

  <h2 mat-dialog-title>{{title}}</h2>

  <mat-dialog-content [formGroup]="form">

    <div style="height:10px;"></div>

    <mat-form-field appearance="fill">
      <mat-label>New Log Level</mat-label>
      <mat-select formControlName="logLevel" >
        <mat-option value="Verbose">Verbose</mat-option>
        <mat-option value="Debug">Debug</mat-option>
        <mat-option value="Information">Information</mat-option>
        <mat-option value="Warning">Warning</mat-option>
        <mat-option value="Error">Error</mat-option>
        <mat-option value="Fatal">Fatal</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Duration In Minutes</mat-label>
      <mat-select formControlName="duration" >
        <mat-option value="1">1</mat-option>
        <mat-option value="5">5</mat-option>
        <mat-option value="10">10</mat-option>
        <mat-option value="15">15</mat-option>
        <mat-option value="20">20</mat-option>
        <mat-option value="25">25</mat-option>
        <mat-option value="30">30</mat-option>
        <mat-option value="35">35</mat-option>
        <mat-option value="40">40</mat-option>
        <mat-option value="45">45</mat-option>
      </mat-select>
    </mat-form-field>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">
      Cancel
    </button>
    <div style="width: 10px;"></div>
    <button mat-button class="mat-dialog-button" [disabled]="!form.valid" (click)="setLogLevel()">
      Set
    </button>
  </mat-dialog-actions>

</div>

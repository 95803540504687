<script src="https://apis.google.com/js/platform.js"></script>

<mat-toolbar>
  <span [hidden]="adapterForm.value.id"> Add {{selectedEnvironment}} Adapter</span>
  <span [hidden]="!adapterForm.value.id"> Edit {{selectedEnvironment}} Adapter</span>
  <span class="spacer"></span>
  <span style="float:right">
  </span>
</mat-toolbar>

<div style="padding: 20px; width: 355px;">

  <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading" style="color: white; font-weight: bold;">Saving Adapter ...</p>
  </ngx-spinner>

  <form [formGroup]="adapterForm">
    <mat-form-field style="width: 500px">
      <mat-label>Name</mat-label>
      <input matInput
             formControlName="name">
    </mat-form-field>

    <div style="height: 10px;"></div>

    <mat-form-field>
      <mat-label>Adapter Type</mat-label>
      <mat-select placeholder="Adapter Type" formControlName="adapterType">
        <mat-option *ngFor="let integrationType of integrationTypes$ | async" [value]="integrationType.code">
          {{ integrationType.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div style="height: 15px;" [hidden]="selectedEnvironment != 'dev'"></div>

    <div style="height: 15px;"></div>

    <mat-form-field style="width: 600px">
      <mat-label>Endpoint</mat-label>
      <input matInput
             formControlName="endpoint">
    </mat-form-field>

    <div style="height: 15px;"></div>

    <mat-form-field style="width: 600px">
      <mat-label>Local Port Forwarding Port</mat-label>
      <input matInput
             formControlName="localPortForwardPort">
    </mat-form-field>

    <div style="height: 10px;"></div>

    <mat-checkbox formControlName="enabled">Enabled</mat-checkbox>

  </form>

  <div style="text-align: left; margin: auto; padding-top: 20px;">
    <button mat-button (click)="upsertAdapter()" [disabled]="!adapterForm.valid" style="margin-right: 10px;" class="actionButton">Save</button>
    <button mat-button (click)="onCancel()" class="actionButton">Cancel</button>
  </div>

</div>






import {BatchResults} from './batch-results';
import {BatchInfo} from './batch-info';


export class BatchJob {
  batchJobId: number;
  jobIdentifier: string = "N/A";
  createdTs: string = "N/A";
  startedTs : string = "N/A";
  completedTs: string = "N/A";
  itemsProcessed: number = 0;
  jobResults: BatchResults = new BatchResults();
  jobInfo: BatchInfo = new BatchInfo();

  public constructor(
    fields?: {
      batchJobId?: number,
      jobIdentifier?: string,
      createdTs? : string ,
      startedTs?: string,
      completedTs?: string,
      itemsProcessed?: number

    }) {
    if (fields) { Object.assign(this, fields); }
  }

  public totalItems(): number
  {
    return this.jobInfo.creates+this.jobInfo.updates+this.jobInfo.deletes
  }

}

import { Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GlobalUiStateQuery} from '../../../state/global-ui-state.query';
import {IntegrationDetails} from '../../../models/integration-details';
import {animate, state, style, transition, trigger} from '@angular/animations';

export enum Mode {
  none,
  edit,
  raw
}

@Component({
  selector: 'app-adapter-integration-jobs',
  templateUrl: './adapter-integration-jobs.component.html',
  styleUrls: ['./adapter-integration-jobs.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AdapterIntegrationJobsComponent implements OnInit,OnDestroy {

  @ViewChild('userTable') userTable: any;

  constructor( private globalStateQuery: GlobalUiStateQuery) {}

  ///Authed user
  authedUser;
  selectedAdapterDetails$: Object;
  selectedIntegrationDetails$: IntegrationDetails;

  tabIndex : Tabs = Tabs.Scheduled;

  ngOnInit(): void {
    console.log("ngOnInit")
    this.authedUser = this.globalStateQuery.getCachedUser();
    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();
  }

  ngOnDestroy(): void {
    console.log("ngOnDestroy")
  }

}

enum Tabs{
  Scheduled = 0,
  Batch = 1
}

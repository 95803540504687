import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Healthcheck} from '../../../models/healthcheck';
import {DynamoTableInfo} from '../../../models/dynamo-table-info';
import {HealthCheckService} from '../../../services/health-check.service';
import {SESSION_STORAGE, StorageService} from 'ngx-webstorage-service';
import {faHeartbeat} from '@fortawesome/free-solid-svg-icons/faHeartbeat';
import {faDatabase} from '@fortawesome/free-solid-svg-icons/faDatabase';
import {faLayerGroup} from '@fortawesome/free-solid-svg-icons/faLayerGroup';
import {faLink} from '@fortawesome/free-solid-svg-icons/faLink';
import {Observable, Subscription, timer} from 'rxjs';
import {GlobalUiStateQuery} from '../../../state/global-ui-state.query';
import {GlobalUiStateService} from '../../../state/global-ui-state.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {faInbox} from '@fortawesome/free-solid-svg-icons/faInbox';
import {QueInfo} from '../../../models/que-info';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {LoglevelDialogComponent} from './loglevel-dialog/loglevel-dialog.component';
import {RedisDialogComponent} from './redis-dialog/redis-dialog.component';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-adapter-details-health',
  templateUrl: './adapter-details-health.component.html',
  styleUrls: ['./adapter-details-health.component.scss']
})
export class AdapterDetailsHealthComponent implements OnInit, OnDestroy{

  healthCheck: Healthcheck  = new Healthcheck(null);
  dynamoDBData: DynamoTableInfo[] = [];
  queueData:QueInfo[] = [];
  topics: string[] = [];
  topicSubscriptions: string[] = [];
  displayedColumns: string[] = ['arn', 'tableStatus', 'itemCount', 'tableSize'];
  displayedTopicColumns: string[] = ['topicArn'];
  displayedTopicSubscriptionColumns: string[] = ['topicSubscriptionArn'];
  displayQueColumns: string[] = ['arn', 'messageCount'];

  hbIcon: any;
  dbIcon: any;
  lgIcon: any;
  lnkIcon: any;
  queueIcon: any;

  subscription: Subscription;
  healthCheckTimer: Observable<any> = timer(15000, 15000);
  selectedAdapterDetails$: Object;
  errorMessage = '';

  constructor(
    private spinner: NgxSpinnerService,
    private service: HealthCheckService,
    private globalStateQuery: GlobalUiStateQuery,
    private globalStateService: GlobalUiStateService,
    private router: Router,
    public matDialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    @Inject(SESSION_STORAGE) private storage: StorageService) {
      this.hbIcon = faHeartbeat;
      this.dbIcon = faDatabase;
      this.lgIcon = faLayerGroup;
      this.lnkIcon = faLink;
      this.queueIcon = faInbox;
      this.subscription = this.healthCheckTimer.subscribe(x => this.getHealthInfo(this.selectedAdapterDetails$));
  }

  ngOnInit(): void {
    this.spinner.show();
    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    this.getHealthInfo(this.selectedAdapterDetails$);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async displayLogLevelDialog()  {

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
        adapterEndpoint: this.selectedAdapterDetails$['endpoint'],
        title: 'Set Log Level And Duration',
        logLevel: '',
        duration: ''
      };

      const confirmDialog = this.matDialog.open(LoglevelDialogComponent, dialogConfig);

      confirmDialog.afterClosed().subscribe(async data => {

        if (data === undefined) {
          return;
        }

        await this.spinner.hide();

        this.healthCheck.logLevel = data;

      });
  }

  getHealthInfo(adapterDetails: Object)
  {
    this.service.checkHealth(adapterDetails).subscribe(
      result => {
        this.healthCheck.uptime = result.body['AdapterUptime'];
        this.healthCheck.clientType = result.body['ClientType'];
        this.healthCheck.operationMode = result.body['Mode'];
        this.healthCheck.assemblyVersion = result.body['AssemblyVersion'];
        this.healthCheck.baseUrl = result.body['BaseUrl'];
        this.healthCheck.logLevel = result.body['LogLevel'];

        // dynamo DB info
        const jsonArray = JSON.parse(JSON.stringify(result.body['DynamoDbTableInfo'], undefined, 4));
        this.healthCheck.dynamo = [];
        for (const jsonData of jsonArray) {
          const tableData = new DynamoTableInfo(null);
          tableData.arn = jsonData['TableArn'];
          tableData.tableStatus = jsonData['TableStatus'];
          tableData.itemCount = jsonData['TableItemCount'];
          tableData.tableSize = jsonData['TableSizeBytes'];
          this.healthCheck.dynamo.push(tableData);
        }

        //Queue info
        this.healthCheck.queInfo = [];
        const queArray = JSON.parse(JSON.stringify(result.body["QueueInfo"], undefined, 4));
        for (let i = 0; i < queArray.length; i++) {
          const jsonData = queArray[i];
          let data = new QueInfo(null);
          data.queueArn = jsonData["QueueArn"];
          data.numberOfMessages = jsonData["NumberOfMessages"];
          this.healthCheck.queInfo.push(data);
        }
        this.healthCheck.queueCount = this.healthCheck.queInfo.length;

        // Topic info
        this.healthCheck.topicCount = result.body['TopicsInfo']['TopicCount'];
        const topicArray = JSON.parse(JSON.stringify(result.body['TopicsInfo']['Topics'], undefined, 4));
        this.healthCheck.topics = [];
        for (const item of topicArray) {
          this.healthCheck.topics.push(item);
        }

        // Subscription info
        this.healthCheck.topicSubscriptionsCount = result.body['TopicsInfo']['TopicSubscriptionsCount'];
        const subscriptionArray = JSON.parse(JSON.stringify(result.body['TopicsInfo']['TopicSubscriptions'], undefined, 4));
        this.healthCheck.topicSubscriptions = [];
        for (const item of subscriptionArray) {
          this.healthCheck.topicSubscriptions.push(item);
        }

        // hack for table data to reload
        this.dynamoDBData = this.healthCheck.dynamo.slice();
        this.topics = this.healthCheck.topics.slice();
        this.topicSubscriptions = this.healthCheck.topicSubscriptions.slice();
        this.queueData = this.healthCheck.queInfo.slice();

      },
      error => {
        if( error.name === "TimeoutError")
        {
          this.errorMessage ='Error calling checkHealth on the Adapter Integration. Call Timed out!';
        }else{
          this.errorMessage = 'Error calling checkHealth on the Adapter Integration API Endpoint: ' +  + JSON.stringify(error);
        }
          this.spinner.hide();
          this.subscription.unsubscribe();
          this.errorMessage = 'Error calling checkHealth on the Adapter Integration API Endpoint: ' +  + JSON.stringify(error);
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  clearRedis() {
    this.spinner.show();
    this.service.clearRedis(this.selectedAdapterDetails$).subscribe(
      result => {

      var dialogResult = new MatDialogConfig();

        dialogResult.disableClose = true;
        dialogResult.autoFocus = true;

        dialogResult.data = {
          result : result
        };

        const confirmDialog = this.matDialog.open(RedisDialogComponent, dialogResult);

      },
      error => {
        this.spinner.hide();
        this.toastr.error(error.message,error.statusText);

      },
      () => {
        this.spinner.hide();
      }
    );
  }
}

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {GlobalUiStateQuery} from '../state/global-ui-state.query';
import {GlobalUiStateService} from '../state/global-ui-state.service';
import { Location } from '@angular/common';
import {Auth} from 'aws-amplify';
import {MessageService} from '../services/message-service.service';
import {ServiceEvent} from '../models/service-event';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  selectedNavPage$: string;
  userName: string;

  constructor(private location: Location,
              private router: Router,
              public globalStateQuery: GlobalUiStateQuery,
              private globalStateService: GlobalUiStateService,
              private messageService: MessageService,
              private chRef: ChangeDetectorRef) { }

  ngOnInit(): void {

    Auth.currentAuthenticatedUser({
      bypassCache: false
    }).then(async user => {
      this.userName = user.attributes.email;
      if(this.globalStateQuery.getSelectedNavPage()) {
        this.selectedNavPage$ = this.globalStateQuery.getSelectedNavPage();
      } else {
        this.selectedNavPage$ = '';
        this.adaptersClicked().then();
      }
    })
      .catch(err => console.log(err));

    // listen for events that require a nav refresh
    this.messageService.onChange.subscribe(async item => {
      await this.handleEvent(item);
    } )
  }

  async handleEvent(event: ServiceEvent) {
    // refresh the nav
    if (event.type == "NavUpdate")  {
      this.selectedNavPage$ = 'adapters';
      this.globalStateService.updateSelectedNavPage(this.selectedNavPage$);
      this.chRef.detectChanges();
    }
  }

  async adaptersClicked(): Promise<void> {

    if (this.globalStateQuery.getSelectedNavPage() === "adapters")  {
      return;
    }

    this.globalStateService.clearUiState();

    this.selectedNavPage$ = 'adapters';
    this.globalStateService.updateSelectedNavPage(this.selectedNavPage$);
    this.globalStateService.updateSelectedAdapterDetailsNavPage('adapter-details-health');

    await this.router.navigate(['/adapters']);

  }

  async securityClicked(): Promise<void> {

    if (this.globalStateQuery.getSelectedNavPage() === "security")  {
      return;
    }

    this.globalStateService.clearUiState();

    this.selectedNavPage$ = 'security';
    this.globalStateService.updateSelectedNavPage(this.selectedNavPage$);

    this.globalStateService.updateSelectedSecurityNavPage('security-admin-ui-users');
    await this.router.navigate(['/security/' + 'security-admin-ui-users']);
  }

  async configClicked(): Promise<void> {

    if (this.globalStateQuery.getSelectedNavPage() === "config")  {
      return;
    }

    this.globalStateService.clearUiState();

    this.selectedNavPage$ = 'config';
    this.globalStateService.updateSelectedNavPage(this.selectedNavPage$);
    this.globalStateService.updateSelectedConfigNavPage('config-integration-types');
    await this.router.navigate(['/config']);
  }

  getSelectedNavBackgroundColor(nav: string): string {
    if (nav === this.selectedNavPage$) {
      return '#4D6A78';
    } else {
      return '';
    }
  }
}

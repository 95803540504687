import {Injectable} from '@angular/core';
import {IntegrationUser} from '../models/integration-user';
import {Action, AuditTracker} from './AuditTracker';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntegrationUserService {
  constructor(private http: HttpClient,
              private auditTracker: AuditTracker) { }

  UpdateIntegrationUser(adapterEndpoint: string, json: string, integrationId): Observable<IntegrationUser> {
    this.auditTracker.log(Action.Update ,"Update System User for integrationid: " + integrationId);
    let url = adapterEndpoint + "/api/admin/integration-user";
    return this.http.patch(url, json, { observe: 'response', headers: {'Content-Type': 'application/json'}, responseType: 'json' })
      .pipe(map(result => new IntegrationUser(result.body)));
  }

  UpdateIntegrationSystemUser(adapterEndpoint: string, json: string, integrationId) {
    this.auditTracker.log(Action.Update ,"Update System User for integrationid: " + integrationId);
    let url = adapterEndpoint + "/api/admin/integration-user/updatesystemuser";
    return this.http.patch(url, json, { observe: 'response', headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }
}

import {ScheduledJob} from './scheduled-job';

export class IntegrationUser {

  userId: string;
  integrationId: number;
  email: string;
  isSystemAccount: boolean;
  authType: number;
  authSettings: Object;
  sharedUser: string;

  constructor(mapValues){
    if (mapValues != null) {
      this.userId = mapValues["UserId"];
      this.authType = mapValues["AuthType"];
      this.integrationId = mapValues["IntegrationId"];
      this.isSystemAccount = mapValues["IsSystemAccount"];
      this.email = mapValues["Email"];
      this.authSettings =  mapValues["AuthSettings"];
      this.sharedUser =  mapValues["SharedUser"];
    }
  }
}

export class UserAuthSettings {
  basicPassword:string;
  basicUserName:string;
  oAuthScope:string;
  oAuthSignature:string;
  oAuthAccessToken:string;
  oAuthInstanceUrl:string;
  oAuthOrgId:string;
  oAuthRefreshToken:string;
  oAuthTokenExpiry:string;
  oAuthTokenType:string;
  oAuthUserId:string;
  oAuthTokenIssueDate:string;

  constructor(mapValues) {
    if (mapValues != null) {
      this.basicPassword = mapValues["basicPassword"];
      this.basicUserName = mapValues["basicUserName"];
      this.oAuthScope = mapValues["oAuthScope"];
      this.oAuthSignature = mapValues["oAuthSignature"];
      this.oAuthAccessToken = mapValues["oAuthAccessToken"];
      this.oAuthInstanceUrl = mapValues["oAuthInstanceUrl"];
      this.oAuthOrgId = mapValues["oAuthOrgId"];
      this.oAuthRefreshToken = mapValues["oAuthRefreshToken"];
      this.oAuthTokenExpiry = mapValues["oAuthTokenExpiry"];
      this.oAuthTokenType = mapValues["oAuthTokenType"];
      this.oAuthUserId = mapValues["oAuthUserId"];
      this.oAuthTokenIssueDate = mapValues["oAuthTokenIssueDate"];
    }
  }
}

<mat-grid-tile-header style="background: linear-gradient(140deg, rgb(21, 50, 64) 11.2%, rgba(21, 50, 64, .12) 120.2%)" [style.height]="'15%'" [style.padding-left]="'8px'"  [style.padding-right]="'8px'">
  <div style="display: flex; align-content: space-between; width: 100%;">
    <div style="flex: 1 1 auto; text-align: left;">
      <mat-icon [matMenuTriggerFor]="menu" [ngClass]="['menu']" style="color: white; cursor: pointer;" (click)="$event.stopPropagation();" >menu</mat-icon>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openDeleteConfirmDialog('Delete', integration, $event); " ><mat-icon matTooltip="Delete" style="color:red">delete</mat-icon>Delete</button>
        <button mat-menu-item (click)="clearIntegrationCache(integration); "><mat-icon matTooltip="Refresh Integration Cache">cached</mat-icon>Clear Integration Cache</button>
      </mat-menu>
    </div>
    <div style="flex: 1 1 auto; text-align: right;">
      <span>
        <mat-icon style="color:green" [hidden]="integration.runningConnectionTest || integration.connectionState != 'connected'" matTooltip="Integration Auth Succeeded - Click to Retry" (click)="$event.stopPropagation(); testIntegrationConnection();">cloud_queue</mat-icon>
        <mat-icon style="color:red" [hidden]="integration.runningConnectionTest || integration.connectionState == 'connected'" matTooltip="Integration Auth Failed - Click to Retry {{integration.connectionMessage}}" (click)="$event.stopPropagation(); testIntegrationConnection();">cloud_off</mat-icon>
        <fa-icon transform="down-2" [icon]="spinnerIcon" style="margin: auto; color:green;" size="lg" [hidden]="!integration.runningConnectionTest" [spin]="true"></fa-icon>
      </span>
    </div>
  </div>
</mat-grid-tile-header>

<div class="Aligner" style="width: 100%; padding-top: 90px;" (click)="integrationClicked(integration)">

  <ngx-spinner [name]="integration.integrationId.toString()" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium"></ngx-spinner>

  <div>
    <span class="image-link" style="color: black; font-size: 14pt;" matTooltip="{{integration.name}}">{{ (integration.name.length>15)? (integration.name | slice:0:15)+'..':(integration.name) }}</span>
  </div>

  <div style="height: 10px;"></div>

  <span>{{ (integration.enabled)? '&#10003;&nbsp;Enabled': '&#10006;&nbsp;Disabled' }}</span>
  <div [hidden]="!integration.isRelay" style="color: black; height: 30px; display: flex;"><div style="margin: auto; padding-right: 5px;">Relay</div><mat-icon style="color: black; height: 20px;" >repeat</mat-icon></div>
  <span [hidden]="integration.isRelay">&nbsp;</span>

  <div class="Aligner-item Aligner-item--bottom" style="color: black; font-size: 9pt;">Account ID: {{integration.accountId}}</div>
  <div class="Aligner-item Aligner-item--bottom" style="color: black; font-size: 9pt;">ID: {{integration.integrationId}}</div>

  <div style="height: 30px;"></div>

</div>


import { Injectable } from '@angular/core';
import {FiltersStore} from './filters.store';
import { APIService } from '../../../API.service';
import { FilterModel } from './filter.model';
import {AdapterService} from '../../../services/adapter.service';
import {GlobalUiStateQuery} from '../../../state/global-ui-state.query';

@Injectable({ providedIn: 'root' })
export class FiltersService {

  constructor(private api: APIService,
              private filtersStore: FiltersStore,
              private adapterService: AdapterService,
              private globalStateQuery: GlobalUiStateQuery) {
  }

  async getFilters(endpoint: string): Promise<void> {
    let filters: FilterModel[] = [];

    this.adapterService.listOutOfTheBoxFilters(endpoint).toPromise().then(result => {
        filters = Object.keys(result.body).map(i => {
          return new FilterModel({id: i, integrationType: result.body[i]["IntegrationType"], filteredEntities: result.body[i]["FilteredEntities"]});
        }
        );
        this.filtersStore.set(filters);
      }
    );

  }

  async resetSelectedFilter(integrationType: string, endpoint: string)
  {
    let filters: FilterModel[] = [];
    this.adapterService.resetOutOfTheBoxFilter(integrationType, endpoint).toPromise().then(result => {
      this.adapterService.listOutOfTheBoxFilters(endpoint).toPromise().then(result => {
          filters = Object.keys(result.body).map(i => {
              return new FilterModel({id: i, integrationType: result.body[i]["IntegrationType"], filteredEntities: result.body[i]["FilteredEntities"]});
            }
          );
          this.filtersStore.set(filters);
        }
      );
      }
    );
  }
}

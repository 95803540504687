<app-error-page [errorMessage]="errorMessage"></app-error-page>

<main style="background-color: white; height: 100%; position: relative;" [hidden]="errorMessage">

    <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
      <p class="loading" style="color: white; font-weight: bold;">Loading Adapter Health ...</p>
    </ngx-spinner>

    <div style="height: 20px;"></div>

    <mat-expansion-panel hideToggle="true" expanded="true">

      <mat-expansion-panel-header>

        <mat-panel-title>
          <fa-icon [icon]="hbIcon" style="color: red; font-size: 20px;"></fa-icon>
          <div style="padding-left: 10px;width: 115px;">Adapter Health</div>
          <div style="text-align: right; width: 100%;"><span style="padding-right: 5px; color: green;">Uptime:</span> {{healthCheck.uptime}}</div>
        </mat-panel-title>

      </mat-expansion-panel-header>

      <mat-grid-list cols="2" rowHeight="20px" >
        <mat-grid-tile>
          <div style="text-align: right; width: 100%; padding-right: 5px;"><span style="font-size: 12pt; text-align: left;">Client Type:</span></div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div style="text-align: left; width: 100%; font-weight: bold;">{{healthCheck.clientType}}</div>
        </mat-grid-tile>
      </mat-grid-list>

      <div style="height: 5px;"></div>

      <mat-grid-list cols="2" rowHeight="20px" >
        <mat-grid-tile>
          <div style="text-align: right; width: 100%; padding-right: 5px;"><span style="font-size: 12pt; text-align: left;">Operation Mode:</span></div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div style="text-align: left; width: 100%; font-weight: bold;">{{healthCheck.operationMode}}</div>
        </mat-grid-tile>
      </mat-grid-list>

      <div style="height: 5px;"></div>

      <mat-grid-list cols="2" rowHeight="20px" >
        <mat-grid-tile>
          <div style="text-align: right; width: 100%; padding-right: 5px;"><span style="font-size: 12pt; text-align: left;">Assembly Version:</span></div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div style="text-align: left; width: 100%; font-weight: bold;">{{healthCheck.assemblyVersion}}</div>
        </mat-grid-tile>
      </mat-grid-list>

      <div style="height: 5px;"></div>

      <mat-grid-list cols="2" rowHeight="20px" >
        <mat-grid-tile>
          <div style="text-align: right; width: 100%; padding-right: 5px;"><span style="font-size: 12pt; text-align: left;">Base Url:</span></div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div style="text-align: left; width: 100%; font-weight: bold;">{{healthCheck.baseUrl}}</div>
        </mat-grid-tile>
      </mat-grid-list>

      <div style="height: 5px;"></div>

      <mat-grid-list cols="2" rowHeight="20px" >
        <mat-grid-tile>
          <div style="text-align: right; width: 100%; padding-right: 5px;"><span style="font-size: 12pt; text-align: left;">Log Level:</span></div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div style="text-align: left; width: 100%; font-weight: bold;">{{healthCheck.logLevel}} <button type="button" (click)="displayLogLevelDialog()" style="padding-left: 10px;" mat-flat-button>Set</button> </div>
        </mat-grid-tile>
      </mat-grid-list>

      <div style="height: 5px;"></div>

      <mat-grid-list cols="2" rowHeight="20px" >
        <mat-grid-tile>
          <div style="text-align: right; width: 100%; padding-right: 5px;"><span style="font-size: 12pt; text-align: left;">Clear Redis:</span></div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div style="text-align: left; width: 100%; font-weight: bold;"><button type="button" (click)="clearRedis()" style="padding-left: 10px;" mat-flat-button>Clear</button> </div>
        </mat-grid-tile>
      </mat-grid-list>

      <div style="height: 10px;"></div>

    </mat-expansion-panel>

    <mat-divider style="height: 5px;"></mat-divider>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <fa-icon [icon]="dbIcon" style="font-size: 20px;"></fa-icon>
          <div style="padding-left: 10px;">DynamoDB</div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table mat-table [dataSource]="dynamoDBData" class="mat-elevation-z8" style="width:95%">
        <!-- IntegrationId Column -->
        <ng-container matColumnDef="arn">
          <th mat-header-cell *matHeaderCellDef>Table Name</th>
          <td mat-cell *matCellDef="let healthCheckItem" class="mat-cell">{{healthCheckItem.arn}}</td>
        </ng-container>
        <ng-container matColumnDef="tableStatus">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let healthCheckItem">{{healthCheckItem.tableStatus}}</td>
        </ng-container>
        <ng-container matColumnDef="itemCount">
          <th mat-header-cell *matHeaderCellDef>Record Count</th>
          <td mat-cell *matCellDef="let healthCheckItem">{{healthCheckItem.itemCount}}</td>
        </ng-container>
        <ng-container matColumnDef="tableSize">
          <th mat-header-cell *matHeaderCellDef>Table Size (bytes)</th>
          <td mat-cell *matCellDef="let healthCheckItem">{{healthCheckItem.tableSize}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </mat-expansion-panel>

  <mat-divider style="height: 5px;"></mat-divider>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon [icon]="queueIcon" style="font-size: 20px;"></fa-icon>
        <div style="padding-left: 10px;">Queue ({{healthCheck.queueCount}})</div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <table mat-table [dataSource]="queueData" class="mat-elevation-z8" style="width:95%">
      <!-- IntegrationId Column -->
      <ng-container matColumnDef="arn">
        <th mat-header-cell *matHeaderCellDef>Queue Name</th>
        <td mat-cell *matCellDef="let queItem" >{{queItem.queueArn}}</td>
      </ng-container>
      <ng-container matColumnDef="messageCount">
        <th mat-header-cell *matHeaderCellDef>Message Count</th>
        <td mat-cell *matCellDef="let queItem">{{queItem.numberOfMessages}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayQueColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayQueColumns;"></tr>
    </table>

  </mat-expansion-panel>

    <mat-divider style="height: 5px;"></mat-divider>

    <mat-expansion-panel>

      <mat-expansion-panel-header>
        <mat-panel-title>
          <fa-icon [icon]="lgIcon" style="font-size: 20px;"></fa-icon>
          <div style="padding-left: 10px;">Topics ({{healthCheck.topicCount}})</div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table mat-table [dataSource]="topics" class="mat-elevation-z8" style="width:95%">
        <!-- IntegrationId Column -->
        <ng-container matColumnDef="topicArn">
          <th mat-header-cell *matHeaderCellDef>Topic Name</th>
          <td mat-cell *matCellDef="let topic">{{topic}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedTopicColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedTopicColumns;"></tr>
      </table>

    </mat-expansion-panel>

    <mat-divider style="height: 5px;"></mat-divider>

    <mat-expansion-panel>

      <mat-expansion-panel-header>
        <mat-panel-title>
          <fa-icon [icon]="lnkIcon" style="font-size: 20px;"></fa-icon>
          <div style="padding-left: 10px;">Subscriptions ({{healthCheck.topicSubscriptionsCount}})</div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table mat-table [dataSource]="topicSubscriptions" class="mat-elevation-z8" style="width:95%">
        <!-- IntegrationId Column -->
        <ng-container matColumnDef="topicSubscriptionArn">
          <th mat-header-cell *matHeaderCellDef>Topic Subscriptions</th>
          <td mat-cell *matCellDef="let subscription">{{subscription}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedTopicSubscriptionColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedTopicSubscriptionColumns;"></tr>
      </table>

    </mat-expansion-panel>

  <br>

</main>

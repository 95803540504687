import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ID} from '@datorama/akita';
import {AuditLogModel} from './audit-log.model';
import {AuditLogStore} from './audit-log.store';
import {APIService, ModelAuditLogFilterInput, ModelSortDirection} from '../../../../API.service';
import {AuditData} from '../../../../models/audit-data';
import {GlobalUiStateQuery} from '../../../../state/global-ui-state.query';

@Injectable({ providedIn: 'root' })
export class AuditLogService {



  constructor( private api: APIService,
               private auditLogStore: AuditLogStore,
               private http: HttpClient,
               public globalStateQuery: GlobalUiStateQuery) {
  }

  async getAuditLogs(pageSize, startTime): Promise<AuditData> {
    const envPrefix =  this.globalStateQuery.getSelectedEnvPrefix();
    const returnData = new AuditData();
    const theList: AuditLogModel[] = [];
    let filter: ModelAuditLogFilterInput = null;

    if(startTime != null && startTime > 0 ) {
      filter = {logTs: {lt: startTime}};
    }
    const auditList = await this.api.LogsByEnv(envPrefix,null, filter, pageSize);
    auditList.items.forEach(log => {
      console.log(Math.trunc(new Date(log.createdAt).getTime() / 1000));
      theList.push(
        {
          payload: log.payload,
          time: log.createdAt,
          message: log.message,
          adapterType: log.adapterType,
          action: log.action,
          id: log.id,
          integration: log.integration,
          userName: log.userName,
          envPrefix: log.envPrefix,
          createTime: Math.trunc(new Date(log.createdAt).getTime() / 1000)
        });
    });

    returnData.theList = theList;
    returnData.nextToken =  auditList.nextToken;
    return returnData;
    // this.usersStore.set(userList);
  }

  add(auditLog: AuditLogModel) {
    this.auditLogStore.add(auditLog);
  }

  update(id, auditLog: Partial<AuditLogModel>) {
    this.auditLogStore.update(id, auditLog);
  }

  remove(id: ID) {
    this.auditLogStore.remove(id);
  }

}

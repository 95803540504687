<h1 mat-dialog-title><strong>{{action}} {{title}} </strong></h1>
<div mat-dialog-content>
  <mat-form-field *ngIf="action != 'Delete' && action != 'Enable' && action != 'Reload' && action != 'Reset' && action != 'ViewRaw' && action !='Update'; else elseTemplate">
    <input placeholder="{{action}} Name" matInput [(ngModel)]="local_data.name">
  </mat-form-field>
  <ng-template #elseTemplate>
    {{msg}}<br/>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="closeDialog(btn1Action)" mat-button color="warn">OK</button>
  <button mat-button (click)="closeDialog(btn2Action)" mat-button  [hidden]="!showChancel">Cancel</button>
</div>

import { Injectable } from '@angular/core';
import {EntityState, EntityStore, EntityUIStore, StoreConfig} from '@datorama/akita';
import { FilterModel, FiltersState } from './filter.model';

export type FiltersUI = {
};

export interface FiltersUIState extends EntityState<FiltersUI> {}

const initialState = {
  ui: {
  }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'filters' })
export class FiltersStore extends EntityStore<FiltersState, FilterModel> {

  ui: EntityUIStore<FiltersUIState>;

  constructor() {
    super(initialState);
  }

  updateUI( ui: FiltersUIState['ui'] ) {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui
      }
    }));
  }

}

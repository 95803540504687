
<div style="width: 100%; position: relative;">

  <app-pager (onFilterChange)="filterChanged($event)"
             [fields]="[{'key':'User','value':'userName'},{'key':'Url','value':'url'}]">
  </app-pager>

  <ngx-datatable
    #auditLogTable
    id="auditLogTable"
    class="material server-scroller"
    [columnMode]="ColumnMode.flex"
    [headerHeight]="headerHeight"
    [rowHeight]="rowHeight"
    [rows]="logs$"
    [loadingIndicator]="isLoading"
    [scrollbarV]="true"
    (scroll)="onScroll($event.offsetY)">

    <ngx-datatable-column name="Time (UTC)" [sortable]="false" [flexGrow]="4">
      <ng-template let-log="row" ngx-datatable-cell-template >
        {{ log.createTime*1000 | date:'short':'+0000' }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Error Id" [sortable]="false" [flexGrow]="7">
      <ng-template let-log="row" ngx-datatable-cell-template >
        {{ log.guid }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="IntId" [sortable]="false" [flexGrow]="2">
      <ng-template let-log="row" ngx-datatable-cell-template >
        {{ log.integrationId > 0 ? log.integrationId : "" }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="IH User ID" [sortable]="false" [flexGrow]="3">
      <ng-template let-log="row" ngx-datatable-cell-template >
        {{ log.ihUserId }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="User" [sortable]="false" [flexGrow]="6">
      <ng-template let-log="row" ngx-datatable-cell-template >
        {{ log.userName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Method" [sortable]="false" [canAutoResize]="true"  [flexGrow]="2">
      <ng-template let-log="row" ngx-datatable-cell-template >
        {{ log.method }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Url" [sortable]="false" [flexGrow]="10">
      <ng-template let-log="row" ngx-datatable-cell-template >
        <a (click)="openPayload(log)" [ngClass]="{'enabled': log.payload.length > 2 ,'disabled': log.payload.length <=2  }" matTooltip="View payload">{{log.url}}</a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Payload" [sortable]="false" [flexGrow]="2">
      <ng-template let-log="row" ngx-datatable-cell-template >
        <a (click)="openPayload(log)" [ngClass]="{'enabled': log.payload.length > 2 ,'disabled': log.payload.length <=2  }" matTooltip="View payload"> <mat-icon *ngIf="log.payload.length > 2" matTooltip="View Payload" style="color:royalblue">visibility</mat-icon></a>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>

</div>


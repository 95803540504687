import { EntityState } from '@datorama/akita';
import {AdminUiUserDetailModel} from './admin-ui-user-detail.model';

export class AdminUiUserModel {
  id: string;
  emailAddress: string;
  role: string;
  userDetail: AdminUiUserDetailModel;

  public constructor(
    fields?: {
      id?: string,
      emailAddress?: string
      role?: string,
      userDetail?: AdminUiUserDetailModel
    }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export interface AdminUiUsersState extends EntityState<AdminUiUserModel> {}

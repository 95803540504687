import {IntegrationPropertyMapper} from './integration-property-mapper';


export class IntegrationAttribute {

  fieldName: string;
  defaultValue: string;
  type: string;
  required: boolean;
  label: string;
  key: string;


  constructor(mapValues) {

    if (mapValues != null) {
      this.fieldName = mapValues['fieldName'];
      this.defaultValue = mapValues['defaultValue'];
      this.type = mapValues['type'];
      this.required = mapValues['isRequired'];
      this.label = mapValues['label'];
      this.key = IntegrationPropertyMapper.GetMappedKey(this.fieldName);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {APIService} from '../../API.service';
import {NgFormsManager} from '@ngneat/forms-manager';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Order} from '@datorama/akita';
import {DialogBoxComponent} from '../../dialog-box/dialog-box.component';
import {IntegrationTypeModel} from './state/integration-type.model';
import {IntegrationTypesQuery} from './state/integration-types.query';
import {IntegrationTypesService} from './state/integration-types.service';
import {AdminUiUserModel} from '../../security/admin-ui-users/state/admin-ui-user.model';
import {AdminUiUsersStore} from '../../security/admin-ui-users/state/admin-ui-users.store';
import {IntegrationTypesStore} from './state/integration-types.store';
import { ColumnMode } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-config-integration-types',
  templateUrl: './config-integration-types.component.html',
  styleUrls: ['./config-integration-types.component.scss']
})

export class ConfigIntegrationTypesComponent implements OnInit {

  integrationTypes$?: Observable<IntegrationTypeModel[]>;
  isEdit = false;
  integrationType: AdminUiUserModel = new AdminUiUserModel();
  loading = true;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private api: APIService,
    private formsManager: NgFormsManager,
    private builder: UntypedFormBuilder,
    private integrationTypesQuery: IntegrationTypesQuery,
    private integrationTypesService: IntegrationTypesService,
    private dialog: MatDialog,
    private integrationTypesStore: IntegrationTypesStore
  ) { }

  ColumnMode = ColumnMode;

  ngOnInit(): void {
    this.integrationTypesStore.set([]);
    this.integrationTypesService.getIntegrationTypes().then(r => {
      this.integrationTypes$ = this.integrationTypesQuery.selectAll({ sortBy: 'displayName', sortByOrder: Order.ASC });
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    });
  }

  async addIntegrationType(): Promise<void> {
    this.formsManager.clear('integrationType');
    await this.router.navigate(['/config/add-edit-integration-type']);
  }

  cancelEdit(): void {

  }

  async integrationTypeClicked(integrationType: IntegrationTypeModel): Promise<void> {

    console.log(JSON.stringify(integrationType));

    this.formsManager.clear('integrationType');

    const integrationTypeForm = this.builder.group({
      id: [integrationType.id],
      code: [integrationType.code, Validators.required],
      displayName: [integrationType.displayName, Validators.required],
      logoFilename: [integrationType.logoFilename],
      logoWidth: [integrationType.logoWidth],
      logoHeight: [integrationType.logoHeight],
    });

    this.formsManager.upsert('integrationType', integrationTypeForm, {
      persistState: true
    });

    await this.router.navigate(['/config/add-edit-integration-type']);

  }

  deleteIntegrationType(action, obj) {

    const integrationType = { ...obj };
    integrationType.action = action;

    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '250px',
      data: {
        dataObj: integrationType,
        title: 'Integration Type',
        message: 'Are you sure you want to delete Integration Type  ' + integrationType.name
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Delete') {
        this.spinner.show();
        console.log('Delete ' + JSON.stringify(result.data));
        this.api.DeleteIntegrationType({ id: integrationType.id }).then(async value => {
          await this.integrationTypesService.getIntegrationTypes();
          this.integrationTypes$ = this.integrationTypesQuery.selectAll();
          await this.spinner.hide();
          this.showToaster('Integration Type ' + integrationType.displayName + ' deleted.', 0);
        }).catch(reason => {
          console.log('Error ' + JSON.stringify(reason));
          console.log('Error status ' + reason.status);
          this.showToaster('Delete call failed. Status code: ' + reason.status, 1);
        });
      }
    });
  }

  showToaster(message, statusCode) {
    statusCode === 0 ? this.toastr.success(message) : this.toastr.error(message);
  }

}


import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ConnectionProfile} from '../../../models/connection-profile';
import {MatSelectChange} from '@angular/material/select';
import {APIService, GetConnectionProfileQuery} from '../../../API.service';
import {Adapter} from '../../../models/adapter';
import {AdaptersQuery} from '../../state/adapters.query';
import {IPService} from '../../../services/ip.service';
import {ToastrService} from 'ngx-toastr';
import {GlobalUiStateService} from '../../../state/global-ui-state.service';
import {Auth} from 'aws-amplify';
import {GlobalUiStateQuery} from '../../../state/global-ui-state.query';
import {NgxSpinnerService} from 'ngx-spinner';
import {DialogBoxComponent} from '../../../dialog-box/dialog-box.component';
import {SecurityService} from '../../../services/security.service';
import {JWTInfo} from '../../../models/jwtinfo';
import {EnvironmentsQuery} from '../../../config/config-environments/state/environments.query';
import {EnvironmentsService} from '../../../config/config-environments/state/environments.service';
import {Order} from '@datorama/akita';
import {Observable} from 'rxjs';
import {EnvironmentModel} from '../../../config/config-environments/state/environment.model';

@Component({
  selector: 'app-jwtinfo-dialog',
  templateUrl: './jwtinfo-dialog.component.html',
  styleUrls: ['./jwtinfo-dialog.component.scss']
})
export class JWTInfoDialogComponent implements OnInit {

  form: UntypedFormGroup;
  isInitForm: boolean = true;
  adapter: Adapter;
  title: string;
  authKey: string;
  userEmailAddress: string;
  userEnvPrefix: string;
  ipUrl: string;
  errorMessage: string;
  cpList: ConnectionProfile[];
  newUser: string;
  showCreate: boolean = false;
  enterJwt: boolean;
  connProf: GetConnectionProfileQuery;
  isNewConnectionProfile: boolean;
  isNewAuthKey: boolean;
  allowEdit: boolean;
  reg = '(https?:\\/\\/)([\\da-z.-]+)\\.?([a-z.]{2,6}):?(\\d*)?[/\\w .-]*/?';
  selectedLoginType: string;
  selectedCp: string;
  jwt: string;
  envs?: Observable<EnvironmentModel[]>;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<JWTInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private api: APIService,
    private adaptersQuery: AdaptersQuery,
    private ipService: IPService,
    private toastr: ToastrService,
    private globalStateService: GlobalUiStateService,
    public globalStateQuery: GlobalUiStateQuery,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private securityService: SecurityService,
    private environmentsQuery: EnvironmentsQuery,
    private environmentsService: EnvironmentsService)
  {
    this.adapter = data.adapter;
    this.title = data.title;
    this.authKey = data.authKey;
    this.userEmailAddress = data.userEmailAddress;
    this.userEnvPrefix = data.userEnvPrefix;
    this.ipUrl = "http://localhost:3000";
    this.errorMessage = data.errorMessage;
    this.cpList = this.globalStateQuery.getSelectedEnvPrefix() === "production" ?
      data.cpList.filter(x => x.envPrefix === this.globalStateQuery.getSelectedEnvPrefix() && x.adapterId === this.adapter.id) :
      data.cpList.filter(x => x.envPrefix === this.userEnvPrefix);
    this.newUser = data.user;
  }

  ngOnInit(): void {
    this.allowEdit = true;
    this.showCreate = false;
    this.enterJwt = false;
    this.selectedLoginType = "cp";
    this.form = this.fb.group({
      jwt: [this.jwt, []],
      selectedCp: [this.selectedCp, []],
      title: [this.title, []],
      authKey: [this.authKey, Validators.required],
      user: [this.userEmailAddress, [Validators.required, Validators.email]],
      ipUrl: [this.ipUrl, Validators.required],
      name: ['', Validators.required],
      cp: [this.connProf, []]
    });

    this.environmentsService.getEnvironments().then((t) =>
      {
        this.envs = this.environmentsQuery.selectAll({ sortBy: 'name', sortByOrder: Order.ASC });
      }
    );
  }

  radioChange(){
    this.resetForm();
  }

  resetForm() {
    this.form.patchValue({
      selectedCp: '',
      cp: null,
      authKey: '',
      name: '',
      ipUrl: '',
      jwt: ''
    });
    this.jwt = '';
    this.showCreate = false;
    this.isInitForm = true;
  }

  newConnectionProfileForm() {
    this.selectedCp = 'New';
    this.form.patchValue({
      cp: this.selectedCp,
      authKey: '',
      name: '',
      ipUrl: ''
    });
    this.showCreate = true;
    this.allowEdit = true;
    this.isNewConnectionProfile = true;
    this.isNewAuthKey = true;
  }

  private resetAuthKeyInput() {
    this.authKey = '';
    this.form.patchValue({
      authKey: this.authKey
    });
    this.showCreate = true;
    this.isNewAuthKey = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  editForm(): void {
    this.showCreate = true;
    this.resetAuthKeyInput();
  }

  async authAdapter() {

    await this.spinner.show("connProfile");

    if (this.selectedLoginType === 'jwt')
    {
      // verify the jwt
      if (!this.securityService.checkValidToken(this.form.controls["jwt"].value)) {
        await this.spinner.hide("connProfile");
        this.toastr.error('Provided JWT is invalid. ', 'Error!', {
          closeButton: true
        });
      }
      else {
        this.globalStateService.updateCachedAdapterConnectionDetails(this.adapter.id, this.form.controls["jwt"].value, "");
        await this.spinner.hide("connProfile");
        this.dialogRef.close({"status": "success"});
      }
    }
    else {
      const adapterStored = this.adaptersQuery.getEntity(this.adapter.id);
      let connectionProfile = this.form.controls["cp"].value;
      try {
        if (connectionProfile === 'New') { connectionProfile = new ConnectionProfile();}
        connectionProfile.authKey = this.form.controls["authKey"].value;
        connectionProfile.userName = this.form.controls["user"].value;
        connectionProfile.ipUrl = this.form.controls["ipUrl"].value;
        connectionProfile.audience = adapterStored.endpoint;
        connectionProfile.environment = adapterStored.endpoint;
        connectionProfile.integrationId = adapterStored.id;
        connectionProfile.purpose = 'toolkit';
        connectionProfile.name = this.form.controls["name"].value;
        try {
          const tokenResponse = await this.getJWTToken(adapterStored.id, adapterStored.endpoint, connectionProfile);
          if (tokenResponse.jwtToken === "Access denied")  {
            await this.spinner.hide("connProfile");
            this.toastr.error(`Unable to retrieve a JWT from the server. Please verify that your API Access Key has not expired.`, 'Error!', {
              closeButton: false
            });
            this.resetAuthKeyInput()
            return;
          } else if (tokenResponse.jwtToken === "Server error")  {
            await this.spinner.hide("connProfile");
            this.toastr.error(`An exception occurred retrieving a JWT from the server.`, 'Error!', {
              closeButton: false
            });
            this.showCreate = true;
            return;
          }
          connectionProfile.authKey = tokenResponse.ipAuthKey;
          const connectionProfileId = await this.persistOrUpdateConnectionProfile(adapterStored.id, adapterStored.endpoint, connectionProfile); // move this to after getting JWT, will have to decode with utf8 decoder
          this.globalStateService.updateCachedAdapterConnectionDetails(adapterStored.id, tokenResponse.jwtToken, connectionProfileId);
        } catch (e) {
          console.log(e);
          await this.spinner.hide("connProfile");
          this.toastr.error(`An exception occurred retrieving a JWT from the server.`, 'Error!', {
            closeButton: false
          });
          this.showCreate = true;
          return;
        }
        await this.spinner.hide("connProfile");
        // close dialog if connection succeeds
        this.dialogRef.close({"status": "success"});
      }
      catch (e) {
        console.log(e);
        this.toastr.error('An error occurred Getting a registration token. ' + e['status'] + ' : ' + e['error'], 'Error!', {
          closeButton: true
        });
        this.showCreate = true;
        await this.spinner.hide("connProfile");
      }
    }
  }

  async deleteConnectionProfile(action, connectionProfile): Promise<void>
  {
    const cProfile = { ...connectionProfile };
    cProfile.action = action;
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '250px',
      data: {
        dataObj: cProfile,
        title: 'Admin User',
        message: 'Are you sure you want to delete Connection Profile  ' + cProfile.name
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Delete') {
        Auth.currentAuthenticatedUser({
          bypassCache: false
        }).then(async () => {
          await this.api.DeleteConnectionProfile({ id: this.connProf.id}).then(deleteConnectionProfileMutation => {
            // re-load global connection profile list
            const connectionProfiles = this.globalStateQuery.getConnectionProfiles();
            let cpIndex = connectionProfiles.findIndex(x => x.id === this.connProf.id);
            connectionProfiles.splice(cpIndex, 1);
            this.globalStateService.updateConnectionProfiles(connectionProfiles.sort((a, b) => {
              const nameA = a.name.toUpperCase(); // ignore upper and lowercase
              const nameB = b.name.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            }));
            console.log(deleteConnectionProfileMutation);
            this.resetForm();
          });
        })
          .catch(err => console.log(err));
      }
    });
  }

  async persistOrUpdateConnectionProfile(adapterId: string, endpoint: string, connectionProfile: ConnectionProfile): Promise<string>
  {
    let id = '';

    if(connectionProfile.id){
      id = connectionProfile.id;
    }

    Auth.currentAuthenticatedUser({
      bypassCache: false
    }).then(async user => {
      const emailAddress = user.attributes.email;
      const userList = await this.api.ListUsers({ emailAddress: { eq: emailAddress}});

      if (connectionProfile.id) {
        await this.api.UpdateConnectionProfile({
          id: connectionProfile.id,
          name: connectionProfile.name,
          accountId: '',
          audience: connectionProfile.audience,
          authKey: connectionProfile.authKey,
          connectionProfileUserDetailId: userList.items[0].userDetail.id,
          environment: connectionProfile.environment,
          adapterId: adapterId,
          envPrefix: userList.items[0].userDetail.envPrefix,
          ipUrl: connectionProfile.ipUrl,
          isRegistered: connectionProfile.isRegistered,
          jwtToken: connectionProfile.jwtToken,
          purpose: connectionProfile.purpose,
          userName: connectionProfile.userName
        }).then(updatedProfile => {
          id = updatedProfile.id;
          // re-load global connection profile list
          const connectionProfiles = this.globalStateQuery.getConnectionProfiles();
          const res = connectionProfiles.map(t => t.id === updatedProfile.id ? { id, name: updatedProfile.name} : t);
          this.globalStateService.updateConnectionProfiles(res.sort((a, b) => {
            return a.name.toUpperCase().localeCompare(b.name.toUpperCase())
          }));
        });
      }
      else {
        await this.api.CreateConnectionProfile({
          name: connectionProfile.name,
          accountId: '',
          audience: connectionProfile.audience,
          authKey: connectionProfile.authKey,
          connectionProfileUserDetailId: userList.items[0].userDetail.id,
          environment: connectionProfile.environment,
          adapterId: adapterId,
          envPrefix: this.globalStateQuery.getSelectedEnvPrefix(),
          ipUrl: connectionProfile.ipUrl,
          isRegistered: connectionProfile.isRegistered,
          jwtToken: connectionProfile.jwtToken,
          purpose: connectionProfile.purpose,
          userName: connectionProfile.userName
        }).then(newProfile => {
          id = newProfile.id;
          // re-load global connection profile list
          const connectionProfiles = this.globalStateQuery.getConnectionProfiles();
          connectionProfiles.push({ id, name: newProfile.name, adapterId: adapterId, envPrefix: this.globalStateQuery.getSelectedEnvPrefix() });
          this.globalStateService.updateConnectionProfiles(connectionProfiles.sort((a, b) => {
            return a.name.toUpperCase().localeCompare(b.name.toUpperCase())
          }));
        });
      }

    })
      .catch(err => console.log(err));

    return id;
  }

  async getJWTToken(adapterId: string, endpoint: string, connectionProfile: ConnectionProfile): Promise<JWTInfo> {
    let response = new JWTInfo();
    response.jwtToken = '';
    response.ipAuthKey = '';

    await this.ipService.getJWTToken(endpoint, connectionProfile, this.isNewAuthKey).toPromise().then(result => {
      response.jwtToken = result.body['Token'];
      response.ipAuthKey = result.body['EncryptedKey'];
    }).catch(err => {
      console.log(err);
      if (err.error.text.indexOf("Access denied") > -1)  {
        response.jwtToken = "Access denied";
      } else {
        response.jwtToken = "Server error";
      }
    });
    return response;
  }

  async applyFilter(event: MatSelectChange) {
    this.isInitForm = false;
    if (event.value === 'New') {
      this.newConnectionProfileForm()
    } else {
      // get form data
      await this.existingConnectionProfileForm(event)
    }
  }

  get f(){
    return this.form.controls;
  }

  private async existingConnectionProfileForm(event: MatSelectChange) {
    this.selectedCp = event.value.id;
    try {
      // get the connectionProfile id and query the db for the connection profile
      const connectionProfile = await this.api.GetConnectionProfile(this.selectedCp);
      this.connProf = connectionProfile;
      this.form.patchValue({
        ipUrl: connectionProfile.ipUrl,
        authKey: connectionProfile.authKey,
        user: connectionProfile.userName,
        name: connectionProfile.name,
        cp: connectionProfile
      });
      this.allowEdit = true;
      this.isNewConnectionProfile = false;
      this.checkExistingProfileValidity()
    } catch (error) {
      console.log(error);
    }
  }

  private checkExistingProfileValidity() {
    if(!this.form.valid){
      this.resetAuthKeyInput()
    }
    else
    {
      this.showCreate = false;
      this.isNewAuthKey = false;
    }
  }
}

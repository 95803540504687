import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {APIService} from '../../API.service';
import {NgFormsManager} from '@ngneat/forms-manager';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Order} from '@datorama/akita';
import {DialogBoxComponent} from '../../dialog-box/dialog-box.component';
import {EnvironmentModel} from './state/environment.model';
import {EnvironmentsQuery} from './state/environments.query';
import {EnvironmentsService} from './state/environments.service';
import {AdminUiUserModel} from '../../security/admin-ui-users/state/admin-ui-user.model';
import { ColumnMode } from '@swimlane/ngx-datatable';
import {EnvironmentsStore} from './state/environments.store';

@Component({
  selector: 'app-config-environments',
  templateUrl: './config-environments.component.html',
  styleUrls: ['./config-environments.component.scss']
})

export class ConfigEnvironmentsComponent implements OnInit {

  environments$?: Observable<EnvironmentModel[]>;
  isEdit = false;
  environment: AdminUiUserModel = new AdminUiUserModel();
  loading = true;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private api: APIService,
    private formsManager: NgFormsManager,
    private builder: UntypedFormBuilder,
    private environmentsQuery: EnvironmentsQuery,
    private environmentsService: EnvironmentsService,
    private environmentsStore: EnvironmentsStore,
    private dialog: MatDialog
  ) { }

  ColumnMode = ColumnMode;

  ngOnInit(): void {
    this.environmentsStore.set([]);
    this.environmentsService.getEnvironments().then(r => {
      this.environments$ = this.environmentsQuery.selectAll({ sortBy: 'name', sortByOrder: Order.ASC });
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    });
  }

  async addEnvironment(): Promise<void> {
    this.formsManager.clear('environment');
    await this.router.navigate(['/config/add-edit-environment']);
  }

  cancelEdit(): void {

  }

  async environmentClicked(environment: EnvironmentModel): Promise<void> {

    console.log(JSON.stringify(environment));

    this.formsManager.clear('environment');

    const environmentForm = this.builder.group({
      id: [environment.id],
      name: [environment.name, Validators.required],
      url: [environment.url, Validators.required],
      reference: [environment.reference]
    });

    this.formsManager.upsert('environment', environmentForm, {
      persistState: true
    });

    await this.router.navigate(['/config/add-edit-environment']);

  }

  deleteEnvironment(action, obj) {

    console.log(obj);
    const environment = { ...obj };
    environment.action = action;

    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '250px',
      data: {
        dataObj: environment,
        title: 'Environment',
        message: 'Are you sure you want to delete Environment  ' + environment.name
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Delete') {
        this.spinner.show();
        console.log('Delete ' + JSON.stringify(result.data));
        this.api.DeleteEnvironment({ id: environment.id }).then(async value => {
          await this.environmentsService.getEnvironments();
          this.environments$ = this.environmentsQuery.selectAll({ sortBy: 'name', sortByOrder: Order.ASC });
          await this.spinner.hide();
          this.showToaster('Environment ' + environment.name + ' deleted.', 0);
        }).catch(reason => {
          console.log('Error ' + JSON.stringify(reason));
          console.log('Error status ' + reason.status);
          this.showToaster('Delete call failed. Status code: ' + reason.status, 1);
        });
      }
    });
  }

  showToaster(message, statusCode) {
    statusCode === 0 ? this.toastr.success(message) : this.toastr.error(message);
  }

}


import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { GlobalUiStateQuery } from '../../../../state/global-ui-state.query';
import { GlobalUiStateService } from '../../../../state/global-ui-state.service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdapterService } from '../../../../services/adapter.service';
import { DialogBoxComponent } from '../../../../dialog-box/dialog-box.component';
import { IntegrationDetails } from '../../../../models/integration-details';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';

@Component({
  selector: 'app-integration-card',
  templateUrl: './integration-card.component.html',
  styleUrls: ['./integration-card.component.scss']
})

export class IntegrationCardComponent implements OnInit, OnDestroy {

  @Input() integration: IntegrationDetails;
  @Output() parentReloadPage: EventEmitter<any> = new EventEmitter();
  activeIntegration: IntegrationDetails = new IntegrationDetails(null);
  selectedAdapterDetails$: Object;
  spinnerIcon: any;
  integrationNameForDisplay: String;

  constructor(
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private adapterService: AdapterService,
    private globalStateQuery: GlobalUiStateQuery,
    private globalStateService: GlobalUiStateService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.spinnerIcon = faSpinner;
  }

  async ngOnInit(): Promise<void> {

    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();

    document.documentElement.style.setProperty('--width', '70%');
    this.integrationNameForDisplay = this.integration.name.length > 13 ? this.integration.name.substring(0,12) + " ..." : this.integration.name

    await this.testIntegrationConnection();

  }

  ngOnDestroy() {
  }

  openDeleteConfirmDialog(action, obj, event) {

    this.activeIntegration = obj;
    obj.action = action;

    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '250px',
      data: {
        dataObj: obj,
        title: 'Integration',
        message: 'Are you sure you want to delete Integration  ' + this.activeIntegration.integrationId
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result.event === 'Delete') {

        this.adapterService.deleteIntegration(this.selectedAdapterDetails$['endpoint'], result.data.dataObj.integrationId).subscribe(
          () => {
            this.showToaster('Integration' + this.activeIntegration.integrationId + ' deleted.', 0);
            this.parentReloadPage.emit();
          },
          error => {
            if( error.name === "TimeoutError")
            {
              this.showToaster('Delete call failed. Call Timed out!', 1);
            }else{
              this.showToaster('Delete call failed. Status code: ' + error.status, 1);
            }
          },
          async () => {
          }
        );
      }
    });
  }

  async clearIntegrationCache(integration: IntegrationDetails) {
    await this.spinner.show(this.integration.integrationId.toString());
    this.adapterService.clearIntegrationCache(this.selectedAdapterDetails$['endpoint'], integration.integrationId).subscribe(
      async result => {
        this.showToaster("Clear Integration Cache call succeeded", 0);
        await this.spinner.hide(this.integration.integrationId.toString());
      },
      async error => {
        if( error.name === "TimeoutError")
        {
          this.showToaster('Clear Integration Cache call failed. Call Timed out!', 1);
        }else{
          this.showToaster("Clear Integration Cache call failed. Status code: " + error.status, 1);
        }
        await this.spinner.hide(this.integration.integrationId.toString());
      },
      async () => {

      }
    );
  }

  integrationClicked(integration: IntegrationDetails) {
    this.globalStateService.updateSelectedAdapterIntegrationNavPage('adapter-integration-details');
    this.globalStateService.updateSelectedAdapterIntegration(integration);
    this.router.navigate(['/adapters/adapter-integration']);
  }

  showToaster(message, statusCode) {
    statusCode === 0 ? this.toastr.success(message) : this.toastr.error(message);
  }

  async testIntegrationConnection() {

    await this.pingIntegration(this.integration);

  }

  async pingIntegration(integration: IntegrationDetails) {
    if (!integration.integrationId) {
      return;
    }

    integration.runningConnectionTest = true;
    this.adapterService.ping(this.selectedAdapterDetails$['endpoint'], integration.integrationId).subscribe(
      result => {
        integration.connectionState = "connected";
        integration.runningConnectionTest = false;
      },
      error => {
        integration.connectionState = "disconnected";
        integration.runningConnectionTest = false;
        integration.connectionMessage = JSON.stringify(error, null, 4);
      },
      async () => {
      }
    );
  }

}

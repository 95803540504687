import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AdaptersComponent } from './adapters/adapter-list/adapters.component';
import { AdapterDetailsComponent } from './adapters/adapter-details/adapter-details.component';
import { IntegrationsComponent } from './integrations/integrations/integrations.component';
import { AdapterDetailsHealthComponent } from './adapters/adapter-details/adapter-details-health/adapter-details-health.component';
import { AdapterDetailsIntegrationsComponent } from './adapters/adapter-details/adapter-details-integrations/adapter-details-integrations.component';
import { AdapterIntegrationComponent } from './adapters/adapter-integration/adapter-integration.component';
import { AdapterselectedIntegrationDetails$Component } from './adapters/adapter-integration/adapter-integration-details/adapter-integration-details.component';
import { AdapterIntegrationSchemasComponent } from './adapters/adapter-integration/adapter-integration-schemas/adapter-integration-schemas.component';
import { AdapterIntegrationStatsComponent } from './adapters/adapter-integration/adapter-integration-stats/adapter-integration-stats.component';
import { AuthGuard } from './auth.guard';
import {AdapterMaintenanceComponent} from './adapters/adapter-maintenance/adapter-maintenance.component';
import {AdapterIntegrationMappingsComponent} from './adapters/adapter-integration/adapter-integration-mappings/adapter-integration-mappings.component';
import {AuditLogsComponent} from './security/audit/audit-logs/audit-logs.component';
import {SecurityComponent} from './security/security.component';
import {AdapterIntegrationQuerytoolComponent} from './adapters/adapter-integration/adapter-integration-querytool/adapter-integration-querytool.component';
import {AdapterDetailsConfigComponent} from './adapters/adapter-details/adapter-details-config/adapter-details-config.component';
import {ConfigComponent} from './config/config.component';
import {ConfigIntegrationTypesComponent} from './config/config-integration-types/config-integration-types.component';
import {AddEditIntTypeComponent} from './config/config-integration-types/add-edit-int-type/add-edit-int-type.component';
import {AdapterAuditLogComponent} from './adapters/adapter-details/adapter-audit-log/adapter-audit-log.component';
import {AdapterIntegrationAuditLogsComponent} from './adapters/adapter-integration/adapter-integration-audit-logs/adapter-integration-audit-logs.component';
import {AdapterDetailsLogsComponent} from './adapters/adapter-details/adapter-details-logs/adapter-details-logs.component';
import {AdapterIntegrationLogsComponent} from './adapters/adapter-integration/adapter-integration-logs/adapter-integration-logs.component';
import {AdminUiUsersComponent} from './security/admin-ui-users/admin-ui-users.component';
import {AddEditAdminUiUserComponent} from './security/admin-ui-users/add-edit-admin-ui-user/add-edit-admin-ui-user.component';
import {ConfigEnvironmentsComponent} from './config/config-environments/config-environments.component';
import {AddEditEnvironmentComponent} from './config/config-environments/add-edit-environment/add-edit-environment.component';
import {AdapterIntegrationUsersComponent} from './adapters/adapter-integration/adapter-integration-users/adapter-integration-users.component';
import {AddEditFilterComponent} from './config/config-filters/add-edit-filter/add-edit-filter.component';
import {ConfigFiltersComponent} from './config/config-filters/config-filters.component';
import {
  AdapterIntegrationBatchjobsComponent
} from './adapters/adapter-integration/adapter-integration-batchjobs/adapter-integration-batchjobs.component';
import {AdapterIntegrationJobsComponent} from './adapters/adapter-integration/adapter-integration-jobs/adapter-integration-jobs.component';
import {
  AdapterIntegrationQueueListenerComponent
} from './adapters/adapter-integration/adapter-integration-queue-listner/adapter-integration-queue-listener.component';

const routes: Routes = [
  {
    path: 'login',
    component: AuthComponent
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'adapters'
  },
  {
    canActivate: [AuthGuard],
    path: 'adapters',
      data: {
        breadcrumbs: [
          {
            label: 'Adapters',
            clickable: false
          }
        ],
      },
      children: [
        {
          path: '',
          component: AdaptersComponent,
          data: {
            breadcrumbs: [
              {
                label: 'Adapters',
                clickable: false
              }
            ],
          },
        },
        {
          path: 'adapter-maintenance',
          component: AdapterMaintenanceComponent,
          data: {
            breadcrumbs: [
              {
                label: 'Adapter Maintenance',
                clickable: false
              }
            ],
          },
        },
        {
          path: 'adapter-details',
          component: AdapterDetailsComponent,
          data: {
            breadcrumbs: [
              {
                label: ':selectedAdapter',
                clickable: false
              }
            ]
          },
          children: [
            {
              path: '',
              component: AdapterDetailsHealthComponent,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    label: ':selectedAdapter',
                    clickable: false
                  }
                ]
              },
            },
            {
              path: 'adapter-details-health',
              component: AdapterDetailsHealthComponent,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    label: ':selectedAdapter',
                    clickable: false
                  }
                ]
              },
            },
            {
              path: 'adapter-details-config',
              component: AdapterDetailsConfigComponent,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    label: ':selectedAdapter',
                    clickable: false
                  }
                ]
              },
            },
            {
              path: 'adapter-details-integrations',
              component: AdapterDetailsIntegrationsComponent,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    label: ':selectedAdapter',
                    clickable: false
                  }
                ]
              },
            },
            {
              path: 'adapter-audit-log',
              component: AdapterAuditLogComponent,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    label: ':selectedAdapter',
                    clickable: false
                  }
                ]
              },
            },
            {
              path: 'adapter-details-logs',
              component: AdapterDetailsLogsComponent,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    label: ':selectedAdapter',
                    clickable: false
                  }
                ]
              },
            }
          ]
        },
        {
          path: 'adapter-integration',
          component: AdapterIntegrationComponent,
          data: {
            breadcrumbs: [
              {
                path: 'adapters',
                label: 'Adapters',
                clickable: true
              },
              {
                path: 'adapters/adapter-details/[selectedAdapterDetailsNavPage]',
                label: ':selectedAdapter',
                clickable: true
              },
              {
                label: ':selectedIntegration',
                clickable: false
              }
            ]
          },
          children: [
            {
              path: '',
              component: AdapterselectedIntegrationDetails$Component,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    path: 'adapters/adapter-details/[selectedAdapterDetailsNavPage]',
                    label: ':selectedAdapter',
                    clickable: true
                  },
                  {
                    label: ':selectedIntegration',
                    clickable: false
                  }
                ]
              },
            },
            {
              path: 'adapter-integration-details',
              component: AdapterselectedIntegrationDetails$Component,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    path: 'adapters/adapter-details/[selectedAdapterDetailsNavPage]',
                    label: ':selectedAdapter',
                    clickable: true
                  },
                  {
                    label: ':selectedIntegration',
                    clickable: false
                  }
                ]
              },
            },
            {
              path: 'adapter-integration-users',
              component: AdapterIntegrationUsersComponent,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    path: 'adapters/adapter-details/[selectedAdapterDetailsNavPage]',
                    label: ':selectedAdapter',
                    clickable: true
                  },
                  {
                    label: ':selectedIntegration',
                    clickable: false
                  }
                ]
              },
            },
            {
              path: 'adapter-integration-batchjobs',
              component: AdapterIntegrationBatchjobsComponent,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    path: 'adapters/adapter-details/[selectedAdapterDetailsNavPage]',
                    label: ':selectedAdapter',
                    clickable: true
                  },
                  {
                    label: ':selectedIntegration',
                    clickable: false
                  }
                ]
              },
            }, {
              path: 'app-adapter-integration-jobs',
              component: AdapterIntegrationJobsComponent,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    path: 'adapters/adapter-details/[selectedAdapterDetailsNavPage]',
                    label: ':selectedAdapter',
                    clickable: true
                  },
                  {
                    label: ':selectedIntegration',
                    clickable: false
                  }
                ]
              },
            },

            {
              path: 'adapter-integration-schemas',
              component: AdapterIntegrationSchemasComponent,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    path: 'adapters/adapter-details/[selectedAdapterDetailsNavPage]',
                    label: ':selectedAdapter',
                    clickable: true
                  },
                  {
                    label: ':selectedIntegration',
                    clickable: false
                  }
                ]
              },
            },
            {
              path: 'adapter-integration-stats',
              component: AdapterIntegrationStatsComponent,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    path: 'adapters/adapter-details/[selectedAdapterDetailsNavPage]',
                    label: ':selectedAdapter',
                    clickable: true
                  },
                  {
                    label: ':selectedIntegration',
                    clickable: false
                  }
                ]
              },
            },
            {
              path: 'adapter-integration-mappings',
              component: AdapterIntegrationMappingsComponent,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    path: 'adapters/adapter-details/[selectedAdapterDetailsNavPage]',
                    label: ':selectedAdapter',
                    clickable: true
                  },
                  {
                    label: ':selectedIntegration',
                    clickable: false
                  }
                ]
              },
            },
            {
              path: 'adapter-integration-querytool',
              component: AdapterIntegrationQuerytoolComponent,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    path: 'adapters/adapter-details/[selectedAdapterDetailsNavPage]',
                    label: ':selectedAdapter',
                    clickable: true
                  },
                  {
                    label: ':selectedIntegration',
                    clickable: false
                  }
                ]
              },
            },
            {
              path: 'adapter-integration-audit-logs',
              component: AdapterIntegrationAuditLogsComponent,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    path: 'adapters/adapter-details/[selectedAdapterDetailsNavPage]',
                    label: ':selectedAdapter',
                    clickable: true
                  },
                  {
                    label: ':selectedIntegration',
                    clickable: false
                  }
                ]
              },
            },
            {
              path: 'adapter-integration-logs',
              component: AdapterIntegrationLogsComponent,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    path: 'adapters/adapter-details/[selectedAdapterDetailsNavPage]',
                    label: ':selectedAdapter',
                    clickable: true
                  },
                  {
                    label: ':selectedIntegration',
                    clickable: false
                  }
                ]
              },
            },
            {
              path: 'adapter-integration-queue-listener',
              component: AdapterIntegrationQueueListenerComponent,
              data: {
                breadcrumbs: [
                  {
                    path: 'adapters',
                    label: 'Adapters',
                    clickable: true
                  },
                  {
                    path: 'adapters/adapter-details/[selectedAdapterDetailsNavPage]',
                    label: ':selectedAdapter',
                    clickable: true
                  },
                  {
                    label: ':selectedIntegration',
                    clickable: false
                  }
                ]
              },
            }
          ],
        },
      ]
  },
  {
    path: 'integrations',
    component: IntegrationsComponent,
    data: {
      breadcrumbs: [
        {
          label: 'Integrations',
          clickable: false
        }
      ]
    }
  },
  {
    canActivate: [AuthGuard],
    path: 'security',
    data: {
      breadcrumbs: [
        {
          label: 'Security',
          clickable: false
        }
      ],
    },
    children: [
      {
        path: '',
        component: SecurityComponent,
        children: [
          {
            path: '',
            component: AdminUiUsersComponent,
            data: {
              breadcrumbs: [
                {
                  path: 'security/security-admin-ui-users',
                  label: 'Security - Users',
                  clickable: false
                }
              ]
            },
          },
          {
            path: 'security-admin-ui-users',
            component: AdminUiUsersComponent,
            data: {
              breadcrumbs: [
                {
                  path: 'security/security-admin-ui-users',
                  label: 'Security - Users',
                  clickable: false
                }
              ]
            },
          },
          {
            path: 'add-edit-admin-ui-user',
            component: AddEditAdminUiUserComponent,
            data: {
              breadcrumbs: [
                {
                  path: 'security',
                  label: 'Security - Users',
                  clickable: true
                },
                {
                  path: 'security/add-edit-admin-ui-user',
                  label: 'Add/Edit User',
                  clickable: false
                }
              ]
            },
          },
          {
            path: 'security-audit-logs',
            component: AuditLogsComponent,
            data: {
              breadcrumbs: [
                {
                  path: 'security/security-audit-logs',
                  label: 'Security - Audit Logs',
                  clickable: false
                }
              ]
            },
          },
        ]
      },
    ],
  },
  {
    canActivate: [AuthGuard],
    path: 'config',
    data: {
      breadcrumbs: [
        {
          label: 'Config',
          clickable: false
        }
      ],
    },
    children: [
      {
        path: '',
        component: ConfigComponent,
        children: [
          {
            path: '',
            component: ConfigIntegrationTypesComponent,
            data: {
              breadcrumbs: [
                {
                  path: 'config/config-integration-types',
                  label: 'Config - Integration Types',
                  clickable: false
                }
              ]
            }
          },
          {
            path: 'config-integration-types',
            component: ConfigIntegrationTypesComponent,
            data: {
              breadcrumbs: [
                {
                  path: 'config/config-integration-types',
                  label: 'Config - Integration Types',
                  clickable: false
                }
              ]
            }
          },
          {
            path: 'config-filters',
            component: ConfigFiltersComponent,
            data: {
              breadcrumbs: [
                {
                  path: 'config/config-filters',
                  label: 'Out of The Box Schema Filters',
                  clickable: false
                }
              ]
            }
          },
          {
            path: 'config-environments',
            component: ConfigEnvironmentsComponent,
            data: {
              breadcrumbs: [
                {
                  path: 'config/config-environments',
                  label: 'Config - Environments',
                  clickable: false
                }
              ]
            }
          },
          {
            path: 'add-edit-integration-type',
            component: AddEditIntTypeComponent,
            data: {
              breadcrumbs: [
                {
                  path: 'config/config-integration-types',
                  label: 'Config - Integration Types',
                  clickable: false
                },
                {
                  path: 'config/add-edit-integration-type',
                  label: 'Add/Edit Integration Type',
                  clickable: false
                }
              ]
            },
          },
          {
            path: 'add-edit-filter',
            component: AddEditFilterComponent,
            data: {
              breadcrumbs: [
                {
                  path: 'config/config-filters',
                  label: 'Out of The Box Schema Filters',
                  clickable: true
                },
                {
                  path: 'config/add-edit-filter',
                  label: 'View Filter',
                  clickable: false
                }
              ]
            },
          },
          {
            path: 'add-edit-environment',
            component: AddEditEnvironmentComponent,
            data: {
              breadcrumbs: [
                {
                  path: 'config/config-environments',
                  label: 'Config - Environment',
                  clickable: false
                },
                {
                  path: 'config/add-edit-environment',
                  label: 'Add/Edit Environment',
                  clickable: false
                }
              ]
            }
          },
        ]
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {GlobalUiStateQuery} from '../../../state/global-ui-state.query';
import {GlobalUiStateService} from '../../../state/global-ui-state.service';
import {AdapterService} from '../../../services/adapter.service';
import {IntegrationDetails} from '../../../models/integration-details';
import {MessageService} from '../../../services/message-service.service';
import {AdapterIntegrationJobsComponent} from '../adapter-integration-jobs/adapter-integration-jobs.component';
import {ServiceEvent} from '../../../models/service-event';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';

@Component({
  selector: 'app-adapter-integration-nav',
  templateUrl: './adapter-integration-nav.component.html',
  styleUrls: ['./adapter-integration-nav.component.scss']
})
export class AdapterIntegrationNavComponent implements OnInit,OnDestroy {

  selectedNavPage$: string;
  selectedAdapterDetails$: Object;
  selectedIntegrationDetails$: IntegrationDetails = new IntegrationDetails(null);
  spinnerIcon: any;

  constructor(private router: Router,
              public globalStateQuery: GlobalUiStateQuery,
              private globalStateService: GlobalUiStateService,
              private adapterService: AdapterService,
              private messageService: MessageService) {
    this.spinnerIcon = faSpinner;
  }

  ngOnInit(): void {
    this.selectedNavPage$ = this.globalStateQuery.getSelectedAdapterIntegrationNavPage() ?? 'adapter-integration-details';
    this.messageService.SendEvent({type:"AdapterActive",data:{ state:"Active",comp:"AdapterIntegrationNavComponent"}})
    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    this.selectedIntegrationDetails$ = { ...this.globalStateQuery.getSelectedAdapterIntegration() };
    this.selectedIntegrationDetails$.connectionState = "unknown";

    this.messageService.onChange.subscribe(async item => {
      await this.handleEvent(item);
    });

  }

  async handleEvent(event: ServiceEvent) {

    if (event.type === "IntegrationConnectionStatusChangeEvent")  {
      this.selectedIntegrationDetails$ = { ...this.globalStateQuery.getSelectedAdapterIntegration() };
      this.selectedIntegrationDetails$.connectionState = event.data.state;
    }

  }

  ngOnDestroy(): void {
    this.messageService.SendEvent({type:"AdapterActive",data:{ state:"InActive",comp:"AdapterIntegrationNavComponent"}})
  }

  adapterIntegrationDetailsClicked(): void {
    this.selectedNavPage$ = 'adapter-integration-details';
    this.globalStateService.updateSelectedAdapterIntegrationNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-integration/adapter-integration-details']);
  }

  adapterIntegrationUsersClicked(): void {
    this.selectedNavPage$ = 'adapter-integration-users';
    this.globalStateService.updateSelectedAdapterIntegrationNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-integration/adapter-integration-users']);
  }

  adapterIntegrationScheduledJobsClicked(): void {
    this.selectedNavPage$ = 'adapter-integration-scheduled-jobs';
    this.globalStateService.updateSelectedAdapterIntegrationNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-integration/adapter-integration-scheduled-jobs']);
  }

  adapterIntegrationSchemasClicked(): void {
    this.selectedNavPage$ = 'adapter-integration-schemas';
    this.globalStateService.updateSelectedAdapterIntegrationNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-integration/adapter-integration-schemas']);
  }

  adapterIntegrationStatsClicked(): void {
    this.selectedNavPage$ = 'adapter-integration-stats';
    this.globalStateService.updateSelectedAdapterIntegrationNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-integration/adapter-integration-stats']);
  }

  adapterIntegrationQueryToolClicked(): void {
    this.selectedNavPage$ = 'adapter-integration-querytool';
    this.globalStateService.updateSelectedAdapterIntegrationNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-integration/adapter-integration-querytool']);
  }

  adapterIntegrationBatchJobClicked(): void {
    this.selectedNavPage$ = 'adapter-integration-batchjobs';
    this.globalStateService.updateSelectedAdapterIntegrationNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-integration/adapter-integration-batchjobs']);
  }

  getSelectedNavBackgroundColor(nav: string): string {
    if (nav === this.selectedNavPage$) {
      return '#898989';
    } else {
      return '';
    }
  }

  adapterAuditLogClicked(): void {
    this.selectedNavPage$ = 'adapter-integration-audit-logs';
    this.globalStateService.updateSelectedAdapterIntegrationNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-integration/adapter-integration-audit-logs']);
  }

  adapterErrorLogClicked() {
    this.selectedNavPage$ = 'adapter-integration-logs';
    this.globalStateService.updateSelectedAdapterIntegrationNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-integration/adapter-integration-logs']);
  }

  adapterIntegrationUserJobsClicked() {
    this.selectedNavPage$ = 'app-adapter-integration-users';
    this.globalStateService.updateSelectedAdapterIntegrationNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-integration/adapter-integration-users']);
  }

  adapterIntegrationJobsClicked() {
    this.selectedNavPage$ = 'app-adapter-integration-jobs';
    this.globalStateService.updateSelectedAdapterIntegrationNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-integration/app-adapter-integration-jobs']);
  }


  adapterQueueListenerClicked() {
    this.selectedNavPage$ = 'adapter-integration-queue-listener';
    this.globalStateService.updateSelectedAdapterIntegrationNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-integration/adapter-integration-queue-listener']);
  }
}

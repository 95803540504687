<script src="https://apis.google.com/js/platform.js"></script>

<mat-toolbar>
  <span> Out of The Box Schema Filter For Integration: {{filterForm.value.integrationType}}</span>
  <span class="spacer"></span>
  <div style="float:right; position: fixed; bottom: 50px; right: 2%; z-index: 100; display: flex; flex-direction: column-reverse; align-items: center;">
    <button mat-fab color="primary" aria-label="Cancel" (click)="onCancel()">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</mat-toolbar>

<div style="padding: 20px;">

  <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading" style="color: white; font-weight: bold;">Loading Filter ...</p>
  </ngx-spinner>

  <form [formGroup]="filterForm">

    <div style="width: 1200px;">
      <ngx-json-viewer [json]="filterForm.value.rawFilteredEntities"  [expanded]="true" [depth]="2"></ngx-json-viewer>
    </div>

    <div style="height: 10px"></div>

  </form>

</div>






import { EntityState } from '@datorama/akita';

export class FilterModel {
  id: string;
  integrationType: string;
  filteredEntities: [];

  public constructor(
    fields?: {
      id?: string,
      integrationType?: string,
      filteredEntities?: []
    }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export interface FiltersState extends EntityState<FilterModel> {}

import { Injectable } from '@angular/core';
import {Action} from './AuditTracker';
import {AdapterUser} from '../models/adapter-user.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdapterUserService {

  constructor(private http: HttpClient) { }

  apiUrl: string;

  createUserKeys(adapterEndpoint: string, user: AdapterUser ){
     this.apiUrl = '/api/admin/user';
     console.log('API Url: ' +adapterEndpoint + this.apiUrl);
     return this.http.post(adapterEndpoint + this.apiUrl,  JSON.stringify(user),{ observe: 'response', headers: {'Content-Type': 'application/json'},
       responseType: 'json' });
  }

  deleteKeys(adapterEndpoint: string, user: AdapterUser ){
    this.apiUrl = '/api/admin/user/'+user.email+"/"+user.type+"/keys";
    console.log('API Url: ' +adapterEndpoint + this.apiUrl);
    return this.http.delete(adapterEndpoint + this.apiUrl,  { observe: 'response', headers: {'Content-Type': 'application/json'},
      responseType: 'json' });
  }
}

<h2 mat-dialog-title> {{title}} </h2>

<ngx-spinner name="processing2" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
  <p class="loading" style="color: white; font-weight: bold; padding-top: 40px;">Processing ...</p>
</ngx-spinner>

<mat-dialog-content [formGroup]="form">

  <div style="width: 100%; padding-bottom: 10px;" [hidden]="!errorMessage">
      <div style="text-align: center; color: red">
        {{errorMessage}}
      </div>
  </div>

  <div style="width: 100%; padding-bottom: 10px;">
    <div style=" display: flex;">
      <div style=" width: 220px; text-align: right;">
        Attribute Name:
      </div>
      <div style="text-align: left; padding-left: 10px; font-weight: bold;">
        {{data.Attribute.Name}}
      </div>
      <div style="width: 75px;"></div>
    </div>
  </div>

  <div style="width: 100%; padding-bottom: 10px;">
    <div style=" display: flex;">
      <div style=" width: 220px; text-align: right;">
        Attribute Type:
      </div>
      <div style="text-align: left; padding-left: 10px; font-weight: bold;">
        {{data.Attribute.Type}}
      </div>
      <div style="width: 75px;"></div>
    </div>
  </div>

  <div style="width: 100%; padding-bottom: 10px;">
    <div style=" display: flex;">
      <div style=" width: 220px; text-align: right;">
        Schema Default Value:
      </div>
      <div style="text-align: left; padding-left: 10px; font-weight: bold;">
        {{data.Attribute.DefaultValue}}
      </div>
      <div style="width: 75px;"></div>
    </div>
  </div>

  <div style="width: 100%; padding-bottom: 5px;">
    <div style=" display: flex;">
      <div style=" width: 220px; text-align: right;">
        Default Value Override:
      </div>
      <div style="text-align: left; padding-left: 10px; font-weight: bold;">
        <mat-form-field appearance="fill">
          <mat-label>Default Value Override</mat-label>
          <input matInput placeholder="Enter a Default Value Override" formControlName="defaultOverride">
        </mat-form-field>
      </div>
      <div style="width: 75px;"></div>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions>

  <button type="button" mat-flat-button (click)="save()">Save</button>
  <button type="button" mat-stroked-button (click)="close()">Close</button>

</mat-dialog-actions>

import { Injectable } from '@angular/core';
import {ServiceEvent} from '../models/service-event';
import {EventEmitter} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor() { }

  public onChange: EventEmitter<ServiceEvent> = new EventEmitter<ServiceEvent>();

  public SendEvent(data: ServiceEvent) {
    this.onChange.emit(data);
  }

}

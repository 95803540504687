<nav>
  <div (click)="integrationTypesClicked()" [style.background-color]="getSelectedNavBackgroundColor('config-integration-types')">
    <div style="text-align: center; padding-top:15px;"><img src="assets/integrations.png" style="height: 32px;"></div>
    <div style="text-align: center; padding-top:3px; padding-bottom:15px; color: black; font-size: 8pt;">Integration Types</div>
  </div>
  <div (click)="environmentsClicked()" [style.background-color]="getSelectedNavBackgroundColor('config-environments')">
    <div style="text-align: center; padding-top:15px;"><img src="assets/envs.png" style="height: 32px;"></div>
    <div style="text-align: center; padding-top:3px; padding-bottom:15px; color: black; font-size: 8pt;">Envs</div>
  </div>
  <div (click)="filtersClicked()" [style.background-color]="getSelectedNavBackgroundColor('config-filters')">
    <div style="text-align: center; padding-top:15px;"><img src="assets/filters.png" style="height: 32px;"></div>
    <div style="text-align: center; padding-top:3px; padding-bottom:15px; color: black; font-size: 8pt;">Schema Filters</div>
  </div>
</nav>

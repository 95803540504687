<main style="text-align: center; background-color: white; height: 100%">

  <div style="width: 50%; text-align: center; padding-top: 50px; margin: auto;" >
    <img mat-card-image src="assets/construction.png" alt="Add new adapter" style="width: 100px" >
    <div>
      Page under construction.
    </div>
    <div>
      Come back soon ...
    </div>
  </div>

</main>

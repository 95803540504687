import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AdapterService} from '../../../services/adapter.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalUiStateQuery} from '../../../state/global-ui-state.query';
import {NgxSpinnerService} from 'ngx-spinner';
import {GlobalUiStateService} from '../../../state/global-ui-state.service';
import {IntegrationDetails} from '../../../models/integration-details';
import {IntegrationUser} from '../../../models/integration-user';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {DialogBoxComponent} from '../../../dialog-box/dialog-box.component';
import {ScheduledJob} from '../../../models/scheduled-job';
import {ScheduledJobAdminService} from '../../../services/scheduled-job-admin.service';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {faSatelliteDish} from '@fortawesome/free-solid-svg-icons/faSatelliteDish';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {interval, Subscription} from 'rxjs';
import {IntegrationUserService} from '../../../services/integration-user.service';
import {startWith, switchMap} from 'rxjs/operators';
import {ResetUserJobDialogComponent} from './reset-user-job-dialog/reset-user-job-dialog.component';

export enum Mode {
  none,
  edit,
  raw
}


@Component({
  selector: 'app-adapter-integration-scheduled-jobs',
  templateUrl: './adapter-integration-scheduled-jobs.component.html',
  styleUrls: ['./adapter-integration-scheduled-jobs.component.scss'] ,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AdapterIntegrationScheduledJobsComponent implements OnInit ,OnDestroy {

  @ViewChild('userTable') userTable: any;

  constructor(
    private service: AdapterService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    public globalStateQuery: GlobalUiStateQuery,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private adapterService: AdapterService,
    private globalStateService: GlobalUiStateService,
    public matDialog: MatDialog,
    private sjService: ScheduledJobAdminService,
    private userService: IntegrationUserService,
  ) {
    this.spinnerIcon = faSpinner;
    this.dishIcon = faSatelliteDish;
  }

  ColumnMode = ColumnMode;
  selectedAdapterDetails$: Object;
  selectedIntegrationDetails$: IntegrationDetails;
  spinnerIcon: any;
  dishIcon: any;
  buttons = [];
  listners = new Map<IntegrationUser,Subscription>();
  showMenu: any | boolean;

  ///Authed user
  authedUser;

  /// Jobs
  displayedColumns: string[] = ['jobDetail', 'CRON', 'status', 'lastRunResult', 'lastRunResultCode', 'action'];
  showScheduledUserJobDetails = true;
  showRawJobView = false;
  scheduledJob: ScheduledJob = new ScheduledJob(null);
  scheduledJobForm: UntypedFormGroup;
  showJobEditView = false;
  rawSJJason: any;
  timeout: any;
  jobList: ScheduledJob[] = null;

  ngOnInit(): void {
    console.log("ngOnInit")
    window.onbeforeunload = () => this.ngOnDestroy();
    this.authedUser = this.globalStateQuery.getCachedUser();
    this.updateFabButtons(Mode.none);

    //Jobs
    this.scheduledJobForm = this.formBuilder.group({
        jobType: [''],
        cronExpression: ['', Validators.required],
        status: ['Idle', Validators.required],
        statusChangeDateTime: [''],
        lastStartedDateTime: [''],
        lastStoppedDateTime: [''],
        lastSuccessDateTime: [''],
        lastRunResult: [''],
        lastRunResultCode: [''],
        lastRunException: [''],
        lastFailureDateTime: [''],
        failedAttempts: [''],
        lastRunDurationInSecs: [''],
        disabledAtDateTime: [''],
        jobDetail: ['']
      }
    );

    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();
    this.refreshJobList();

  }

  getRowClass = (row) => {
    return {
      'entitysync-row-color': row.jobType == "EntitySync",
      'not-entitysync-row-color': row.jobType != "EntitySync",
    };
  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log('paged!', event);
    }, 100);
  }

  async refreshJobList() {

    await this.spinner.show("processing");
    this.sjService.listScheduledJobsForIntegration(this.selectedAdapterDetails$['endpoint'],
      this.selectedIntegrationDetails$.integrationId).subscribe(
      result => {
        const newJobResultList = Object.keys(result.body).map(i =>
          (new ScheduledJob(result.body[i]))
        );

        const systemJobs = [];
        const entitySyncJobs = [];

        newJobResultList.forEach(sj => {
          if (sj.jobType === "EntitySync")  {
            entitySyncJobs.push(sj);
          } else {
            systemJobs.push(sj);
          }
        });
        this.jobList = systemJobs.concat(entitySyncJobs);

        this.changeDetectorRefs.detectChanges();
      },
      async error => {
        console.log('Error ' + JSON.stringify(error));
        console.log('Error status ' + error.status);
        if (error.name === "TimeoutError") {
          this.showToaster('List jobs call failed. Call Timed out!', 1);
        } else {
          this.showToaster('List jobs call failed. Status code: ' + error.status, 1);
        }

        await this.spinner.hide("processing");
      },
      async () => {
        await this.spinner.hide("processing");
      }
    );

  }

//Utility Methods
  showToaster(message, statusCode) {
    statusCode === 0 ? this.toastr.success(message) : this.toastr.error(message);
  }

  toggleExpandSelectedEntitiesRow(row: any, expanded: any) {
    row['isExpanding'] = !expanded;
    this.userTable.rowDetail.toggleExpandRow(row);
  }

  processFabButtonClick(buttonId) {

    if (buttonId == 1) { // cancel edit mode
      this.showScheduledUserJobDetails = true;
      this.showJobEditView = false;
      this.showRawJobView = false;
      this.rawSJJason = "";
      this.scheduledJob = new ScheduledJob(null);
      this.updateFabButtons(Mode.none);
    }
    else if (buttonId == 3)  {
      if (!this.scheduledJobForm.valid)  {
        this.showToaster('Form Validation failed. Please address validation errors ...', 1);
      }
      else {
        this.saveScheduledJob(Mode.edit);
      }
    }
    else if (buttonId == 4)  {
      this.openResetJobDialog();
    }else if (buttonId == 2) {
      this.openViewRawJsonDialog();
    }
    else if (buttonId == 5) {
      this.saveRawJason();
    }
    else if (buttonId == 6) {
      this.openReloadAndWipeJobsDialog();
    }
  }

  openReloadAndWipeJobsDialog() {
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '350px',
      data: {
        dataObj: {action: 'Reload'},
        btn1Action: 'Reload',
        title: 'Scheduled Jobs',
        message: 'Are you sure you want to reload all Scheduled Job? Any jobs added after initial load and all history and metadata within existing jobs will be lost! '
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result.event === 'Reload') {
        await this.reloadScheduledJobs();
      }
    });
  }

  async reloadScheduledJobs() {
    await this.spinner.show("processing");
    this.sjService.refreshScheduledJob(this.selectedAdapterDetails$['endpoint'],
      this.selectedIntegrationDetails$.integrationId).subscribe(
      async result => {
        this.refreshSelectedIntegration();
      },
      error => {
        this.spinner.hide("processing");
        console.log('Error ' + JSON.stringify(error));
        console.log('Error status ' + error.status);
        this.showToaster('reloadScheduledJobs call failed. Status code: ' + error.status, 1);
      },
      () => {
        this.spinner.hide("processing");
      }
    );
  }

  refreshSelectedIntegration() {

    this.adapterService.listIntegrations(this.selectedAdapterDetails$['endpoint']).subscribe(
      async result => {

        const integrationList = Object.keys(result.body).map(i =>
          new IntegrationDetails(result.body[i])
        );
        const filteredIntegrationList = integrationList.filter(x => x.integrationId === this.selectedIntegrationDetails$.integrationId);
        const integration = filteredIntegrationList[0];
        this.globalStateService.updateSelectedAdapterIntegration(integration);
        this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();
        await this.refreshJobList();
      },
      error => {
        if (error.name === "TimeoutError") {
          this.showToaster('List Integrations call failed. Call Timed out!', 1);
        } else {
          this.showToaster("List Integrations failed. Status code: " + error.status, 1);
        }
      },
      () => {
      }
    );

  }

  updateFabButtons(mode: Mode) {

    const updatedFabButtons = [];

    if( mode == Mode.none ) {

      updatedFabButtons.push({
        id: 6,
        icon: 'undo',
        tooltip: 'Reload Scheduled Jobs',
        tooltipPosition: 'left'
      });

      this.showMenu = true;
    }
    else if( mode == Mode.edit ){
      this.showMenu = true;
      updatedFabButtons.push({
        id: 3,
        icon: 'save',
        tooltip: 'Save',
        tooltipPosition: 'left'
      });
      updatedFabButtons.push({
        id: 4,
        icon: 'undo',
        tooltip: 'Reset',
        tooltipPosition: 'left'
      });
      updatedFabButtons.push({
        id: 2,
        icon: 'code',
        tooltip: 'View Raw JSON',
        tooltipPosition: 'left'
      });
      updatedFabButtons.push({
        id: 1,
        icon: 'cancel',
        tooltip: 'Cancel',
        tooltipPosition: 'left'
      });
    }else if( mode == Mode.raw)
    {
      this.showMenu = true;
      updatedFabButtons.push({
        id: 5,
        icon: 'save',
        tooltip: 'Save',
        tooltipPosition: 'left'
      });
      updatedFabButtons.push({
        id: 1,
        icon: 'cancel',
        tooltip: 'Cancel',
        tooltipPosition: 'left'
      });
    }

    this.buttons = JSON.parse(JSON.stringify(updatedFabButtons));
  }

  async onSelectedEntityDetailToggle(event: any) {
    let user = event.value;
    if (user !== null && user['isExpanding'] == true) {
      await this.laodUserJobs(user);
      this.changeDetectorRefs.detectChanges();
    }else if (user !== null && user['isExpanding'] == false) {
      this.listners.get(user).unsubscribe();
      this.listners.delete(user);
    }

  }

  //Utility Methods for jobs
  noOp() {
  }

  editScheduledJobClicked(job: any) {
    this.updateFabButtons(Mode.edit);

    this.showScheduledUserJobDetails = false;
    this.showJobEditView = true;
    this.showRawJobView = false;
    this.scheduledJob = job;
    //this.currentUser = user;
    this.initializeScheduledJobForm();
    console.log(job)
  }

  runScheduledJobClicked(schJob: ScheduledJob) {
    this.sjService.executeScheduledJob(this.selectedAdapterDetails$['endpoint'], schJob).subscribe(
      async result => {
        this.showToaster('Scheduled job ' + schJob.jobId + ' queued for execution.', 0);
        schJob.status = "Queueing";
        console.log('Call runScheduledJobClicked ' + schJob.jobId);
      },
      error => {

        console.log('Error ' + JSON.stringify(error));
        console.log('Error status ' + error.status);
        this.showToaster('Run scheduled job call failed. Status code: ' + error.status, 1);
      },
      async () => {

      }
    );
  }

  scheduleScheduledJob(scheduledJob: ScheduledJob) {
    console.log(scheduledJob);
    if (scheduledJob.status === 'Disabled') {
      this.openEnableJobDialog('Enable', scheduledJob);
    } else {
      this.processScheduledJobToHangfire(scheduledJob);
    }
  }

  cancelRunningScheduledJob(schJob) {
    this.sjService.cancelScheduledJob(this.selectedAdapterDetails$['endpoint'], schJob).subscribe(
      async result => {
        this.showToaster('Scheduled job ' + schJob.jobId + ' cancelled.', 0);
        await this.refreshJobList();
      },
      error => {
        console.log('Error ' + JSON.stringify(error));
        console.log('Error status ' + error.status);
        this.showToaster('Cancel scheduled job call failed. Status code: ' + error.status, 1);
      },
      () => {
      }
    );
  }

  onSelectedEntityDetailToggleShow($event: any) {
    console.log($event)
  }

  async laodUserJobs(user: any, showLoading:boolean = true) {

    if( user.authType == 1 )
    {
      this.showToaster('Unable to show user jobs as the user is not authorized.', 1);
      return;
    }
    if( showLoading)
    {
      this.spinner.show('loadingjobs');
    }

    await this.sjService.listScheduledJobsForIntegrationUser(this.selectedAdapterDetails$['endpoint'],
      this.selectedIntegrationDetails$.integrationId, user['email']).toPromise().then(result => {
      if (result.status == 200) {

        user['jobs'] = [];

        const newScheduledJobsResultList = Object.keys(result.body).map(i => new ScheduledJob(result.body[i]));

        var systemJobs = [];
        var entitySyncJobs = [];

        newScheduledJobsResultList.forEach(sj => {
          if (sj.jobType === "EntitySync")  {
            entitySyncJobs.push(sj);
          } else {
            systemJobs.push(sj);
          }
        });
        user['jobs'] = systemJobs.concat(entitySyncJobs);
        if( !this.listners.has(user))
        {
          var x = interval(10000).pipe(startWith(0),switchMap(()=> this.laodUserJobs(user,false))).subscribe(err => console.log(err));
          this.listners.set(user,x);
        }

        this.spinner.hide('loadingjobs');
      } else {
        this.spinner.hide('loadingjobs');
      }
    }).catch(reason => {
      this.spinner.hide('loadingjobs');
    });
  }

  openEnableJobDialog(action, job :ScheduledJob) {
    job['action'] = action;
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '250px',
      data: {
        dataObj: job,
        title: 'Scheduled Job',
        message: 'Would you like to enable Scheduled Job  ' + job.jobId,
        btn1Action: 'Enable',
        action: 'Enable'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Event = ' + result.event);
      if (result.event === 'Enable') {
        console.log('Enable ' + JSON.stringify(result.data));
        result.data.dataObj.status = 'Idle';
        this.updateScheduledJobObject(result.data.dataObj);
        this.processScheduledJobToHangfire(result.data.dataObj);
      }
    });

  }

  updateScheduledJobObject(schJob) {
    console.log('Scheduled Job ' + JSON.stringify(schJob));
    this.sjService.updateScheduledJob(this.selectedAdapterDetails$['endpoint'], schJob).subscribe(
      async result => {
        this.showToaster('Scheduled Job updated', 0);
      },
      error => {
        console.log('Error ' + JSON.stringify(error));
        console.log('Error status ' + error.status);
        this.showToaster('Create scheduled job call failed. Status code: ' + error.status, 1);
      },
      () => {

      }
    );
  }

  processScheduledJobToHangfire(schJob) {
    if (schJob.isScheduled === false) {
      this.sjService.scheduleScheduledJob(this.selectedAdapterDetails$['endpoint'], schJob).subscribe(
        async result => {
          this.showToaster('Scheduled job ' + schJob.jobId + ' Scheduled.', 0);
          schJob.isScheduled = true;
          console.log('Call scheduleScheduledJob ' + schJob.jobId);
        },
        error => {
          console.log('Error ' + JSON.stringify(error));
          console.log('Error status ' + error.status);
          this.showToaster('Schedule scheduled job call failed. Status code: ' + error.status, 1);
        },
        () => {
        }
      );
    } else {
      this.sjService.unScheduleScheduledJob(this.selectedAdapterDetails$['endpoint'], schJob).subscribe(
        async result => {
          this.showToaster('Scheduled job ' + schJob.jobId + ' removed from scheduled.', 0);
          schJob.isScheduled = false;
          console.log('Call scheduleScheduledJob ' + schJob.jobId);
        },
        error => {
          console.log('Error ' + JSON.stringify(error));
          console.log('Error status ' + error.status);
          this.showToaster('Schedule scheduled job call failed. Status code: ' + error.status, 1);
        },
        () => {
        }
      );
    }
  }

  saveScheduledJob(mode: Mode) {
    if( mode == Mode.edit )
    {
      this.getEditedJobData();
    }

    this.sjService.updateScheduledJob(this.selectedAdapterDetails$['endpoint'], this.scheduledJob).subscribe(
      async result => {
        this.showToaster('Scheduled Job updated', 0);
        this.showScheduledUserJobDetails = true;
        this.showJobEditView = false;
        this.showRawJobView = false;
        await this.refreshJobList();
        this.scheduledJob = new ScheduledJob(null);
        //this.currentUser = null;
        this.updateFabButtons(Mode.none);
      },
      error => {
        console.log('Error ' + JSON.stringify(error));
        console.log('Error status ' + error.status);
        this.showToaster('Update scheduled job call failed. Status code: ' + error.status, 1);
      },
      () => {

      }
    );
  }

  getEditedJobData() {
    console.log('Gathering changes');
    this.scheduledJob.cronExpression = this.scheduledJobForm.controls.cronExpression.value;
  }


  openResetJobDialog() {
    const dialogRef = this.dialog.open(ResetUserJobDialogComponent, {
      width: '350px',
      data: {
        title: 'Scheduled Job',
        message: 'Are you sure you want to Reset Scheduled Job [' + this.scheduledJob.name + ']? All history and metadata for this job will be lost! ',
        jobType: this.scheduledJob["jobType"]
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log(result);
      if (result.selection === 'yes') {
        await this.resetScheduledJobRaw(result.preserveNextRunDate);
      }
    });
  }

  async resetScheduledJobRaw(preserveNextFetchStartDate) {
    var sj = new ScheduledJob(null);
    sj.cronExpression = this.scheduledJob.cronExpression;
    sj.integrationId = this.scheduledJob.integrationId;
    sj.jobId = this.scheduledJob.jobId;
    sj.jobType = this.scheduledJob.jobType;
    sj.status = "Idle";
    sj.isScheduled = this.scheduledJob.isScheduled;
    sj.integrationType = this.scheduledJob.integrationType;
    sj.name = this.scheduledJob.name;
    sj.userId = this.scheduledJob.userId

    if (sj.jobType === "EntitySync") {
      sj.jobDetail = {
        "EntityType": this.scheduledJob.jobDetail["EntityType"],
        "assembly": this.scheduledJob.jobDetail["assembly"],
        "class": this.scheduledJob.jobDetail["class"]
      };
      if (preserveNextFetchStartDate ==  true)  {
        sj.jobDetail["NextFetchStartDate"] = this.scheduledJob.jobDetail["NextFetchStartDate"];
      }
    } else if (sj.jobType === "SchemaCheck")  {
      sj.jobDetail = {
        "assembly": this.scheduledJob.jobDetail["assembly"],
        "class": this.scheduledJob.jobDetail["class"],
        "schemaFormat": this.scheduledJob.jobDetail["schemaFormat"]
      };
      if (preserveNextFetchStartDate ==  true)  {
        sj.jobDetail["NextFetchStartDate"] = this.scheduledJob.jobDetail["NextFetchStartDate"];
      }
    } else {
      sj.jobDetail = {
        "assembly": this.scheduledJob.jobDetail["assembly"],
        "class": this.scheduledJob.jobDetail["class"]
      };
    }

    this.scheduledJob = sj;
    this. saveScheduledJob(Mode.raw);
  }

  openViewRawJsonDialog() {
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '350px',
      data: {
        dataObj: { action: 'ViewRaw' },
        btn1Action: 'ViewRaw',
        title: 'Raw Scheduled Job JSON',
        message: 'Warning!!! Modifying the raw Scheduled Job JSON can have unintended consequences. Process at your own risk!'
      },

    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result.event === 'ViewRaw') {
        this.rawSJJason = JSON.stringify(this.scheduledJob, null, 2);
        this.updateFabButtons(Mode.raw);
        this.showScheduledUserJobDetails = false;
        this.showJobEditView = false;
        this.showRawJobView = true;
      }
    });
  }

  initializeScheduledJobForm() {
    this.scheduledJobForm.controls.jobType.reset(this.scheduledJob.jobType);
    this.scheduledJobForm.controls.cronExpression.reset(this.scheduledJob.cronExpression);
    this.scheduledJobForm.controls.status.reset(this.scheduledJob.status);
    this.scheduledJobForm.controls.lastRunResult.reset(this.scheduledJob.lastRunResult);
    this.scheduledJobForm.controls.statusChangeDateTime.reset(this.scheduledJob.statusChangeDateTime);
    this.scheduledJobForm.controls.lastStartedDateTime.reset(this.scheduledJob.lastStartedDateTime);
    this.scheduledJobForm.controls.lastStoppedDateTime.reset(this.scheduledJob.lastStoppedDateTime);
    this.scheduledJobForm.controls.lastSuccessDateTime.reset(this.scheduledJob.lastSuccessDateTime);
    this.scheduledJobForm.controls.lastRunResultCode.reset(this.scheduledJob.lastRunResultCode);
    this.scheduledJobForm.controls.lastRunException.reset(this.scheduledJob.lastRunException);
    this.scheduledJobForm.controls.lastFailureDateTime.reset(this.scheduledJob.lastFailureDateTime);
    this.scheduledJobForm.controls.failedAttempts.reset(this.scheduledJob.failedAttempts);
    this.scheduledJobForm.controls.lastRunDurationInSecs.reset(this.scheduledJob.lastRunDurationInSecs);
    this.scheduledJobForm.controls.disabledAtDateTime.reset(this.scheduledJob.disabledAtDateTime);
  }

  private saveRawJason() {
    console.log(this.scheduledJob)
    var newJob = <ScheduledJob>JSON.parse(this.rawSJJason);
    newJob.jobId = this.scheduledJob.jobId;
    newJob.userId = this.scheduledJob.userId;
    newJob.integrationId = this.scheduledJob.integrationId;
    newJob.jobType = this.scheduledJob.jobType;
    newJob.name = this.scheduledJob.name;
    newJob.integrationType = this.scheduledJob.integrationType;
    newJob.isScheduled = this.scheduledJob.isScheduled;
    this.scheduledJob = newJob;
    this.saveScheduledJob(Mode.raw);
  }

  /* openReloadAndWipeJobsDialog() {
     //this.currentUser = user;
     const dialogRef = this.dialog.open(DialogBoxComponent, {
       width: '350px',
       data: {
         dataObj: {action: 'Reload'},
         btn1Action: 'Reload',
         title: 'Scheduled Jobs',
         message: 'Are you sure you want to reload all Scheduled Job? Any jobs added after initial load and all history and metadata within existing jobs will be lost! '
       },
     });

     dialogRef.afterClosed().subscribe(async result => {
       if (result.event === 'Reload') {
         await this.refreshJobList();
       }
       //this.currentUser = null;
     });
   }*/

  /* async reloadScheduledJobs() {
     await this.spinner.show("processing");
     this.sjService.refreshScheduledJob(this.selectedAdapterDetails$['endpoint'],
       this.selectedIntegrationDetails$.integrationId,this.currentUser.email).subscribe(
       async result => {
         this.userTable.rowDetail.collapseAllRows();
         this.userTable.rowDetail.toggleExpandRow(this.currentUser);
         this.currentUser = null;
       },
       error => {
         this.spinner.hide("processing");
         this.currentUser = null;
         console.log('Error ' + JSON.stringify(error));
         console.log('Error status ' + error.status);
         this.showToaster('reloadScheduledJobs call failed. Status code: ' + error.status, 1);
       },
       () => {
         this.currentUser = null;
         this.spinner.hide("processing");
       }
     );
   }*/

  /*getAuthType(user: any) {
    switch (user.authType) {
      case 1:
        return "None";
      case 2:
        return "OAuth2Authorization";
      case 4:
        return "CustomAuth";
      default:
        return "N/A";
    }
  }*/


  ngOnDestroy(): void {
    console.log("ngOnDestroy scheduled jobs")
    this.listners.forEach( (value: Subscription, key: IntegrationUser) => value.unsubscribe())
  }
}


export class IntegrationSchemaEntity {

  constructor(mapValues) {
    if (mapValues != null) {

      this.Description = mapValues['Description'];
      this.EntityName = mapValues['EntityName'];
      this.State = mapValues['State'];
      this.Version =  mapValues['Version'];
      this.AttributeDefinitions = JSON.parse(JSON.stringify(mapValues['AttributeDefinitions']));
    }
  }
  Description: string;
  EntityName: string;
  AttributeDefinitions: object;
  State: string;
  Version: number;
  Sync: boolean = false;

}

export class IntegrationPropertyMapper {

  private static _integrationProperties: Map<string, string> = new Map<string, string>([
      ['name', 'name'],
      ['environment', 'environment'],
      ['endpoint', 'endPoint'],
      ['deepLinkUrl', 'deepLinkURL'],
      ['dateTimeFormat', 'dateTimeFormat'],
      ['maxPageSize', 'maxPageSize'],
      ["defaultPageSize","defaultPageSize"],
      ["connectionTimeout","connectionTimeout"],
      ["responseTimeout","responseTimeout"],
      ["deepLinkMagicNumber","deepLinkMagicNumber"],
      ["revokingEndpoint","revokingEndpoint"]
    ]
  );

  static GetMappedKey(key: string)
  {
    var returnKey = IntegrationPropertyMapper._integrationProperties.get(key);
    if( returnKey == undefined )
      return key;
    return returnKey;
  }
}

import {Inject, Injectable} from '@angular/core';
import {SESSION_STORAGE, StorageService} from 'ngx-webstorage-service';
import {GlobalUiStateService} from '../state/global-ui-state.service';
import {GlobalUiStateQuery} from '../state/global-ui-state.query';
import {APIService} from '../API.service';

export enum Action{
  Create = "Create",
  Update = "Update",
  List = "List",
  Delete = "Delete",
  Get = "Get",
  Set = "Set",
  Clear = "Clear",
  Execute = "Execute"
}

@Injectable({
  providedIn: 'root'
})

export class AuditTracker {

  constructor( @Inject(SESSION_STORAGE) private storage: StorageService,
               public globalStateQuery: GlobalUiStateQuery,
               private api: APIService) {
  }
  adapterType: String
  integration: String

  log(action: Action, msg: string, payload: string = "{}") {
    if ( action == Action.Get || action == Action.List)
      return;
    var user = this.globalStateQuery.getCachedUser();
    var selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    var selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();

    var adapterType = "";
    if( selectedAdapterDetails$ !== undefined )
      adapterType = selectedAdapterDetails$.adapterType;

    var integration = "";
    if(selectedIntegrationDetails$ != undefined )
     integration = selectedIntegrationDetails$.integrationId;

    const envPrefix =  this.globalStateQuery.getSelectedEnvPrefix();
    var futureDate = new Date();
    var ttl = (selectedAdapterDetails$.auditTtl == undefined ? 7 : selectedAdapterDetails$.auditTtl);

    futureDate.setDate(futureDate.getDate() + ttl);

    var logMessage = {
      'envPrefix':envPrefix,
      'adapterType': adapterType,
      'integration': integration,
      'userName': user.name,
      'action': action,
      'message': msg,
      'payload': payload,
      'expirationUnixTime': Math.round(futureDate.getTime() / 1000)
    };
    this.api.CreateAuditLog(logMessage).then(r => r.logTs);
  }
}


<div id="modal-content-wrapper">
  <header id="modal-header">
    <h1 id="modal-title">Clear Redis Result</h1>
  </header>
  <section id="modal-body">
    <mat-list>
      <mat-list-item> Total Records : {{totalKeys}} </mat-list-item>
      <mat-list-item> Keys Removed : {{keysRemoved}} </mat-list-item>
      <mat-list-item> Failed Keys Removed : {{failedKeysRemoved}} </mat-list-item>
      <mat-list-item> Stats Keys Removed : {{statsKeysRemoved}} </mat-list-item>

    </mat-list>
  </section>
  <footer id="modal-footer">
    <button mat-raised-button id="modal-cancel-button" (click)="closeModal()">Close</button>
  </footer>
</div>


<main style="height: 100%; position: relative;">

  <ngx-spinner name="processing" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading" style="color: white; font-weight: bold; padding-top: 40px;">Processing ...</p>
  </ngx-spinner>


  <mat-toolbar style="background-color: white;">
    <span>User Management</span>
    <div  style="float:right; position: fixed; bottom: 30px; right: 2%; z-index: 100; display: flex; flex-direction: column-reverse; align-items: center;">
      <mat-fab-menu color="primary"
                    [fabButtons]="buttons"
                    [hidden]="( authedUser.role !== 'admin') || ( selectedIntegrationDetails$.individualContributor)"
                    icon="menu"
                    (onFabMenuItemSelected)="processFabButtonClick($event)">
      </mat-fab-menu>
    </div>
    <span class="spacer"></span>
  </mat-toolbar>

  <form [formGroup]="userForm" >

    <div style="width: 98%; position: relative; min-height: 400px; margin: auto;" >

      <ngx-spinner name="loadingjobs" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
        <p class="loading" style="color: white; font-weight: bold; padding-top: 40px;">Loading user details ...</p>
      </ngx-spinner>

      <ngx-datatable
        #userTable
        class="material expandable"
        [columnMode]="ColumnMode.force"
        [headerHeight]="60"
        [footerHeight]="40"
        [rowHeight]="60"
        [rows]="userResultList"
        [limit]="10">

        <!--Detail Section -->
        <ngx-datatable-row-detail [rowHeight]="'100%'" (toggle)="onSelectedEntityDetailToggle($event)">

          <ng-template let-user2="row" let-expanded="expanded" ngx-datatable-row-detail-template>

            <div style="margin-bottom: 15px; width: 100%">

              <mat-card class="example-card">
                <mat-card-title>User Information</mat-card-title>
                <mat-card-content>
                  <div [hidden]="(_env == 'production')">
                    <mat-form-field style="width: 300px !important;" [hidden]="!getVisual(user2)">
                      <mat-label>Shared User</mat-label>
                      <input matInput type="text" formControlName="sharedUserId{{user2.userId}}" required  [defaultValue]="user2.sharedUser" value="{{user2.sharedUser?.trim().length > 0 ? user2.sharedUser : 'N/A'}}">
                    </mat-form-field>
                    <span style="display: flex; align-items: start;" [hidden]="getVisual(user2)" style="margin-top: 15px; font-weight: bold;">
                      <span style="font-weight: normal;">Shared User :</span>  {{user2.sharedUser?.trim().length > 0 ? user2.sharedUser : "N/A"}}
                    </span>
                  </div>
                </mat-card-content>
                <mat-divider inset></mat-divider>
                <mat-card-actions>

                </mat-card-actions>
                <mat-card-footer>
                  <div  style=" width: 100%; text-align: right;">
                    <button  type="button" mat-flat-button (click)="processEditButtonClick($event,user2)" [hidden]="getVisual(user2)" class="editButton" >Edit</button>
                    <button  type="button" mat-flat-button (click)="processCancelButtonClick($event,user2)"  [hidden]="!getVisual(user2)" class="cancelButton">Cancel</button>
                    <button  type="button" mat-flat-button (click)="processSaveButtonClick($event,user2)" class="saveButton" [disabled]="!getVisual(user2)">Save</button>
                  </div>
                </mat-card-footer>
              </mat-card>

            </div>

          </ng-template>

        </ngx-datatable-row-detail>

        <!--Basic Table -->
        <ngx-datatable-column
          [width]="50"
          [resizeable]="false"
          [sortable]="false"
          [draggable]="false"
          [canAutoResize]="false">
          <ng-template let-user="row" let-expanded="expanded" ngx-datatable-cell-template>
            <mat-icon (click)="toggleExpandSelectedEntitiesRow(user,expanded)" title="Expand/Collapse Row" style="color: black; cursor: pointer;">
              {{expanded ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Email" [width]="300" [sortable]="false" [canAutoResize]="false">
          <ng-template let-user="row" ngx-datatable-cell-template>
            <strong  style="font-size: 12pt;">{{ user['email'] }}</strong>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Auth Type" [width]="300" [sortable]="false" [canAutoResize]="false">
          <ng-template let-user="row" ngx-datatable-cell-template>
            <strong style="font-size: 12pt;">
              {{getAuthType(user)}}</strong>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="System Account" [width]="300" [sortable]="false" [canAutoResize]="false">
          <ng-template let-user="row" ngx-datatable-cell-template>
            <input type="radio" name="systemAccountUserRadio"
                   [id]="user.email"
                   [value] = user.email
                   [checked] ="user.isSystemAccount"
                   [disabled]="user.isSystemAccount"
                   (click) = updateUser(user,$event)
            >
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Actions" [width]="300" [sortable]="false" [canAutoResize]="false">
          <ng-template let-user="row" ngx-datatable-cell-template>
            <strong  style="font-size: 12pt;">
              <a (click)="viewUserSettings(user)" style="color: blue; cursor: pointer;"><mat-icon matTooltip="View" style="color:green">visibility</mat-icon></a>
<!--              <mat-icon style="color:gray" [hidden] ="user.isSystemAccount && userResultList.length>1">more_vert</mat-icon>-->
<!--              <a (click)="processFabButtonClick(6)" style="color: blue; cursor: pointer;" [hidden] ="user.isSystemAccount && userResultList.length>1">-->
<!--                <mat-icon matTooltip="Re-Authenticate" style="color:black">vpn_key</mat-icon>-->
<!--              </a>-->
              <mat-icon style="color:gray" [hidden] ="user.isSystemAccount && userResultList.length>1">more_vert</mat-icon>
              <a (click)="openDeleteDialog('Delete', user)" style="color: blue; cursor: pointer;" [hidden] ="user.isSystemAccount && userResultList.length>1">
                <mat-icon matTooltip="Delete" style="color:red">delete</mat-icon>
              </a>
              <mat-icon style="color:gray" [hidden]="hideRevokeIcon(user)">more_vert</mat-icon>
              <a (click)="revokeToken(user)" [hidden]="hideRevokeIcon(user) || userResultList.length>1" style="color: blue; cursor: pointer;">
                <mat-icon matTooltip="Revoke Credentials" style="color:red">undo</mat-icon>
              </a>
            </strong>
          </ng-template>
        </ngx-datatable-column>

      </ngx-datatable>

    </div>

  </form>

</main>

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-auditlogdialog',
  templateUrl: './auditlogdialog.component.html',
  styleUrls: ['./auditlogdialog.component.scss']
})
export class AuditlogdialogComponent implements OnInit {

  jsondata = null;
  titleBase = 'Attributes';
  title = '';
  stackTrace = null;

  constructor(public dialogRef: MatDialogRef<AuditlogdialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
      this.jsondata = this.data.json;
      this.stackTrace = this.data.error;
      this.title = [this.data.action, this.data.message].join(' ');
  }

  close() {
    this.dialogRef.close();
  }
}

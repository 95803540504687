import {Component, Inject, OnInit} from '@angular/core';
import {Auth} from 'aws-amplify';
import {ActivatedRoute} from '@angular/router';
import {Hub} from '@aws-amplify/core';
import {CognitoIdentityServiceProvider} from 'aws-sdk';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  statusMessage: String;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {

    this.route.queryParams
      .subscribe(params => {
          if (params["status"]) {
            if (params["status"] === "1")  {
              this.statusMessage = "Login Error: User not found. Contact the Toolkit Team to have your account created."
            }
          }
        }
      );
  }

  async doFederatedLogin(): Promise<void> {
    await Auth.federatedSignIn();

  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {AdapterModel} from '../../state/adapter.model';
import {SESSION_STORAGE, StorageService} from 'ngx-webstorage-service';
import {GlobalUiStateQuery} from '../../../state/global-ui-state.query';
import {GlobalUiStateService} from '../../../state/global-ui-state.service';
import {Order} from '@datorama/akita';
import {IntegrationTypesService} from '../../../config/config-integration-types/state/integration-types.service';
import {IntegrationTypesQuery} from '../../../config/config-integration-types/state/integration-types.query';

@Component({
  selector: 'app-adapter-details-header',
  templateUrl: './adapter-details-header.component.html',
  styleUrls: ['./adapter-details-header.component.scss']
})
export class AdapterDetailsHeaderComponent implements OnInit {

  selectedAdapterDetails$: Object;
  adapterLogoFilename: string;

  constructor( private globalStateQuery: GlobalUiStateQuery,
               private globalStateService: GlobalUiStateService,
               @Inject(SESSION_STORAGE) private storage: StorageService,
               private integrationTypesQuery: IntegrationTypesQuery,
               private integrationTypesService: IntegrationTypesService) { }

  ngOnInit(): void {
    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    this.integrationTypesService.getIntegrationTypes().then(() =>
      {
        let integrationTypes = this.integrationTypesQuery.selectAll({ sortBy: 'displayName', sortByOrder: Order.ASC });
        integrationTypes.subscribe(list =>
          list.forEach(integrationType => {
            if (integrationType["code"] === this.selectedAdapterDetails$["adapterType"]) {
              this.adapterLogoFilename = integrationType["logoFilename"];
            }
          })
        );
      }
    );

    document.documentElement.style.setProperty('--width', '70%');

  }

}

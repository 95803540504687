import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GlobalUiStateQuery} from '../state/global-ui-state.query';

@Injectable()
export class AuthInterceptor  implements HttpInterceptor {

  constructor(private globalStateQuery: GlobalUiStateQuery) {}

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    const outOfTheBoxSchemaFilterMatcher = req.url.match(/\/outoftheboxschemafilter/);
    const directCallHealthCheckMatcher = req.url.match(/directCall=true/);

    if (outOfTheBoxSchemaFilterMatcher || directCallHealthCheckMatcher)
    {
      const jwtToken = this.globalStateQuery.getCachedDirectCallAdapter().jwtToken;
      if (jwtToken && !req.url.includes('requesttoken')) {
        const newIdToken = jwtToken.replace(/^"|"$/g, '');
        const cloned = this.globalStateQuery.getSelectedImpersonatedEnvPrefix() ? req.clone({
          headers: req.headers.set('Authorization',
            'Bearer ' + newIdToken),
          params: req.params.set("impersonatedEnvPrefix", this.globalStateQuery.getSelectedImpersonatedEnvPrefix())
        }) : req.clone({
          headers: req.headers.set('Authorization',
            'Bearer ' + newIdToken)
        });
        return next.handle(cloned);
      }
    }

    const adapterMetadataMatcher = req.url.match(/\/adaptermetadata/);
    if (adapterMetadataMatcher)
    {
      const cloned = this.globalStateQuery.getSelectedImpersonatedEnvPrefix() ? req.clone({
        params: req.params.set("impersonatedEnvPrefix", this.globalStateQuery.getSelectedImpersonatedEnvPrefix())
      }) : req.clone();
      return next.handle(cloned);
    }

    const selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    const connectionDetails = this.globalStateQuery.getCachedAdapterConnectionDetails()[selectedAdapterDetails$['adapterId']];

    if (connectionDetails && connectionDetails.jwtToken && !req.url.includes('requesttoken')) {
      const newIdToken = connectionDetails.jwtToken.replace(/^"|"$/g, '');
      const cloned = this.globalStateQuery.getSelectedImpersonatedEnvPrefix() ? req.clone({
        headers: req.headers.set('Authorization',
          'Bearer ' + newIdToken),
        params: req.params.set("impersonatedEnvPrefix", this.globalStateQuery.getSelectedImpersonatedEnvPrefix())
      }) : req.clone({
        headers: req.headers.set('Authorization',
          'Bearer ' + newIdToken)
      });

      return next.handle(cloned);
    }
    else {
      const cloned = this.globalStateQuery.getSelectedImpersonatedEnvPrefix() ? req.clone({
        headers: req.headers.set('X-Permitted-Cross-Domain-Policies',
          'none '),
        params: req.params.set("impersonatedEnvPrefix", this.globalStateQuery.getSelectedImpersonatedEnvPrefix())
      }) : req.clone({
        headers: req.headers.set('X-Permitted-Cross-Domain-Policies',
          'none ')
      });
      return next.handle(cloned);
    }
  }
}

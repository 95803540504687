import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {BatchService} from '../../../services/batch.service';
import {IntegrationDetails} from '../../../models/integration-details';
import {GlobalUiStateQuery} from '../../../state/global-ui-state.query';
import {ActivatedRoute} from '@angular/router';
import {BatchJob} from '../../../models/batch-job';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ViewJsonDialogComponent} from '../../../shared/view-json-dialog/view-json-dialog.component';
import {DialogBoxComponent} from '../../../dialog-box/dialog-box.component';
import {ToastrService} from 'ngx-toastr';
import {BatchInfo} from '../../../models/batch-info';

@Component({
  selector: 'app-adapter-integration-batchjobs',
  templateUrl: './adapter-integration-batchjobs.component.html',
  styleUrls: ['./adapter-integration-batchjobs.component.scss']
})
export class AdapterIntegrationBatchjobsComponent implements OnInit {

  @ViewChild('jobTable') jobTable: any;

  selectedAdapterDetails$: Object;
  selectedIntegrationDetails$: IntegrationDetails;


  constructor(
    private spinner: NgxSpinnerService,
    private batchService: BatchService,
    private globalStateQuery: GlobalUiStateQuery,
    private route: ActivatedRoute,
    private changeDetectorRefs: ChangeDetectorRef,
    private toastr: ToastrService,
    public matDialog: MatDialog
  ) {
        this.route.queryParams
          .subscribe(async params => {
              this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
              this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();
            this.refreshBatchList();
            }
          );
  }

  ColumnMode = ColumnMode;

  //jobs
  jobForm: FormGroup;
  jobList: BatchJob[] = null;

  ngOnInit(): void {

    this.jobForm = new FormGroup({
    });

  }

  refreshBatchList(){
    this.spinner.show("processing");
    this.batchService.listBatchJobs(this.selectedAdapterDetails$['endpoint'],
      this.selectedIntegrationDetails$.integrationId).toPromise().then(result => {
      if (result.status == 200) {
        const resultList = Object.keys(result.body).map(i => new BatchJob(result.body[i]));
        this.jobList = resultList.slice();
        this.changeDetectorRefs.detectChanges();
        console.log(resultList)
        this.spinner.hide("processing");
      }else {
        this.spinner.hide("processing");
      }
    }).catch(reason => {
      this.spinner.hide("processing");
    });
  }

  toggleExpandSelectedEntitiesRow(job: any, expanded: any) {
    job['isExpanding'] = !expanded;
    var messages:any[] = [];
    var errors:any[] = [];
    job.jobResults['message'].forEach(function(value){
      messages.push({"name":value});
    });
    job['messages'] = messages;
    job.jobResults['errorMessages'].forEach(function(value){
      errors.push({"name":value});
    });
    job['errors'] = errors;
    this.jobTable.rowDetail.toggleExpandRow(job);
  }

  onSelectedEntityDetailToggle($event: any) {

  }

  viewJobItems(job: BatchJob) {
    const dialogConfig = new MatDialogConfig();
    this.spinner.show("processing");
    this.batchService.listBatchJobsItems(this.selectedAdapterDetails$['endpoint'],
      this.selectedIntegrationDetails$.integrationId, job.jobIdentifier).toPromise().then(result => {
      if (result.status == 200) {
        let items = result.body['JobItems'];
        var message = "Job Items"
        if(items.length == 0 )
        {
          message = "No items for job to display"
        }
        //console.log(result.body)

        dialogConfig.data =
          {
            "json":items ,
            "message":message
          };
        this.matDialog.open(ViewJsonDialogComponent, dialogConfig);
        this.spinner.hide("processing");
      }else {
        this.spinner.hide("processing");
      }
    }).catch(reason => {
      this.spinner.hide("processing");
    });
  }

  openDeleteDialog(action: string, job: any) {
    job.action = action;
    const dialogRef = this.matDialog.open(DialogBoxComponent, {
      width: '350px',
      data: {
        dataObj: job,
        title: 'Job',
        message: 'Are you sure you want to delete job ' + job.jobIdentifier
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Delete') {
        this.spinner.show('processing');
        console.log('Delete ' + JSON.stringify(result.data));
        let user = result.data.dataObj;
        this.batchService.deleteBatchJob(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, job.jobIdentifier).subscribe(
          result => {
            let lst = this.jobList.slice();
            this.jobList = lst.filter( item => item.jobIdentifier !== job.jobIdentifier);
            this.changeDetectorRefs.detectChanges();
            this.showToaster('Job ' + job.jobIdentifier + ' deleted', 0);
            this.spinner.hide('processing');
          },
          error => {
            console.log('Error ' + JSON.stringify(error));
            console.log('Error status ' + error.status);
            this.spinner.hide('processing');
            if (error.name === "TimeoutError") {
              this.showToaster('Delete call failed. Call Timed out!', 1);
            } else {
              this.showToaster(error['error']['Message'] + " Status:" + error['error']['Status'], 1);
            }
          },
          () => {
            this.spinner.hide('processing');
          }
        )
      }
    });
  }

  clearJobItems(action: string, job: any) {
    job.action = action;
    const dialogRef = this.matDialog.open(DialogBoxComponent, {
      width: '350px',
      data: {
        dataObj: job,
        title: 'Job',
        message: 'Are you sure you want to clear items for job ' + job.jobIdentifier
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Delete') {
        this.spinner.show('processing');
        console.log('Delete ' + JSON.stringify(result.data));
        let user = result.data.dataObj;
        this.batchService.deleteBatchJobItems(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, job.jobIdentifier).subscribe(
          result => {
            var x = this.jobList.find( j => j.jobIdentifier==job.jobIdentifier);
            x .jobInfo = new BatchInfo();
            this.changeDetectorRefs.detectChanges();
            this.showToaster('Job ' + job.jobIdentifier + ' items deleted', 0);
            this.spinner.hide('processing');
          },
          error => {
            console.log('Error ' + JSON.stringify(error));
            console.log('Error status ' + error.status);
            this.spinner.hide('processing');
            if (error.name === "TimeoutError") {
              this.showToaster('Delete call failed. Call Timed out!', 1);
            } else {
              this.showToaster(error['error']['Message'] + " Status:" + error['error']['Status'], 1);
            }
          },
          () => {
            this.spinner.hide('processing');
          }
        )
      }
    });
  }

  showToaster(message, statusCode) {
    statusCode === 0 ? this.toastr.success(message,"Success",{timeOut:5000}) : this.toastr.error(message,"Error",{timeOut:5000});
  }

  executeJob(job: BatchJob) {
    this.batchService.executeBatchJob(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, job.jobIdentifier).subscribe(
      result => {
        this.refreshJob(job);
        this.showToaster(result['Message'] + " Status:" + result['Status'], 0);
        this.spinner.hide('processing');
      },
      error => {
        console.log('Error ' + JSON.stringify(error));
        console.log('Error status ' + error.status);
        this.spinner.hide('processing');
        if (error.name === "TimeoutError") {
          this.showToaster('Execute call failed. Call Timed out!', 1);
        } else {
          this.showToaster(error['Message'] + " Status:" + error['Status'], 1);
        }
      },
      () => {
        this.spinner.hide('processing');
      }
    )
  }

  refreshJob(job: any) {
    this.spinner.show('processing');
   this.batchService.getBatchJob(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, job.jobIdentifier).subscribe(
      result => {

        if (result.status == 200) {
          const nj = new BatchJob(result.body);
          let lst = this.jobList.slice();
          let index =  this.jobList.findIndex(item => item.jobIdentifier === job.jobIdentifier);
          if( index > -1 )
          {
            lst.splice(index, 1,nj);
            this.jobList = lst;
          }
          this.changeDetectorRefs.detectChanges();
          this.spinner.hide('processing');
        }else {
          this.spinner.hide("processing");
        }
      },
      error => {
        console.log('Error ' + JSON.stringify(error));
        console.log('Error status ' + error.status);
        this.spinner.hide('processing');
        if (error.name === "TimeoutError") {
          this.showToaster('Refresh call failed. Call Timed out!', 1);
        } else {
          this.showToaster(error['error']['Message'] + " Status:" + error['error']['Status'], 1);
        }
      },
      () => {
        this.spinner.hide('processing');
      }
    );
  }

}

<div class="container">
  <app-header></app-header>

  <app-nav></app-nav>

  <router-outlet></router-outlet>

  <app-footer></app-footer>

</div>

<div style="position: relative; padding: 20px;  width: 350px;">

  <h2 mat-dialog-title>New Query Builder Condition</h2>

  <mat-dialog-content [formGroup]="conditionForm">

      <form class="form-vertical" [formGroup]="conditionForm">

          <mat-form-field appearance="fill">
            <mat-label>And/OR</mat-label>
            <mat-select  name="andor" [(ngModel)]="andOr" formControlName="andOrControlName">
              <mat-option value="and" >And</mat-option>
              <mat-option value="or">Or</mat-option>
            </mat-select>
          </mat-form-field>

        <div style="height: 10px;"></div>

        <mat-form-field appearance="fill" style="width: 150px;">
          <mat-label>Field</mat-label>
          <mat-select  name="field" [(ngModel)]="field" formControlName="fieldControlName" placeholder="Field">
            <mat-option *ngFor="let field of fields" [value]="field.Name">{{field.Name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <div style="height: 5px;"></div>

        <mat-form-field appearance="fill" style="width: 150px;">
          <mat-label>Condition</mat-label>
          <mat-select  name="oper" [(ngModel)]="oper" formControlName="operControlName">
            <mat-option value="notLike" >Not Like</mat-option>
            <mat-option value="like">Like</mat-option>
            <mat-option value="eq">Equal</mat-option>
            <mat-option value="noteq">Not Equal</mat-option>
            <mat-option value="lt">Less Than</mat-option>
            <mat-option value="gt">Greater Than</mat-option>
            <mat-option value="lte">Less than or Equal</mat-option>
            <mat-option value="gte">Greater than or Equal</mat-option>
          </mat-select>
        </mat-form-field>

        <div style="height: 5px;"></div>

        <mat-form-field appearance="fill" style="width: 250px;">
          <mat-label>Value</mat-label>
          <input matInput placeholder="" value="" [ngModel]="conditionValue" (ngModelChange)="conditionValue = $event" formControlName="valueControlName">
        </mat-form-field>

    </form>

  </mat-dialog-content>

  <mat-dialog-actions style="float:right;margin:20px;">
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-button (click)="save()" [disabled]="!conditionForm.valid">Save</button>
  </mat-dialog-actions>

</div>



import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Action, AuditTracker} from './AuditTracker';

@Injectable({
  providedIn: 'root'
})
export class BatchService {

  apiUrl: string;

  constructor(private http: HttpClient,
              private auditTracker: AuditTracker) { }

  listBatchJobs(adapterEndpoint: string, integrationId){
    this.auditTracker.log(Action.List,"Listing Batch Jobs  @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/batch/list/' + integrationId;
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  listBatchJobsItems(adapterEndpoint: string, integrationId, jobId){
    this.auditTracker.log(Action.List,"Listing Batch Jobs items  @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/batch/' + integrationId +'/' + jobId + '/items';
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  deleteBatchJob(adapterEndpoint: string, integrationId, jobId){
    this.auditTracker.log(Action.List,"Deleting Batch Jobs " + jobId +"  @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/batch/' + integrationId +'/' + jobId ;
    console.log('API Url: ' + this.apiUrl);
    return this.http.delete(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  deleteBatchJobItems(adapterEndpoint: string, integrationId, jobId){
    this.auditTracker.log(Action.List,"Deleting Batch Jobs items for job " + jobId +"  @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/batch/' + integrationId +'/' + jobId + '/items' ;
    console.log('API Url: ' + this.apiUrl);
    return this.http.delete(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  executeBatchJob(adapterEndpoint: string, integrationId, jobId){
    this.auditTracker.log(Action.List,"Deleting Batch Jobs items for job " + jobId +"  @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/batch/' + integrationId +'/' + jobId + '/execute' ;
    console.log('API Url: ' + this.apiUrl);
    return this.http.post(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  getBatchJob(adapterEndpoint: string, integrationId, jobId){
    this.auditTracker.log(Action.List,"Retrieving Batch Job " + jobId +"  @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/batch/' + integrationId +'/' + jobId  ;
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

}

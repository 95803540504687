
<div style="width: 100%; position: relative;">

  <app-pager (onFilterChange)="filterChanged($event)"
             [fields]="[{'key':'User','value':'userName'},{'key':'Adapter','value':'adapterType'},{'key':'Integration','value':'integration'}]">
  </app-pager>

  <ngx-datatable
    #auditLogTable
    id="auditLogTable"
    class="material server-scroller"
    [columnMode]="ColumnMode.flex"
    [headerHeight]="headerHeight"
    [rowHeight]="rowHeight"
    [rows]="logs$"
    [loadingIndicator]="isLoading"
    [scrollbarV]="true"
    (scroll)="onScroll($event.offsetY)">

    <ngx-datatable-column name="Time" [sortable]="false"  [flexGrow]="1">
      <ng-template let-log="row" ngx-datatable-cell-template>
        <strong>{{ log.time }}</strong>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Adapter" [sortable]="false"  [flexGrow]="1">
      <ng-template let-log="row" ngx-datatable-cell-template>
        <strong>{{ log.adapterType }}</strong>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Integration" [sortable]="false"  [flexGrow]="1">
      <ng-template let-log="row" ngx-datatable-cell-template>
        <strong>{{ log.integration }}</strong>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="User" [sortable]="false"  [flexGrow]="1">
      <ng-template let-log="row" ngx-datatable-cell-template>
        <strong>{{ log.userName }}</strong>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Action" [sortable]="false"  [flexGrow]="1">
      <ng-template let-log="row" ngx-datatable-cell-template>
        <strong>{{ log.action }}</strong>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Message" [sortable]="false"  [flexGrow]="2">
      <ng-template let-log="row" ngx-datatable-cell-template>
        <a (click)="openPayload(log)" [ngClass]="{'enabled': log.payload.length > 2 ,'disabled': log.payload.length <=2  }" matTooltip="View payload">{{log.message}}</a>
      </ng-template>
    </ngx-datatable-column>


  </ngx-datatable>

</div>

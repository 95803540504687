<div style="margin: 0; text-align: right; height: 21px;">
    <div style="padding: 0 0 0 0px; margin: 0 0 0 0;  width: 100%;">

      <div style="background-color: white; padding: 0 0 0 0; margin: 0 0 0 0; width: 100%; ">

        <div style="padding: 0 0 0 0; margin: 0 0 0 0; background-color: black;">
          <!--     ENVIRONMENT SELECTION [local integration]    -->
          <div [hidden]='selectedEnv$ === "production" || selectedEnv$ === "staging"'>
            <button  [matMenuTriggerFor]="menu" style="padding: 0px 10px 2px 10px; margin: 0 0 0 0; background-color: white; vertical-align: middle; border: none;">
              <img class="images" src="assets/down-arrow.png" style="width: 12px; padding: 0 4px 0 0px; margin-top:4px;"/>
              <span style="color: black; padding: 0 0 0 0; margin: 0 0 0 0;">{{selectedEnvLabel}}</span>
            </button>
            <mat-menu #menu="matMenu" class="develop-integration-div">
              <mat-nav-list class="list-horizontal" style="display: flex; flex-direction: row; padding: 0 0 0 0; margin: 0 0 0 0; height: 40px;">
                <div class="develop-integration-selection"><a mat-list-item href="#" (click)="selectedEnvChanged('dev')">Development</a></div>
                <div class="develop-integration-selection"><a mat-list-item href="#" (click)="selectedEnvChanged('integration')">Integration</a></div>
              </mat-nav-list>
            </mat-menu>
          </div>
          <!--     Environment Definition [staging production]     -->
          <div [hidden]='selectedEnv$ === "dev" || selectedEnv$ === "integration"'>
            <button  style="padding: 0px 10px 2px 10px; margin: 0 0 0 0; background-color: white; vertical-align: middle; border: none;">
              <span style="color: black; padding: 0 0 0 0; margin: 0 0 0 0;">{{selectedEnvLabel}}</span>
            </button>
          </div>
        </div>

      </div>

    </div>
</div>

<div style="height: 31px; vertical-align: middle; padding-top:5px; padding-bottom:5px; padding-left: 10px; display: flex; justify-content: space-between;">
  <div style="display: flex; justify-content: space-between; align-content: center; padding-top: 8px; margin: 0; padding-right: 0;">
    <app-breadcrumb [hidden]="!userName"></app-breadcrumb>
  </div>
  <div style="display: flex; justify-content: space-between; align-content: center; padding-top: 8px; margin: 0; padding-right: 0;">
    <div [hidden]="!userName" style="color: lightgray; padding-right: 20px; padding-bottom: 0; padding-top: 0; margin: 0">User: <i>{{userName}}</i></div>
    <div [hidden]="!userName || !globalStateQuery.getSelectedImpersonatedEnvPrefix()" style="display: flex;  color: green; font-weight: bold; padding-right: 20px; padding-bottom: 0; padding-top: 0; margin: 0">
      Impersonating Env Prefix: {{globalStateQuery.getSelectedImpersonatedEnvPrefix()}}
      <mat-icon (click)="cancelImpersonation()" style="transform: scale(.7); cursor: pointer; color: red;" title="Remove Impersonation">cancel</mat-icon>
    </div>
    <div [hidden]="!showKey">
      <mat-icon [ngClass] ="fullAdminUser != undefined && (fullAdminUser.userDetail.hasKeys === null || fullAdminUser.userDetail.hasKeys === false )?'noKey':'hasKey'" (click)="processKeys()">vpn_key</mat-icon>
    </div>
      <div [hidden]="!userName"><a class="authLink" style="color: lightgray; padding-right: 5px; padding-bottom: 0; padding-top: 0;" (click)="logOut()">Logout</a></div>
  </div>
</div>

import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {GlobalUiStateQuery} from '../../../state/global-ui-state.query';
import {GlobalUiStateService} from '../../../state/global-ui-state.service';
import {MessageService} from '../../../services/message-service.service';

@Component({
  selector: 'app-adapter-details-nav',
  templateUrl: './adapter-details-nav.component.html',
  styleUrls: ['./adapter-details-nav.component.scss']
})
export class AdapterDetailsNavComponent implements OnInit,OnDestroy {

  selectedNavPage$: string;

  constructor(private router: Router,
              private globalStateQuery: GlobalUiStateQuery,
              private globalStateService: GlobalUiStateService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.selectedNavPage$ = this.globalStateQuery.getSelectedAdapterDetailsNavPage() ?? 'adapter-details-health';
    this.messageService.SendEvent({type:"AdapterActive",data:{ state:"Active",comp:"AdapterDetailsNavComponent"}})
    if( this.selectedNavPage$.trim().length == 0 )
    {
      this.selectedNavPage$ = 'adapter-details-health';
      this.globalStateService.updateSelectedAdapterDetailsNavPage(this.selectedNavPage$);
      this.router.navigate(['/adapters/adapter-details/adapter-details-health']);
    }
  }

  ngOnDestroy(): void {
    this.messageService.SendEvent({type:"AdapterActive",data:{ state:"InActive",comp:"AdapterDetailsNavComponent"}})
  }

  adapterDetailsHealthClicked(): void {
    this.selectedNavPage$ = 'adapter-details-health';
    this.globalStateService.updateSelectedAdapterDetailsNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-details/adapter-details-health']);
  }

  adapterDetailsIntegrationsClicked(): void {
    this.selectedNavPage$ = 'adapter-details-integrations';
    this.globalStateService.updateSelectedAdapterDetailsNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-details/adapter-details-integrations']);
  }
  adapterDetailsConfigClicked(): void {
    this.selectedNavPage$ = 'adapter-details-config';
    this.globalStateService.updateSelectedAdapterDetailsNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-details/adapter-details-config']);
  }

  adapterAuditLogClicked(): void {
    this.selectedNavPage$ = 'adapter-audit-log';
    this.globalStateService.updateSelectedAdapterDetailsNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-details/adapter-audit-log']);
  }

  getSelectedNavBackgroundColor(nav: string): string {
    if (nav === this.selectedNavPage$) {
      return '#898989';
    } else {
      return '';
    }
  }

  adapterErrorLogClicked() {
    this.selectedNavPage$ = 'adapter-error-logs';
    this.globalStateService.updateSelectedAdapterDetailsNavPage(this.selectedNavPage$);
    this.router.navigate(['/adapters/adapter-details/adapter-details-logs']);
  }


}

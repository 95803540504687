import { Injectable } from '@angular/core';
import { GlobalUIStateStore, GlobalUIState } from './global-ui-state.store';
import { AdapterModel } from '../adapters/state/adapter.model';
import { IntegrationDetails } from '../models/integration-details';
import {GlobalUiStateQuery} from './global-ui-state.query';

@Injectable({ providedIn: 'root' })
export class GlobalUiStateService {

  constructor(private globalStateStore: GlobalUIStateStore,
              private globalStateQuery: GlobalUiStateQuery) {
  }

  clearUiState(): void {
    this.updateSelectedNavPage('');
    this.updateSelectedAdapterDetails(null);
    this.updateSelectedAdapterIntegration(null);
    this.updateSelectedAdapterIntegrationNavPage('');
    this.updateSelectedAdapterDetailsNavPage('');
    this.updateSelectedIntegrationDetailsSearchCriteria('');
  }

  updateSelectedEnvironment(selectedEnvironment: string) {
    this.globalStateStore.updateUI({
      selectedEnvironment
    });
  }

  updateSelectedEnvPrefix(selectedEnvPrefix: string) {
    this.globalStateStore.updateUI({
      selectedEnvPrefix
    });
  }

  updateSelectedImpersonatedEnvPrefix(selectedImpersonatedEnvPrefix: string) {
    this.globalStateStore.updateUI({
      selectedImpersonatedEnvPrefix
    });
  }

  updateSelectedNavPage(selectedNavPage: string) {
    this.globalStateStore.updateUI({
      selectedNavPage
    });
  }

  updateSelectedAdapterDetails(selectedAdapterDetails: Object) {
    this.globalStateStore.updateUI({
      selectedAdapterDetails: { ...selectedAdapterDetails }
    });
  }

  updateSelectedAdapterIntegration(selectedAdapterIntegration: IntegrationDetails) {
    this.globalStateStore.updateUI({
      selectedAdapterIntegration
    });
  }

  updateSelectedAdapterIntegrationNavPage(selectedAdapterIntegrationNavPage: string) {
    this.globalStateStore.updateUI({
      selectedAdapterIntegrationNavPage
    });
  }

  updateSelectedAdapterDetailsNavPage(selectedAdapterDetailsNavPage: string) {
    this.globalStateStore.updateUI({
      selectedAdapterDetailsNavPage
    });
  }

  updateSelectedSecurityNavPage(selectedSecurityNavPage: string) {
    this.globalStateStore.updateUI({
      selectedSecurityNavPage
    });
  }

  updateSelectedConfigNavPage(selectedConfigNavPage: string) {
    this.globalStateStore.updateUI({
      selectedConfigNavPage
    });
  }

  updateSelectedIntegrationDetailsSearchCriteria(selectedIntegrationDetailsSearchCriteria: string) {
    this.globalStateStore.updateUI({
      selectedIntegrationDetailsSearchCriteria
    });
  }

  updateCachedAdapterConnectionDetails( adapterId: string, jwtToken: string, connectionProfileId: string) {

    const updatedCachedAdapterConnectionDetails = { ...this.globalStateQuery.getCachedAdapterConnectionDetails() };
    updatedCachedAdapterConnectionDetails[adapterId] = { jwtToken, connectionProfileId};

    this.globalStateStore.updateUI({
      cachedAdapterConnectionDetails: updatedCachedAdapterConnectionDetails
    });
  }

  updateCachedDirectCallAdapter( adapter ) {

    this.globalStateStore.updateUI({
      cachedDirectCallAdapter: adapter
    });
  }

  updateLoggedInUser( user: Object) {
    this.globalStateStore.updateUI({
      cachedUser: user
    });
  }

  updateConnectionProfiles(connectionProfiles: Object[])  {
    this.globalStateStore.updateUI({
      connectionProfiles
    });
  }

  updateInstanceDirect(instanceDirect: boolean) {
    this.globalStateStore.updateUI({
      instanceDirect
    });
  }

  updateCallbackUrl(callbackUrl: string) {
    this.globalStateStore.updateUI({
      callbackUrl
    });
  }

  clearCachedAdapterConnectionDetails() {

    this.globalStateStore.updateUI({
      cachedAdapterConnectionDetails: []
    });
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { IBreadCrumb } from './breadcrumb.interface';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import {AdapterModel} from '../adapters/state/adapter.model';
import {IntegrationDetails} from '../models/integration-details';
import {GlobalUiStateQuery} from '../state/global-ui-state.query';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: IBreadCrumb[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalStateQuery: GlobalUiStateQuery,
  ) {
    this.breadcrumbs = this.buildBreadCrumbs(this.activatedRoute.root);
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.breadcrumbs = this.buildBreadCrumbs(this.activatedRoute.root);
    });
  }

  /**
   * Recursively build breadcrumb according to activated route.
   * route
   * url
   * breadcrumbs
   */
  buildBreadCrumbs(route: ActivatedRoute, breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {

    while (route.firstChild) {
        route = route.firstChild;
    }

    if (route.routeConfig && route.routeConfig.data)
    {
      route.routeConfig.data.breadcrumbs.forEach(element => {
        let path = element.path;
        let label = element.label;
        if (label.indexOf(':') > -1)
        {
          if ( label.substring(1) === 'selectedAdapter')
          {
             const adapterDetails: Object = this.globalStateQuery.getSelectedAdapterDetails();
             label = adapterDetails['name'];
          }
          else if (label.substring(1) === 'selectedIntegration')
          {
            const integration: IntegrationDetails = this.globalStateQuery.getSelectedAdapterIntegration();
            if (integration.integrationId) {
              label = integration.name;
            } else {
              label = "New Integration";
            }


          }
        }
        if (path?.indexOf('[selectedAdapterDetailsNavPage]') > -1)
        {
          path = path.replace('[selectedAdapterDetailsNavPage]', this.globalStateQuery.getSelectedAdapterDetailsNavPage());
        }
        const isClickable = element.clickable;
        const breadcrumb: IBreadCrumb = {
            label,
            url: path,
            isClickable
        };
        breadcrumbs = [ ...breadcrumbs, breadcrumb ];
      });
    }

    return breadcrumbs;
  }
}

import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { GlobalUIStateStore, GlobalUIState } from './global-ui-state.store';

@Injectable({ providedIn: 'root' })
export class GlobalUiStateQuery extends QueryEntity<GlobalUIState> {

  constructor(protected store: GlobalUIStateStore) {
    super(store);
  }

  getSelectedEnvironment() {
    return this.getValue().ui.selectedEnvironment; // raw value
  }

  getSelectedEnvPrefix() {
    if (this.getValue().ui.selectedImpersonatedEnvPrefix)
      return this.getValue().ui.selectedImpersonatedEnvPrefix; // raw value
    else
      return this.getValue().ui.selectedEnvPrefix; // raw value
  }

  getSelectedImpersonatedEnvPrefix() {
    return this.getValue().ui.selectedImpersonatedEnvPrefix; // raw value
  }

  getSelectedNavPage() {
    return this.getValue().ui.selectedNavPage; // raw value
  }

  getSelectedAdapterDetails() {
    return this.getValue().ui.selectedAdapterDetails; // raw value
  }

  getSelectedAdapterIntegration() {
    return this.getValue().ui.selectedAdapterIntegration; // raw value
  }

  getSelectedAdapterDetailsNavPage() {
    return this.getValue().ui.selectedAdapterDetailsNavPage; // raw value
  }

  getSelectedSecurityNavPage() {
    return this.getValue().ui.selectedSecurityNavPage; // raw value
  }

  getSelectedConfigNavPage() {
    return this.getValue().ui.selectedConfigNavPage; // raw value
  }

  getSelectedAdapterIntegrationNavPage() {
    return this.getValue().ui.selectedAdapterIntegrationNavPage; // raw value
  }

  getSelectedIntegrationDetailsSearchCriteria() {
    return this.getValue().ui.selectedIntegrationDetailsSearchCriteria; // raw value
  }

  getCachedAdapterConnectionDetails() {
    return this.getValue().ui.cachedAdapterConnectionDetails; // raw value
  }

  getCachedDirectCallAdapter() {
    return this.getValue().ui.cachedDirectCallAdapter; // raw value
  }

  getConnectionProfiles() {
    return this.getValue().ui.connectionProfiles; // raw value
  }

  getInstanceDirect() {
    return this.getValue().ui.instanceDirect; // raw value
  }

  getCallBackUrl() {
    return this.getValue().ui.callbackUrl; // raw value
  }

  getCachedUser() {
    return this.getValue().ui.cachedUser; // raw value
  }
}

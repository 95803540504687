import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IntegrationTypesStore } from './integration-types.store';
import { IntegrationTypeModel, IntegrationTypesState } from './integration-type.model';

@Injectable({ providedIn: 'root' })
export class IntegrationTypesQuery extends QueryEntity<IntegrationTypesState, IntegrationTypeModel> {

  constructor(protected store: IntegrationTypesStore) {
    super(store);
  }

}

import {Component, OnDestroy, OnInit, ChangeDetectorRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {APIService} from '../../../API.service';
import {Router} from '@angular/router';
import {NgFormsManager} from '@ngneat/forms-manager';

@Component({
  selector: 'app-add-edit-filter',
  templateUrl: './add-edit-filter.component.html',
  styleUrls: ['./add-edit-filter.component.scss']
})
export class AddEditFilterComponent implements OnInit, OnDestroy {

  filterForm: UntypedFormGroup;

  constructor(
    private spinner: NgxSpinnerService,
    private api: APIService,
    private router: Router,
    private formsManager: NgFormsManager,
    private builder: UntypedFormBuilder,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.filterForm = this.builder.group({
      id: [null],
      integrationType: [null],
      rawFilteredEntities: [null]
    });

    this.formsManager.upsert('filter', this.filterForm, {
      persistState: true
    });

  }

  ngOnDestroy() {
    this.formsManager.unsubscribe('filter');
  }

  async onCancel(): Promise<void> {
    this.formsManager.clear('filter');
    await this.router.navigate(['/config/config-filters']);
  }

  async upsertFilter(): Promise<void> {

    await this.spinner.show();

    const filter = this.formsManager.getControl('filter').value;

    if (filter.id)
    {

    }
    else
    {

    }

    this.formsManager.clear('filter');
    await this.router.navigate(['/config/config-filters']);

    await this.spinner.hide();

  }

}


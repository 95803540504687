import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FiltersStore } from './filters.store';
import { FilterModel, FiltersState } from './filter.model';

@Injectable({ providedIn: 'root' })
export class FiltersQuery extends QueryEntity<FiltersState, FilterModel> {

  constructor(protected store: FiltersStore) {
    super(store);
  }

}

<script src="https://apis.google.com/js/platform.js"></script>

<mat-toolbar>
  <span [hidden]="userForm.value.id"> Add Toolkit Admin User</span>
  <span [hidden]="!userForm.value.id"> Edit Toolkit Admin User</span>
  <span class="spacer"></span>
  <div style="float:right; position: fixed; bottom: 50px; right: 2%; z-index: 100; display: flex; flex-direction: column-reverse; align-items: center;">
    <button mat-fab color="primary" aria-label="Cancel" (click)="onCancel()">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</mat-toolbar>

<div style="padding: 20px; width: 450px;">

  <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading" style="color: white; font-weight: bold;">Saving User ...</p>
  </ngx-spinner>

  <form [formGroup]="userForm">

    <mat-form-field [style.width.px]="350">
      <mat-label>Name</mat-label>
      <input matInput
             formControlName="name">
    </mat-form-field>

    <div style="height: 1px;"></div>

    <mat-form-field [hidden]="emailAddress" [style.width.px]="350">
      <mat-label>Email Address</mat-label>
      <input matInput
             formControlName="emailAddress">
    </mat-form-field>

    <div style="height: 1px;"></div>

    <div [hidden]="!emailAddress" style="padding-bottom:10px;">
      <span style="display: flex; align-items: start;" style="margin-top: 15px; font-weight: bold;"><span style="font-weight: normal;">Email Address:  </span><span style="background-color: lightgray;">{{userForm.controls['emailAddress'].value}}</span></span>
    </div>

    <div style="height: 1px;"></div>

    <mat-form-field  [style.width.px]="350"
                     [hidden]="(this.globalStateQuery.getSelectedEnvironment() === 'staging' || this.globalStateQuery.getSelectedEnvironment() === 'production')">
      <mat-label>Env Prefix</mat-label>
      <input matInput
             formControlName="envPrefix">
    </mat-form-field>

    <div style="height: 1px;"></div>

    <mat-form-field  [style.width.px]="100" [hidden]="!this.globalStateQuery.getCachedUser() || (this.globalStateQuery.getCachedUser() && this.globalStateQuery.getCachedUser().role !== 'admin')">
      <mat-label>Role</mat-label>
      <mat-select style="width: 75px;" formControlName="role">
        <mat-option value="admin">Admin</mat-option>
        <mat-option value="user">User</mat-option>
      </mat-select>
    </mat-form-field >

    <div style="height: 1px;"></div>

    <div [hidden]="!this.globalStateQuery.getCachedUser() || (this.globalStateQuery.getCachedUser() && this.globalStateQuery.getCachedUser().role == 'admin')" style="padding-bottom:10px;">
      <span style="display: flex; align-items: start;" style="margin-top: 15px; font-weight: bold;"><span style="font-weight: normal;">Role:  </span><span style="background-color: lightgray;">{{userForm.controls['role'].value}}</span></span>
    </div>

    <div style="padding-bottom:10px;">
      <span style="display: flex; align-items: start;" style="margin-top: 15px; font-weight: bold;"><span style="font-weight: normal;">Federated Username:  </span><span style="background-color: lightgray;">{{userForm.controls['username'].value ?? '-- Awaiting 1st Login --'}}</span></span>
    </div>

    <div style="height: 10px"></div>

  </form>

  <div style="text-align: left; margin: auto">
    <button mat-button class="actionButton" (click)="upsertUser()" style="margin-right: 10px;" [disabled]="!userForm.valid">
      Save
    </button>
    <button mat-button class="actionButton" (click)="onCancel()">
      Cancel
    </button>
  </div>

</div>






import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Action, AuditTracker} from './AuditTracker';

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  apiUrl: string;

  constructor(private http: HttpClient,
              private auditTracker: AuditTracker) { }

  executeQuery(adapterEndpoint: string, integrationId,data: string){
    this.auditTracker.log(Action.Execute,"Executing query against integration  "+ integrationId + " @ " + adapterEndpoint,data);
    this.apiUrl = '/api/admin/search/' + integrationId;
    console.log('API Url: ' + this.apiUrl);
    return this.http.post(adapterEndpoint + this.apiUrl, data,{ observe: 'response', responseType: 'json' });
  }

}

<header class="{{selectedAdapterDetails$['endpoint'] == 'http://localhost:' + (selectedAdapterDetails$['localPortForwardPort'] == undefined ? 9090 : selectedAdapterDetails$['localPortForwardPort'])?'workerAdapter':'adapter'}}">

  <div class="flex-grid" style="padding: 10px;" >
    <div class="flex-grid" style="flex: 1">
      <div style="width: 20px;"></div>

      <div class="Aligner Aligner-item Aligner-item--middle" style="max-height: 120px; max-width: 120px; text-align: center;">
        <div style="flex: 1; margin: auto;">
              <amplify-s3-image class="Aligner Aligner-item Aligner-item--middle" [path]="adapterLogoFilename" level="public" *ngIf="adapterLogoFilename"></amplify-s3-image>
        </div>
        <div class="flex-grid" style="justify-content: flex-start; padding-right: 10px;" [hidden]="!selectedIntegrationDetails$.integrationId">
          <div style=" width: 120px; text-align: right;">Status:</div>
          <div style="text-align: left; padding-left: 10px;">
            <mat-icon style="color:green; cursor: pointer;" [hidden]="selectedIntegrationDetails$.runningConnectionTest || selectedIntegrationDetails$.connectionState != 'connected'" (click)="$event.stopPropagation(); testSpecifiedIntegrationConnection();">cloud_queue</mat-icon>
            <mat-icon style="color:red; cursor: pointer;" [hidden]="selectedIntegrationDetails$.runningConnectionTest || selectedIntegrationDetails$.connectionState != 'disconnected'" (click)="$event.stopPropagation(); testSpecifiedIntegrationConnection();">cloud_off</mat-icon>
            <fa-icon transform="down-2" [icon]="spinnerIcon" style="margin: auto; color:green;" size="lg" [hidden]="!selectedIntegrationDetails$.runningConnectionTest" [spin]="true"></fa-icon>
          </div>
          <div style="padding-left: 10px;">
            <mat-icon style="height: 20px; cursor: pointer;" (click)="connectivityDetails();" [hidden]="selectedIntegrationDetails$.runningConnectionTest || selectedIntegrationDetails$.connectionState == 'connected'">visibility</mat-icon>
          </div>
        </div>
      </div>

      <div style="flex: 1; margin: auto;">

        <div style="text-align: left; display: flex; justify-content: space-evenly;">

          <div class="flex-grid" style="flex-direction: column;">

            <div class="flex-grid" style="justify-content: flex-start; padding-right: 10px;">
              <div style=" width: 120px; text-align: right;" [hidden]="selectedAdapterDetails$['name'] === 'instance_direct'">Adapter Name:</div>
              <div style="text-align: left; padding-left: 10px;" [hidden]="selectedAdapterDetails$['name'] === 'instance_direct'"><b>{{selectedAdapterDetails$['name']}}</b></div>
            </div>

            <div class="flex-grid" style="justify-content: flex-start; padding-right: 10px;">
              <div style="width: 120px; text-align: right;" [hidden]="selectedAdapterDetails$['name'] !== 'instance_direct'">Direct Access - </div>
              <div style="text-align: left; padding-left: 10px;" [hidden]="selectedAdapterDetails$['name'] !== 'instance_direct'">
                <div [hidden]="!callBackURL"><a class="returnLink" style="color: lightgray; padding-right: 5px; padding-bottom: 0; padding-top: 0;" (click)="return()">
                  <fa-icon [icon]="returnIcon" style="color:green" size="lg" matTooltip="Return"></fa-icon></a></div></div>
            </div>


            <div style="height: 10px;" [hidden]="!globalStateQuery.getSelectedAdapterIntegration() || !globalStateQuery.getSelectedAdapterIntegration()['integrationId']"></div>
            <div class="flex-grid" style="justify-content: flex-start; padding-right: 10px;" [hidden]="!globalStateQuery.getSelectedAdapterIntegration() || !globalStateQuery.getSelectedAdapterIntegration()['integrationId']">
              <div style="width: 120px; text-align: right;">Integration ID:</div>
              <div style="text-align: left; padding-left: 10px;"><b>{{globalStateQuery.getSelectedAdapterIntegration() && globalStateQuery.getSelectedAdapterIntegration()['integrationId'] ? globalStateQuery.getSelectedAdapterIntegration()['integrationId'] : ''}}</b></div>
            </div>

          </div>
          <div class="flex-grid" style="flex-direction: row;">

            <div class="flex-grid" style="flex-direction: column;">

              <div class="flex-grid" style="justify-content: flex-start; padding-right: 10px;">
                <div style="width: 120px; text-align: right;" [hidden]="selectedAdapterDetails$['name'] === 'instance_direct'">Adapter EndPoint:</div>
                <div style="text-align: left; padding-left: 10px;" [hidden]="selectedAdapterDetails$['name'] === 'instance_direct'"><b>{{selectedAdapterDetails$['endpoint']}}</b></div>
              </div>

              <div style="height: 10px;" [hidden]="!globalStateQuery.getSelectedAdapterIntegration() || !globalStateQuery.getSelectedAdapterIntegration()['integrationId']"></div>
              <div class="flex-grid" style="justify-content: flex-start; padding-right: 10px;" [hidden]="!globalStateQuery.getSelectedAdapterIntegration() || !globalStateQuery.getSelectedAdapterIntegration()['integrationId']">
                <div style="width: 120px; text-align: right;">Integration Name:</div>
                <div style="text-align: left; padding-left: 10px;"><b>{{globalStateQuery.getSelectedAdapterIntegration() && globalStateQuery.getSelectedAdapterIntegration()['name'] ? globalStateQuery.getSelectedAdapterIntegration()['name'] : ''}}</b></div>
              </div>

            </div>

            <div class="flex-grid" style="flex-direction: column;">

              <div class="flex-grid" style="justify-content: flex-start; padding-right: 10px;">
                <div style="width: 120px; text-align: right;" [hidden]="selectedAdapterDetails$['name'] === 'instance_direct'">Adapter ID:</div>
                <div style="text-align: left; padding-left: 10px;" [hidden]="selectedAdapterDetails$['name'] === 'instance_direct'"><b>{{selectedAdapterDetails$['adapterId']}}</b></div>
              </div>
              <div style="height: 10px;" [hidden]="!globalStateQuery.getSelectedAdapterIntegration()"></div>
              <div class="flex-grid" style="justify-content: flex-start; padding-right: 10px;" [hidden]="!globalStateQuery.getSelectedAdapterIntegration() || !globalStateQuery.getSelectedAdapterIntegration()['integrationId']">
                <div style="width: 120px; text-align: right;">Env:</div>
                <div style="text-align: left; padding-left: 10px;"><b>{{globalStateQuery.getSelectedAdapterIntegration() && globalStateQuery.getSelectedAdapterIntegration()['environment'] ? globalStateQuery.getSelectedAdapterIntegration()['environment'] : ''}}</b></div>
              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

</header>

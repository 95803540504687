import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';

interface Page {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnInit {

  @Input()
  fields =  [];
  @Input()
  operators =   [{'key':'Equal','value':'eq'},{'key':'Begins With','value':'beginsWith'}];

  filterApplied: boolean = false;

  @Output()
  onFilterChange: EventEmitter<object> = new EventEmitter<object>();

  // numPerPage: Page[] = [
  //   {value: '10', viewValue: '10'},
  //   {value: '50', viewValue: '50'},
  //   {value: '100', viewValue: '100'}
  // ];
  pagerForm: UntypedFormGroup;
  filerOperatorSelect: 'and';

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {


  }

  ngOnInit(): void {

    this.pagerForm = this.formBuilder.group({
      filterField: ['', Validators.required],
      filterOperator: ['', Validators.required],
      filterValue: ['', Validators.required]
      }
    );
  }

  filterChanged() {
    var hasOp = this.pagerForm.controls['filterOperator'].value?.length > 0;
    var hasField = this.pagerForm.controls['filterField'].value?.length > 0;
    var haTxt =this.pagerForm.controls['filterValue'].value;

    if( haTxt?.length > 0  && hasOp && hasField )
      this.filterApplied = true;
    else
      this.filterApplied = false;
  }

  updateFilter() {
    var operator ={};
    operator[this.pagerForm.controls['filterOperator'].value] = this.pagerForm.controls['filterValue'].value;
    var field = {
    };
    field[this.pagerForm.controls['filterField'].value]=operator;
    this.onFilterChange.emit(field);
  }

  clearFilter() {
    this.filterApplied = false;
    this.pagerForm.controls['filterOperator'].reset();
    this.pagerForm.controls['filterValue'].reset();
    this.pagerForm.controls['filterField'].reset();
    this.onFilterChange.emit(null);
  }

  filterValueChanged(event: string) {
    var hasOp = this.pagerForm.controls['filterOperator'].value?.length > 0;
    var hasField = this.pagerForm.controls['filterField'].value?.length > 0;
    var haTxt = event != null && event.length > 0;

    if( haTxt  && hasOp && hasField )
      this.filterApplied = true;
    else
      this.filterApplied = false;
  }

}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AdapterModel} from '../adapters/state/adapter.model';
import {Action, AuditTracker} from './AuditTracker';

@Injectable({
  providedIn: 'root'
})
export class ScheduledJobAdminService {

  apiUrl: string;

  constructor(private http: HttpClient,
              private auditTracker: AuditTracker) {
  }

  getScheduledJob(adapterEndpoint: string, integrationId, jobId){
    this.auditTracker.log(Action.Get,"Getting scheduled job " +jobId +" for integration " + integrationId + " @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/scheduledjob/' + integrationId + '/' + jobId;
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  executeScheduledJob(adapterEndpoint: string, scheduledJob){
    this.apiUrl = '/api/admin/scheduledjob/execute';
    console.log('API Url: ' + this.apiUrl);
    const json = JSON.stringify(scheduledJob, (key, value) => {
      if (value !== null) { return value; }
    });
    this.auditTracker.log(Action.Execute,"Executing scheduled job  @ " + adapterEndpoint,json);
    return this.http.post(adapterEndpoint + this.apiUrl, json, { observe: 'response',
      headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }


  scheduleScheduledJob(adapterEndpoint: string, scheduledJob){
    this.apiUrl = '/api/admin/scheduledjob/schedule';
    console.log('API Url: ' + this.apiUrl);
    const json = JSON.stringify(scheduledJob, (key, value) => {
      if (value !== null) { return value; }
    });
    this.auditTracker.log(Action.Execute,"Scheduling scheduled job  @ " + adapterEndpoint,json);
    return this.http.post(adapterEndpoint + this.apiUrl, json, { observe: 'response',
      headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }

  unScheduleScheduledJob(adapterEndpoint: string, scheduledJob){
    this.apiUrl = '/api/admin/scheduledjob/unschedule';
    console.log('API Url: ' + this.apiUrl);
    const json = JSON.stringify(scheduledJob, (key, value) => {
      if (value !== null) { return value; }
    });
    this.auditTracker.log(Action.Execute,"Un-Scheduling scheduled job  @ " + adapterEndpoint,json);
    return this.http.post(adapterEndpoint + this.apiUrl, json, { observe: 'response',
      headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }

  cancelScheduledJob(adapterEndpoint: string, scheduledJob){
    this.apiUrl = '/api/admin/scheduledjob/cancel';
    console.log('API Url: ' + this.apiUrl);
    const json = JSON.stringify(scheduledJob, (key, value) => {
      if (value !== null) { return value; }
    });
    this.auditTracker.log(Action.Execute,"Canceling scheduled job  @ " + adapterEndpoint,json);
    return this.http.patch(adapterEndpoint + this.apiUrl, json, { observe: 'response',
      headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }

  createScheduledJob(adapterEndpoint: string, scheduledJob) {
    this.apiUrl = '/api/admin/scheduledjob';
    console.log('API Url: ' + this.apiUrl);
    const json = JSON.stringify(scheduledJob, (key, value) => {
      if (value !== null) { return value; }
    });
    this.auditTracker.log(Action.Create,"Creating scheduled job  @ " + adapterEndpoint,json);
    return this.http.post(adapterEndpoint + this.apiUrl, json, { observe: 'response',
      headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }

  updateScheduledJob(adapterEndpoint: string, scheduledJob) {
    this.apiUrl = '/api/admin/scheduledjob';
    console.log('API Url: ' + this.apiUrl);
    const json = JSON.stringify(scheduledJob, (key, value) => {
      if (value !== null) { return value; }
    });
    this.auditTracker.log(Action.Update,"Updating scheduled job  @ " + adapterEndpoint,json);
    return this.http.patch(adapterEndpoint + this.apiUrl, json, { observe: 'response',
      headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }

  deleteScheduledJob(adapterEndpoint: string, integrationId, jobId) {
    this.auditTracker.log(Action.Delete,"Deleting scheduled job "+ jobId + " for integration " + integrationId + " @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/scheduledjob/' + integrationId + '/' + jobId;
    return this.http.delete(adapterEndpoint + this.apiUrl, { observe: 'response',
      headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }

  listScheduledJobsForIntegration(adapterEndpoint: string, integrationId){
    this.auditTracker.log(Action.List,"Listing scheduled jobs for integration " + integrationId + " @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/scheduledjob/' + integrationId;
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  listScheduledJobsForIntegrationUser(adapterEndpoint: string, integrationId, userEmail){
    this.auditTracker.log(Action.List,"Listing scheduled jobs for integration " + integrationId + " @ " + adapterEndpoint +" for user " + userEmail);
    this.apiUrl = '/api/admin/scheduledjob/foruser/' + integrationId+ "/" + userEmail;
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  refreshScheduledJob(adapterEndpoint: string, integrationId) {
    this.apiUrl = "/api/admin/scheduledjob/" + integrationId + "/refresh";
    return this.http.post(adapterEndpoint + this.apiUrl, "",{ observe: 'response', headers: {"Content-Type": "application/json"}, responseType: "json" });
  }

}

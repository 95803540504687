<header  class="{{selectedAdapterDetails$['endpoint'] == 'http://localhost:' + (selectedAdapterDetails$['localPortForwardPort'] == undefined ? 9090 : selectedAdapterDetails$['localPortForwardPort'])?'workerAdapter':'adapter'}}">

    <div class="flex-grid" style="padding: 10px;">
      <div class="flex-grid" style="flex: 1">
        <div style="width: 20px;"></div>
        <div class="Aligner Aligner-item Aligner-item--middle" style="max-height: 80px; max-width: 120px;">
          <amplify-s3-image class="Aligner Aligner-item Aligner-item--middle" [path]="adapterLogoFilename" level="public" *ngIf="adapterLogoFilename"></amplify-s3-image>
        </div>

        <div style="flex: 1; margin: auto;">
          <div style="text-align: left; display: flex; justify-content: space-evenly;">
            <div class="flex-grid" style="justify-content: flex-start;  padding-left: 10px; padding-right: 10px;">
              <div style=" width: 60px; text-align: right;" >Name:</div>
              <div style="text-align: left; padding-left: 10px;"><b>{{selectedAdapterDetails$['name']}}</b></div>
            </div>
            <div class="flex-grid" style="justify-content: flex-start; padding-left: 10px; padding-right: 10px;">
              <div style="width: 60px; text-align: right;">EndPoint:</div>
              <div style="text-align: left; padding-left: 10px;"><b>{{selectedAdapterDetails$['endpoint']}}</b></div>
            </div>
              <div class="col" style="text-align: center;vertical-align: middle;">ID: <b>{{selectedAdapterDetails$['adapterId']}}</b></div>

          </div>
        </div>
      </div>

    </div>

</header>

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SchemaEntities} from '../models/schema-entities';
import {AdapterModel} from '../adapters/state/adapter.model';
import {IntegrationSchema} from '../models/integration-schema';
import {forEach} from '@angular-devkit/schematics';
import {Action, AuditTracker} from './AuditTracker';

@Injectable({
  providedIn: 'root'
})
export class SchemaService {

  apiUrl: string;

  constructor(private http: HttpClient,
              private auditTracker: AuditTracker) { }

  /*getIntegrationSchema(adapterEndpoint: string, integrationId){
    this.auditTracker.log(Action.Get,"Getting schema integration " + integrationId + " @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/integration/' + integrationId + '/schema';
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }*/

  getIntegrationSchema(adapterEndpoint: string, integrationId){
    this.auditTracker.log(Action.Get,"Getting schema integration " + integrationId + " @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/schema/' + integrationId ;
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  updateSchemaEntity(adapterEndpoint: string, integrationId, entityList: SchemaEntities)
  {
    this.apiUrl = '/api/admin/schema/' + integrationId;
    console.log('API Url: ' + this.apiUrl);
    const json = JSON.stringify(entityList, (key, value) => {
      if (value !== null) { return value; }
    });
    this.auditTracker.log(Action.Update,"Updating schema integration " + integrationId + " @ " + adapterEndpoint,json);
    return this.http.patch(adapterEndpoint + this.apiUrl, json, { observe: 'response',
      headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }

  deleteSchemaEntity(adapterEndpoint: string, integrationId)
  {
    this.auditTracker.log(Action.Delete,"Deleting schema integration " + integrationId + " @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/schema/' + integrationId;
    console.log('API Url: ' + this.apiUrl);
    return this.http.delete(adapterEndpoint + this.apiUrl, { observe: 'response',
      headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }

  activateSchemaEntities(adapterEndpoint: string, integrationId, retirePrevious: boolean, entityList: SchemaEntities)
  {
    this.apiUrl = '/api/admin/schema/' + integrationId + '/activate?retirePrevious=' + retirePrevious;
    const json = JSON.stringify(entityList, (key, value) => {
      if (value !== null) { return value; }
    });
    this.auditTracker.log(Action.List,"Activating schema entities for integration " + integrationId + " @ " + adapterEndpoint,json);
    return this.http.post(adapterEndpoint + this.apiUrl, json, { observe: 'response',
      headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }

  retireSchemaEntities(adapterEndpoint: string, integrationId, entityList: SchemaEntities)
  {
    this.apiUrl = '/api/admin/schema/' + integrationId + '/retire';
    console.log('API Url: ' + this.apiUrl);
    const json = JSON.stringify(entityList, (key, value) => {
      if (value !== null) { return value; }
    });
    console.log('JSON: ' + json);
    return this.http.post(adapterEndpoint + this.apiUrl, json, { observe: 'response',
      headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }

  updateSchemaFilter(adapterEndpoint: string, integrationId, selectedEntityList: any)
  {
    this.apiUrl = '/api/admin/schema/' + integrationId + '/schemafilter';
    console.log('API Url: ' + this.apiUrl);

    const filteredEntityList = [];
    selectedEntityList.forEach(filteredEntity => {
      const filteredAttributes = [];
      if (filteredEntity.SelectedAttributes)  {
        filteredEntity.SelectedAttributes.forEach(selectedAttribute => {
          const defaultValueOverride = selectedAttribute.EntityAttributeCustomizations ? selectedAttribute.EntityAttributeCustomizations.DefaultOverride : null;
          filteredAttributes.push({"AttributeName": selectedAttribute.Attribute.Name, "EntityAttributeCustomizations": { "DefaultOverride": defaultValueOverride }});
        });
      }

      filteredEntityList.push({
        "EntityName": filteredEntity.Name,
        "FilteredAttributes": filteredAttributes
      });

    });

    let schemaFilter = {
      "IntegrationId": integrationId,
      "FilteredEntities": filteredEntityList
    }

    const json = JSON.stringify(schemaFilter, (key, value) => {
      if (value !== null) { return value; }
    });

    this.auditTracker.log(Action.Update,"Updating schema filter for integration " + integrationId + " @ " + adapterEndpoint,json);
    return this.http.put(adapterEndpoint + this.apiUrl, json, { observe: 'response',
      headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }

  removeEntitiesFromSchemaFilter(adapterEndpoint: string, integrationId, removedEntityList: any)
  {
    this.apiUrl = '/api/admin/schema/' + integrationId + '/schemafilter/remove';
    console.log('API Url: ' + this.apiUrl);

    const filteredEntityList = [];
    removedEntityList.forEach(filteredEntity => {
      const filteredAttributes = [];
      if (filteredEntity.SelectedAttributes)  {
        filteredEntity.SelectedAttributes.forEach(selectedAttribute => {
          const defaultValueOverride = selectedAttribute.EntityAttributeCustomizations ? selectedAttribute.EntityAttributeCustomizations.DefaultOverride : null;
          filteredAttributes.push({"AttributeName": selectedAttribute.Attribute.Name, "EntityAttributeCustomizations": { "DefaultOverride": defaultValueOverride }});
        });
      }

      filteredEntityList.push({
        "EntityName": filteredEntity.Name,
        "FilteredAttributes": filteredAttributes
      });

    });

    let schemaFilter = {
      "IntegrationId": integrationId,
      "FilteredEntities": filteredEntityList
    }

    const json = JSON.stringify(schemaFilter, (key, value) => {
      if (value !== null) { return value; }
    });

    this.auditTracker.log(Action.Update,"Updating schema filter for integration " + integrationId + " @ " + adapterEndpoint,json);
    return this.http.put(adapterEndpoint + this.apiUrl, json, { observe: 'response',
      headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }

  updateEntitySync(adapterEndpoint: string, integrationId, syncs: string[])
  {
    this.apiUrl = '/api/admin/scheduledjob/' + integrationId + '/updatesync';
    var json = '{ "entitySync":' + JSON.stringify(syncs) + '}';
    this.auditTracker.log(Action.Update,"Updating Entity Sync for integration " + integrationId + " @ " + adapterEndpoint,json);
    return this.http.patch(adapterEndpoint + this.apiUrl, json, { observe: 'response',
      headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }

  listIntegrationSchemaV1(adapterEndpoint: string, integrationId){
    this.auditTracker.log(Action.List,"Listing schema for integration " + integrationId + " @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/schema/' + integrationId + '/list' ;
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  getShallowSchemaEntitiesAsync(adapterEndpoint: string, integrationId, selected){
    this.auditTracker.log(Action.List,"Listing shallow Entity Sync for integration " + integrationId + " @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/schema/' + integrationId + "/entity?selected=" + selected;
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  applyDefaultSchemaFilter(adapterEndpoint: string, integrationId){
    this.auditTracker.log(Action.Update,"Updating default filter for integration " + integrationId + " @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/integration/' + integrationId + "/schemaFilter/load";
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  deleteSchemaFilter(adapterEndpoint: string, integrationId){
    this.auditTracker.log(Action.Update,"Deleting schema filter for integration " + integrationId + " @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/schema/' + integrationId + "/schemaFilter";
    console.log('API Url: ' + this.apiUrl);
    return this.http.delete(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  getSchemaEntityAttributesAsync(adapterEndpoint: string, integrationId, entityName, selected){
    this.auditTracker.log(Action.Get,"Getting schema entity attributes("+entityName+") for integration " + integrationId + " @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/schema/' + integrationId + "/entityattribute?entityName=" + entityName + "&selected=" + selected;
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  getSchemaEntityAttributesAndCustomizationsAsync(adapterEndpoint: string, integrationId, entityName, selected){
    this.auditTracker.log(Action.Get,"Getting schema entity attributes("+entityName+") for integration " + integrationId + " @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/schema/' + integrationId + "/entityattributesandcustomizations?entityName=" + entityName + "&selected=" + selected;
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  deleteIntegrationSchemaV1(adapterEndpoint: string, schema: IntegrationSchema){
    this.auditTracker.log(Action.Delete,"Deleting schema for integration " + schema.IntegrationId + " @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/schema/' + schema.IntegrationId + '/' + schema.SchemaVersion ;
    console.log('API Url: ' + this.apiUrl);
    return this.http.delete(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  clearSchemaCache(adapterEndpoint: string, integrationId){
    this.auditTracker.log(Action.Clear,"Clearing schema cache for integration " + integrationId + " @ " + adapterEndpoint);
    this.apiUrl = "/api/admin/cache/schema/" + integrationId;
    console.log('API Url: ' + this.apiUrl);
    return this.http.delete(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: "text" });
  }

  getSingleSchemaEntity(adapterEndpoint: string, integrationId,entity: string){
    this.apiUrl = "/api/admin/schema/" + integrationId + "?entityNames=" + entity ;
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: "json" });
  }

  updateSingleSchemaEntity(adapterEndpoint: string, integrationId, payload: string){
    console.log(payload);
    this.auditTracker.log(Action.Update,"Updating schema entity for integration " + integrationId + " @ " + adapterEndpoint,"{\"schemaEntities\":[" + payload + "]}");
    this.apiUrl = "/api/admin/schema/entity/" + integrationId;
    console.log('API Url: ' + this.apiUrl);
    return this.http.patch(adapterEndpoint + this.apiUrl,"{\"schemaEntities\":[" + payload + "]}", { observe: 'response', responseType: "json" });
  }

}

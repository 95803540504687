import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-redis-dialog',
  templateUrl: './redis-dialog.component.html',
  styleUrls: ['./redis-dialog.component.scss']
})
export class RedisDialogComponent implements OnInit {

  totalKeys: string;
  keysRemoved : string;
  failedKeysRemoved: string;
  statsKeysRemoved: string;

  constructor(@Inject(MAT_DIALOG_DATA) data,
              public dialogRef: MatDialogRef<RedisDialogComponent>) {

    this.totalKeys = data.result["totalKeys"];
    this.keysRemoved = data.result["keysRemoved"];
    this.failedKeysRemoved = data.result["failedKeysRemoved"];
    this.statsKeysRemoved = data.result["statsKeysRemoved"];
  }


  ngOnInit(): void {

  }

  closeModal() {
    this.dialogRef.close();
  }

}

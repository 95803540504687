<main style="height: 100%; position: relative;">

  <ngx-spinner name="processing" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading" style="color: white; font-weight: bold; padding-top: 40px;">Processing ...</p>
  </ngx-spinner>

  <div  style="float:right; position: fixed; bottom: 30px; right: 2%; z-index: 100; display: flex; flex-direction: column-reverse; align-items: center;">
    <mat-fab-menu color="primary"
                  [fabButtons]="buttons"
                  [hidden]="(!showMenu || (!showJobEditView && !showRawJobView) && (this.globalStateQuery.getSelectedEnvironment() !== 'dev' && authedUser.role !== 'admin')) || ((!showJobEditView && !showRawJobView) && selectedIntegrationDetails$.individualContributor)"
                  icon="menu"
                  (onFabMenuItemSelected)="processFabButtonClick($event)">
    </mat-fab-menu>
  </div>

  <form [formGroup]="scheduledJobForm" >

    <div [hidden]="!showScheduledUserJobDetails" style="width: 98%; position: relative; min-height: 400px; margin: auto;" >

      <ngx-spinner name="loadingjobs" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
        <p class="loading" style="color: white; font-weight: bold; padding-top: 40px;">Loading Scheduled Jobs ...</p>
      </ngx-spinner>

      <div style="height: 20px;"></div>

      <!--Basic Table -->
      <ngx-datatable
        #userJobsTable
        id="userJobsTable"
        class="material expandable"
        [columnMode]="ColumnMode.flex"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="50"
        [rows]="jobList"
        [limit]="10"
        [rowClass]="getRowClass">

        <ngx-datatable-column name="Job Name" [width]="350" [sortable]="false" [canAutoResize]="false">
          <ng-template let-scheduledJob="row" ngx-datatable-cell-template >
            <strong class="{{ scheduledJob['isScheduled'] ? 'isScheduledYes' : 'isScheduledNo'}}">{{ scheduledJob['name'] }}</strong>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Cron" [sortable]="false" [canAutoResize]="false">
          <ng-template let-scheduledJob="row" ngx-datatable-cell-template>
            <strong class="{{ scheduledJob['isScheduled'] ? 'isScheduledYes' : 'isScheduledNo'}}">{{ scheduledJob['cronExpression'] }}</strong>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Status" [sortable]="false" [canAutoResize]="false">
          <ng-template let-scheduledJob="row" ngx-datatable-cell-template>
            <strong class="{{ scheduledJob['isScheduled'] ? 'isScheduledYes' : 'isScheduledNo'}}">{{ scheduledJob['status'] }}</strong>
            <fa-icon [icon]="spinnerIcon" style="color:green;padding-left: 5px" [hidden]="scheduledJob.status != 'Running'" [spin]="true"></fa-icon>
            <fa-icon [icon]="dishIcon" style="color:green ;padding-left: 5px" [hidden]="scheduledJob.status != 'Queueing'"></fa-icon>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Last Result" [sortable]="false" [canAutoResize]="false">
          <ng-template let-scheduledJob="row" ngx-datatable-cell-template>
            <strong class="{{ scheduledJob['isScheduled'] ? 'isScheduledYes' : 'isScheduledNo'}}">{{ scheduledJob['lastRunResult'] }}</strong>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Code" [sortable]="false" [canAutoResize]="false">
          <ng-template let-scheduledJob="row" ngx-datatable-cell-template>
            <strong class="{{ scheduledJob['isScheduled'] && scheduledJob['lastRunResultCode'] == 200 ? 'isScheduledYes' : !scheduledJob['isScheduled'] && scheduledJob['lastRunResultCode'] == 200 ? 'isScheduledNo' : 'lastRunError'}}">
              {{ scheduledJob['lastRunResultCode'] }}
            </strong>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Actions" [width]="350" [sortable]="false" [canAutoResize]="false">
          <ng-template let-scheduledJob="row" ngx-datatable-cell-template>
            <a (click)="editScheduledJobClicked(scheduledJob)" style="color: blue; cursor: pointer;">
              <mat-icon matTooltip="View Details" style="color:royalblue">visibility</mat-icon>
            </a>
            <mat-icon style="color:gray">more_vert</mat-icon>
            <a (click)="refreshJobList()" style="color: blue; cursor: pointer;">
              <mat-icon matTooltip="Refresh" style="color:black">refresh</mat-icon>
            </a>
            <mat-icon style="color:gray">more_vert</mat-icon>
            <a (click)="runScheduledJobClicked(scheduledJob)" [ngClass]="{disabled: false, playenabled: true}" [hidden]="(!scheduledJob.isScheduled || (scheduledJob.jobType !== 'SchemaCheck' && !selectedIntegrationDetails$.enabled))">
              <mat-icon matTooltip="Run">play_circle_filled</mat-icon>
            </a>
            <a (click)="noOp()" [ngClass]="{disabled: false, playenabled: false}" [hidden]="!(!scheduledJob.isScheduled || (scheduledJob.jobType !== 'SchemaCheck' && !selectedIntegrationDetails$.enabled))">
              <mat-icon matTooltip="Job is not scheduled" [hidden]="scheduledJob.jobType !== 'SchemaCheck' && !selectedIntegrationDetails$.enabled">play_circle_filled</mat-icon>
              <mat-icon matTooltip="Cannot run EntitySyncs when integration is disabled" [hidden]="scheduledJob.jobType === 'SchemaCheck' || selectedIntegrationDetails$.enabled">play_circle_filled</mat-icon>
            </a>
            <mat-icon style="color:gray">more_vert</mat-icon>
            <span [hidden]="!selectedIntegrationDetails$.enabled && scheduledJob.jobType !== 'SchemaCheck'">
                        <a (click)="scheduleScheduledJob(scheduledJob)" [hidden]="scheduledJob.isScheduled" class="notscheduled">
                          <mat-icon matTooltip="Schedule">update</mat-icon>
                        </a>
                        <a (click)="scheduleScheduledJob(scheduledJob)" [hidden]="!scheduledJob.isScheduled" class="scheduled">
                          <mat-icon matTooltip="UnSchedule">update_disabled</mat-icon>
                        </a>
                      </span>
            <span [hidden]="selectedIntegrationDetails$.enabled || scheduledJob.jobType === 'SchemaCheck'">
                        <a (click)="noOp()" [hidden]="scheduledJob.isScheduled" class="scheduledisabled">
                          <mat-icon matTooltip="Cannot Schedule or Unschedule jobs when integration is disabled">update</mat-icon>
                        </a>
                        <a (click)="noOp()" [hidden]="!scheduledJob.isScheduled" class="scheduledisabled">
                          <mat-icon matTooltip="Cannot Schedule or Unschedule jobs when integration is disabled">update_disabled</mat-icon>
                        </a>
                      </span>
            <mat-icon style="color:gray">more_vert</mat-icon>
            <a (click)="cancelRunningScheduledJob(scheduledJob)">
              <mat-icon matTooltip="Cancel Running Job" class="{{scheduledJob.status !== 'Running' ? 'canceldisabled' : 'cancelenabled'}}">cancel</mat-icon>
            </a>
          </ng-template>
        </ngx-datatable-column>

      </ngx-datatable>

      <div style="height: 20px;"></div>

    </div>

  </form>

  <!--Edit Section -->
  <div [hidden]="!showJobEditView" style="margin-left: 10px; margin-bottom: 15px;width: 98%">

    <form  class="form-vertical" [formGroup]="scheduledJobForm">

      <div style="height: 10px"></div>

      <mat-card >

        <mat-card-header>
          <mat-card-title>Job Details</mat-card-title>
        </mat-card-header>

        <div style="height: 20px;"></div>

        <div style="padding-left: 30px; width: 80%;  display: flex; ">

          <div>
            <mat-form-field style="width:300px !important">
              <mat-label>Job Id</mat-label>
              <input matInput type="text" value="{{scheduledJob.jobId}}" [readonly]="true" [disabled]="true">
            </mat-form-field>
          </div>

        </div>

        <div style="padding-left: 30px; width: 80%;  display: flex; ">

          <div>
            <mat-form-field style="width:100px !important">
              <mat-label>Cron Expression</mat-label>
              <input matInput type="text" formControlName="cronExpression" required  value="{{scheduledJob.cronExpression}}">
              <mat-error *ngIf="scheduledJobForm.controls.cronExpression.invalid">Cron Expression Required</mat-error>
            </mat-form-field>
          </div>

        </div>

        <div style="height: 10px;"></div>

        <div style="padding-left: 30px; width: 80%;  display: flex; ">

          <mat-form-field style="width:300px !important">
            <mat-label>Status</mat-label>
            <input matInput type="text" value="{{scheduledJob.status}}" [readonly]="true" [disabled]="true">
          </mat-form-field>

          <div style="width: 20px;"></div>

          <mat-form-field style="width:300px !important">
            <mat-label>Status Change DateTime</mat-label>
            <input matInput type="text" value="{{scheduledJob.statusChangeDateTime ? scheduledJob.statusChangeDateTime : '-'}}" [readonly]="true" [disabled]="true">
          </mat-form-field>

        </div>

        <div style="padding-left: 30px; width: 80%;  display: flex; ">

          <mat-form-field style="width:300px !important">
            <mat-label>Last Run Result</mat-label>
            <input matInput type="text" value="{{scheduledJob.lastRunResult ? scheduledJob.lastRunResult : '-'}}" [readonly]="true" [disabled]="true">
          </mat-form-field>

          <div style="width: 20px;"></div>

          <mat-form-field style="width:300px !important">
            <mat-label>Last Run Result Code</mat-label>
            <input matInput type="text" value="{{scheduledJob.lastRunResultCode ? scheduledJob.lastRunResultCode : '-'}}" [readonly]="true" [disabled]="true">
          </mat-form-field>

        </div>

        <div style="padding-left: 30px; width: 80%;  display: flex; ">

          <mat-form-field style="width:300px !important">
            <mat-label>Last Started DateTime</mat-label>
            <input matInput type="text" value="{{scheduledJob.lastStartedDateTime ? scheduledJob.lastStartedDateTime : '-'}}" [readonly]="true" [disabled]="true">
          </mat-form-field>

          <div style="width: 20px;"></div>

          <mat-form-field style="width:300px !important">
            <mat-label>Last Stopped DateTime</mat-label>
            <input matInput type="text" value="{{scheduledJob.lastStoppedDateTime ? scheduledJob.lastStoppedDateTime :'-'}}" [readonly]="true" [disabled]="true">
          </mat-form-field>

        </div>

        <div style="padding-left: 30px; width: 80%;  display: flex; ">

          <mat-form-field style="width:300px !important">
            <mat-label>Last Success DateTime</mat-label>
            <input matInput type="text" value="{{scheduledJob.lastSuccessDateTime ? scheduledJob.lastSuccessDateTime : '-'}}" [readonly]="true" [disabled]="true">
          </mat-form-field>

          <div style="width: 20px;"></div>

          <mat-form-field style="width:300px !important">
            <mat-label>Last Run Duration In Secs</mat-label>
            <input matInput type="text" value="{{scheduledJob.lastRunDurationInSecs ? scheduledJob.lastRunDurationInSecs : '-'}}" [readonly]="true" [disabled]="true">
          </mat-form-field>

        </div>

        <div style="padding-left: 30px; width: 80%;  display: flex; ">

          <mat-form-field style="width:300px !important">
            <mat-label>Last Run Exception</mat-label>
            <input matInput type="text" value="{{scheduledJob.lastRunException ? scheduledJob.lastRunException : '-'}}" [readonly]="true" [disabled]="true">
          </mat-form-field>

          <div style="width: 20px;"></div>

          <mat-form-field style="width:300px !important">
            <mat-label>Last Failure DateTime</mat-label>
            <input matInput type="text" value="{{scheduledJob.lastFailureDateTime ? scheduledJob.lastFailureDateTime : '-'}}" [readonly]="true" [disabled]="true">
          </mat-form-field>

        </div>

        <div style="padding-left: 30px; width: 80%;  display: flex; ">

          <mat-form-field style="width:300px !important">
            <mat-label>Failed Attempts</mat-label>
            <input matInput type="text" value="{{scheduledJob.failedAttempts ? scheduledJob.failedAttempts : '-'}}" [readonly]="true" [disabled]="true">
          </mat-form-field>

          <div style="width: 20px;"></div>

          <mat-form-field style="width:300px !important">
            <mat-label>Disabled At DateTime</mat-label>
            <input matInput type="text" value="{{scheduledJob.disabledAtDateTime ? scheduledJob.disabledAtDateTime : '-'}}" [readonly]="true" [disabled]="true">
          </mat-form-field>

        </div>

        <div style="padding-left: 30px; width: 80%;">
          <div>Job Details Element</div>
          <div style="height: 5px"></div>
          <div style="width: 800px" >
            <pre>{{scheduledJob.jobDetail  | json }}</pre>
          </div>
        </div>

      </mat-card>

    </form>

  </div>

  <!--Raw View-->
  <div [hidden]="!showRawJobView" style="margin-left: 10px; margin-bottom: 15px;width: 98%">
    <mat-card >

      <mat-card-header>
        <mat-card-title>Job Details - Raw JSON</mat-card-title>
      </mat-card-header>

      <div style="height: 20px;"></div>
      <!--(change)="rawChanged($event)" [hidden]="!showJobRawView"-->
      <div  style="width: 100%;" >
        <textarea class="commentA" [(ngModel)]="rawSJJason"></textarea>
      </div>

      <div style="height: 20px;"></div>
    </mat-card>
  </div>

</main>

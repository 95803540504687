import {ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuditLogService} from '../../../security/audit/audit-logs/state/audit-log.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AdapterService} from '../../../services/adapter.service';
import {GlobalUiStateQuery} from '../../../state/global-ui-state.query';
import {IntegrationDetails} from '../../../models/integration-details';
import {PagingDetails} from '../../../models/paging-details';
import { ColumnMode } from '@swimlane/ngx-datatable';
import {QueueDetails} from '../../../models/queue-details';
import {ToastrService} from 'ngx-toastr';
import {QueueMessage} from '../../../models/queue-message';
import {ViewJsonDialogComponent} from '../../../shared/view-json-dialog/view-json-dialog.component';
import {ConfirmationDialogComponent} from '../../../components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-adapter-integration-queue-listener',
  templateUrl: './adapter-integration-queue-listener.component.html',
  styleUrls: ['./adapter-integration-queue-listener.component.scss']
})
export class AdapterIntegrationQueueListenerComponent implements OnInit {

  selectedIntegrationDetails$: IntegrationDetails = new IntegrationDetails(null);
  queueMessages$?: QueueMessage[] = [];
  displayedColumns: string[] = ['EpochCreationTs',  'MessageBody.meta','MessageBody.meta', 'MessageBody.meta', 'MessageAttributes',  'MessageAttributes', 'url', 'payload'];
  defaultPageSize = 10;
  filter: string = null;
  pagingDetails = new PagingDetails();
  readonly headerHeight = 50;
  readonly rowHeight = 50;
  readonly pageLimit = 10;
  startTime:  number = 0;
  selectedAdapterDetails$: Object;
  isLoading: boolean;
  searchComplete: boolean = false;

  _queueDetails: QueueDetails = null;
  _queueDetailsLoaded: boolean = false;
  _queueListenerRunning: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private auditLogService: AuditLogService,
    public matDialog: MatDialog,
    public adapterService: AdapterService,
    private globalStateQuery: GlobalUiStateQuery,
    private el: ElementRef,
    private toastr: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ColumnMode = ColumnMode;

  async ngOnInit(): Promise<void> {

    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();

    this._queueDetailsLoaded = false;
    this._queueListenerRunning = false;

    await this.checkStatus();
    await this.onScroll(0);

  }

  async checkStatus(){
    this._queueDetails = await this.adapterService.getIntegrationQueueListenerDetails(this.selectedAdapterDetails$['endpoint'],this.selectedIntegrationDetails$.integrationId);
    this._queueDetailsLoaded = true;
  }

   startListener() {
     const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
       width: '250px',
       data: {
         dataObj:null ,
         title:"Persist Records",
         message:"Would you like to keep the current records that have been retrieved?"
       }
     });
     dialogRef.afterClosed().subscribe(async dialogResult => {
       let purgeRecords = true;
       if (dialogResult.response) {
         purgeRecords = false;
       }else{
         this.queueMessages$ = [];
       }
       await this.spinner.show("processing");
       //var result =  this.adapterService.startIntegrationQueueListener(this.selectedAdapterDetails$['endpoint'],this.selectedIntegrationDetails$.integrationId);
       this.adapterService.startIntegrationQueueListener(this.selectedAdapterDetails$['endpoint'],this.selectedIntegrationDetails$.integrationId,purgeRecords).subscribe(
         async response => {
           setTimeout(async () => {
             await this.checkStatus();
             this.changeDetectorRef.detectChanges();
             await this.spinner.hide("processing");
             this.showToaster("Queue Listener started", 0);
           }, 2000)
         },
         error => {
           const er = error.error.errors[0];
           this.spinner.hide("processing");
           this.showToaster("Queue Listener was unable to start " + er.Title + ". " + er.Detail,1);
         } ,
         () => {
           this.changeDetectorRef.detectChanges();
         }
       );
     });
  }

  async stopListener() {
    await this.spinner.show("processing");
    this.adapterService.stopIntegrationQueueListener(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId).subscribe(
      async response => {
        await this.checkStatus();
        this.changeDetectorRef.detectChanges();
        this.showToaster("Queue Listener stopped", 0);
      },
      error => {
        const er = error.error.errors[0];
        this.showToaster("Queue Listener was unable to stop " + er.Title + ". " + er.Detail, 1);
      },
      async () => {
        await this.spinner.hide("processing");
      }
    );

  }

  showToaster(message, statusCode) {
    statusCode === 0 ? this.toastr.success(message) : this.toastr.error(message);
  }

  async onScroll(offsetY: number) {

    // total height of all rows in the viewport
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;

    // check if we scrolled to the end of the viewport
    if (!this.isLoading && offsetY + viewHeight >= this.queueMessages$.length * this.rowHeight) {

      // check if we haven't fetched any results yet
      if (this.queueMessages$.length === 0) {
        // calculate the number of rows that fit within viewport
        const pageSize = Math.ceil(viewHeight / this.rowHeight);

        // change the limit to pageSize such that we fill the first page entirely
        // (otherwise, we won't be able to scroll past it)
        this.pagingDetails.size = Math.max(pageSize, this.pageLimit);
      }
      await this.loadPage();
    }
  }

  private async loadPage() {

    if (this.searchComplete)  {
      return;
    }

    // set the loading flag, which serves two purposes:
    // 1) it prevents the same page from being loaded twice
    // 2) it enables display of the loading indicator
    this.isLoading = true;
    const x = await this.adapterService.getQueueMessages(this.selectedAdapterDetails$['endpoint'],
      this.selectedIntegrationDetails$.integrationId, this.pagingDetails.size,  this.startTime);

    const results = x ? x.theList.sort(function compareFn(a, b) {
      if (a['EpochCreationTs'] > b['EpochCreationTs']) {
        return -1;
      }
      if (a['EpochCreationTs'] < b['EpochCreationTs']) {
        return 1
      }
      return 0;
    }) : [];

    if (results.length > 0) {
      this.startTime = results[results.length-1]["EpochCreationTs"];
      const queueMessages  = [...this.queueMessages$, ...results];
      this.queueMessages$ = queueMessages;
    } else  {
      this.searchComplete = true;
    }

    this.isLoading = false;

  }

  // async filterChanged(event) {
  //   if( event == this.filter)
  //     return;
  //
  //   this.filter = event;
  //   this.startTime = 0;
  //   this.logs$ = [];
  //   this.searchComplete = false;
  //   await this.onScroll(0);
  //
  // }

  openPayload(log: QueueMessage) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data =
      {
        "json":log.MessageBody['data'] ,
        "message":"Meta Data"
      };
    this.matDialog.open(ViewJsonDialogComponent, dialogConfig);
  }


  openMetaData(log: QueueMessage) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data =
      {
        "json":log.MessageBody['meta'] ,
        "message":"Meta Data"
      };
    this.matDialog.open(ViewJsonDialogComponent, dialogConfig);
  }



}


<div style="width: 100%; position: relative;">
  <ngx-spinner name="processing" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading" style="color: white; font-weight: bold; padding-top: 40px;">Processing Request...</p>
  </ngx-spinner>

  <div>

    <mat-toolbar style="font-size: 12px;">
      <span class="spacer"></span>
      <button mat-raised-button [hidden]="!_queueDetailsLoaded || !(_queueDetails !=null && _queueDetails.status =='running')"  (click)="stopListener()">Stop Listener</button>
      <span class="small-spacer"></span>
      <button mat-raised-button [hidden]="!_queueDetailsLoaded || !( _queueDetails == null || _queueDetails.status !='running')"  (click)="startListener()">Start Listener</button>
    </mat-toolbar>

  </div>

  <ngx-datatable
    #queueable
    id="queueable"
    class="material server-scroller"
    [columnMode]="ColumnMode.flex"
    [headerHeight]="headerHeight"
    [rowHeight]="rowHeight"
    [rows]="queueMessages$"
    [loadingIndicator]="isLoading"
    [scrollbarV]="true"
    (scroll)="onScroll($event.offsetY)">

    <ngx-datatable-column name="Published Time (UTC)" [sortable]="false" [flexGrow]="2">
      <ng-template let-log="row" ngx-datatable-cell-template >
        {{ log.MessageBody.meta.publishedTime | date:'dd-mm-yyyy,  hh:mm:ss ':'+0000' }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Origin" [sortable]="false" [flexGrow]="2">
      <ng-template let-log="row" ngx-datatable-cell-template >
        <a (click)="openMetaData(log)" [ngClass]="'enabled'" matTooltip="View payload">  {{ log.MessageBody.meta.origin == null? "N/A":  log.MessageBody.meta.origin}}</a>

      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Operation" [sortable]="false" [flexGrow]="2">
      <ng-template let-log="row" ngx-datatable-cell-template >
        <a (click)="openPayload(log)" [ngClass]="{'enabled': log.MessageBody.meta.operation != null ,'disabled': log.MessageBody.meta.operation == null  }" matTooltip="View payload">  {{ log.MessageBody.meta.operation == null ? log.MessageBody.meta.eventType : log.MessageBody.meta.operation }}</a>

      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Type" [sortable]="false" [flexGrow]="2">
      <ng-template let-log="row" ngx-datatable-cell-template >
        {{ log.MessageBody.meta.entityType == null ? "N/A" :log.MessageBody.meta.entityType }}
      </ng-template>
    </ngx-datatable-column>



  </ngx-datatable>

</div>


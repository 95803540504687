<script src="https://apis.google.com/js/platform.js"></script>

<mat-toolbar>
  <span [hidden]="integrationTypeForm.value.id"> Add Toolkit Integration Type</span>
  <span [hidden]="!integrationTypeForm.value.id"> Edit Toolkit Integration Type</span>
  <span class="spacer"></span>
  <div style="float:right; position: fixed; bottom: 50px; right: 2%; z-index: 100; display: flex; flex-direction: column-reverse; align-items: center;">
    <button mat-fab color="primary" aria-label="Cancel" (click)="onCancel()">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</mat-toolbar>

<div style="padding: 20px; width: 100%;">

  <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading" style="color: white; font-weight: bold;">Saving Integration Type ...</p>
  </ngx-spinner>

  <form [formGroup]="integrationTypeForm">

    <mat-form-field>
      <mat-label>Display Name</mat-label>
      <input matInput
             formControlName="displayName">
    </mat-form-field>

    <div style="height: 10px"></div>

    <mat-form-field>
      <mat-label>Type Code</mat-label>
      <input matInput
             formControlName="code">
    </mat-form-field>

    <div style="height: 10px"></div>

    <div style="width:100%; max-width:100px;">

      <mat-icon *ngIf="showPhoto" (click)="editPhoto()" size="2x" class="upload-icon">upload</mat-icon>
      <!-- Display Image -->
      <div style="height: 10px" *ngIf="showPhoto"></div>
      <div *ngIf="showPhoto">Logo Image:</div>
      <div style="padding-top: 10px;">
        <amplify-s3-image id="intTypeImage" [path]="logoFilename" level="public" *ngIf="showPhoto" size="50px;" ></amplify-s3-image>
      </div>
      <!-- Photo Picker -->
      <amplify-s3-image-picker *ngIf="!showPhoto" level="public" track header-title="Upload Logo Image"></amplify-s3-image-picker>
      <div style="height: 10px"></div>
    </div>

    <div style="height: 10px"></div>

  </form>

  <div style="text-align: left; margin: auto">
    <button mat-button (click)="upsertIntegrationType()" [disabled]="!integrationTypeForm.valid" style="margin-right: 10px;" class="actionButton">Save</button>
    <button mat-button (click)="onCancel()" class="actionButton">Cancel</button>
  </div>

</div>







<h1 mat-dialog-title><strong>{{title}} </strong></h1>
<div mat-dialog-content>
  <div >
    <span [ngClass]="contentHasError == false ?'':'hasError'" >{{message}}</span>
  </div>

  <ngx-codemirror ref="editor" (ngModelChange)="checkEditorContent($event)"
    [(ngModel)]="content"
    (cursorActivity)="checkEditorContent($event)"
    [options]=codeMirrorOptions>
  </ngx-codemirror>

</div>

<mat-dialog-actions>
  <button mat-raised-button (click)="cancel()" >Cancel</button>
  <button mat-raised-button (click)="close()" [disabled]="!showSave">Save</button>
</mat-dialog-actions>

<script src="https://apis.google.com/js/platform.js"></script>

<main class="adapter-details-container">
  
    <app-adapter-details-header></app-adapter-details-header>

    <app-adapter-details-nav></app-adapter-details-nav>

    <router-outlet></router-outlet>

</main>
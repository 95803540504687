import { Injectable } from '@angular/core';
import {AuditTracker} from './AuditTracker';
import {HttpClient} from '@angular/common/http';
import {LogData} from '../models/log-data';
import {ErrorLogModel} from '../models/error-log-model';
import {PagingDetails} from '../models/paging-details';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  apiUrl: string;

  constructor(private http: HttpClient,
              private auditTracker: AuditTracker) { }

  async listAdapterLogs(adapterEndpoint: string, page: PagingDetails, filter, startTime): Promise<LogData>{
    this.apiUrl = "/api/admin/logging/logs?";

    this.apiUrl = this.apiUrl + "pageSize=" + page.size;

    if(startTime != null && startTime > 0 ) {
      this.apiUrl = this.apiUrl + "&startTime=" + startTime;
    }

    if(filter != null ) {
      this.apiUrl = this.apiUrl + "&filter=" + JSON.stringify(filter);
    }

    console.log(this.apiUrl);

    const result = await this.http.get(adapterEndpoint + this.apiUrl, {observe: 'response', responseType: 'json'}).toPromise();

    const strData = JSON.stringify(result.body);

    return <LogData>JSON.parse(strData);

  }

  async listAdapterLogsForIntegration(adapterEndpoint: string, integrationId, page: PagingDetails, filter,startTime): Promise<LogData>{
    this.apiUrl = "/api/admin/logging/logs/"+integrationId+"?";

    this.apiUrl = this.apiUrl + "pageSize=" + page.size;

    if(startTime != null && startTime > 0 ) {
      this.apiUrl = this.apiUrl + "&startTime=" + startTime;
    }

    if(filter != null ) {
      this.apiUrl = this.apiUrl + "&filter=" + JSON.stringify(filter);
    }

    console.log(this.apiUrl);

    const result = await this.http.get(adapterEndpoint + this.apiUrl, {observe: 'response', responseType: 'json'}).toPromise();

    const strData = JSON.stringify(result.body);

    return <LogData>JSON.parse(strData);
  }
}

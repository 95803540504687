<script src="https://apis.google.com/js/platform.js"></script>

<mat-toolbar>
  <div>Admin UI User Management</div>
  <span class="spacer"></span>
  <form>

    <div style="display: flex; justify-content: space-between;">
      <div style="flex: 0 0 27px;">
        <img  src="assets/search.png" alt="Search" style="height: 16px; width: 16px; vertical-align: middle; padding-right: 5px; padding-left: 5px;" >
      </div>
      <div style="flex: 1 1 auto; font-size: 10pt;">
        <input matInput placeholder="Search Users" style="width: 100%; border: none; border-bottom: solid 1px;" [value]="searchCriteria" (input)="onSearchChange($event.target.value)">
      </div>
      <div style="flex: 0 0 25px;">
        <img  src="assets/clear.png" alt="Search" style="height: 20px; width: 20px; vertical-align: middle; padding-left: 5px;" (click)="clearSearch()" >
      </div>

    </div>

  </form>
  <div style="float:right; position: fixed; bottom: 50px; right: 2%; z-index: 100; display: flex; flex-direction: column-reverse; align-items: center;" [hidden]="!this.globalStateQuery.getCachedUser() || (this.globalStateQuery.getCachedUser() && this.globalStateQuery.getCachedUser().role !== 'admin')">
    <button mat-fab color="primary" aria-label="Add User" (click)="addUser()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</mat-toolbar>

<div style="width: 100%">

  <ngx-datatable
    #userJobsTable
    class="material"
    [loadingIndicator]=loading
    [columnMode]="ColumnMode.flex"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="50"
    [rows]="users$ | async"
    [limit]="10">

    <ngx-datatable-column name="Name" [sortable]="false"  [flexGrow]="2">
      <ng-template let-user="row" ngx-datatable-cell-template>
        <strong>{{ user.userDetail.name }}</strong>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Email" [sortable]="false"  [flexGrow]="3">
      <ng-template let-user="row" ngx-datatable-cell-template>
        <strong>{{ user.emailAddress }}</strong>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Username" [sortable]="false"  [flexGrow]="3">
      <ng-template let-user="row" ngx-datatable-cell-template>
        <strong>{{ user.userDetail.username ?? '-- Awaiting 1st Login --' }}</strong>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Env Prefix" [sortable]="false"  [flexGrow]="3">
      <ng-template let-user="row" ngx-datatable-cell-template>
        <strong>{{ user.userDetail.envPrefix }}</strong>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Role" [sortable]="false" [flexGrow]="1">
      <ng-template let-user="row" ngx-datatable-cell-template>
        <strong>{{ user.role }}</strong>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Actions" [sortable]="false" [flexGrow]="2">
      <ng-template let-user="row" ngx-datatable-cell-template>
        <a (click)="$event.stopPropagation(); userClicked(user)" style="color: blue; cursor: pointer;"><mat-icon matTooltip="View" style="color:royalblue">visibility</mat-icon></a>
        <mat-icon style="color:gray" [hidden]="!this.globalStateQuery.getCachedUser() || (this.globalStateQuery.getCachedUser() && this.globalStateQuery.getCachedUser().role !== 'admin')">more_vert</mat-icon>
        <a (click)="$event.stopPropagation(); deleteUser('Delete', user)" style="color: blue; cursor: pointer;" [hidden]="!this.globalStateQuery.getCachedUser() || (this.globalStateQuery.getCachedUser() && this.globalStateQuery.getCachedUser().role !== 'admin')"><mat-icon matTooltip="Delete" style="color:red">delete</mat-icon></a>
        <mat-icon style="color:gray" [hidden]="this.globalStateQuery.getSelectedEnvPrefix() === 'production' || this.globalStateQuery.getSelectedEnvPrefix() === 'staging' || !this.globalStateQuery.getCachedUser() || (this.globalStateQuery.getCachedUser() && this.globalStateQuery.getCachedUser().role !== 'admin')">more_vert</mat-icon>
        <a (click)="$event.stopPropagation(); impersonateUser(user)" style="color: blue; cursor: pointer;" [hidden]="this.globalStateQuery.getSelectedEnvPrefix() === 'production' || this.globalStateQuery.getSelectedEnvPrefix() === 'staging' || !this.globalStateQuery.getCachedUser() || (this.globalStateQuery.getCachedUser() && this.globalStateQuery.getCachedUser().role !== 'admin') || this.globalStateQuery.getSelectedImpersonatedEnvPrefix() === user.userDetail.envPrefix">
          <img src="assets/impersonate_user.png" title="Impersonate" style="height: 22px; width: 22px; vertical-align: top;"/>
        </a>
        <a (click)="$event.stopPropagation(); cancelImpersonation()" style="color: blue; cursor: pointer;" [hidden]="this.globalStateQuery.getSelectedEnvPrefix() === 'production' || this.globalStateQuery.getSelectedEnvPrefix() === 'staging' || !this.globalStateQuery.getCachedUser() || (this.globalStateQuery.getCachedUser() && this.globalStateQuery.getCachedUser().role !== 'admin') || this.globalStateQuery.getSelectedImpersonatedEnvPrefix() !== user.userDetail.envPrefix">
          <img src="assets/impersonate_user_green.png" title="Impersonate" style="height: 22px; width: 22px; vertical-align: top;"/>
        </a>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>

</div>




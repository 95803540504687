import {HostListener, Component, OnInit, Inject, ChangeDetectorRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AdapterService } from '../../../services/adapter.service';
import { IntegrationDetails } from '../../../models/integration-details';
import { MatDialog } from '@angular/material/dialog';
import {GlobalConfig, ToastrService} from 'ngx-toastr';
import { GlobalUiStateQuery } from '../../../state/global-ui-state.query';
import { GlobalUiStateService } from '../../../state/global-ui-state.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {CustomToastComponent} from '../../../components/custom-toast/custom-toast.component';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'app-adapter-details-integrations',
  templateUrl: './adapter-details-integrations.component.html',
  styleUrls: ['./adapter-details-integrations.component.scss']
})
export class AdapterDetailsIntegrationsComponent implements OnInit {

  public numCols = 0;
  public colPadding = '10px';
  public width = 0;
  public remainder = 0;
  loading: boolean;

  selectedAdapterDetails$: Object;
  public searchResultList: IntegrationDetails[] = [];
  public filteredResultList: IntegrationDetails[] = [];
  activeIntegration: IntegrationDetails = new IntegrationDetails(null);
  errorMessage = '';
  spinnerIcon: any;
  searchCriteria: string;
  options: GlobalConfig;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(SESSION_STORAGE) private storage: StorageService,
    private adapterService: AdapterService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private globalStateQuery: GlobalUiStateQuery,
    private globalStateService: GlobalUiStateService,
    private chRef: ChangeDetectorRef
  ) {
    this.spinnerIcon = faSpinner;
    this.options = this.toastr.toastrConfig;
  }

  ngOnInit(): void {
    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    this.calcSizes();
    this.searchCriteria = "";
    this.loading = false;

    this.activatedRoute.queryParams.subscribe(async queryParams => {
      await this.parentReloadPage();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calcSizes();
  }

  calcSizes()
  {
    this.numCols = Math.floor((window.innerWidth - 200) / 165);
    this.remainder = window.innerWidth - 200 - (this.numCols * 165);
    if (this.remainder < this.numCols * 10)
    {
      this.numCols = this.numCols - 1;
      this.remainder = window.innerWidth - 200 - (this.numCols * 165);
    }
    this.colPadding = (Math.ceil(this.remainder / (this.numCols - 1))).toString() + "px";
    this.width = window.innerWidth;
  }

  async onboardIntegrationClicked() {

    const newIntegration = new IntegrationDetails(null);
    newIntegration.apiLimits.deleteJobLimitPercentage = 0;
    newIntegration.apiLimits.crudLimitPercentage = 0;
    newIntegration.apiLimits.searchLimitPercentage = 0;
    newIntegration.apiLimits.entitySyncLimitPercentage = 0;

    this.globalStateService.updateSelectedAdapterIntegrationNavPage('adapter-integration-details');
    this.globalStateService.updateSelectedAdapterIntegration(newIntegration);
    await this.router.navigate(['/adapters/adapter-integration']);

  }

  async parentReloadPage(): Promise<void> {
    await this.listIntegrations();
    this.chRef.detectChanges();
  }

  async listIntegrations() {

    this.loading = true;

    const opt = cloneDeep(this.options);
    opt.toastComponent = CustomToastComponent;
    opt.toastClass = 'info';
    opt.closeButton = false,
    opt.positionClass = 'toast-center-center',
    opt.iconClasses = 'info'
    this.toastr.show("Loading Integrations ...", "", opt);

    this.adapterService.listIntegrations(this.selectedAdapterDetails$['endpoint']).subscribe(
      async result => {
        console.log(result.body);
        this.searchResultList = Object.keys(result.body).map(i =>
          new IntegrationDetails(result.body[i])
        );
        this.filteredResultList = this.searchResultList.sort((a, b) => {
          return a.integrationId - b.integrationId;
        });
      },
      async error => {
        if (error.name === "TimeoutError") {
          this.showToaster('Error calling listIntegrations on the Adapter Integration. Call Timed out!', 1);
        } else {
          this.showToaster('Error calling listIntegrations on the Adapter Integration. Status code: ' + error.status, 1);
        }
        this.errorMessage = 'Error calling listIntegrations on the Adapter Integration API Endpoint: ' + JSON.stringify(error);
      },
      async () => {
        this.loading = false;
        this.toastr.clear();
      }
    );
  }

  showToaster(message, statusCode){
    statusCode === 0 ? this.toastr.success(message) : this.toastr.error(message);
  }

  onSearchChange(searchValue: string): void {
    this.searchCriteria = searchValue;
    if ( searchValue.length === 0 ) {
      this.filteredResultList =  this.searchResultList;
    }
    else {
      this.filteredResultList = this.searchResultList.filter(x => { console.log(x.name); return (x.name.toUpperCase().includes(searchValue.toUpperCase()) ||
        x.integrationId === Number(searchValue) ); });
    }
  }

  clearSearch(): void {
    this.filteredResultList =  this.searchResultList;
    this.searchCriteria = "";
  }

}

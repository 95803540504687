import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputDialogComponent implements OnInit {

  title: string;
  message: string;
  content: any;
  showSave = false;
  contentHasError = false;
  orgMessage: string;

  codeMirrorOptions: any = {
    theme: 'moxer',
    mode: 'application/ld+json',
    lineNumbers: true,
    lineWrapping: true,
    foldGutter: true,
    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true
  };

  constructor(public dialogRef: MatDialogRef<InputDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title =  this.data.title;
    this.message = this.data.message;
    this.orgMessage = this.message;
    this.content = "";
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close({data:JSON.parse(this.content)});
  }

  checkEditorContent(event) {
    if( this.content.length > 0 )
    {
      try{
        JSON.parse(this.content)
        this.contentHasError = false;
        this.message = this.orgMessage;
        this.showSave = true;
      }
      catch (e) {
        this.contentHasError = true;
        this.message = "Please provide valid json";
        this.showSave = false;
      }
    }
    else{
      this.contentHasError = false;
      this.message = this.orgMessage;
      this.showSave = false;
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}

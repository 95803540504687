<h2 mat-dialog-title> {{title}} </h2>

<ngx-spinner name="processing2" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
  <p class="loading" style="color: white; font-weight: bold; padding-top: 40px;">Processing ...</p>
</ngx-spinner>

<mat-dialog-content>

  <div style="width: 800px">
    <ngx-json-viewer [json]="jsondata"  [expanded]="false" [hidden]="isEdit"></ngx-json-viewer>
    <textarea class="queryTextArea" [value]="editData | json" [hidden]="!isEdit" (change)="entityChanged($event)"></textarea>
    <div style="height: 20px;"></div>
  </div>

</mat-dialog-content>

<mat-dialog-actions>

  <button type="button" mat-flat-button (click)="close()" [hidden] ="isEdit">Close</button>
  <button type="button" mat-flat-button (click)="cancel()" [hidden] ="!isEdit">Cancel</button>
  <button type="button" mat-flat-button (click)="edit()" [hidden] ="isEdit">Edit</button>
  <button type="button" mat-flat-button (click)="save()" [hidden] ="!isEdit" [disabled]="!validJson" [ngClass]="{disabled: !validJson}">Save</button>

</mat-dialog-actions>

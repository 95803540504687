import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {Auth} from 'aws-amplify';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import {GlobalUiStateService} from './state/global-ui-state.service';
import {APIService} from './API.service';
import {GlobalUiStateQuery} from './state/global-ui-state.query';
import {SecurityService} from './services/security.service';
import {IntegrationDetails} from './models/integration-details';
import {AdapterService} from './services/adapter.service';
import {Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              @Inject(SESSION_STORAGE) private storage: StorageService,
              private globalStateService: GlobalUiStateService,
              private api: APIService,
              private globalStateQuery: GlobalUiStateQuery,
              private securityService: SecurityService,
              private adapterService: AdapterService,
              private titleService: Title) {

    this.titleService.setTitle("Cerebro - " + this.globalStateQuery.getSelectedEnvironment());
  }

  canActivate(route: ActivatedRouteSnapshot): Promise < boolean > {

    const queryParams = route.queryParams;
    if (route.children.length > 0 &&
      route.children[route.children.length-1].url.length > 0 &&
      route.children[route.children.length-1].url[0].path.indexOf("adapter-integration") > -1 &&
      queryParams["integrationId"] &&
      queryParams["jwt"] &&
      queryParams["adapterUrl"])
    {
      return new Promise(async (resolve) => {
        // validate and set the jwt in global memory
        // verify the jwt
        if (!this.securityService.checkValidToken(queryParams["jwt"])) {
          console.log("Instance direct parameters invalid...")
          await this.router.navigate(['/login']);
          resolve(false);
        }
        else {
          var ad = { adapterId: "instance_direct", adapterType: "instance_direct", name: "instance_direct", endpoint: queryParams["adapterUrl"] };
          this.globalStateService.updateSelectedAdapterDetails(ad);
          this.globalStateService.updateCachedAdapterConnectionDetails("instance_direct", queryParams["jwt"], "");
          this.adapterService.getIntegration(queryParams["adapterUrl"], queryParams["integrationId"]).subscribe(
            async result => {
              const integration = new IntegrationDetails(result.body)
              ad["adapterType"] = integration.type;
              this.globalStateService.updateSelectedAdapterDetails(ad);

              // if the user is not logged in, set the instanceDirect flag in global memory
              // This will restrict access to most of the UI
              this.globalStateService.updateInstanceDirect(true);
              this.globalStateService.updateCallbackUrl(queryParams["callback"]);

              this.globalStateService.updateSelectedAdapterIntegrationNavPage('adapter-integration-details');
              this.globalStateService.updateSelectedAdapterIntegration(integration);
              //await this.router.navigate(['/adapters/adapter-integration']);
              resolve(true);
            },
            async error => {
              console.log("Instance direct parameters invalid...")
              console.log(error);
              this.globalStateService.updateSelectedAdapterDetails(null);
              this.globalStateService.updateCachedAdapterConnectionDetails(null, null, null);
              await this.router.navigate(['/login']);
              resolve(false);
            }
          );
        }
      });
    }
    else {
      return new Promise((resolve) => {
        Auth.currentAuthenticatedUser({
          bypassCache: false
        })
          .then(async (user) => {
            if (user){
              const userList = await this.api.ListUsers({ emailAddress: { eq: user.attributes.email}});
              if (userList.items.length > 0) {
                const fullUser = await this.api.GetUser(userList.items[0].id);
                const connectionProfileList = [];
                fullUser.userDetail.connectionProfiles.items.forEach( profile => {
                  connectionProfileList.push({ id: profile.id, name: profile.name, envPrefix: this.globalStateQuery.getSelectedImpersonatedEnvPrefix()?? profile.envPrefix, adapterId: profile.adapterId });
                });
                this.globalStateService.updateConnectionProfiles(connectionProfileList.sort((a, b) => {
                  return a.name.toUpperCase().localeCompare(b.name.toUpperCase())
                }));

                if (!this.globalStateQuery.getSelectedEnvironment()) {
                  this.globalStateService.updateSelectedEnvironment("dev");
                }

                const envPrefix = this.globalStateQuery.getSelectedEnvironment() === "dev" ? fullUser.userDetail.envPrefix : this.globalStateQuery.getSelectedEnvironment();
                this.globalStateService.updateSelectedEnvPrefix(envPrefix);
                this.globalStateService.updateLoggedInUser(fullUser);
              }
              else {
                await this.router.navigate(['/login'], { queryParams: { status: '1' } });
                resolve(true);
              }
              if (this.globalStateQuery.getSelectedAdapterDetails()['name'] === 'instance_direct') {
                if (route.children[route.children.length-1].url.length == 0 || route.children[route.children.length-1].url[0].path.indexOf("adapter-integration") == -1) {
                  await this.router.navigate(['/adapters/adapter-integration']);
                }
                resolve(true);
              }
              else {
                resolve(true);
              }

            }
          })
          .catch(async (e) => {
            console.log(e)
            if (this.globalStateQuery.getSelectedAdapterDetails()['name'] === 'instance_direct') {
              if (route.children[route.children.length-1].url.length == 0 || route.children[route.children.length-1].url[0].path.indexOf("adapter-integration") == -1) {
                await this.router.navigate(['/adapters/adapter-integration']);
              }
              resolve(true);
            }
            else {
              await this.router.navigate(['/login']);
              resolve(true);
            }
          });
      });
    }
  }
}

<main style="height: 100%; position: relative;">

  <ngx-spinner name="processing" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading...." style="color: white; font-weight: bold; padding-top: 40px;">Processing ...</p>
  </ngx-spinner>

  <form [formGroup]="jobForm" >

      <div [hidden]="false" style="width: 98%; position: relative; min-height: 400px; margin: auto;" >

        <ngx-spinner name="loadingjobs" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
          <p class="loading" style="color: white; font-weight: bold; padding-top: 40px;">Loading Scheduled Jobs ...</p>
        </ngx-spinner>

        <div style="height: 20px;"></div>

        <ngx-datatable
          #jobTable
          class="material expandable"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="50"
          [rows]="jobList"
          [limit]="10">

          <ngx-datatable-row-detail [rowHeight]="'100%'" (toggle)="onSelectedEntityDetailToggle($event)">
            <ng-template let-job="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            <mat-card class="example-card">
              <mat-card-subtitle>Job # {{job['batchJobId']}}</mat-card-subtitle>
              <mat-card-title>Job Information</mat-card-title>
              <mat-card-content>
                <mat-grid-list cols="4" rowHeight="20">
                  <mat-grid-tile  [colspan]="1" [rowspan]="1" ><div class="text-inside-grid"><b>Created:</b> {{job['createdTs']?.trim().length > 0 ? job['createdTs'] : "N/A"}}</div></mat-grid-tile>
                  <mat-grid-tile  [colspan]="1" [rowspan]="1" ><div class="text-inside-grid"><b>Started:</b> {{job['startedTs']?.trim().length > 0 ? job['createdTs'] : "N/A"}}</div></mat-grid-tile>
                  <mat-grid-tile  [colspan]="1" [rowspan]="1" ><div class="text-inside-grid"><b>Completed:</b> {{job['completedTs']?.trim().length > 0 ? job['createdTs'] : "N/A"}}</div></mat-grid-tile>
                  <mat-grid-tile  [colspan]="1" [rowspan]="1" ><div class="text-inside-grid"><b>Status:</b> {{job['status']?.trim().length > 0 ? job['createdTs'] : "N/A"}}</div></mat-grid-tile>

                  <mat-grid-tile  [colspan]="1" [rowspan]="1" ><div class="text-inside-grid"><b># of Creates:</b> {{job['jobInfo']['creates']}}</div></mat-grid-tile>
                  <mat-grid-tile  [colspan]="1" [rowspan]="1" ><div class="text-inside-grid"><b># of Updates:</b> {{job['jobInfo']['updates']}}</div></mat-grid-tile>
                  <mat-grid-tile  [colspan]="2" [rowspan]="1" ><div class="text-inside-grid"><b># of Deletes:</b> {{job['jobInfo']['deletes']}}</div></mat-grid-tile>

                  <mat-grid-tile  [colspan]="1" [rowspan]="1" ><div class="text-inside-grid"><b>Items Processed:</b> {{job['itemsProcessed']}}</div></mat-grid-tile>
                  <mat-grid-tile  [colspan]="1" [rowspan]="1" ><div class="text-inside-grid"><b>Succeeded:</b> {{job['jobResults']['succeeded']}}</div></mat-grid-tile>
                  <mat-grid-tile  [colspan]="2" [rowspan]="1" ><div class="text-inside-grid"><b>Failed:</b> {{job['jobResults']['failed']}}</div></mat-grid-tile>
                </mat-grid-list>
              </mat-card-content>
              <mat-divider inset></mat-divider>
              <mat-card-actions>

              </mat-card-actions>
              <mat-card-footer>

              </mat-card-footer>
            </mat-card>

              <div style="margin-bottom: 15px; width: 100%" >

                <ngx-datatable
                  #jobsMessageTable
                  id="jobsMessageTable"
                  class="material expandable"
                  [columnMode]="ColumnMode.force"
                  [headerHeight]="50"
                  [footerHeight]="50"
                  [rowHeight]="50"
                  [rows]="job['messages']"
                  [messages]="{emptyMessage: 'N/A'}"
                  [limit]="5"
                  [hidden]="job['messages'].length == 0"
                  >

                  <ngx-datatable-column name="Messages"  [sortable]="false" >
                    <ng-template let-x="row"  ngx-datatable-cell-template >
                      <strong >{{x['name']}}</strong>
                    </ng-template>
                  </ngx-datatable-column>

                </ngx-datatable>

              </div>

              <mat-divider inset  [hidden]="job['messages'].length == 0"></mat-divider>

              <div style="margin-bottom: 15px; width: 100%">

                <ngx-datatable
                  #jobsErrorTable
                  id="jobsErrorTable"
                  class="material expandable"
                  [columnMode]="ColumnMode.force"
                  [headerHeight]="50"
                  [footerHeight]="50"
                  [rowHeight]="50"
                  [rows]="job['errors']"
                  [messages]="{emptyMessage: 'N/A'}"
                  [limit]="5"
                  [hidden]="job['errors'].length == 0"
                >
                  <ngx-datatable-column name="Errors"  [sortable]="false" >
                    <ng-template let-x="row"  ngx-datatable-cell-template >
                      <strong >{{x['name']}}</strong>
                    </ng-template>
                  </ngx-datatable-column>

                </ngx-datatable>

              </div>
            </ng-template>

          </ngx-datatable-row-detail>


          <!--Basic Table -->
          <ngx-datatable-column
            [width]="50"
            [resizeable]="false"
            [sortable]="false"
            [draggable]="false"
            [canAutoResize]="false">
            <ng-template let-job="row" let-expanded="expanded" ngx-datatable-cell-template>
              <mat-icon (click)="toggleExpandSelectedEntitiesRow(job,expanded)" title="Expand/Collapse Row" style="color: black; cursor: pointer;">
                {{expanded ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Job Inentifer" [width]="350" [sortable]="false" [canAutoResize]="false">
            <ng-template let-job="row" ngx-datatable-cell-template>
              <strong  style="font-size: 12pt;">{{ job['jobIdentifier'] }}</strong>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Created" [width]="300" [sortable]="false" [canAutoResize]="false">
            <ng-template let-job="row" ngx-datatable-cell-template>
              <strong style="font-size: 12pt;">
                {{ job['createdTs'] }}
                </strong>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Status" [width]="300" [sortable]="false" [canAutoResize]="false">
            <ng-template let-job="row" ngx-datatable-cell-template>
              <strong style="font-size: 12pt;">
                {{ job['status'] }}
              </strong>
            </ng-template>
          </ngx-datatable-column>



          <ngx-datatable-column name="Actions" [width]="300" [sortable]="false" [canAutoResize]="false">
            <ng-template let-job="row" ngx-datatable-cell-template>
              <strong  style="font-size: 12pt;">
               <a (click)="viewJobItems(job)"  [ngClass]="{disabled: job.totalItems()==0, enabledlist: job.totalItems() > 0}"><mat-icon matTooltip="View Items" >visibility</mat-icon></a>
                <mat-icon style="color:gray" >more_vert</mat-icon>
                 <a (click)="openDeleteDialog('Delete', job)" style="color: blue; cursor: pointer;" ><mat-icon matTooltip="Delete" style="color:red">delete</mat-icon></a>
                <mat-icon style="color:gray" >more_vert</mat-icon>
                <a (click)="clearJobItems('Delete',job)" [ngClass]="{disabled: job.totalItems()==0, enabledDelete: job.totalItems() > 0}"><mat-icon matTooltip="Clear Job Items" >clear_all</mat-icon></a>
                   <mat-icon style="color:gray">more_vert</mat-icon>
                 <a (click)="executeJob(job)"  [ngClass]="{disabled: job['status']!= 'created', enabledlist:  job['status'] == 'created'}">
                   <mat-icon matTooltip="Execute Job" >play_circle_filled</mat-icon>
                 </a>
                <mat-icon style="color:gray">more_vert</mat-icon>
                <a (click)="refreshJob(job)"  style="color: blue; cursor: pointer;">
                  <mat-icon matTooltip="Refresh Job" style="color:green">refresh</mat-icon>
                </a>
              </strong>
            </ng-template>
          </ngx-datatable-column>

        </ngx-datatable>

        <div style="height: 20px;"></div>

      </div>


  </form>

</main>

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AdapterModel} from '../adapters/state/adapter.model';

@Injectable({
  providedIn: 'root'
})
export class HealthCheckService {

  apiUrl = '/api/admin/healthcheckdetails';

  constructor(private http: HttpClient) { }

  checkHealth(adapterDetails: Object){
    return this.http.get(adapterDetails['endpoint'] + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  checkHealthForDirectCall(adapterDetails: Object){
    return this.http.get(adapterDetails['endpoint'] + this.apiUrl + "?directCall=true", { observe: 'response', responseType: 'json' });
  }

  clearRedis(adapterDetails: Object){
    return this.http.post(adapterDetails['endpoint'] + "/api/admin/scheduledjob/clearredis", { observe: 'response', responseType: 'json' });
  }

}

import {Component, Inject, OnInit} from '@angular/core';
import {IntegrationDetails} from '../../../models/integration-details';
import { GlobalUiStateQuery } from '../../../state/global-ui-state.query';
import { GlobalUiStateService } from '../../../state/global-ui-state.service';
import {faReply} from '@fortawesome/free-solid-svg-icons/';
import {Order} from '@datorama/akita';
import {IntegrationTypesQuery} from '../../../config/config-integration-types/state/integration-types.query';
import {IntegrationTypesService} from '../../../config/config-integration-types/state/integration-types.service';
import {MatDialog} from '@angular/material/dialog';
import {ConnectionTestDialogComponent} from './connectiontest-dialog/connection-test-dialog.component';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {AdapterService} from '../../../services/adapter.service';
import {MessageService} from '../../../services/message-service.service';
import {ServiceEvent} from '../../../models/service-event';


@Component({
  selector: 'app-adapter-integration-header',
  templateUrl: './adapter-integration-header.component.html',
  styleUrls: ['./adapter-integration-header.component.scss']
})
export class AdapterIntegrationHeaderComponent implements OnInit {

  selectedAdapterDetails$: Object;
  selectedIntegrationDetails$: IntegrationDetails = new IntegrationDetails(null);
  returnIcon: any;
  callBackURL : string;
  adapterLogoFilename: string;
  spinnerIcon: any;

  constructor( public globalStateQuery: GlobalUiStateQuery,
               private globalStateService: GlobalUiStateService,
               private integrationTypesQuery: IntegrationTypesQuery,
               private integrationTypesService: IntegrationTypesService,
               private dialog: MatDialog,
               private adapterService: AdapterService,
               private messageService: MessageService) {
    this.returnIcon = faReply;
    this.spinnerIcon = faSpinner;
    this.callBackURL = this.globalStateQuery.getCallBackUrl();
  }

  ngOnInit(): void {

    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    this.selectedIntegrationDetails$ = { ...this.globalStateQuery.getSelectedAdapterIntegration() };
    this.selectedIntegrationDetails$.connectionState = "unknown";

    this.integrationTypesService.getIntegrationTypes().then(() =>
      {
        let integrationTypes = this.integrationTypesQuery.selectAll({ sortBy: 'displayName', sortByOrder: Order.ASC });
        integrationTypes.subscribe(list =>
          list.forEach(integrationType => {
            if (integrationType["code"] === this.selectedAdapterDetails$["adapterType"]) {
              this.adapterLogoFilename = integrationType["logoFilename"];
            }
          })
        );
      }
    );
    document.documentElement.style.setProperty('--width', '70%');

    this.messageService.onChange.subscribe(async item => {
      await this.handleEvent(item);
    });

    this.testSpecifiedIntegrationConnection();

  }

  async handleEvent(event: ServiceEvent) {
    if (event.type === "IntegrationConnectionStatusChangeEvent")  {
      if (event.data["state"] == "connected") {
        this.selectedIntegrationDetails$.connectionState = "connected";
        this.selectedIntegrationDetails$.runningConnectionTest = false;
        this.globalStateService.updateSelectedAdapterIntegration(this.selectedIntegrationDetails$);
      } else if (event.data["state"] == "disconnected") {
        this.selectedIntegrationDetails$.connectionState = "disconnected";
        this.selectedIntegrationDetails$.runningConnectionTest = false;
        this.selectedIntegrationDetails$.connectionMessage = event.data["error"];
        this.globalStateService.updateSelectedAdapterIntegration(this.selectedIntegrationDetails$);
      }
    }
  }

  testSpecifiedIntegrationConnection()
  {

    this.messageService.SendEvent({type:"IntegrationConnectionStatusChangeEvent", data:{ state: "unknown"}});

    if (!this.selectedIntegrationDetails$.integrationId) {

      this.messageService.SendEvent({type:"IntegrationConnectionStatusChangeEvent", data:{ state: "disconnected"}});
      return;
    }

    this.selectedIntegrationDetails$.runningConnectionTest = true;
    this.selectedIntegrationDetails$.connectionState = "unknown";

    this.adapterService.ping(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId).subscribe(
      result => {
        this.messageService.SendEvent({type:"IntegrationConnectionStatusChangeEvent", data:{ state: "connected"}});
      },
      error => {
        this.messageService.SendEvent({type:"IntegrationConnectionStatusChangeEvent", data:{ state: "disconnected", error: JSON.stringify(error, null, 4)}});
      },
      () => {
      }
    );
  }

  async return(): Promise<void> {
    this.globalStateService.updateInstanceDirect(false);
    this.globalStateService.updateCallbackUrl(null);
    this.globalStateService.clearUiState();
    window.location.href = this.callBackURL  ;
  }

  connectivityDetails(): void   {
    const dialogRef = this.dialog.open(ConnectionTestDialogComponent, {
      width: '600px',
      data: {
        title: 'Integration Connection Details',
        message: this.selectedIntegrationDetails$.connectionMessage,
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log(result);
      if (result.selection === 'yes') {

      }
    });
  }


}

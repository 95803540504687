import {Component, ViewChild, OnDestroy, OnInit, ElementRef, ChangeDetectorRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {APIService} from '../../../API.service';
import {Router} from '@angular/router';
import {NgFormsManager} from '@ngneat/forms-manager';
import {Hub} from '@aws-amplify/core';

@Component({
  selector: 'app-add-edit-environment',
  templateUrl: './add-edit-environment.component.html',
  styleUrls: ['./add-edit-environment.component.scss']
})
export class AddEditEnvironmentComponent implements OnInit, OnDestroy {

  showPhoto: boolean;
  logoFilename: string;
  logoWidth: string;
  logoHeight: string;

  environmentForm: UntypedFormGroup;

  constructor(
    private spinner: NgxSpinnerService,
    private api: APIService,
    private router: Router,
    private formsManager: NgFormsManager,
    private builder: UntypedFormBuilder,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.showPhoto = false;

    this.environmentForm = this.builder.group({
      id: [null],
      name: [null, Validators.required],
      url: [null, Validators.required],
      reference: [null, Validators.required],
    });

    this.formsManager.upsert('environment', this.environmentForm, {
      persistState: true
    });

  }

  ngOnDestroy() {
    this.formsManager.unsubscribe('environment');
  }

  async onCancel(): Promise<void> {
    this.formsManager.clear('environment');
    await this.router.navigate(['/config/config-environments']);
  }

  async upsertEnvironment(): Promise<void> {

    await this.spinner.show();

    const environment = this.formsManager.getControl('environment').value;

    if (environment.id)
    {
      await this.api.UpdateEnvironment(environment);
    }
    else
    {
      await this.api.CreateEnvironment(environment);
    }

    this.formsManager.clear('environment');
    await this.router.navigate(['/config/config-environments']);

    await this.spinner.hide();

  }

}


<main style="height: 100%; position: relative;">
<mat-toolbar style="background-color: white;">
  <span>Job Management</span>

  <span class="spacer"></span>
</mat-toolbar>
<div>
  <mat-tab-group mat-align-tabs="start" [selectedIndex]="tabIndex">
    <mat-tab label="Scheduled Jobs" >
      <app-adapter-integration-scheduled-jobs></app-adapter-integration-scheduled-jobs>
    </mat-tab>
    <mat-tab label="Batch Jobs" >
      <app-adapter-integration-batchjobs></app-adapter-integration-batchjobs>
    </mat-tab>
  </mat-tab-group>
</div>

</main>

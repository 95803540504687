<main style="height: 100%; position: relative;">

  <ngx-spinner name="processing" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading" style="color: white; font-weight: bold; padding-top: 40px;">Processing ...</p>
  </ngx-spinner>

  <mat-toolbar style="background-color: white;">

    <span>Schema Management</span>

    <span class="spacer"></span>

    <span style="float:right" [hidden] = "v1Selected">

      <span style="padding: 0 0 0 0; margin: 0 0 0 0; position: relative; top: -5px; font-size: 12pt">
        {{v2DataSource.filter}}
      </span>
      <span>
<!--        <mat-icon [matMenuTriggerFor]="actions" [ngClass]="['actions']" >filter_list</mat-icon>-->
<!--        <mat-menu #actions="matMenu">-->
<!--          <button mat-menu-item-->
<!--                  *ngFor="let filter of filters"-->
<!--                  (click)="applyFilter(filter)">-->
<!--            <span>{{filter}}</span>-->
<!--          </button>-->
<!--        </mat-menu>-->
      </span>

    </span>

    <div style="float:right; position: fixed; bottom: 30px; right: 2%; z-index: 100; display: flex; flex-direction: column-reverse; align-items: center;">
      <mat-fab-menu color="primary" [fabButtons]="buttons" icon="menu" (onFabMenuItemSelected)=" processFabButtonClick($event)">
      </mat-fab-menu>
    </div>

  </mat-toolbar>

  <div style="width: 100%; position: relative; min-height: 400px; color:red" [hidden]="(!v2SchemaLoadError && v2Selected) || v1Selected">
  {{v2SchemaLoadErrorMessage}}
  </div>

  <div style="width: 98%; position: relative; min-height: 400px; margin: auto;" [hidden]="(v2SchemaLoadError && v2Selected) || v1Selected">

    <form class="form-horizontal" [formGroup]="schemaForm2">

      <div style="position: relative; padding: 10px;">

        <ngx-spinner name="selectedEntities" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
          <p class="loading" style="color: white; font-weight: bold; padding-top: 120px;">Loading Selected Schema Entities ...</p>
        </ngx-spinner>

        <div style="padding-bottom: 10px; margin: auto; text-align: center; position: relative;">
          <div style="display: flex; align-items: flex-end;">
            <span style="font-weight: bold; font-size: 14pt;">Selected Entities</span>
            <div style="padding-left: 10px; padding-right: 5px;">
              <input matInput placeholder="Entity Search" style="border: none; border-bottom: solid 1px;" value="{{selectedEntitiesFilterValue}}" (input)="onSelectedEntityFilterChange($event.target.value)">
            </div>
            <img src="assets/clear.png" alt="Search" style="height: 20px; width: 20px; vertical-align: middle;" (click)="onSelectedEntityFilterClear()" >
          </div>
        </div>

        <ngx-datatable
          #selectedEntitiesTable
          class="material expandable"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="50"
          [rows]="filteredSelectedEntities"
          [limit]="10"
        >
          <!-- Row Detail Template -->
          <ngx-datatable-row-detail [rowHeight]="'100%'" (toggle)="onSelectedEntityDetailToggle($event)">

            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>

              <div style="padding-left:5px; position: relative;">

                <ngx-spinner name="selectedAttributes_{{row['Name']}}" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
                  <p class="loading" style="color: white; font-weight: bold; padding-top: 30px;">Loading Selected Attributes ...</p>
                </ngx-spinner>

                <ngx-spinner name="unselectedAttributes_{{row['Name']}}" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
                  <p class="loading" style="color: white; font-weight: bold; padding-top: 30px;">Loading Unselected Attributes ...</p>
                </ngx-spinner>

                <div style="padding: 10px;">

                    <div style="padding-bottom: 15px; display: flex; align-items: flex-end;">

                      <span style="padding-right: 10px; font-size: 12pt;">
                        Selected Attributes (
                        <mat-icon title="Remove All Selected Attributes" [hidden]="!row['SelectedAttributesLoaded'] || row['SelectedAttributesLoaded'] === false || row['SelectedAttributes'].length == 0" (click)="removeAllSelectedAttributes(row['Name'])"
                                  style="color: indianred; cursor: pointer; padding: 0; margin: 0;">remove_circle_outline</mat-icon>
                        {{row['SelectedAttributesLoaded'] === true && row['SelectedAttributes'] ? row['SelectedAttributes'].length : "..."}} )
                      </span>
                      <div style="padding-left: 10px; padding-right: 5px;" [hidden]="!row['SelectedAttributesLoaded'] || row['SelectedAttributesLoaded'] == false">
                          <input matInput placeholder="Attribute Search" style="border: none; border-bottom: solid 1px;" value="{{row['selectedEntitySelectedAttributeFilterValue']}}" (input)="onSelectedEntitySelectedAttributeFilterChange(row['Name'], $event.target.value)">
                      </div>
                      <img src="assets/clear.png" alt="Search" style="height: 20px; width: 20px; vertical-align: middle;" (click)="onSelectedEntitySelectedAttributeFilterClear(row['Name'])"  [hidden]="!row['SelectedAttributesLoaded'] || row['SelectedAttributesLoaded'] == false">
                    </div>

                  <div style="position: relative;">
                    <mat-chip-list>
                      <mat-chip *ngFor="let attribute of row['FilteredSelectedAttributes']" title="Click to view/modify schema attribute customizations ..." (click)="openAttributeCustomizationsDialog(row['Name'], attribute.Attribute['Name'])" style="cursor: pointer;">
                        <span style="color: black; font-weight: normal; margin-top: -10px; margin-left: -5px; font-size: small;" [hidden]="!attribute.EntityAttributeCustomizations || !attribute.EntityAttributeCustomizations['DefaultOverride'] || attribute.EntityAttributeCustomizations['DefaultOverride']==''">&nbsp;C&nbsp; </span>
                        <span style="color: darkseagreen; font-weight: normal;" [hidden]="attribute.Attribute['Name'].length < 4">{{attribute.Attribute['Name']}}</span>
                        <span style="color: darkseagreen; font-weight: normal;" [hidden]="attribute.Attribute['Name'].length > 3">{{"  " + attribute.Attribute['Name'] + ""}}</span>
                        <mat-icon matChipRemove (click)="removeSelectedAttribute(row['Name'], attribute.Attribute['Name'])" [hidden]="attribute.Attribute['Name'].indexOf('#V_') > -1" title="Remove Attribute" style="color: indianred; cursor: pointer;">remove_circle_outline</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </div>

                </div>

                <div style="padding: 10px;">
                  <div style="padding-bottom: 15px; display: flex; align-items: flex-end;">
                    <span style="padding-right: 10px; font-size: 12pt;">
                      Unselected Attributes (
                      <mat-icon title="Add All Unselected Attributes" (click)="addAllUnselectedAttributes(row['Name'])"
                                [hidden]="!row['UnselectedAttributesLoaded'] || row['UnselectedAttributesLoaded'] == false || row['UnselectedAttributes'].length == 0" style="color: darkgreen; cursor: pointer; padding: 0; margin: 0;">add_circle_outline</mat-icon>
                      {{row['UnselectedAttributesLoaded'] === true && row['UnselectedAttributes'] ? row['UnselectedAttributes'].length : "..."}} )
                    </span>
                    <div style="padding-left: 10px; padding-right: 5px;" [hidden]="!row['UnselectedAttributesLoaded'] || row['UnselectedAttributesLoaded'] == false">
                      <input matInput placeholder="Attribute Search" style="border: none; border-bottom: solid 1px;" value="{{row['selectedEntityUnselectedAttributeFilterValue']}}" (input)="onSelectedEntityUnselectedAttributeFilterChange(row['Name'], $event.target.value)">
                    </div>
                    <img src="assets/clear.png" alt="Search" style="height: 20px; width: 20px; vertical-align: middle;" (click)="onSelectedEntityUnselectedAttributeFilterClear(row['Name'])"  [hidden]="!row['UnselectedAttributesLoaded'] || row['UnselectedAttributesLoaded'] == false">
                    <mat-icon title="Fetch Unselected Attributes" (click)="toggleUnselectedAttributes(row['Name'])" [hidden]="row['UnselectedAttributesLoaded'] == true" style="color: black; cursor: pointer; vertical-align: center; ">
                      expand_more
                    </mat-icon>
                  </div>
                  <div style="position: relative;">

                    <mat-chip-list>
                      <mat-chip *ngFor="let attribute of row['FilteredUnselectedAttributes']" matTooltip="Click to view raw schema / modify">
                        <span style="color: indianred; font-weight: lighter;">{{attribute.Attribute['Name']}}</span>
                        <mat-icon matChipRemove (click)="addUnselectedAttribute(row['Name'], attribute.Attribute['Name'])" title="Add Attribute" style="color: darkgreen; cursor: pointer;">
                          add_circle_outline
                        </mat-icon>
                      </mat-chip>
                    </mat-chip-list>

                  </div>

                </div>
              </div>
            </ng-template>
          </ngx-datatable-row-detail>
          <!-- Column Templates -->
          <ngx-datatable-column
            [width]="50"
            [resizeable]="false"
            [sortable]="false"
            [draggable]="false"
            [canAutoResize]="false"
          >
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
              <mat-icon (click)="toggleExpandSelectedEntitiesRow(row)" title="Expand Selected Entities" style="color: black; cursor: pointer;">
                {{expanded ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Entity Name" [width]="300" [sortable]="false" [canAutoResize]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <strong  (click)="toggleExpandSelectedEntitiesRow(row)">{{ row['Name'] }}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Description" [sortable]="false" [canAutoResize]="true">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <strong (click)="toggleExpandSelectedEntitiesRow(row)">{{ row['Description'] }}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Access Level" [width]="50" [sortable]="false" [canAutoResize]="true">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <strong>{{ row['Access'] == undefined ? "ALL":row['Access'] }}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Sync" [width]="50" [sortable]="false" [canAutoResize]="true">
            <ng-template let-row="row" ngx-datatable-header-template>
              <div style="text-align: center;">
                Sync
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div style="text-align: center; margin-top: -.75em">
                <mat-checkbox [checked]="row['Sync']" (change)="syncChanged($event,row)"></mat-checkbox>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Actions" [width]="300" [sortable]="false" [canAutoResize]="false">
            <ng-template let-row="row" ngx-datatable-header-template>
              <div style="text-align: center;">
                Actions
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div style="text-align: center;">
                <mat-icon (click)="openAttributesOfSelectedEntity(row['Name'])" title="View Raw Schema Entity" style="color: royalblue; cursor: pointer;">
                  visibility
                </mat-icon>
                <mat-icon style="color:gray">more_vert</mat-icon>
                <mat-icon (click)="removeSelectedEntity(row['Name'])" title="Deselect Entity" style="color: indianred; cursor: pointer;">
                  remove_circle_outline
                </mat-icon>
              </div>
            </ng-template>
          </ngx-datatable-column>

        </ngx-datatable>

      </div>

      <div style="height: 20px;"></div>

      <div style="position: relative; padding: 10px;">

        <ngx-spinner name="deselectedEntities" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
          <p class="loading" style="color: white; font-weight: bold; padding-top: 120px;">Loading Unselected Schema Entities ...</p>
        </ngx-spinner>

        <div style="padding-bottom: 10px; margin: auto; text-align: center; position: relative;">
          <div style="display: flex; align-items: flex-end;">
            <span style="font-weight: bold; font-size: 14pt;">Unselected Entities</span>
            <mat-icon title="Fetch Unselected Entities" (click)="fetchUnselectedEntities()" [hidden]="unselectedEntitiesLoaded == true" style="color: black; cursor: pointer; vertical-align: center; padding-left: 10px;">
              expand_more
            </mat-icon>
            <div style="padding-left: 10px; padding-right: 10px;" [hidden]="unselectedEntitiesLoaded == false">
              <input matInput placeholder="Entity Search" style="border: none; border-bottom: solid 1px;" value="{{unselectedEntitiesFilterValue}}" (input)="onUnselectedEntityFilterChange($event.target.value)">
            </div>
            <img src="assets/clear.png" alt="Search" style="height: 20px; width: 20px; vertical-align: middle;" (click)="onUnselectedEntityFilterClear()" [hidden]="unselectedEntitiesLoaded == false">
          </div>
        </div>

        <div style="position: relative; height: 90px;" [hidden]="unselectedEntitiesLoaded == true"></div>

        <ngx-datatable
          #unselectedEntitiesTable
          class="material expandable"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="50"
          [rows]="filteredUnselectedEntities"
          [limit]="10"
          [hidden]="unselectedEntities === null || unselectedEntities.length === 0">
          <!-- Row Detail Template -->
          <ngx-datatable-row-detail [rowHeight]="'100%'" (toggle)="onUnselectedEntityDetailToggle($event)">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
              <div style="padding-bottom: 5px;">
                <span style="font-weight: bold;">Attributes ({{row['SelectedAttributesLoaded'] === true && row['SelectedAttributes'] ? row['SelectedAttributes'].length : "..."}})</span>
              </div>
              <div style="padding-left:5px;">
                <div style="padding: 10px;">
                  <mat-chip-list>
                    <mat-chip *ngFor="let attribute of row['SelectedAttributes']">
                      <span style="color: black; font-weight: normal;">{{attribute.Attribute['Name']}}</span>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-row-detail>
          <!-- Column Templates -->
          <ngx-datatable-column
            [width]="50"
            [resizeable]="false"
            [sortable]="false"
            [draggable]="false"
            [canAutoResize]="false"
          >
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
              <mat-icon (click)="toggleExpandUnselectedEntitiesRow(row)" title="Expand Unselected Entities" style="color: black; cursor: pointer;">
                {{expanded ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Entity Name" [width]="300" [sortable]="false" [canAutoResize]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <strong>{{ row['Name'] }}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Description" [sortable]="false" [canAutoResize]="true">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <strong>{{ row['Description'] }}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Actions" [width]="150" [sortable]="false" [canAutoResize]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <mat-icon (click)="openAttributesOfUnselectedEntity(row['Name'])" title="View Raw Schema Entity" style="color: royalblue; cursor: pointer;">
                visibility
              </mat-icon>
              <mat-icon style="color:gray">more_vert</mat-icon>
              <mat-icon title="Select Entity" (click)="addUnselectedEntity(row['Name'])" style="color: darkseagreen; cursor: pointer;">
                add_circle_outline
              </mat-icon>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>

      </div>

    </form>

  </div>

</main>

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {APIService} from '../../../../API.service';
import {AdaptersQuery} from '../../../state/adapters.query';
import {IPService} from '../../../../services/ip.service';
import {ToastrService} from 'ngx-toastr';
import {GlobalUiStateService} from '../../../../state/global-ui-state.service';
import {GlobalUiStateQuery} from '../../../../state/global-ui-state.query';
import {NgxSpinnerService} from 'ngx-spinner';
import {AdapterService} from '../../../../services/adapter.service';

@Component({
  selector: 'app-reset-job-dialog',
  templateUrl: './connection-test-dialog.component.html',
  styleUrls: ['./connection-test-dialog.component.scss']
})
export class ConnectionTestDialogComponent implements OnInit {

  form: UntypedFormGroup;
  adapterEndpoint: string;
  title: string;
  preserveNextRunDate: boolean;
  jobType: string;
  errorMessage: string;
  message: string;
  jsonPrettyMessage: string;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ConnectionTestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private api: APIService,
    private adaptersQuery: AdaptersQuery,
    private ipService: IPService,
    private toastr: ToastrService,
    private globalStateService: GlobalUiStateService,
    public globalStateQuery: GlobalUiStateQuery,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private adapterService: AdapterService)
  {
    this.adapterEndpoint = data.adapterEndpoint;
    this.errorMessage = data.errorMessage;

    this.message = data.message;
    this.title = data.title;
    this.jobType = data.jobType;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      preserveNextRunDate: [this.preserveNextRunDate],
    });
  }

  onNoClick(): void {
    this.dialogRef.close({ selection: "no" });
  }

  onYesClick(): void {
    let preserveNextRunDate = false;
    if (this.form.controls['preserveNextRunDate'].value)
      preserveNextRunDate = this.form.controls['preserveNextRunDate'].value;

    this.dialogRef.close(
      { preserveNextRunDate: preserveNextRunDate,
        selection: "yes"
    });
  }

  get f(){
    return this.form.controls;
  }
}

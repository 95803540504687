<mat-grid-tile-header style="background: linear-gradient(140deg, rgb(21, 50, 64) 11.2%, rgba(21, 50, 64, .12) 120.2%)" [style.height]="'15%'" [style.padding-left]="'8px'"  [style.padding-right]="'8px'">
  <div style="display: flex; align-content: space-between; width: 100%;">
    <div style="flex: 1 1 auto; text-align: left;">
      <mat-icon [matMenuTriggerFor]="menu" [ngClass]="['menu']" style="color: white; cursor: pointer;" (click)="$event.stopPropagation();" >menu</mat-icon>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [hidden]="isDev" (click)="connectWorkerAdapter(adapter);"  [ngClass]="isWorkerConnected?'workerConnected-yes':'workerConnected-no'"><mat-icon matTooltip="Connect to Worker" [ngClass]="isWorkerConnected?'workerConnected-yes':'workerConnected-no'"> {{isWorkerConnected?'cast_connected':'cast'}}</mat-icon>Connect to Worker</button>
        <button mat-menu-item (click)="editAdapter(adapter);" [hidden]="this.globalStateQuery.getSelectedEnvironment() !== 'dev' && (!this.globalStateQuery.getCachedUser() || (this.globalStateQuery.getCachedUser() && this.globalStateQuery.getCachedUser().role !== 'admin'))"><mat-icon matTooltip="Edit Adapter Details" style="color:black">edit</mat-icon>Edit Adapter Details</button>
        <button mat-menu-item (click)="deleteAdapter('Delete', adapter);" [hidden]="this.globalStateQuery.getSelectedEnvironment() !== 'dev' && (!this.globalStateQuery.getCachedUser() || (this.globalStateQuery.getCachedUser() && this.globalStateQuery.getCachedUser().role !== 'admin'))"><mat-icon matTooltip="Delete Adapter" style="color:red">delete</mat-icon>Delete Adapter</button>
        <mat-divider [hidden]="!isLoggedIn"></mat-divider>
        <button mat-menu-item [hidden]="!isLoggedIn" (click)="LogoutOfAdapter(adapter); " ><mat-icon matTooltip="Log Out Of Adapter" style="color:black;">person</mat-icon>Logout of Adapter</button>
      </mat-menu>
    </div>
    <div style="flex: 1 1 auto; text-align: right;">
      <mat-icon [hidden]="isConnected" style="color: red">cloud_off</mat-icon>
    </div>
  </div>
</mat-grid-tile-header>

<div (click)="adapterClicked(adapter,null)" [ngClass]="{'enabled-yes': isConnected,'enabled-no': !isConnected }" style="height: 100%; padding-top: 50px; ">

  <ngx-spinner [name]="adapter.id" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium"></ngx-spinner>

  <div class="Aligner" style="width: 100%;" >

    <div>
      {{adapterNameForDisplay}}
    </div>

    <div class="Aligner Aligner-item Aligner-item--middle" style="max-height: 80px;" [hidden]="!adapter.logoFilename">
      <img mat-card-image src="assets/{{adapter.logoFilename}}" style="width: 70%" alt=""/>
    </div>

    <div class="Aligner Aligner-item Aligner-item--middle" style="max-height: 80px;" [hidden]="adapter.logoFilename">
      <img mat-card-image src="assets/adapter_black.png" style="height: 60px;" alt=""/>
    </div>

    <span [ngClass]="{'adapterEnabled-yes': adapter.enabled,'adapterEnabled-no': ! adapter.enabled }">{{ (adapter.enabled)? '&#10003;&nbsp;Enabled': '&#10006;&nbsp;Disabled' }}</span>
    <div [hidden]="!isLoggedIn" [ngClass]="{'adapterConnected-yes': isLoggedIn,'adapterConnected-no': !isLoggedIn }"><mat-icon style="height: 24px; vertical-align: middle; padding-right: 5px;">how_to_reg</mat-icon><span>Logged In</span></div><div [hidden]="isLoggedIn" [ngClass]="{'adapterConnected-yes': isLoggedIn,'adapterConnected-no': !isLoggedIn }"><span>Logged Out</span></div>

    <div class="Aligner-item Aligner-item--bottom" style="font-size: 8pt;">{{adapterEndpointForDisplay}}</div>

    <div style="height: 60px;"></div>

  </div>

</div>


import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {GlobalUiStateQuery} from '../../state/global-ui-state.query';
import {GlobalUiStateService} from '../../state/global-ui-state.service';

@Component({
  selector: 'app-security-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class SecurityNavComponent implements OnInit {

  selectedNavPage$: string;

  constructor(private router: Router,
              public globalStateQuery: GlobalUiStateQuery,
              private globalStateService: GlobalUiStateService) { }

  ngOnInit(): void {
    let selectedDefaultPage = 'security-audit-logs';
    if (this.globalStateQuery.getCachedUser().role === 'admin') {
      selectedDefaultPage = 'security-users';
    }

    this.selectedNavPage$ = this.globalStateQuery.getSelectedSecurityNavPage() ?? selectedDefaultPage;
    this.globalStateService.updateSelectedSecurityNavPage(this.selectedNavPage$);
  }

  async securityAdminUIUsersClicked(): Promise<void> {
    this.selectedNavPage$ = 'security-admin-ui-users';
    this.globalStateService.updateSelectedSecurityNavPage(this.selectedNavPage$);
    const temp = this.globalStateQuery.getSelectedSecurityNavPage();
    await this.router.navigate(['/security/security-admin-ui-users']);
  }

  async securityAuditLogsClicked(): Promise<void> {
    this.selectedNavPage$ = 'security-audit-logs';
    this.globalStateService.updateSelectedSecurityNavPage(this.selectedNavPage$);
    const temp = this.globalStateQuery.getSelectedSecurityNavPage();
    await this.router.navigate(['/security/security-audit-logs']);
  }

  getSelectedNavBackgroundColor(nav: string): string {
    if (nav === this.selectedNavPage$) {
      return '#898989';
    } else {
      return '';
    }
  }

}

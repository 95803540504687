import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {IntegrationSchema} from '../../../../models/integration-schema';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Query} from '../../../../models/query';
import {QueryFrom} from '../../../../models/query-from';
import {QueryField} from '../../../../models/query-field';
import {int} from 'aws-sdk/clients/datapipeline';
import {SchemaService} from '../../../../services/schema.service';
import {IntegrationDetails} from '../../../../models/integration-details';
import {GlobalUiStateQuery} from '../../../../state/global-ui-state.query';
import {MatTableDataSource} from '@angular/material/table';
import {Querywhere} from '../../../../models/querywhere';
import {ToastrService} from 'ngx-toastr';
import {QueryBuilderConditionDialogComponent} from './query-builder-condition-dialog/query-builder-condition-dialog.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {forEach} from '@angular-devkit/schematics';

export interface WhereData {
  andor: string;
  operator: string;
  field: string;
  value: any;
}

@Component({
  selector: 'app-query-builder-dialog',
  templateUrl: './query-builder-dialog.component.html',
  styleUrls: ['./query-builder-dialog.component.scss']
})
export class QueryBuilderDialogComponent implements OnInit {

  query: Object;
  entities: any[] = [];
  selectedEntity: string;
  fields:any
  queryForm: UntypedFormGroup;
  selectedAdapterDetails$: Object;
  selectedIntegrationDetails$: IntegrationDetails;
  isLoading = false;
  isAdd = false;
  entitySelected = false;
  limitValue: number = 0;

  fieldControl: UntypedFormControl = new UntypedFormControl([], Validators.required);
  fieldControl2: UntypedFormControl = new UntypedFormControl([]);

  whereData: WhereData[] = [];
  dataSource: MatTableDataSource<WhereData> ;
  displayedColumns: string[] = ['andor','field', 'operator', 'value', 'actions'];

  constructor(private dialogRef: MatDialogRef<QueryBuilderDialogComponent>,
              private service: SchemaService,
              private globalStateQuery: GlobalUiStateQuery,
              private changeDetectorRefs: ChangeDetectorRef,
              private dialog: MatDialog,
              private spinner: NgxSpinnerService,
              @Inject(MAT_DIALOG_DATA) data) {

    this.query = data.query;
    this.entities = data.entities;
  }

  populateFormFields(query)  {
    console.log(query["query"]);
    this.populateFrom(query["query"]["from"]);
    this.populateFields(query["query"]["fields"]);
    this.populateConditions(query["query"]["where"]);
    this.populateLimit(query["query"]["limit"]);
  }

  populateFrom(from)  {
    if (from && !(Object.keys(from).length === 0 && from.constructor === Object)) {
      this.selectedEntity = from[0]["entity"];
      this.entitySelected = true;
    }
  }

  async populateFields(selectedFields) {
    if (selectedFields && !(Object.keys(selectedFields).length === 0 && selectedFields.constructor === Object)) {
      const result = await this.service.getSchemaEntityAttributesAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, this.selectedEntity, true).toPromise();
      this.fields = JSON.parse(JSON.stringify(result.body));
      var fieldsValue = [];
      selectedFields.forEach(selectedField => {
        if (selectedField["field"] == "*")  {
          fieldsValue.push("All");
        } else {
          fieldsValue.push(selectedField["field"]);
        }
      });
      this.fieldControl.setValue(fieldsValue);
    }
  }

  populateConditions(where)  {
    if (where && !(Object.keys(where).length === 0 && where.constructor === Object)) {
      where["and"]?.forEach(andCondition => {
        if (andCondition["notLike"]) {
          this.whereData.push({andor: "and", operator: "notLike", field: andCondition["notLike"]["field"]["field"], value: andCondition["notLike"]["value"]});
        } else if(andCondition["like"]) {
          this.whereData.push({andor: "and", operator: "like", field: andCondition["like"]["field"]["field"], value: andCondition["like"]["value"]});
        } else if(andCondition["eq"]) {
          this.whereData.push({andor: "and", operator: "eq", field: andCondition["eq"]["field"]["field"], value: andCondition["eq"]["value"]});
        } else if(andCondition["noteq"]) {
          this.whereData.push({andor: "and", operator: "noteq", field: andCondition["noteq"]["field"]["field"], value: andCondition["noteq"]["value"]});
        } else if(andCondition["lt"]) {
          this.whereData.push({andor: "and", operator: "lt", field: andCondition["lt"]["field"]["field"], value: andCondition["lt"]["value"]});
        } else if(andCondition["gt"]) {
          this.whereData.push({andor: "and", operator: "gt", field: andCondition["gt"]["field"]["field"], value: andCondition["gt"]["value"]});
        } else if(andCondition["lte"]) {
          this.whereData.push({andor: "and", operator: "lte", field: andCondition["lte"]["field"]["field"], value: andCondition["lte"]["value"]});
        } else if(andCondition["gte"]) {
          this.whereData.push({andor: "and", operator: "gte", field: andCondition["gte"]["field"]["field"], value: andCondition["gte"]["value"]});
        }
      });

      where["or"]?.forEach(orCondition => {
        if (orCondition["notLike"]) {
          this.whereData.push({andor: "or", operator: "notLike", field: orCondition["notLike"]["field"]["field"], value: orCondition["notLike"]["value"]});
        } else if(orCondition["like"]) {
          this.whereData.push({andor: "or", operator: "like", field: orCondition["like"]["field"]["field"], value: orCondition["like"]["value"]});
        } else if(orCondition["eq"]) {
          this.whereData.push({andor: "or", operator: "eq", field: orCondition["eq"]["field"]["field"], value: orCondition["eq"]["value"]});
        } else if(orCondition["noteq"]) {
          this.whereData.push({andor: "or", operator: "noteq", field: orCondition["noteq"]["field"]["field"], value: orCondition["noteq"]["value"]});
        } else if(orCondition["lt"]) {
          this.whereData.push({andor: "or", operator: "lt", field: orCondition["lt"]["field"]["field"], value: orCondition["lt"]["value"]});
        } else if(orCondition["gt"]) {
          this.whereData.push({andor: "or", operator: "gt", field: orCondition["gt"]["field"]["field"], value: orCondition["gt"]["value"]});
        } else if(orCondition["lte"]) {
          this.whereData.push({andor: "or", operator: "lte", field: orCondition["lte"]["field"]["field"], value: orCondition["lte"]["value"]});
        } else if(orCondition["gte"]) {
          this.whereData.push({andor: "or", operator: "gte", field: orCondition["gte"]["field"]["field"], value: orCondition["gte"]["value"]});
        }
      });

      this.dataSource = new MatTableDataSource(this.whereData);
    }
  }

  populateLimit(limit)  {
    this.limitValue = limit;
  }

  ngOnInit(): void {
    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();

    if (!(Object.keys(this.query).length === 0 && this.query.constructor === Object)) {
      this.populateFormFields(this.query);
    }

    const group: any = {};
    group['fieldControlName'] = this.fieldControl;
    group['fieldControlName2'] = this.fieldControl2;
    group['entityControlName'] =  new UntypedFormControl(this.selectedEntity, Validators.required);
    group['fieldControlAll'] =  new UntypedFormControl(false);
    group['limitControlName'] =  new UntypedFormControl(this.limitValue);
    this.queryForm = new UntypedFormGroup(group);
  }

  async onEntityChanged() {

    await this.spinner.show("loadingEntity");

    const result = await this.service.getSchemaEntityAttributesAsync(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, this.selectedEntity, true).toPromise();

    this.queryForm.controls['fieldControlName'].reset();
    this.whereData = [];
    this.dataSource = new MatTableDataSource(this.whereData);
    this.changeDetectorRefs.detectChanges();
    this.fields = JSON.parse(JSON.stringify(result.body));
    this.entitySelected = true;

    await this.spinner.hide("loadingEntity");

  }

  deleteCondition(index) {
    this.whereData.splice(index, 1);
    this.dataSource = new MatTableDataSource(this.whereData);
    this.changeDetectorRefs.detectChanges();
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    let query = new Query();

    const entityField = this.queryForm.controls['entityControlName'].value;
    const fromField = new QueryFrom();
    fromField.entity = entityField;
    query.from.push(fromField);

    const fieldArray = this.queryForm.controls['fieldControlName'].value as Array<string>;
    var index = fieldArray.findIndex(x => x == "All")
    if( index >= 0)
    {
      var filedElement = new QueryField();
      filedElement.entity = entityField;
      filedElement.field = "*";
      query.fields.push(filedElement);
    }else{
      fieldArray.forEach(x => {
        var filedElement = new QueryField();
        filedElement.entity = entityField;
        filedElement.field = x;
        query.fields.push(filedElement);
      })
    }

    //where clause
    let andOr = {};
    this.whereData.forEach(x=>{
      let field = {};
      field["entity"] = entityField;
      field["field"] = x.field;

      let oper = {};
      oper["field"] = field;
      oper["value"] = x.value;

      let operObj = {};
      operObj[x.operator] = oper;

     if(andOr[x.andor])
     {
       andOr[x.andor].push(operObj);
     }else{
       andOr[x.andor] = [];
       andOr[x.andor].push(operObj);
     }

    });

    if(this.whereData.length > 0 )
      query.where = andOr;

    if( this.limitValue > 0)
      query["limit"] = this.limitValue;

    var queryString = '{"query" :' + JSON.stringify(query) + '}';

    this.dialogRef.close(queryString);
  }

  add() {
    this.isAdd = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      fields: this.fields,
      whereData: this.whereData
    }

    this.dialog.open(QueryBuilderConditionDialogComponent, dialogConfig).afterClosed()
      .subscribe(
        data => {
          this.dataSource = new MatTableDataSource(this.whereData);
          this.changeDetectorRefs.detectChanges();
        }
      );
  }
}

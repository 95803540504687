import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {GlobalUiStateQuery} from '../../state/global-ui-state.query';
import {GlobalUiStateService} from '../../state/global-ui-state.service';

@Component({
  selector: 'app-config-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class ConfigNavComponent implements OnInit {

  selectedNavPage$: string;

  constructor(private location: Location,
              private router: Router,
              public globalStateQuery: GlobalUiStateQuery,
              private globalStateService: GlobalUiStateService) { }

  ngOnInit(): void {
    this.selectedNavPage$ = this.globalStateQuery.getSelectedConfigNavPage() ?? 'config-integration-types';
  }

  async integrationTypesClicked(): Promise<void> {

    this.selectedNavPage$ = 'config-integration-types';
    this.globalStateService.updateSelectedConfigNavPage(this.selectedNavPage$);

    await this.router.navigate(['/config/config-integration-types']);

  }

  async environmentsClicked(): Promise<void> {

    this.selectedNavPage$ = 'config-environments';
    this.globalStateService.updateSelectedConfigNavPage(this.selectedNavPage$);

    await this.router.navigate(['/config/config-environments']);

  }

  async filtersClicked(): Promise<void> {

    this.selectedNavPage$ = 'config-filters';
    this.globalStateService.updateSelectedConfigNavPage(this.selectedNavPage$);

    await this.router.navigate(['/config/config-filters']);

  }

  getSelectedNavBackgroundColor(nav: string): string {
    if (nav === this.selectedNavPage$) {
      return '#898989';
    } else {
      return '';
    }
  }

}

import {Component, ViewChild, OnDestroy, OnInit, ElementRef, ChangeDetectorRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {APIService} from '../../../API.service';
import {Router} from '@angular/router';
import {NgFormsManager} from '@ngneat/forms-manager';
import {Hub} from '@aws-amplify/core';

@Component({
  selector: 'app-add-edit-int-type',
  templateUrl: './add-edit-int-type.component.html',
  styleUrls: ['./add-edit-int-type.component.scss']
})
export class AddEditIntTypeComponent implements OnInit, OnDestroy {

  showPhoto: boolean;
  logoFilename: string;
  logoWidth: string;
  logoHeight: string;

  integrationTypeForm: UntypedFormGroup;

  constructor(
    private spinner: NgxSpinnerService,
    private api: APIService,
    private router: Router,
    private formsManager: NgFormsManager,
    private builder: UntypedFormBuilder,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.showPhoto = false;

    this.integrationTypeForm = this.builder.group({
      id: [null],
      code: [null, Validators.required],
      displayName: [null, Validators.required],
      logoFilename: [null],
      logoWidth: [null],
      logoHeight: [null]
    });

    this.formsManager.upsert('integrationType', this.integrationTypeForm, {
      persistState: true
    });

    if (this.formsManager.getControl('integrationType').value.logoFilename)  {
      this.logoFilename = this.formsManager.getControl('integrationType').value.logoFilename;
      this.logoWidth = this.formsManager.getControl('integrationType').value.logoWidth;
      this.logoHeight = this.formsManager.getControl('integrationType').value.logoHeight;
      this.showPhoto = true;
    }

    Hub.listen('storage', async (data) => {
      const {payload} = data;
      await this.onImageUploaded(payload);
    });

  }

  ngOnDestroy() {
    this.formsManager.unsubscribe('integrationType');
  }

  editPhoto(): void {
    this.showPhoto = false;
  }

  async onImageUploaded(data): Promise<void> {
    if (data.event === 'getSignedUrl') {
      const baseUrl = data.message.substring(0, data.message.indexOf('?'));
      const filename = baseUrl.substring(baseUrl.lastIndexOf('/') + 1);
      this.logoFilename = filename;
      this.showPhoto = true;
      this.ref.detectChanges();
    }
  }

  async onCancel(): Promise<void> {
    this.formsManager.clear('integrationType');
    await this.router.navigate(['/config/config-integration-types']);
  }

  async upsertIntegrationType(): Promise<void> {

    await this.spinner.show();

    const integrationType = this.formsManager.getControl('integrationType').value;
    if (this.logoFilename && this.logoFilename.length > 0)  {
      integrationType.logoFilename = this.logoFilename;
    }

    if (integrationType.id)
    {
      await this.api.UpdateIntegrationType(integrationType);
    }
    else
    {
      await this.api.CreateIntegrationType(integrationType);
    }

    this.formsManager.clear('integrationType');
    await this.router.navigate(['/config/config-integration-types']);

    await this.spinner.hide();

  }

}


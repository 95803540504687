import { Injectable } from '@angular/core';
import { APIService } from '../../API.service';
import { AdaptersStore } from './adapters.store';
import {Auth, API, graphqlOperation} from 'aws-amplify';
import { AdapterModel } from './adapter.model';
import { ScheduledJob } from '../../models/scheduled-job';
import {GlobalUiStateQuery} from '../../state/global-ui-state.query';
import {NgxSpinnerService} from 'ngx-spinner';


@Injectable({ providedIn: 'root' })
export class AdaptersService {

  constructor(private spinner: NgxSpinnerService,
              private api: APIService,
              private globalStateQuery: GlobalUiStateQuery,
              private adaptersStore: AdaptersStore) {
  }

  async getAdapters(): Promise<AdapterModel[]> {

    const adapterList: AdapterModel[] = [];

    const envPrefix =  this.globalStateQuery.getSelectedEnvPrefix();
    let adapters = await this.api.ListAdapters({envPrefix: {eq: envPrefix}});
    adapters.items.forEach(adapter => {
      adapterList.push(
        {
          id: adapter.id,
          name: adapter.name,
          adapterType: adapter.adapterType,
          envPrefix: adapter.envPrefix,
          enabled: adapter.enabled,
          endpoint: adapter.endpoint.substring(adapter.endpoint.length-1) === "/" ? adapter.endpoint.substring(0, adapter.endpoint.length-1) :
            adapter.endpoint,
          lastSuccessfulPing: adapter.lastSuccessfulPing,
          logoFilename: adapter.logoFilename,
          logoWidth: adapter.logoWidth,
          logoHeight: adapter.logoHeight,
          auditTtl:null,
          localPortForwardPort: adapter.localPortForwardPort

      });
    });
    while( adapters.nextToken)  {
      adapters = await this.api.ListAdapters( { envPrefix: { eq: envPrefix } }, 0, adapters.nextToken);
      adapters.items.forEach(adapter => {
        adapterList.push(
          {
            id: adapter.id,
            name: adapter.name,
            adapterType: adapter.adapterType,
            envPrefix: adapter.envPrefix,
            enabled: adapter.enabled,
            endpoint: adapter.endpoint.substring(adapter.endpoint.length-1) === "/" ? adapter.endpoint.substring(0, adapter.endpoint.length-1) :
              adapter.endpoint,
            lastSuccessfulPing: adapter.lastSuccessfulPing,
            logoFilename: adapter.logoFilename,
            logoWidth: adapter.logoWidth,
            logoHeight: adapter.logoHeight,
            auditTtl:null,
            localPortForwardPort: adapter.localPortForwardPort
          });
      });
    }
    this.adaptersStore.set(adapterList);


    return adapterList;
  }

  // listenForAdapterActivity(): void
  // {
  //   this.api.OnCreateAdapterListener.subscribe({
  //     next: adapter => {
  //       //    console.log(adapter.id);
  //     }
  //   });
  // }

  updateSelectedAdapter(adapter: AdapterModel)
  {
     this.api.UpdateAdapter(adapter);
  }

  updateShowScheduledJobSearchResults(showScheduledJobSearchResults: boolean) {
    this.adaptersStore.updateUI({
      showScheduledJobSearchResults
    });
  }

  updateShowScheduledJobDiv(showScheduledJobDiv: boolean) {
    this.adaptersStore.updateUI({
      showScheduledJobDiv
    });
  }

  updateScheduledJobIsEdit(scheduledJobIsEdit: boolean) {
    this.adaptersStore.updateUI({
      scheduledJobIsEdit
    });
  }

  updateScheduledJobSearchResultList(scheduledJobSearchResultList: ScheduledJob[]) {
    this.adaptersStore.updateUI({
      scheduledJobSearchResultList
    });
  }

  updateScheduleJob(scheduleJob: ScheduledJob) {
    this.adaptersStore.updateUI({
      scheduleJob
    });
  }

}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-view-json-diolog',
  templateUrl: './view-json-dialog.component.html',
  styleUrls: ['./view-json-dialog.component.scss']
})
export class ViewJsonDialogComponent implements OnInit {

  jsonData = null;
  titleBase = 'Attributes';
  title = '';
  stackTrace = null;

  constructor(public dialogRef: MatDialogRef<ViewJsonDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.jsonData = this.data.json;
    this.stackTrace = this.data.error;
    this.title = [this.data.action, this.data.message].join(' ');
  }

  close() {
    this.dialogRef.close();
  }
}

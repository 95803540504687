import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  checkValidToken(token: string){
    console.log('checking token for expiry');
    if (!token) {
      return false;
    }

    try {
      const decodedToken = jwt_decode(token);
      // @ts-ignore
      if (decodedToken.exp * 1000 < Date.now())
      {
        console.log('Token has Expired. Logging out');
        return false;
      }
    } catch (e) {
      return false;
    }

    return true;
  }

}

<nav>
    <div>
        <div class="logo">
            <div class="ih-logo-spacing"><img src="assets/icon_black_white.png" class="ih-logo"></div>
            <div class="toolkit-logo-spacing"><span class="toolkit-logo">Toolkit</span></div>
            <div class="toolkit-logo-spacing-below"><span class="toolkit-logo-below">Admin</span></div>
        </div>
    </div>
    <div [hidden] = !userName>
      <div (click)="adaptersClicked()"
           [hidden]="globalStateQuery.getSelectedAdapterDetails()['name'] === 'instance_direct' && (userName == null || userName == '')"
           [style.background-color]="getSelectedNavBackgroundColor('adapters')">
          <div class="nav-button"><img src="assets/adapter.png"></div>
          <div class="nav-text">Adapters</div>
      </div>
      <div (click)="securityClicked()"
           [hidden]="globalStateQuery.getSelectedAdapterDetails()['name'] === 'instance_direct' && (userName == null || userName == '')"
           [style.background-color]="getSelectedNavBackgroundColor('security')">
          <div class="nav-button"><img class="nav-button" src="assets/security.png"></div>
          <div class="nav-text">Security</div>
      </div>
      <div (click)="configClicked()"
           [hidden]="
            (!this.globalStateQuery.getCachedUser() || (this.globalStateQuery.getCachedUser() && this.globalStateQuery.getCachedUser().role !== 'admin') ) ||
            (globalStateQuery.getSelectedAdapterDetails()['name'] === 'instance_direct' && (userName == null || userName == ''))"
           [style.background-color]="getSelectedNavBackgroundColor('config')">
        <div class="nav-button"><img src="assets/config.png"></div>
        <div class="nav-text">Config</div>
      </div>
    </div>
</nav>



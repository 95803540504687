import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AdminUiUsersQuery } from './state/admin-ui-users.query';
import { AdminUiUsersService } from './state/admin-ui-users.service';
import { Observable } from 'rxjs';
import { AdminUiUserModel } from './state/admin-ui-user.model';
import { APIService } from '../../API.service';
import { Router } from '@angular/router';
import { NgFormsManager } from '@ngneat/forms-manager';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DialogBoxComponent } from '../../dialog-box/dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import {Order} from '@datorama/akita';
import {GlobalUiStateQuery} from '../../state/global-ui-state.query';
import { ColumnMode } from '@swimlane/ngx-datatable';
import {AdminUiUsersStore} from './state/admin-ui-users.store';
import {GlobalUiStateService} from '../../state/global-ui-state.service';
import {AdapterModel} from '../../adapters/state/adapter.model';

@Component({
  selector: 'app-security-admin-ui-users',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './admin-ui-users.component.html',
  styleUrls: ['./admin-ui-users.component.scss']
})
export class AdminUiUsersComponent implements OnInit {

  users$?: Observable<AdminUiUserModel[]>;
  displayedColumns: string[] = ['name', 'emailAddress', 'envPrefix', 'role', 'action'];
  isEdit = false;
  user: AdminUiUserModel = new AdminUiUserModel();
  loading = true;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private api: APIService,
    private formsManager: NgFormsManager,
    private builder: UntypedFormBuilder,
    private usersQuery: AdminUiUsersQuery,
    private usersService: AdminUiUsersService,
    public globalStateQuery: GlobalUiStateQuery,
    private dialog: MatDialog,
    private usersStore: AdminUiUsersStore,
    private globalStateService: GlobalUiStateService
  ) { }

  ColumnMode = ColumnMode;
  searchCriteria: string;

  async ngOnInit(): Promise<void> {
    this.searchCriteria = "";
    this.usersStore.set([]);
    this.users$ = this.usersQuery.selectAll({sortBy: 'emailAddress', sortByOrder: Order.ASC});
    await this.usersService.getUsers();
    setTimeout(() => {
      this.loading = false;
    }, 1500);
  }

  onSearchChange(searchValue: string): void {
    this.searchCriteria = searchValue;
    if ( searchValue.length === 0 ) {
      this.users$ = this.usersQuery.selectAll({sortBy: 'emailAddress', sortByOrder: Order.ASC});
    }
    else {
      this.users$ = this.usersQuery.selectAll(
        {filterBy: entity => entity.emailAddress.toUpperCase().includes(this.searchCriteria.toUpperCase())
            || entity.userDetail.name.toUpperCase().includes(this.searchCriteria.toUpperCase()),
          sortBy: 'emailAddress', sortByOrder: Order.ASC});
    }
  }

  clearSearch(): void {
    this.users$ = this.usersQuery.selectAll({sortBy: 'emailAddress', sortByOrder: Order.ASC});
    this.searchCriteria = "";
  }

  async addUser(): Promise<void> {
    this.formsManager.clear('admin-ui-user');
    await this.router.navigate(['/security/add-edit-admin-ui-user']);
  }

  cancelEdit(): void {

  }

  async userClicked(user: AdminUiUserModel): Promise<void> {

    this.formsManager.clear('admin-ui-user');

    const userForm = this.builder.group({
      id: [user.id],
      detailId: [user.userDetail.id],
      role: [user.role, Validators.required],
      name: [user.userDetail.name, Validators.required],
      username: [user.userDetail.username],
      emailAddress: [user.emailAddress, Validators.required],
      envPrefix: [user.userDetail.envPrefix, Validators.required],
    });

    this.formsManager.upsert('admin-ui-user', userForm, {
      persistState: true
    });

    await this.router.navigate(['/security/add-edit-admin-ui-user']);

  }

  deleteUser(action, obj) {

    const user = { ...obj };
    user.action = action;

    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '250px',
      data: {
        dataObj: user,
        title: 'Admin User',
        message: 'Are you sure you want to delete User  ' + user.name
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Delete') {
        this.spinner.show();
        console.log('Delete ' + JSON.stringify(result.data));
        this.api.DeleteUser({ id: user.id }).then(async value => {
          await this.usersService.getUsers();
          this.users$ = this.usersQuery.selectAll();
          this.spinner.hide();
          this.showToaster('Admin User ' + user.name + ' deleted.', 0);
        }).catch(reason => {
          console.log('Error ' + JSON.stringify(reason));
          console.log('Error status ' + reason.status);
          this.showToaster('Delete call failed. Status code: ' + reason.status, 1);
        });
      }
    });
  }

  impersonateUser(obj) {
    // set the global environment prefix to this user's prefix.
    this.globalStateService.updateSelectedImpersonatedEnvPrefix(obj.userDetail.envPrefix);
  }

  cancelImpersonation() {
    // set the global environment prefix to this user's prefix.
    this.globalStateService.updateSelectedImpersonatedEnvPrefix("");
  }

  showToaster(message, statusCode) {
    statusCode === 0 ? this.toastr.success(message) : this.toastr.error(message);
  }

}

<script src="https://apis.google.com/js/platform.js"></script>

<main>

  <form>

    <div style="background-color: white; border-bottom: solid 1px gray; border-top: solid 1px gray; vertical-align: middle; padding: 10px;">

      <div style="display: flex; justify-content: space-between;">

          <div style="flex: 1 1 auto;">
            <input matInput style="width: 100%; border: none; border-bottom: solid 1px;" placeholder="Search by AdapterModel name, ID or Type" [value]="searchCriteria" (input)="onSearchChange($event.target.value)">
          </div>
          <div style="flex: 0 0 25px;">
            <img  src="assets/clear.png" alt="Search" style="height: 24px; width: 24px; padding-left: 5px;" (click)="clearSearch()" >
          </div>

      </div>

    </div>

  </form>

  <div style="padding: 25px;">

    <mat-grid-list cols="{{numCols}}" rowHeight="3:4" [gutterSize]="colPadding" [hidden]="this.globalStateQuery.getSelectedEnvironment() !== 'dev' && (!this.globalStateQuery.getCachedUser() || (this.globalStateQuery.getCachedUser() && this.globalStateQuery.getCachedUser().role !== 'admin'))">

      <mat-grid-tile (click)="addAdapter()">
        <div style="text-align: center; vertical-align: middle;">
          <img  src="assets/addAdapter.png" alt="Add new adapter" style="height: 100px; width: 100px;" >
          <div style="height: 10px;"></div>
          Add Adapter
        </div>
      </mat-grid-tile>

      <mat-grid-tile *ngFor="let adapter of filteredResultList$">

        <app-adapter-card [adapter]="adapter" (parentReloadPage)="parentReloadPage()" style="height: 100%; width: 100%;"></app-adapter-card>

      </mat-grid-tile>

    </mat-grid-list>

    <mat-grid-list cols="{{numCols}}" rowHeight="3:4" [gutterSize]="colPadding" [hidden]="this.globalStateQuery.getSelectedEnvironment() === 'dev' || (!this.globalStateQuery.getCachedUser() || (this.globalStateQuery.getCachedUser() && this.globalStateQuery.getCachedUser().role === 'admin'))">

      <mat-grid-tile *ngFor="let adapter of filteredResultList$">

        <app-adapter-card [adapter]="adapter" (parentReloadPage)="parentReloadPage()" style="height: 100%; width: 100%;"></app-adapter-card>

      </mat-grid-tile>

    </mat-grid-list>

  </div>

</main>


import { Component, OnInit } from '@angular/core';
import {IntegrationDetails} from '../../models/integration-details';
import {GlobalUiStateQuery} from '../../state/global-ui-state.query';
import {GlobalUiStateService} from '../../state/global-ui-state.service';
import {AdapterService} from '../../services/adapter.service';
import {MessageService} from '../../services/message-service.service';

@Component({
  selector: 'app-adapter-integration',
  templateUrl: './adapter-integration.component.html',
  styleUrls: ['./adapter-integration.component.scss']
})
export class AdapterIntegrationComponent implements OnInit {

  constructor(public globalStateQuery: GlobalUiStateQuery,
              private globalStateService: GlobalUiStateService,
              private adapterService: AdapterService,
              private messageService: MessageService) { }

  selectedAdapterDetails$: Object;
  selectedIntegrationDetails$: IntegrationDetails = new IntegrationDetails(null);

  ngOnInit(): void {
    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    this.selectedIntegrationDetails$ = { ...this.globalStateQuery.getSelectedAdapterIntegration() };
    if (this.selectedIntegrationDetails$.integrationId) {
      this.testSpecifiedIntegrationConnection();
    }
  }

  testSpecifiedIntegrationConnection()
  {
    this.selectedIntegrationDetails$.runningConnectionTest = true;
    this.adapterService.ping(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId).subscribe(
      result => {
        const newIntegration = {...this.selectedIntegrationDetails$};
        newIntegration.connectionState = "connected";
        newIntegration.runningConnectionTest = false;
        this.globalStateService.updateSelectedAdapterIntegration(newIntegration);
        this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();
        this.messageService.SendEvent({type:"IntegrationConnectionStatusChangeEvent", data:{ state: "connected"}});
      },
      error => {
        const newIntegration = {...this.selectedIntegrationDetails$};
        newIntegration.connectionState = "connected";
        newIntegration.runningConnectionTest = false;
        newIntegration.connectionMessage = JSON.stringify(error, null, 4);
        this.globalStateService.updateSelectedAdapterIntegration(newIntegration);
        this.messageService.SendEvent({type:"IntegrationConnectionStatusChangeEvent", data:{ state: "disconnected"}});
      },
      () => {
      }
    );
  }

}

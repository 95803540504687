<h2 mat-dialog-title> {{title}} </h2>

<mat-dialog-content *ngIf="jsondata != null">
  <ngx-json-viewer [json]="jsondata"  [expanded]="true"></ngx-json-viewer>
</mat-dialog-content>
<mat-dialog-content *ngIf="stackTrace != null">
  <div>
    {{stackTrace}}
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button  mat-button (click)="close()">Close</button>
</mat-dialog-actions>

import {Component, OnInit} from '@angular/core';
import {IntegrationDetails} from '../../../models/integration-details';
import {AdapterService} from '../../../services/adapter.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IntegrationAttribute} from '../../../models/integration-attribute';
import {IntegrationPropertyMapper} from '../../../models/integration-property-mapper';
import { GlobalUiStateQuery } from '../../../state/global-ui-state.query';
import { GlobalUiStateService } from '../../../state/global-ui-state.service';
import {MatFabMenu} from '@angular-material-extensions/fab-menu';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-adapter-integration-details',
  templateUrl: './adapter-integration-details.component.html',
  styleUrls: ['./adapter-integration-details.component.scss']
})
export class AdapterselectedIntegrationDetails$Component implements OnInit {

  selectedAdapterDetails$: Object;
  selectedIntegrationDetails$: IntegrationDetails = new IntegrationDetails(null);
  selectedIntegrationDetails$Form: UntypedFormGroup;
  adapterType: string;
  searchCriteria: string;
  integrationFields: IntegrationAttribute[];
  addedIntegrationFields: Array<IntegrationAttribute> = [];
  displayRelayFields: boolean;
  isEdit = false;
  buttons = [];
  fabTogglerState: string;
  fabButtons: MatFabMenu[] = [];
  public integrationInformation: object;
  liveApiLimit: number;
  liveApiLimitLoaded: boolean;
  _env: string;


  constructor(private router: Router,
              private service: AdapterService,
              private formBuilder: UntypedFormBuilder,
              private toastr: ToastrService,
              private dialog: MatDialog,
              public globalStateQuery: GlobalUiStateQuery,
              private globalStateService: GlobalUiStateService,
              private spinner: NgxSpinnerService,
              private adapterService: AdapterService)
  {

  }

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  ngOnInit(): void {

    this._env =  this.globalStateQuery.getSelectedEnvironment();

    this.liveApiLimitLoaded = false;
    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();
    console.log(this.selectedIntegrationDetails$ );

    this.searchCriteria = this.globalStateQuery.getSelectedIntegrationDetailsSearchCriteria();
    this.adapterType = localStorage.getItem('ADAPTER_TYPE');

    const group: any = {};
    group['environment'] = new UntypedFormControl('', Validators.required);
    group['accountId'] = new UntypedFormControl('', Validators.required);
    group['type'] = new UntypedFormControl('');
    group['authType'] = new UntypedFormControl('');
    group['enabled'] = new UntypedFormControl('');
    group['enableBatch'] = new UntypedFormControl('');
    group['batchSize'] = new UntypedFormControl('');
    group['enableRelay'] = new UntypedFormControl('');
    group['relayQueueName'] = new UntypedFormControl('');
    group['relayEncPassword'] = new UntypedFormControl('');
    group['relayResponseQueueName'] = new UntypedFormControl('');
    group['pingQueueName'] = new UntypedFormControl('');
    group['authSettings'] = new UntypedFormControl('');
    group['individualContributor'] = new UntypedFormControl('');
    group['overallLimit'] = new UntypedFormControl('');
    group['searchLimitPercentage'] = new UntypedFormControl('');
    group['crudLimitPercentage'] = new UntypedFormControl('');
    group['entitySyncLimitPercentage'] = new UntypedFormControl('');
    group['deleteJobLimitPercentage'] = new UntypedFormControl('');
    group['sharedCreds'] = new UntypedFormControl('');

    this.selectedIntegrationDetails$Form = new UntypedFormGroup(group);
    this.getRequiredFields();

    if (!this.globalStateQuery.getSelectedAdapterIntegration()['integrationId'])
      this.isEdit = true;

    this.updateFabButtons();
    this.fabTogglerState = 'inactive';

    if (this.isEdit === false && this.selectedIntegrationDetails$.connectionState == 'connected') {
      this.service
        .getInformation(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId)
        .subscribe(
          response => {
            if (Object.keys(response.body).length > 0) {
              this.integrationInformation = JSON.parse(JSON.stringify(response.body));
            }
          },
          error => {
            if (error.name === "TimeoutError") {
              this.showToaster('Listing Integrations information call failed. Call Timed out!', 1);
            } else {
              this.showToaster("Listing Integrations information call failed. " + error.status, 1);
            }
          }
        );
    }

    if (this.selectedIntegrationDetails$.connectionState == 'connected') {
      this.service
        .getApiLimit(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId)
        .subscribe(
          response => {

            if (parseInt(response.body.toString()) > 0) {
              this.liveApiLimit = JSON.parse(JSON.stringify(response.body));
              // if (this.selectedIntegrationDetails$.apiLimits.overallLimit == 0) {
              //   this.selectedIntegrationDetails$.apiLimits.overallLimit = this.liveApiLimit;
              // }
              this.liveApiLimitLoaded = true;
            }
          },
          error => {
            if (error.name === "TimeoutError") {
              this.showToaster('Get API Limit call failed. Call Timed out!', 1);
            } else {
              this.showToaster("Get API Limit call failed. " + error.status, 1);
            }
          }
        );
    }
  }

  showToaster(message, statusCode){
    statusCode === 0 ? this.toastr.success(message) : this.toastr.error(message);
  }

  getDynamicIntegrationForm()
  {
    const regexNumberPattern = /^(?:\-?\d*\.?\d{1,2})/;
    const regexUrlPattern = '(https?:\\/\\/)([\\da-z.-]+)\\.?([a-z.]{2,6}):?(\\d*)?[/\\w,/\\W .-]*/?';

    this.integrationFields.forEach(field => {

      if ( !this.selectedIntegrationDetails$Form.contains(field.fieldName) ) {
        this.addedIntegrationFields.push(field);

        if (field.type === 'NUMBER') {
          this.selectedIntegrationDetails$Form.addControl(field.fieldName, field.required ? new UntypedFormControl(field.defaultValue ||
            '', [Validators.required, Validators.pattern(regexNumberPattern)])
            : new UntypedFormControl(field.defaultValue || 0, Validators.pattern(regexNumberPattern)));
        } else if (field.type === 'URI') {
          this.selectedIntegrationDetails$Form.addControl(field.fieldName, field.required ? new UntypedFormControl(field.defaultValue ||
            '', [Validators.required, Validators.pattern(regexUrlPattern)])
            : new UntypedFormControl(field.defaultValue || '', Validators.pattern(regexUrlPattern)));
        } else {
          this.selectedIntegrationDetails$Form.addControl(field.fieldName, field.required ? new UntypedFormControl(field.defaultValue ||
            '', Validators.required)
            : new UntypedFormControl(field.defaultValue || ''));
        }
      }
    });
  }

  initializeSelectedIntegrationDetails$Form()
  {
    try {

      this.selectedIntegrationDetails$Form.controls['environment'].reset(this.selectedIntegrationDetails$.environment);
      this.selectedIntegrationDetails$Form.controls['accountId'].reset(this.selectedIntegrationDetails$.accountId);
      this.selectedIntegrationDetails$Form.controls['type'].reset(this.selectedIntegrationDetails$.type);
      this.selectedIntegrationDetails$Form.controls['enabled'].reset(this.selectedIntegrationDetails$.enabled);
      this.selectedIntegrationDetails$Form.controls['enableBatch'].reset(this.selectedIntegrationDetails$.enableBatch);
      this.selectedIntegrationDetails$Form.controls['batchSize'].reset(this.selectedIntegrationDetails$.maxPublishedMessageSize);
      this.selectedIntegrationDetails$Form.controls['individualContributor'].reset(this.selectedIntegrationDetails$.individualContributor);
      this.selectedIntegrationDetails$Form.controls['sharedCreds'].reset(this.selectedIntegrationDetails$.isSharedCreds);

      this.selectedIntegrationDetails$Form.controls['authType'].reset(this.selectedIntegrationDetails$.authType);

      if (this.selectedIntegrationDetails$.authType === 4) {
        this.selectedIntegrationDetails$Form.controls['authSettings'].reset(this.selectedIntegrationDetails$.customAuthSettings);
      } else {
        this.selectedIntegrationDetails$Form.controls['authSettings'].reset(this.selectedIntegrationDetails$.oAuthSettings);
      }
      this.selectedIntegrationDetails$Form.controls['enableRelay'].reset(this.selectedIntegrationDetails$.isRelay);
      this.selectedIntegrationDetails$Form.controls['relayQueueName'].reset(this.selectedIntegrationDetails$.relayQueueName);
      this.selectedIntegrationDetails$Form.controls['relayEncPassword'].reset(this.selectedIntegrationDetails$.relayEncryptionPassword);
      this.selectedIntegrationDetails$Form.controls['relayResponseQueueName'].reset(this.selectedIntegrationDetails$.relayResponseQueueName);
      this.selectedIntegrationDetails$Form.controls['pingQueueName'].reset(this.selectedIntegrationDetails$.pingQueueName);
      this.selectedIntegrationDetails$Form.controls['overallLimit'].reset(this.selectedIntegrationDetails$.apiLimits.overallLimit);
      this.selectedIntegrationDetails$Form.controls['searchLimitPercentage'].reset(this.selectedIntegrationDetails$.apiLimits.searchLimitPercentage);
      this.selectedIntegrationDetails$Form.controls['crudLimitPercentage'].reset(this.selectedIntegrationDetails$.apiLimits.crudLimitPercentage);
      this.selectedIntegrationDetails$Form.controls['entitySyncLimitPercentage'].reset(this.selectedIntegrationDetails$.apiLimits.entitySyncLimitPercentage);
      this.selectedIntegrationDetails$Form.controls['deleteJobLimitPercentage'].reset(this.selectedIntegrationDetails$.apiLimits.deleteJobLimitPercentage);

      this.addedIntegrationFields.forEach((field, index) => {
        const key = IntegrationPropertyMapper.GetMappedKey(field.fieldName);
        if (key in this.selectedIntegrationDetails$) {
          this.selectedIntegrationDetails$Form.controls[field.fieldName].reset(this.selectedIntegrationDetails$[key]);
        } else if (field.key in this.selectedIntegrationDetails$.config) {
          this.selectedIntegrationDetails$Form.controls[field.fieldName].reset(this.selectedIntegrationDetails$.config[key]);
        }
      });

      this.displayRelayFields = this.selectedIntegrationDetails$.isRelay === undefined ? false : this.selectedIntegrationDetails$.isRelay;
    }
    catch ( error )
    {
      console.log(error);
    }
  }


  isRelayChanged(event) {
    this.selectedIntegrationDetails$ = {...this.selectedIntegrationDetails$};
    if (!event.checked)
    {
      this.selectedIntegrationDetails$.relayEncryptionPassword = '';
      this.selectedIntegrationDetails$.relayQueueName = '';
      this.selectedIntegrationDetails$.relayResponseQueueName = '';
      this.selectedIntegrationDetails$.pingQueueName = '';
      this.selectedIntegrationDetails$Form.controls['relayQueueName'].reset(this.selectedIntegrationDetails$.relayQueueName);
      this.selectedIntegrationDetails$Form.controls['relayEncPassword'].reset(this.selectedIntegrationDetails$.relayEncryptionPassword);
      this.selectedIntegrationDetails$Form.controls['relayResponseQueueName'].reset(this.selectedIntegrationDetails$.relayResponseQueueName);
      this.selectedIntegrationDetails$Form.controls['pingQueueName'].reset(this.selectedIntegrationDetails$.pingQueueName);
    }
    // this.selectedIntegrationDetails$.isRelay = event.checked;
    this.displayRelayFields = event.checked;
  }

  isEnabled(event) {
    this.selectedIntegrationDetails$ = {... this.selectedIntegrationDetails$};
    if (!event.checked)
    {
      this.selectedIntegrationDetails$.enabled = false;
    }else {
      this.selectedIntegrationDetails$.enabled = true;
    }
  }

  isBatchEnabled(event) {
    this.selectedIntegrationDetails$ = {... this.selectedIntegrationDetails$};
    if (!event.checked)
    {
      this.selectedIntegrationDetails$.enableBatch = false;
    }else {
      this.selectedIntegrationDetails$.enableBatch = true;
    }
  }

  indivContrChanged(event)
  {
    this.selectedIntegrationDetails$ = {... this.selectedIntegrationDetails$};
    if(!event.checked)
    {
      this.selectedIntegrationDetails$.individualContributor = false;
    }
    else
    {
      this.selectedIntegrationDetails$.individualContributor = true;
    }
  }

  sharedCredsChanged(event)
  {
    this.selectedIntegrationDetails$ = {... this.selectedIntegrationDetails$};
    if(!event.checked)
    {
      this.selectedIntegrationDetails$.isSharedCreds = false;
    }
    else
    {
      this.selectedIntegrationDetails$.isSharedCreds = true;
    }
  }

  updateIntegration() {

    this.spinner.show();

    if (!this.setSelectedIntegrationDetails$FormFromEntity()) {
      this.spinner.hide();
      return;
    }

    var connected = this.selectedIntegrationDetails$['connected'];

    this.service.updateIntegration(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$).subscribe(
      result => {
        this.adapterService.getIntegration(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId).subscribe(
          async result => {
            const integration = new IntegrationDetails(result.body)
            this.globalStateService.updateSelectedAdapterIntegration(integration);
            window.location.href = window.location.href;
          },
          async error => {
            this.showToaster('Retrieving integration after Update Integration call failed.', 1);
          }
        );


      },
      error => {
        console.log('Error ' + JSON.stringify(error));
        console.log('Error status ' + error.status);
        if( error.name === "TimeoutError")
        {
          this.showToaster('Update Integration call failed. Call Timed out!', 1);
        }else{
          this.showToaster('Update Integration call failed. Status code: ' + error.status, 1);
        }
        this.spinner.hide();
      },
      () => {
        this.isEdit = false;
        this.globalStateService.updateSelectedAdapterIntegration(this.selectedIntegrationDetails$);
        this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();
        this.selectedIntegrationDetails$Form.reset();

        this.initializeSelectedIntegrationDetails$Form();
        this.updateFabButtons();
      }
    );
  }

  revokeIntegrationClientInfo() {

    this.spinner.show();
    var connected = this.selectedIntegrationDetails$['connected'];

    this.service.revokeIntegrationClientInfo(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId).subscribe(
      result => {
        //window.location.href = window.location.href;
        this.spinner.hide();
        this.showToaster('Successfully revoked client id and secret', 0);
      },
      error => {
        console.log('Error ' + JSON.stringify(error));
        console.log('Error status ' + error.status);
        if( error.name === "TimeoutError")
        {
          this.showToaster('Update Integration call failed. Call Timed out!', 1);
        }else{
          this.showToaster('Update Integration call failed. Status code: ' + error.status, 1);
        }
        this.spinner.hide();
      },
      () => {
        this.isEdit = false;
        this.selectedIntegrationDetails$ = {... this.selectedIntegrationDetails$};
        var x = {... this.selectedIntegrationDetails$.authSettings};
        x["clientId"] = "";
        x["clientSecret"] = "";
        this.selectedIntegrationDetails$.authSettings = x;
        this.globalStateService.updateSelectedAdapterIntegration(this.selectedIntegrationDetails$);
        this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();
        this.selectedIntegrationDetails$Form.reset();

        this.initializeSelectedIntegrationDetails$Form();
        this.updateFabButtons();
      }
    );
  }

  createIntegration() {

    this.spinner.show();

    if (!this.setSelectedIntegrationDetails$FormFromEntity()) {
      this.spinner.hide();
      return;
    }

    this.service.createIntegration(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$).subscribe(
      async result => {

        this.searchCriteria = result.body['IntegrationId'];
        this.globalStateService.updateSelectedAdapterIntegrationNavPage('adapter-integration-details');
        this.selectedIntegrationDetails$ = new IntegrationDetails(result.body);
        this.globalStateService.updateSelectedIntegrationDetailsSearchCriteria(this.searchCriteria);
        this.globalStateService.updateSelectedAdapterIntegration(this.selectedIntegrationDetails$);

        window.location.href = window.location.href;

      },
      error => {
        console.log('Error ' + JSON.stringify(error));
        console.log('Error status ' + error.status);
        this.showToaster('createIntegration call failed. Status code: ' + error.status, 1);
        this.spinner.hide();
      },
      () => {
        this.updateFabButtons();
      }
    );
  }

  setSelectedIntegrationDetails$FormFromEntity()
  {
    this.selectedIntegrationDetails$ = {... this.selectedIntegrationDetails$};

    try {
      this.selectedIntegrationDetails$.apiLimits = {
        overallLimit: this.selectedIntegrationDetails$.apiLimits.overallLimit,
        searchLimitPercentage: parseInt(this.selectedIntegrationDetails$Form.controls['searchLimitPercentage'].value),
        crudLimitPercentage: parseInt(this.selectedIntegrationDetails$Form.controls['crudLimitPercentage'].value),
        entitySyncLimitPercentage: parseInt(this.selectedIntegrationDetails$Form.controls['entitySyncLimitPercentage'].value),
        deleteJobLimitPercentage: parseInt(this.selectedIntegrationDetails$Form.controls['deleteJobLimitPercentage'].value)
      };
    } catch ( error )
    {
      this.showToaster('Error parsing Fetch Limits', 1);
      return false;
    }

    const limitTotal = this.selectedIntegrationDetails$.apiLimits['searchLimitPercentage'] + this.selectedIntegrationDetails$.apiLimits['crudLimitPercentage'] +
      this.selectedIntegrationDetails$.apiLimits['entitySyncLimitPercentage'] + this.selectedIntegrationDetails$.apiLimits['deleteJobLimitPercentage'];
    // check that limit percentages add to 100%
    if (limitTotal != 100 && limitTotal != 0)  {
      this.showToaster('Fetch Limits % must total 100% or must all be set to zero', 1);
      return false;
    }

    this.selectedIntegrationDetails$.config = {...this.selectedIntegrationDetails$.config};
    this.selectedIntegrationDetails$.customAuthSettings = {...this.selectedIntegrationDetails$.customAuthSettings};
    this.selectedIntegrationDetails$.oAuthSettings = {...this.selectedIntegrationDetails$.oAuthSettings};

    this.selectedIntegrationDetails$.name = this.selectedIntegrationDetails$Form.controls['name'].value;
    this.selectedIntegrationDetails$.environment = this.selectedIntegrationDetails$Form.controls['environment'].value;
    this.selectedIntegrationDetails$.accountId =  parseInt(this.selectedIntegrationDetails$Form.controls['accountId'].value, 10);
    this.selectedIntegrationDetails$.type = this.selectedIntegrationDetails$Form.controls['type'].value;
    this.selectedIntegrationDetails$.isRelay = this.selectedIntegrationDetails$Form.controls['enableRelay'].value;
    this.selectedIntegrationDetails$.relayQueueName = this .selectedIntegrationDetails$Form.controls['relayQueueName'].value;
    this.selectedIntegrationDetails$.relayEncryptionPassword = this.selectedIntegrationDetails$Form.controls['relayEncPassword'].value;
    this.selectedIntegrationDetails$.relayResponseQueueName = this.selectedIntegrationDetails$Form.controls['relayResponseQueueName'].value;
    this.selectedIntegrationDetails$.pingQueueName = this.selectedIntegrationDetails$Form.controls['pingQueueName'].value;
    this.selectedIntegrationDetails$.authType = this.selectedIntegrationDetails$Form.controls['authType'].value;
    this.selectedIntegrationDetails$.maxPublishedMessageSize =  parseInt(this.selectedIntegrationDetails$Form.controls['batchSize'].value, 10);
    if (this.selectedIntegrationDetails$.integrationId != null)
    {
      if ( this.selectedIntegrationDetails$.authType === 4){
        this.selectedIntegrationDetails$.customAuthSettings = this.selectedIntegrationDetails$Form.controls['authSettings'].value;
      }
    }
    else{
      this.selectedIntegrationDetails$.customAuthSettings = JSON.parse('{}');
    }
    const formData: any = this.selectedIntegrationDetails$Form.value;
    this.addedIntegrationFields.forEach((field, index) => {
      let key =  IntegrationPropertyMapper.GetMappedKey(field.fieldName);
      if( key === undefined )
        key = field.fieldName;
      field.defaultValue =  formData[field.fieldName];
      if ( key in this.selectedIntegrationDetails$ )
      {
        if ( field.type === 'NUMBER')
        {
          this.selectedIntegrationDetails$[key] = parseInt(formData[field.fieldName], 10);

        }
        else if ( field.type === 'Bool')
        {

        }else{
          this.selectedIntegrationDetails$[key] = formData[field.fieldName];
        }
      }else if (key in this.selectedIntegrationDetails$.config) {
        if (field.type === 'NUMBER') {
          this.selectedIntegrationDetails$.config[key] = parseInt(formData[field.fieldName], 10);
        } else {
          this.selectedIntegrationDetails$.config[key] = formData[field.fieldName];
        }
      }
      else{
        if (field.type === 'NUMBER') {
          this.selectedIntegrationDetails$.config[key] = parseInt(formData[field.fieldName], 10);
        } else {
          this.selectedIntegrationDetails$.config[key] = formData[field.fieldName];
        }
      }
    });

    this.selectedIntegrationDetails$.isRelay = this.displayRelayFields;

    return true;
  }

  setUpForm()
  {
    this.selectedIntegrationDetails$Form.reset();
    Object.keys(this.selectedIntegrationDetails$Form.controls).forEach(key => {
        this.selectedIntegrationDetails$Form.get(key).setErrors(null) ;
      }
    );

    if (this.globalStateQuery.getSelectedAdapterIntegration() != null) {
      this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();
    }
    this.initializeSelectedIntegrationDetails$Form();
  }

  getRequiredFields(){

    this.spinner.show();

    this.service.getIntegrationFields(this.selectedAdapterDetails$['endpoint']).subscribe(
      result => {
        const newAttritributeList = Object.keys(result.body).map(i =>
          (new IntegrationAttribute(result.body[i]))
        );
        this.integrationFields = newAttritributeList;
        this.integrationFields.forEach(field => {
          if ( field.key in this.selectedIntegrationDetails$ )
          {
            field.defaultValue = this.selectedIntegrationDetails$[field.key];
          }else if (field.key in this.selectedIntegrationDetails$.config)
          {
            field.defaultValue = this.selectedIntegrationDetails$.config[field.key];
          }

        });
        this.setUpForm();
        this.getDynamicIntegrationForm();
      },
      error => {},
      () => {
        this.spinner.hide();
      }
    );
  }

  async processFabButtonClick(buttonId) {
    if (buttonId == 1) { // cancel edit mode
      this.isEdit = false;
      this.updateFabButtons();
    } else if (buttonId == 2) { // cancel back to integrations
      this.isEdit = false;
      await this.router.navigate(['/adapters/adapter-details/adapter-details-integrations']);
    } else if (buttonId == 6){
        this.isEdit = false;
        this.revokeIntegrationClientInfo();
        this.updateFabButtons();
    } else if (buttonId == 3) { // move to edit mode
      this.isEdit = true;
      this.updateFabButtons();
    } else if (buttonId == 4) { // save existing integration
      if (!this.selectedIntegrationDetails$Form.valid)  {
        this.showToaster('Form Validation failed. Please address validation errors ...', 1);
      }
      else {
        this.updateIntegration();
      }
    } else if (buttonId == 5) { // insert new integration
      if (!this.selectedIntegrationDetails$Form.valid)  {
        this.showToaster('Form Validation failed. Please address validation errors ...', 1);
      }
      else {
        this.createIntegration();
      }
    }
  }

  updateFabButtons()  {

    const updatedFabButtons = [];
    if (!this.isEdit) {
      updatedFabButtons.push({
        id: 3,
        icon: 'edit',
        tooltip: 'Edit',
        tooltipPosition: 'left'
      });
      if (this.selectedIntegrationDetails$.authSettings["clientId"])  {
        updatedFabButtons.push({
          id: 6,
          icon: 'undo',
          tooltip: 'Revoke Client Auth ',
          tooltipPosition: 'left'
        });
      }
    }
    if (!(!this.isEdit || !this.globalStateQuery.getSelectedAdapterIntegration()['integrationId']))  {
      updatedFabButtons.push({
        id: 4,
        icon: 'save',
        tooltip: 'Save',
        tooltipPosition: 'left'
      });
    }
    if (!this.globalStateQuery.getSelectedAdapterIntegration()['integrationId'])  {
      updatedFabButtons.push({
        id: 5,
        icon: 'save',
        tooltip: 'Save',
        tooltipPosition: 'left'
      });
    }
    if (this.isEdit && this.globalStateQuery.getSelectedAdapterIntegration()['integrationId']) {
      updatedFabButtons.push({
        id: 1,
        icon: 'cancel',
        tooltip: 'Cancel',
        tooltipPosition: 'left'
      });
    }
    if (this.isEdit && !this.globalStateQuery.getSelectedAdapterIntegration()['integrationId']) {
      updatedFabButtons.push({
        id: 2,
        icon: 'cancel',
        tooltip: 'Cancel',
        tooltipPosition: 'left'
      });
    }

    this.fabButtons = JSON.parse(JSON.stringify(updatedFabButtons));
    this.buttons = JSON.parse(JSON.stringify(updatedFabButtons));
  }

}

<script src="https://apis.google.com/js/platform.js"></script>

<mat-toolbar>
  <div>Integration Type Management</div>
  <span class="spacer"></span>
  <div style="float:right; position: fixed; bottom: 50px; right: 2%; z-index: 100; display: flex; flex-direction: column-reverse; align-items: center;">
    <button mat-fab color="primary" aria-label="Add User" (click)="addIntegrationType()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</mat-toolbar>

<div>

  <ngx-datatable
    #filtersTable
    class="material"
    [loadingIndicator]=loading
    [columnMode]="ColumnMode.flex"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="50"
    [rows]="integrationTypes$ | async"
    [limit]="10">

    <ngx-datatable-column name="Code" [sortable]="false" [flexGrow]="4">
      <ng-template let-integrationType="row" ngx-datatable-cell-template>
        <strong>{{ integrationType.code }}</strong>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Display Name" [sortable]="false" [flexGrow]="4">
      <ng-template let-integrationType="row" ngx-datatable-cell-template>
        <strong>{{ integrationType.displayName }}</strong>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Logo Filename" [sortable]="false" [flexGrow]="4">
      <ng-template let-integrationType="row" ngx-datatable-cell-template>
        <strong>{{ integrationType.logoS3Ref }}</strong>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Actions" [sortable]="false" [flexGrow]="2">
      <ng-template let-integrationType="row" ngx-datatable-cell-template>
        <a (click)="$event.stopPropagation(); integrationTypeClicked(integrationType)" style="color: blue; cursor: pointer;">
          <mat-icon matTooltip="View" style="color:royalblue">visibility</mat-icon>
        </a>
        <mat-icon style="color:gray">more_vert</mat-icon>
        <a (click)="$event.stopPropagation(); deleteIntegrationType('Delete', integrationType)" style="color: blue; cursor: pointer;">
          <mat-icon matTooltip="Delete" style="color:red">delete</mat-icon>
        </a>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>

</div>




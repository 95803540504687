import { Component, OnInit, OnDestroy } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { NgFormsManager } from '@ngneat/forms-manager';
import { Router } from '@angular/router';
import { APIService } from '../../../API.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {MatFabMenu} from '@angular-material-extensions/fab-menu';
import {Auth} from 'aws-amplify';
import {CognitoIdentityServiceProvider} from 'aws-sdk';
import {ToastrService} from 'ngx-toastr';
import {GlobalUiStateQuery} from '../../../state/global-ui-state.query';

@Component({
  selector: 'app-security-add-edit-admin-ui-user',
  templateUrl: './add-edit-admin-ui-user.component.html',
  styleUrls: ['./add-edit-admin-ui-user.component.scss']
})
export class AddEditAdminUiUserComponent implements OnInit, OnDestroy {

  userForm: UntypedFormGroup;
  buttons = [];
  fabTogglerState: string;
  fabButtons: MatFabMenu[] = [];
  oldRole: string;
  emailAddress: string;

  constructor(
    private spinner: NgxSpinnerService,
    private api: APIService,
    private router: Router,
    private formsManager: NgFormsManager,
    private builder: UntypedFormBuilder,
    private toastr: ToastrService,
    public globalStateQuery: GlobalUiStateQuery
  ) { }

  ngOnInit(): void {

    this.userForm = (this.globalStateQuery.getSelectedEnvironment() === "staging" || this.globalStateQuery.getSelectedEnvironment() === "production") ? this.builder.group({
      id: [null],
      detailId: [null],
      role: [null, Validators.required],
      name: [null, Validators.required],
      username: [null],
      emailAddress: [null, Validators.required],
      envPrefix: [null]
    }):
      this.builder.group({
        id: [null],
        detailId: [null],
        role: [null, Validators.required],
        name: [null, Validators.required],
        username: [null],
        emailAddress: [null, Validators.required],
        envPrefix: [null]
      });

    this.formsManager.upsert('admin-ui-user', this.userForm, {
      persistState: true
    });

    this.updateFabButtons();
    this.fabTogglerState = 'inactive';
    this.oldRole = this.formsManager.getControl('admin-ui-user').value["role"];
    this.emailAddress = this.formsManager.getControl('admin-ui-user').value["emailAddress"];

    console.log(this.userForm);
  }

  updateFabButtons() {

    const updatedFabButtons = [];

    updatedFabButtons.push({
      id: 1,
      icon: 'cancel',
      tooltip: 'Cancel',
      tooltipPosition: 'left'
    });

    this.fabButtons = JSON.parse(JSON.stringify(updatedFabButtons));
    this.buttons = JSON.parse(JSON.stringify(updatedFabButtons));
  }

  ngOnDestroy() {
    this.formsManager.unsubscribe('admin-ui-user');
  }

  async onCancel(): Promise<void> {
    this.formsManager.clear('admin-ui-user');
    await this.router.navigate(['/security/security-admin-ui-users']);
  }

  async upsertUser(): Promise<void> {

    this.spinner.show();

    const user = this.formsManager.getControl('admin-ui-user').value;

    user.username = user.username?.trim();
    user.name = user.name.trim();
    user.emailAddress = user.emailAddress.trim();
    user.envPrefix = (this.globalStateQuery.getSelectedEnvironment() === "staging" || this.globalStateQuery.getSelectedEnvironment() === "production") ? user.emailAddress.substring(0, user.emailAddress.indexOf('@')) : user.envPrefix.trim();

    if (user.id)
    {

      try {
        await this.api.UpdateUserDetail({
          id: user.detailId,
          ownerEmail: user.emailAddress,
          username: user.username,
          name: user.name,
          envPrefix: user.envPrefix
        });
      } catch (e) {
        await this.spinner.hide();
        if (e.errors[0].message.indexOf("Status Code: 400") > -1) {
          this.showToaster('Update User Detail call failed. User does not have permission to perform this operation', 1);
        } else {
          this.showToaster('Update User Detail call failed. Status code: ' + e.errors[0].message, 1);
        }
        return;
      }
      if (this.globalStateQuery.getCachedUser().role === 'admin') {
        try {
          await this.api.UpdateUser({
            id: user.id,
            emailAddress: user.emailAddress,
            userUserDetailId: user.detailId,
            role: user.role
          });
        } catch (e) {
          await this.spinner.hide();
          if (e.errors[0].message.indexOf("Status Code: 400") > -1) {
            this.showToaster('Update User call failed. User does not have permission to perform this operation', 1);
          } else {
            this.showToaster('Update User call failed. Status code: ' + e.errors[0].message, 1);
          }
          return;
        }

        // need to check to see if the user was in a previous group and remove them if so
        if (user.role != this.oldRole) {
          Promise.resolve(Auth.currentAuthenticatedUser())
            .then(authenticatedUser =>
              Auth.currentCredentials()
                .then(credentials =>
                  Promise.resolve(
                    new CognitoIdentityServiceProvider({
                      apiVersion: '2016-04-18',
                      credentials: Auth.essentialCredentials(credentials),
                      region: "us-east-1"
                    })
                  )
                )
                .then(client => {
                  if (user.username) {

                    client.adminRemoveUserFromGroup({
                      GroupName: this.oldRole,
                      UserPoolId: authenticatedUser.pool.userPoolId,
                      Username: user.username
                    }).promise()

                    client.adminAddUserToGroup({
                      GroupName: user.role,
                      UserPoolId: authenticatedUser.pool.userPoolId,
                      Username: user.username
                    }).promise()
                  }
                })
            )
        }
      }
    }
    else
    {
      const userList = await this.api.ListUsers({emailAddress: {eq: user.emailAddress}});
      if (userList !== null && userList.items.length > 0) {
        this.showToaster('Cannot add user - user with email ' + user.emailAddress + ' already exists ', 1);
        await this.spinner.hide();
        return;
      }

      try {
        const userDetail = await this.api.CreateUserDetail({
          ownerEmail: user.emailAddress,
          username: user.username,
          name: user.name,
          envPrefix: user.envPrefix
        });

        await this.api.CreateUser({
          userUserDetailId: userDetail.id,
          emailAddress: user.emailAddress,
          role: user.role
        });
      } catch (e) {
        console.log(e.errors[0].message);
        await this.spinner.hide();
        if (e.errors[0].message.indexOf("Status Code: 400") > -1) {
          this.showToaster('Update User call failed. User does not have permission to perform this operation', 1);
        } else {
          this.showToaster('Update User call failed. Status code: ' + e.errors[0].message, 1);
        }
        return;
      }
    }

    this.formsManager.clear('admin-ui-user');
    await this.router.navigate(['/security/security-admin-ui-users']);

    this.spinner.hide();

  }

  showToaster(message, statusCode) {
    statusCode === 0 ? this.toastr.success(message) : this.toastr.error(message);
  }

}

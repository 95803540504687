<nav>
    <div (click)="adapterDetailsHealthClicked()" [style.background-color]="getSelectedNavBackgroundColor('adapter-details-health')">
        <div style="text-align: center; padding-top:15px;"><img src="assets/health.png" style="height: 24px;"></div>
        <div style="text-align: center; padding-top:3px; padding-bottom:15px; color: black; font-size: 8pt;">Health</div>
    </div>
    <div (click)="adapterDetailsConfigClicked()" [style.background-color]="getSelectedNavBackgroundColor('adapter-details-config')" >
      <div style="text-align: center; padding-top:15px;"><img src="assets/adapter-config.png" style="height: 24px;"></div>
      <div style="text-align: center; padding-top:5px; padding-bottom:15px; color: black; font-size: 8pt;">Config</div>
    </div>
    <div (click)="adapterDetailsIntegrationsClicked()" [style.background-color]="getSelectedNavBackgroundColor('adapter-details-integrations')" >
        <div style="text-align: center; padding-top:15px;"><img src="assets/adapter-instance.png" style="height: 24px;"></div>
        <div style="text-align: center; padding-top:5px; padding-bottom:15px; color: black; font-size: 8pt;">Integrations</div>
    </div>

  <div (click)="adapterAuditLogClicked()" [style.background-color]="getSelectedNavBackgroundColor('adapter-audit-log')" >
    <div style="text-align: center; padding-top:15px;"><img src="assets/logs.png" style="height: 32px;"></div>
    <div style="text-align: center; padding-top:5px; padding-bottom:15px; color: black; font-size: 8pt;">Audit Logs</div>
  </div>

  <div (click)="adapterErrorLogClicked()" [style.background-color]="getSelectedNavBackgroundColor('adapter-error-logs')" >
    <div style="text-align: center; padding-top:15px;"><img src="assets/error-logs.png" style="height: 32px;"></div>
    <div style="text-align: center; padding-top:5px; padding-bottom:15px; color: black; font-size: 8pt;">Error Logs</div>
  </div>

</nav>

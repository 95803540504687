export class ScheduledJob {

  constructor(mapValues)
  {

    if (mapValues != null)
    {
      this.jobId = mapValues['JobId'];
      this.integrationId = mapValues['IntegrationId'];
      this.integrationType = mapValues['IntegrationType'];
      this.jobType = mapValues['JobType'];
      this.cronExpression = mapValues['CronExpression'];
      this.status = mapValues['Status'];
      this.lastRunResult = mapValues['LastRunResult'];
      this.statusChangeDateTime = mapValues['StatusChangeDateTime'];
      this.lastStartedDateTime = mapValues['LastStartedDateTime'];
      this.lastStoppedDateTime = mapValues['LastStoppedDateTime'];
      this.lastSuccessDateTime = mapValues['LastSuccessDateTime'];
      this.lastRunResultCode = mapValues['LastRunResultCode'];
      this.lastRunException = mapValues['LastRunException'];
      this.lastFailureDateTime = mapValues['LastFailureDateTime'];
      this.failedAttempts = mapValues['FailedAttempts'];
      this.lastRunDurationInSecs = mapValues['LastRunDurationInSecs'];
      this.disabledAtDateTime = mapValues['DisabledAtDateTime'];
      this.jobDetail = mapValues['JobDetail'];
      this.isScheduled =  mapValues['IsScheduled'];
      this.name =  mapValues['Name'];
      this.userId =  mapValues['UserId'];
    }
    else
    {

    }

  }

  jobId: string;
  integrationId: number;
  integrationType: string;
  jobType: string;
  cronExpression: string;
  status: string;
  lastRunResult: string;
  statusChangeDateTime: string;
  lastStartedDateTime: string;
  lastStoppedDateTime: string;
  lastSuccessDateTime: string;
  lastRunResultCode: string;
  lastRunException: string;
  lastFailureDateTime: string;
  failedAttempts: number;
  lastRunDurationInSecs: number;
  disabledAtDateTime: string;
  jobDetail: Object;
  isScheduled: boolean;
  name: string;
  userId: string;

  runtime()  {
    const startDate = new Date(2020, 1, 1);
    const endDate = new Date(2020, 2, 1);
    const seconds = (+endDate - +startDate) / 1000;
    return seconds;
  }

}

<main style="background-color: white; height: 100%; position: relative;">

   <div style="width:98%; height: 95%;">

     <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
       <p class="loading" style="color: white; font-weight: bold;">Executing Query ...</p>
     </ngx-spinner>

     <mat-toolbar style="background-color: white;">

       <span>Query Tool</span>

     </mat-toolbar>

     <div class="row" style="padding: 10px 10px 10px 10px;">
       <div class="column" style="padding: 10px 10px 10px 10px;">
         <mat-toolbar style="font-size: 16px; height:30px; background-color:white; padding: 0 0 0 0; ">
           <span>Enter Query Below</span>
           <span class="example-spacer"></span>
           <mat-icon matTooltip="Clear" (click)="clearQuery()">clear</mat-icon>
           <div style="width: 5px;"></div>
           <mat-icon class= "play" matTooltip="Execute" (click)="performQuery()">play_arrow</mat-icon>
           <div style="width: 5px;"></div>
           <mat-icon matTooltip="Build Query" (click)="buildQuery()" id="queryButton" [ngClass]="{disabledQuery:(selectedEntities == undefined || selectedEntities.length == 0 ),enableQuery:(selectedEntities != undefined && selectedEntities.length > 0 )}">settings</mat-icon>
         </mat-toolbar>

         <form [formGroup]="form">
           <div>
             <textarea class="queryTextArea" formControlName="rawQuery"></textarea>
           </div>
         </form>
       </div>
       <div class="column" style="padding: 10px 10px 10px 10px;">

         <mat-toolbar style="font-size: 16px; height:30px; background-color:white; padding: 0 0 0 0; ">
           <span>Query Results</span>
         </mat-toolbar>

         <div class="queryTextArea" style="border: solid 1px black; min-height: 430px; max-width: 850px; padding: 10px 10px 10px 10px;">
           <ngx-json-viewer [json]="queryResult" [expanded]="true"></ngx-json-viewer>
         </div>

       </div>
     </div>

  </div>

</main>












import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AdapterService} from '../../../services/adapter.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalUiStateQuery} from '../../../state/global-ui-state.query';
import {NgxSpinnerService} from 'ngx-spinner';
import {GlobalUiStateService} from '../../../state/global-ui-state.service';
import {IntegrationDetails} from '../../../models/integration-details';
import {IntegrationUser} from '../../../models/integration-user';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {ViewJsonDialogComponent} from '../../../shared/view-json-dialog/view-json-dialog.component';
import {DialogBoxComponent} from '../../../dialog-box/dialog-box.component';
import {ScheduledJobAdminService} from '../../../services/scheduled-job-admin.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {IntegrationUserService} from '../../../services/integration-user.service';
import {MessageService} from '../../../services/message-service.service';

export enum Mode {
  none,
  edit,
  raw
}

@Component({
  selector: 'app-adapter-integration-users',
  templateUrl: './adapter-integration-users.component.html',
  styleUrls: ['./adapter-integration-users.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AdapterIntegrationUsersComponent implements OnInit,OnDestroy {

  @ViewChild('userTable') userTable: any;

  constructor(
    private service: AdapterService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private globalStateQuery: GlobalUiStateQuery,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private adapterService: AdapterService,
    private globalStateService: GlobalUiStateService,
    public matDialog: MatDialog,
    private sjService: ScheduledJobAdminService,
    private userService: IntegrationUserService,
    private messageService: MessageService
  ) {

  }

  ColumnMode = ColumnMode;
  selectedAdapterDetails$: Object;
  selectedIntegrationDetails$: IntegrationDetails;

  buttons = [];
  showEdit = new Map<string, boolean>();

  ///users
  authedUser;
  userResultList: IntegrationUser[] = null;
  currentUser: IntegrationUser = new IntegrationUser(null);
  userForm: UntypedFormGroup;
  _env: string;

  ngOnInit(): void {
    console.log("ngOnInit")
    this._env =  this.globalStateQuery.getSelectedEnvironment();
    window.onbeforeunload = () => this.ngOnDestroy();
    this.authedUser = this.globalStateQuery.getCachedUser();
    this.updateFabButtons(Mode.none);

    const group: any = {};
   //group['sharedUserId'] = new UntypedFormControl('');
   group['systemAccountUserRadio'] = new UntypedFormControl('')

   this.userForm = new UntypedFormGroup(group);


    this.route.queryParams
      .subscribe(async params => {
          this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
          this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();
         if (params["callback"] && params["callback"] === "true") {
          this.pingIntegration(this.selectedIntegrationDetails$);
          this.refreshSelectedIntegration();
        }
          this.refreshUserList();
        }
      );

  }

  getRowClass = (row) => {
    return {
      'entitysync-row-color': row.jobType == "EntitySync",
      'not-entitysync-row-color': row.jobType != "EntitySync",
    };
  }

  pingIntegration(integration: IntegrationDetails) {
    this.adapterService.ping(this.selectedAdapterDetails$['endpoint'], integration.integrationId).subscribe(
      result => {
        if (integration.connectionState != "connected") {
          const newIntegration = {...integration};
          newIntegration.connectionState = "connected";
          this.globalStateService.updateSelectedAdapterIntegration(newIntegration);
          this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();
        }
      },
      error => {
        if (integration.connectionState == "connected") {
          const newIntegration = {...integration};
          newIntegration.connectionState = "disconnected";
          newIntegration.connectionMessage = JSON.stringify(error, null, 4);
          this.globalStateService.updateSelectedAdapterIntegration(newIntegration);
          this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();
        }
      },
      () => {
      }
    );
  }

  refreshSelectedIntegration() {

    this.adapterService.listIntegrations(this.selectedAdapterDetails$['endpoint']).subscribe(
      result => {

        const integrationList = Object.keys(result.body).map(i =>
          new IntegrationDetails(result.body[i])
        );
        console.log(this.selectedIntegrationDetails$.integrationId);
        const filteredIntegrationList = integrationList.filter(x => x.integrationId === this.selectedIntegrationDetails$.integrationId);
        const integration = filteredIntegrationList[0];
        this.globalStateService.updateSelectedAdapterIntegration(integration);
        this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();
      },
      error => {
        if (error.name === "TimeoutError") {
          this.showToaster('List Integrations call failed. Call Timed out!', 1);
        } else {
          this.showToaster("List Integrations failed. Status code: " + error.status, 1);
        }
      },
      () => {
      }
    );

  }

  getRowHeight(row) {
    return row.height;
  }

  onPage(event) {

  }

  refreshUserList() {
    this.spinner.show("processing");

    this.service.listUsersForIntegration(this.selectedAdapterDetails$['endpoint'],
      this.selectedIntegrationDetails$.integrationId).subscribe(
      result => {
        const newUserResultList = Object.keys(result.body).map(i =>
          (new IntegrationUser(result.body[i]))
        );
        this.userResultList = newUserResultList.slice();
        this.userResultList.forEach( x => this.showEdit.set(x.userId,false));
        this.userResultList.forEach( x =>
          this.userForm.addControl("sharedUserId" + x.userId, new UntypedFormControl(x.sharedUser || ''))
        );
        this.changeDetectorRefs.detectChanges();
      },
      error => {
        console.log('Error ' + JSON.stringify(error));
        console.log('Error status ' + error.status);
        if (error.name === "TimeoutError") {
          this.showToaster('List users call failed. Call Timed out!', 1);
        } else {
          this.showToaster('List users call failed. Status code: ' + error.status, 1);
        }

        this.spinner.hide("processing");
      },
      () => {
        this.spinner.hide("processing");
      }
    );

  }

//Utility Methods
  showToaster(message, statusCode) {
    statusCode === 0 ? this.toastr.success(message) : this.toastr.error(message);
  }

  toggleExpandSelectedEntitiesRow(row: any, expanded: any) {
    row['isExpanding'] = !expanded;
    this.userTable.rowDetail.toggleExpandRow(row);
  }

  processFabButtonClick(buttonId) {
     if (buttonId == 6) {
      this.triggerUserRegistration();
    }
  }

  updateFabButtons(mode: Mode) {

    const updatedFabButtons = [];
    if( mode == Mode.none ) {
      updatedFabButtons.push({
        id: 6,
        icon: 'group_add',
        tooltip: 'Register User ',
        tooltipPosition: 'left'
      });
    }
    this.buttons = JSON.parse(JSON.stringify(updatedFabButtons));
  }

  //Utility Methods for user
  viewUserSettings(user: IntegrationUser) {
    const dialogConfig = new MatDialogConfig();
    this.currentUser = user;
    dialogConfig.data =
      {
        "json": JSON.parse(JSON.stringify(user)),
        "action": "View",
        "message": "User Details"
      };
    this.matDialog.open(ViewJsonDialogComponent, dialogConfig);
  }

  openDeleteDialog(action, obj) {
    this.currentUser = obj;
    obj.action = action;

    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '350px',
      data: {
        dataObj: obj,
        title: 'User',
        message: 'Are you sure you want to delete User ' + this.currentUser.email
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Delete') {
        this.spinner.show('processing');
        console.log('Delete ' + JSON.stringify(result.data));
        let user = result.data.dataObj;
        this.service.deleteUser(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, user.email).subscribe(
          result => {
            this.refreshSelectedIntegration();
            this.spinner.hide('processing');
            this.refreshUserList();
            this.testSpecifiedIntegrationConnection();
          },
          error => {
            console.log('Error ' + JSON.stringify(error));
            console.log('Error status ' + error.status);
            this.spinner.hide('processing');
            if (error.name === "TimeoutError") {
              this.showToaster('Delete call failed. Call Timed out!', 1);
            } else {
              this.showToaster("Delete call failed. Status code: " + error.status, 1);
            }
          },
          () => {
            this.spinner.hide('processing');
          }
        )
      }
    });
  }

  testSpecifiedIntegrationConnection()
  {
    this.adapterService.ping(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId).subscribe(
      result => {
        this.messageService.SendEvent({type:"IntegrationConnectionStatusChangeEvent", data:{ state: "connected"}});
      },
      error => {
        this.messageService.SendEvent({type:"IntegrationConnectionStatusChangeEvent", data:{ state: "disconnected", error: JSON.stringify(error, null, 4)}});
      },
      () => {
      }
    );
  }

  hideRevokeIcon(user: IntegrationUser) {
    if (user.authSettings == undefined)
      return true;
    if (user.authSettings['oAuthAccessToken'] == undefined || user.authSettings['oAuthAccessToken'].length == 0 ||
      this.selectedIntegrationDetails$.config.revokingEndpoint == undefined || this.selectedIntegrationDetails$.config.revokingEndpoint.length == 0)
      return true;
    return false;
  }

  revokeToken(user: IntegrationUser) {
    this.spinner.show("delete");
    this.service.revokeUserToken(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, user.email).subscribe(
      result => {
        user.authSettings['oAuthAccessToken'] = "";
        this.toastr.success("User Token revoked");
        this.spinner.hide("delete");
        window.location.href = window.location.href;
      },
      error => {
        this.spinner.hide("delete");
        this.showToaster("Revoking of Token failed. Status code: " + error.status, 1);
      },
      () => {
      }
    )

  }

  updateSystemAccountUser(selectedUser) {
    this.userTable.rowDetail.collapseAllRows();
    this.spinner.show('processing');
    let existingSystemUser = this.userResultList.find(user => user.isSystemAccount == true);
    let payload = {
      "integrationId": this.selectedIntegrationDetails$.integrationId,
      "newSystemUser": selectedUser.email
    };
    if (existingSystemUser) {
      payload['currentSystemUser'] = existingSystemUser.email;
    }
    this.userService.UpdateIntegrationSystemUser(this.selectedAdapterDetails$['endpoint'],JSON.stringify(payload),this.selectedIntegrationDetails$.integrationId).subscribe(
      result =>{
        this.userResultList.find(user => (user.userId == selectedUser.userId)).isSystemAccount = true;
        if( existingSystemUser != null )
          this.userResultList.find(user => (user.userId == existingSystemUser.userId)).isSystemAccount = false;
        this.spinner.hide('processing');
        this.showToaster('The System Account user has been updated.', 0);
      },
      error => {
        this.spinner.hide('processing');
        if( error.name === "TimeoutError")
        {
          this.showToaster('Error updating system user - call timed out.', 1);
        } else if(error.error.errors) {
          let errorMessage;
          error.error.errors.forEach(errorDetail => {errorMessage += errorDetail.Detail + '\n';});
          this.showToaster(error.status + ': ' + errorMessage  , 1);
        } else {
          this.showToaster(
            error.status + ': Error updating system user.' +
            ' Error Detail: ' +  error.message + '\n', 1);
        }
      },
      () => {
        this.spinner.hide('processing');
      }
    )
  }

  async onSelectedEntityDetailToggle(event: any) {
    let user = event.value;
    if (user !== null && user['isExpanding'] == true) {
      //do stuff here for loading of detail section
      this.changeDetectorRefs.detectChanges();
    }else if (user !== null && user['isExpanding'] == false) {

    }

  }

  updateUser(selectedUser,event) {
    console.log("selected system user is: ", selectedUser)
    event.preventDefault();
    selectedUser.action = 'Update';
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '350px',
      data: {
        btn1Action: 'Update',
        dataObj: selectedUser,
        title: 'User',
        message: 'Are you sure you want to update the System Account User?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      switch(result.event) {
        case 'Update':
          this.updateSystemAccountUser(result.data.dataObj)
          break;
        case 'Cancel':
          this.userForm.controls["systemAccountUserRadio"].reset();
          break;
      }
    });
  }


  triggerUserRegistration() {
    const selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    const connectionDetails = this.globalStateQuery.getCachedAdapterConnectionDetails()[selectedAdapterDetails$['adapterId']];
    const newIdToken = connectionDetails.jwtToken.replace(/^"|"$/g, '');

    const url = this.globalStateQuery.getSelectedAdapterDetails()['endpoint'] +
      '/toolkit-admin/user-registration?int_id=' + this.selectedIntegrationDetails$.integrationId + '&ihbackurl=' +
      encodeURIComponent(window.location.href + '?callback=true') + '&ihjwt=' + newIdToken;
    window.location.href = url;
  }

  //Utility Methods for jobs
  noOp() {
  }

  onSelectedEntityDetailToggleShow($event: any) {
    console.log($event)
  }

  getAuthType(user: any) {
    switch (user.authType) {
      case 1:
        return "None";
      case 2:
        return "OAuth2Authorization";
      case 4:
        return "CustomAuth";
      default:
        return "N/A";
    }
  }

  ngOnDestroy(): void {
   // console.log("ngOnDestroy")

  }

  processEditButtonClick($event: MouseEvent, user: IntegrationUser) {
    this.showEdit.set(user.userId,true);
    this.changeDetectorRefs.detectChanges();
  }

  getVisual( user: IntegrationUser){
    return this.showEdit.get(user.userId);
  }

  processCancelButtonClick($event: MouseEvent, user: IntegrationUser) {
    this.showEdit.set(user.userId,false);
    this.changeDetectorRefs.detectChanges();
  }

  processSaveButtonClick($event: MouseEvent, user: any) {

    const suValue = this.userForm.controls['sharedUserId' + user.userId].value;
    this.userResultList.find( x => x.userId === user.userId ).sharedUser = suValue;

    this.showEdit.set(user.userId,false);
    this.userService.UpdateIntegrationUser(this.selectedAdapterDetails$['endpoint'],JSON.stringify(this.userResultList.find( x => x.userId === user.userId )),this.selectedIntegrationDetails$.integrationId).subscribe(
      result =>{
        this.changeDetectorRefs.detectChanges();
        this.showToaster('The  user has been updated.', 0);
      },
      error => {
        this.spinner.hide('processing');
        if( error.name === "TimeoutError")
        {
          this.showToaster('Error updating system user - call timed out.', 1);
        } else if(error.error.errors) {
          let errorMessage;
          error.error.errors.forEach(errorDetail => {errorMessage += errorDetail.Detail + '\n';});
          this.showToaster(error.status + ': ' + errorMessage  , 1);
        } else {
          this.showToaster(
            error.status + ': Error updating system user.' +
            ' Error Detail: ' +  error.message + '\n', 1);
        }
      },
      () => {
        this.spinner.hide('processing');
      }
    )

  }
}

export class ConnectionProfile {

  id: string
  adapterId: string;
  envPrefix: string;
  accountId: string;
  environment: string;
  userName: string;
  audience: string;
  authKey: string;
  ipUrl: string;
  purpose: string;
  isRegistered: boolean = false;
  jwtToken: string;
  name: string;
}

import {Component, ElementRef, OnInit} from '@angular/core';
import {AuditLogModel} from '../../../security/audit/audit-logs/state/audit-log.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuditLogService} from '../../../security/audit/audit-logs/state/audit-log.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AuditlogdialogComponent} from '../../../components/auditlogdialog/auditlogdialog.component';
import {AdapterService} from '../../../services/adapter.service';
import {GlobalUiStateQuery} from '../../../state/global-ui-state.query';
import {PagingDetails} from '../../../models/paging-details';
import { ColumnMode } from '@swimlane/ngx-datatable';


@Component({
  selector: 'app-adapter-audit-log',
  templateUrl: './adapter-audit-log.component.html',
  styleUrls: ['./adapter-audit-log.component.scss']
})
export class AdapterAuditLogComponent implements OnInit {

  logs$?: AuditLogModel[] = [];
  displayedColumns: string[] = ['time', 'integration', 'userName', 'ihUserId',  'method', 'url', 'payload'];
  defaultPageSize = 10;
  filter: string = null;
  pagingDetails = new PagingDetails();
  readonly headerHeight = 50;
  readonly rowHeight = 50;
  readonly pageLimit = 10;
  startTime:  number = 0;
  selectedAdapterDetails$: Object;
  isLoading: boolean;
  searchComplete: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private auditLogService: AuditLogService,
    public matDialog: MatDialog,
    public adapterService: AdapterService,
    private globalStateQuery: GlobalUiStateQuery,
    private el: ElementRef
  ) {
    this.pagingDetails.pageNumber = 0;
    this.pagingDetails.size = 10;
  }

  ColumnMode = ColumnMode;

  async ngOnInit(): Promise<void> {

    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();

    await this.onScroll(0);

  }

  async onScroll(offsetY: number) {

    // total height of all rows in the viewport
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;

    // check if we scrolled to the end of the viewport
    if (!this.isLoading && offsetY + viewHeight >= this.logs$.length * this.rowHeight) {

      // check if we haven't fetched any results yet
      if (this.logs$.length === 0) {
        // calculate the number of rows that fit within viewport
        const pageSize = Math.ceil(viewHeight / this.rowHeight);

        // change the limit to pageSize such that we fill the first page entirely
        // (otherwise, we won't be able to scroll past it)
        this.pagingDetails.size = Math.max(pageSize, this.pageLimit);
      }
      await this.loadPage();
    }
  }

  private async loadPage() {

    if (this.searchComplete)  {
      return;
    }

    // set the loading flag, which serves two purposes:
    // 1) it prevents the same page from being loaded twice
    // 2) it enables display of the loading indicator
    this.isLoading = true;
    const x = await this.adapterService.getAuditLogs(this.selectedAdapterDetails$['endpoint'],null, this.pagingDetails.size, this.filter, this.startTime);

    const results = x ? x.theList.sort(function compareFn(a, b) {
      if (a['createTime'] > b['createTime']) {
        return -1;
      }
      if (a['createTime'] < b['createTime']) {
        return 1
      }
      return 0;
    }) : [];

    if (results.length > 0) {
      this.startTime = results[results.length-1]["createTime"];
      this.logs$ = [...this.logs$, ...results];
    } else  {
      this.searchComplete = true;
    }

    this.isLoading = false;

  }

  async filterChanged(event) {
    if( event == this.filter)
      return;

    this.filter = event;
    this.startTime = 0;
    this.logs$ = [];
    this.searchComplete = false;
    await this.onScroll(0);

  }

  openPayload(log: AuditLogModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data =
      {
        "json":JSON.parse(log.payload)
      };
    this.matDialog.open(AuditlogdialogComponent, dialogConfig);
  }

}

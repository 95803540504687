import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminUiUsersStore } from './admin-ui-users.store';
import { APIService } from '../../../API.service';
import {AdminUiUserModel} from './admin-ui-user.model';

@Injectable({ providedIn: 'root' })
export class AdminUiUsersService {

  constructor(private api: APIService,
              private usersStore: AdminUiUsersStore,
              private http: HttpClient) {
  }

  async getUsers(): Promise<void> {

    const userList: AdminUiUserModel[] = [];
    const adminUserList = await this.api.ListUsers();
    adminUserList.items.forEach(user => {

      userList.push(
        {
          id: user.id,
          role: user.role,
          emailAddress: user.emailAddress,
          userDetail: {
            id: user.userDetail.id,
            envPrefix: user.userDetail.envPrefix,
            name: user.userDetail.name,
            username: user.userDetail.username
          }

      });
    });
    this.usersStore.set(userList);
  }

  async updateSelectedUser(user: AdminUiUserModel)
  {
    await this.api.UpdateUser(user);
  }
}

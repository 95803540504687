<script src="https://apis.google.com/js/platform.js"></script>

<main class="adapter-integration-container">

    <app-adapter-integration-header></app-adapter-integration-header>

    <app-adapter-integration-nav></app-adapter-integration-nav>

    <router-outlet></router-outlet>

</main>

export class DynamoTableInfo {

  constructor(mapValues) {
    if ( mapValues != null)
    {
      this.arn = mapValues.TableArn;
      this.tableStatus = mapValues.TableStatus;
      this.itemCount = mapValues.TableItemCount;
      this.tableSize = mapValues.TableSizeBytes;
    }
  }

  arn: string;
  tableStatus: string;
  itemCount: string;
  tableSize: string;
}

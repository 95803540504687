import { Injectable } from '@angular/core';
import {EntityState, EntityStore, EntityUIStore, StoreConfig} from '@datorama/akita';
import { AdapterModel, AdaptersState } from './adapter.model';
import {ScheduledJob} from '../../models/scheduled-job';

export type AdaptersUI = {
  showScheduledJobSearchResults: boolean;
  showScheduledJobDiv: boolean;
  scheduledJobIsEdit: boolean;
  scheduledJobSearchResultList: ScheduledJob[];
  scheduleJob: ScheduledJob;
};

export interface AdaptersUIState extends EntityState<AdaptersUI> {}

const initialState = {
  ui: {
    showScheduledJobSearchResults: false,
    showScheduledJobDiv: false,
    scheduledJobIsEdit: false,
    scheduledJobSearchResultList: [],
    scheduleJob: null
  }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'adapters' })
export class AdaptersStore extends EntityStore<AdaptersState, AdapterModel> {

  ui: EntityUIStore<AdaptersUIState>;

  constructor() {
    super(initialState);
  }

  updateUI( ui: AdaptersUIState['ui'] ) {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui
      }
    }));
  }

}

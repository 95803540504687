import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AdapterModel} from '../adapters/state/adapter.model';
import {IntegrationDetails} from '../models/integration-details';
import {firstValueFrom, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuditData} from '../models/audit-data';
import {AuditLogModel} from '../security/audit/audit-logs/state/audit-log.model';
import {Action, AuditTracker} from './AuditTracker';
import { action } from '@datorama/akita';
import {GlobalUiStateQuery} from '../state/global-ui-state.query';
import {QueueDetails} from '../models/queue-details';
import {ErrorResponse} from '../models/error-response';
import {QueueMessages} from '../models/queue-messages';
import {QueueMessage} from '../models/queue-message';

@Injectable({
  providedIn: 'root'
})
export class AdapterService {

  apiUrl: string;

  constructor(private http: HttpClient,
              private auditTracker: AuditTracker,
              private globalStateQuery: GlobalUiStateQuery) { }

  getIntegration(adapterEndpoint: string, integrationId){
    this.auditTracker.log(Action.Get,"Getting Integration "+ integrationId + " @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/integration/' + integrationId;
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  listIntegrations(adapterEndpoint: string){
    const env =  this.globalStateQuery.getSelectedEnvironment();
    console.log("env..." + env);
    this.auditTracker.log(Action.List,"Listing Integrations  @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/integration';
    if(env == 'staging')
      this.apiUrl = this.apiUrl + "?includeLocal=true"
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  listOutOfTheBoxFilters(adapterEndpoint: string){
    this.auditTracker.log(Action.List,"Listing Out Of The Box Filters  @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/outoftheboxschemafilter';
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  resetOutOfTheBoxFilter(integrationType: string, adapterEndpoint: string){
    this.auditTracker.log(Action.List,"Listing Out Of The Box Filters  @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/outoftheboxschemafilter/' + integrationType + '/reset';
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  deleteIntegration(adapterEndpoint: string, integrationId) {
    this.auditTracker.log(Action.Delete,"Deleting Integration "+ integrationId + " @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/integration/' + integrationId;
    console.log('API Url: ' + this.apiUrl);
    return this.http.delete(adapterEndpoint + this.apiUrl, { observe: 'response', headers: {'Content-Type': 'application/json'},
      responseType: 'json' });
  }

  listUsersForIntegration(adapterEndpoint: string, integrationId) {
    this.auditTracker.log(Action.List,"Listing users @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/integration/' + integrationId + '/users';
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }

  setLogLevel(adapterEndpoint: string, logLevel: string, duration: string) {
    this.auditTracker.log(Action.Set,"Setting log level to  "+ logLevel + " @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/log/' + logLevel + '/' + duration;
    console.log('API Url: ' + this.apiUrl);
    return this.http.patch(adapterEndpoint + this.apiUrl, "", { observe: 'response', headers: {'Content-Type': 'application/json'}, responseType: 'text' });
  }

  updateIntegration(adapterEndpoint: string, selectedIntegrationDetails$: IntegrationDetails){
    const json = JSON.stringify(selectedIntegrationDetails$, (key, value) => {
      if (value !== null) { return value; }
    });
    console.log(json)
    this.auditTracker.log(Action.Update,"Updating integration @ " + adapterEndpoint,json);
    this.apiUrl = '/api/admin/integration';
    return this.http.put(adapterEndpoint + this.apiUrl, json, { observe: 'response',
      headers: {'Content-Type': 'application/json'}, responseType: 'json' });
  }

  createIntegration(adapterEndpoint: string, selectedIntegrationDetails$: IntegrationDetails){
    this.apiUrl = '/api/admin/integration';

    const json = JSON.stringify(selectedIntegrationDetails$, (key, value) => {
      if (value !== null) { return value; }
    });
    this.auditTracker.log(Action.Create,"Creating integration @ " + adapterEndpoint,json);
    return this.http.post(adapterEndpoint + this.apiUrl, json, { observe: 'response', headers: {'Content-Type': 'application/json'},
      responseType: 'json' });
  }

  getIntegrationFields(adapterEndpoint: string){
    this.auditTracker.log(Action.Get,"Listing integration attributes @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/integration/integrationattributelist';
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  getApiLimit(adapterEndpoint: string, integrationId){
    this.auditTracker.log(Action.Get,"Getting API Limit @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/information/limits/'  + integrationId;
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  getAdapterMetadata(adapterEndpoint: string){
    this.auditTracker.log(Action.Get,"Listing integration metadata @ " + adapterEndpoint);
    this.apiUrl = adapterEndpoint + '/api/admin/adaptermetadata';
    return this.http.get(this.apiUrl, { observe: 'response', responseType: 'json' });
  }

  ping(adapterEndpoint: string, integrationId){
    this.apiUrl = "/api/admin/ping/" + integrationId;
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: "text" });
  }

  getConfig(adapterEndpoint: string){
    this.auditTracker.log(Action.Get,"Listing adapter config @ " + adapterEndpoint);
    this.apiUrl = "/api/admin/config" ;
    console.log('API Url: ' + this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: "json" });
  }

  clearIntegrationCache(adapterEndpoint: string, integrationId){
    this.auditTracker.log(Action.Clear,"Clearing integration cache for integration "+ integrationId +" @ " + adapterEndpoint);
    this.apiUrl = "/api/admin/cache/integration/" + integrationId;
    return this.http.delete(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: "text" });
  }

    async getAuditLogs(adapterEndpoint: string, integrationId,defaultPageSize, filter,startTime) : Promise<AuditData>{
    this.apiUrl = "/api/admin/audit/logs/list?";
    if(integrationId != null && integrationId > 0)
      this.apiUrl = "/api/admin/audit/logs/list/" + integrationId +"?";

    if(defaultPageSize > 0 )
      this.apiUrl = this.apiUrl + "pageSize=" + defaultPageSize;

      if(startTime != null && startTime > 0 )
        this.apiUrl = this.apiUrl + "&startTime=" + startTime;
      if(filter != null )
        this.apiUrl = this.apiUrl + "&filter=" + JSON.stringify(filter);

      var result = await this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: "json" }).toPromise();

      var strData = JSON.stringify(result.body);
      return <AuditData>JSON.parse(strData);
  }

  deleteUser(adapterEndpoint: string, integrationId, email) {
    this.auditTracker.log(Action.Delete,"Deleting integration user " + email +" for "+ integrationId +" @ " + adapterEndpoint);
    this.apiUrl = '/api/admin/integration-user/' + integrationId + '/' + email;
    return this.http.delete(adapterEndpoint + this.apiUrl, { observe: 'response', headers: {'Content-Type': 'application/json'},
      responseType: 'json' });
  }

  getInformation(adapterEndpoint: string, integrationId) {
    this.auditTracker.log(Action.Get ,"Get information for integrationid: " + integrationId);
    this.apiUrl = '/api/admin/information/' + integrationId;
    return this.http.get(adapterEndpoint + this.apiUrl, {observe: "response", responseType:"json"})
  }

  revokeUserToken(adapterEndpoint: string, integrationId,email) {
    this.auditTracker.log(Action.Update ,"Revoking Token for  " + email + "@ " + integrationId);
    this.apiUrl = '/api/admin/integration-user/revoke/' + integrationId+"/"+email;
    return this.http.patch(adapterEndpoint + this.apiUrl, {observe: "response", responseType:"json"})
  }

  revokeIntegrationClientInfo(adapterEndpoint: string, integrationId) {
    this.auditTracker.log(Action.Update ,"Revoking Client Info  for  integrationId "  + integrationId);
    this.apiUrl = '/api/admin/integration/' +integrationId +'/revoke/clientinfo';
    return this.http.patch(adapterEndpoint + this.apiUrl, {observe: "response", responseType:"json"})
  }

  async getIntegrationQueueListenerDetails(adapterEndpoint: string, integrationId) {
    this.auditTracker.log(Action.Get, "Get Queue Listener details call   for  integrationId " + integrationId);
    this.apiUrl = '/api/admin/queuelistener/' + integrationId + '/queuelistenerdetails';
    var getResult = this.http.get(adapterEndpoint + this.apiUrl, {observe: "response", responseType: "json"});
    var detail = await firstValueFrom(getResult).then(x => {
        var detail = new QueueDetails(x.body);
        console.log(detail);
        return detail;
      }
    ).catch(x => {
      console.log(x);
      return null;
    });
    return detail;
  }

   startIntegrationQueueListener(adapterEndpoint: string, integrationId, purgeRecords) {
    this.auditTracker.log(Action.Get ,"Starting Queue Listener for  integrationId "  + integrationId);
    this.apiUrl = '/api/admin/queuelistener/' +integrationId +'/start?purgeRecords=' + purgeRecords;
    console.log(this.apiUrl);
    return this.http.get(adapterEndpoint + this.apiUrl, {observe: "response", responseType:"json"});
  }

  stopIntegrationQueueListener(adapterEndpoint: string, integrationId) {
    this.auditTracker.log(Action.Get ,"Stopping Queue Listener for  integrationId "  + integrationId);
    this.apiUrl = '/api/admin/queuelistener/' +integrationId +'/stop';
    return this.http.get(adapterEndpoint + this.apiUrl, {observe: "response", responseType:"json"});
  }

  async getQueueMessages(adapterEndpoint: string, integrationId,defaultPageSize, startTime) : Promise<QueueMessages>{
    this.apiUrl = "/api/admin/queuelistener/" + integrationId +"/listqueuemessages?";
    if(defaultPageSize > 0 )
      this.apiUrl = this.apiUrl + "pageSize=" + defaultPageSize;
    if(startTime != null && startTime > 0 )
      this.apiUrl = this.apiUrl + "&startTime=" + startTime;


    var result = await this.http.get(adapterEndpoint + this.apiUrl, { observe: 'response', responseType: "json" });

    var detail = await firstValueFrom(result).then(x => {
      var strData = JSON.stringify(x.body);
      console.log(strData);
      return <QueueMessages>JSON.parse(strData);
      }
    ).catch(x => {
      console.log(x);
      return null;
    });
    return detail;
  }



}

import {Component, ElementRef, OnInit} from '@angular/core';
import {IntegrationDetails} from '../../../models/integration-details';
import {ErrorLogModel} from '../../../models/error-log-model';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuditLogService} from '../../../security/audit/audit-logs/state/audit-log.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AdapterService} from '../../../services/adapter.service';
import {GlobalUiStateQuery} from '../../../state/global-ui-state.query';
import {LoggingService} from '../../../services/logging.service';
import {AuditlogdialogComponent} from '../../../components/auditlogdialog/auditlogdialog.component';
import { ColumnMode } from '@swimlane/ngx-datatable';
import {PagingDetails} from '../../../models/paging-details';

@Component({
  selector: 'app-adapter-integration-logs',
  templateUrl: './adapter-integration-logs.component.html',
  styleUrls: ['./adapter-integration-logs.component.scss']
})
export class AdapterIntegrationLogsComponent implements OnInit {

  selectedIntegrationDetails$: IntegrationDetails = new IntegrationDetails(null);
  logs$?: ErrorLogModel[] = [];
  displayedColumns: string[] = ['time', 'integration', 'guid','errorid', 'message',  'description', 'exception', 'payload'];
  defaultPageSize = 10;
  filter: string = null;
  startTime:  number = 0;
  selectedAdapterDetails$: Object;
  pagingDetails = new PagingDetails();
  readonly headerHeight = 50;
  readonly rowHeight = 50;
  readonly pageLimit = 10;
  isLoading: boolean;
  searchComplete: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private auditLogService: AuditLogService,
    public matDialog: MatDialog,
    public adapterService: AdapterService,
    private globalStateQuery: GlobalUiStateQuery,
    private loggingService: LoggingService,
    private el: ElementRef
  ) { }

  ColumnMode = ColumnMode;

  async ngOnInit(): Promise<void> {

    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();

    await this.onScroll(0);

  }

  async onScroll(offsetY: number) {

    // total height of all rows in the viewport
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;

    // check if we scrolled to the end of the viewport
    if (!this.isLoading && offsetY + viewHeight >= this.logs$.length * this.rowHeight) {

      // check if we haven't fetched any results yet
      if (this.logs$.length === 0) {
        // calculate the number of rows that fit within viewport
        const pageSize = Math.ceil(viewHeight / this.rowHeight);

        // change the limit to pageSize such that we fill the first page entirely
        // (otherwise, we won't be able to scroll past it)
        this.pagingDetails.size = Math.max(pageSize, this.pageLimit);
      }
      await this.loadPage();
    }
  }

  private async loadPage() {

    if (this.searchComplete)  {
      return;
    }

    // set the loading flag, which serves two purposes:
    // 1) it prevents the same page from being loaded twice
    // 2) it enables display of the loading indicator
    this.isLoading = true;
    const x = await this.loggingService.listAdapterLogsForIntegration(this.selectedAdapterDetails$['endpoint'], this.selectedIntegrationDetails$.integrationId, this.pagingDetails, this.filter,this.startTime);

    const results = x ? x.resultList.sort(function compareFn(a, b) {
      if (a['createTime'] > b['createTime']) {
        return -1;
      }
      if (a['createTime'] < b['createTime']) {
        return 1
      }
      return 0;
    }) : [];

    if (results.length > 0) {
      this.startTime = results[results.length-1]["createTime"];
      const logs  = [...this.logs$, ...results];
      this.logs$ = logs;
    } else  {
      this.searchComplete = true;
    }

    this.isLoading = false;

  }

  async filterChanged(event) {
    if( event == this.filter)
      return;

    this.filter = event;
    this.startTime = 0;
    this.logs$ = [];
    this.searchComplete = false;
    await this.onScroll(0);

  }

  openPayload(log: ErrorLogModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data =
      {
        "json":log.payload
      };
    this.matDialog.open(AuditlogdialogComponent, dialogConfig);
  }

  openException(log) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data =
      {
        "error":log.errorException
      };
    this.matDialog.open(AuditlogdialogComponent, dialogConfig);
  }
}


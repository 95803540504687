import { Injectable } from '@angular/core';
import {EntityState, EntityStore, EntityUIStore, StoreConfig} from '@datorama/akita';
import {IntegrationDetails} from '../models/integration-details';

export type GlobalUI = {
    selectedEnvironment: string,
    selectedEnvPrefix: string,
    selectedImpersonatedEnvPrefix: string,
    selectedNavPage: string;
    selectedAdapterDetails: Object;
    selectedAdapterIntegration: IntegrationDetails;
    selectedAdapterDetailsNavPage: string;
    selectedAdapterIntegrationNavPage: string;
    selectedSecurityNavPage: string;
    selectedConfigNavPage: string;
    selectedIntegrationDetails$SearchCriteria: string;
    cachedAdapterConnectionDetails: Object[];
    connectionProfiles: Object[];
    instanceDirect: boolean;
    callbackUrl: string;
    cachedUser: Object;
};

export interface GlobalUIState extends EntityState<GlobalUI> {}

export function createInitialState(): GlobalUIState {
  return {
    ui: {
      selectedNavPage: 'adapters',
      selectedAdapterDetails: { },
      selectedAdapterIntegration: null,
      selectedAdapterDetailsNavPage: '',
      selectedAdapterIntegrationNavPage: '',
      selectedSecurityNavPage: '',
      selectedConfigNavPage: '',
      selectedIntegrationDetails$SearchCriteria: '',
      cachedAdapterConnectionDetails: [],
      connectionProfiles: [],
      instanceDirect: false,
      callbackUrl: '',
      cachedUser: {}
    }
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'globalUIState' })
export class GlobalUIStateStore extends EntityStore<GlobalUIState> {

  ui: EntityUIStore<GlobalUIState>;

  constructor() {
    super(createInitialState());
  }

  updateUI( ui: GlobalUIState['ui'] ) {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui
      }
    }));
  }
}

export class BatchInfo {
  creates: number = 0;
  updates: number = 0;
  deletes: number = 0;

  public constructor(
    fields?: {
      creates?: number;
      updates?: number;
      deletes?: number;
    }) {
    if (fields) { Object.assign(this, fields); }
  }



}

<app-error-page [errorMessage]="errorMessage"></app-error-page>

<main style="background-color: white; height: 100%; position: relative;" [hidden]="errorMessage">

  <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading" style="color: white; font-weight: bold;">Loading Adapter Config ...</p>
  </ngx-spinner>

  <div style="background-color: white; vertical-align: middle; padding: 10px;">

    <div style="background-color: white; vertical-align: middle; padding: 10px;">

      <input matInput style="width: 500px; border: none; border-bottom: solid 1px; padding-right: 10px;" placeholder="Search for config keywords" [(ngModel)]="filterText" (input)="filterChanged()">
      <img src="assets/clear.png" alt="Search" style="height: 24px; width: 24px; padding-left: 5px;" (click)="clearSearch()" >

    </div>

  </div>

  <div style="text-align: center; margin-top: 30px;">

    <mat-tree [dataSource]="nestedDataSource" [treeControl]="nestedTreeControl">
      <mat-nested-tree-node *matTreeNodeDef="let node">
        <li>
          <div class="mat-tree-node">{{node.filename}}:<span style="font-weight: bold; padding-left: 10px;">{{node.type}}</span></div>
        </li>
      </mat-nested-tree-node>
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
        <li class="example-tree-container">
          <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'toggle ' + node.filename">
              <mat-icon>
                {{nestedTreeControl.isExpanded(node) ? 'remove_circle_outline' : 'add_circle_outline'}}
              </mat-icon>
            </button>
            {{node.filename}}
          </div>
          <ul class="example-tree-nested-node">
            <div  *ngIf="nestedTreeControl.isExpanded(node)">
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          </ul>
        </li>

      </mat-nested-tree-node>
    </mat-tree>

  </div>

</main>

import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { EnvironmentsStore } from './environments.store';
import { EnvironmentModel, EnvironmentsState } from './environment.model';

@Injectable({ providedIn: 'root' })
export class EnvironmentsQuery extends QueryEntity<EnvironmentsState, EnvironmentModel> {

  constructor(protected store: EnvironmentsStore) {
    super(store);
  }

}

import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';

export interface WhereData {
  andor: string;
  operator: string;
  field: string;
  value: any;
}

@Component({
  selector: 'app-query-builder-condition-dialog',
  templateUrl: './query-builder-condition-dialog.component.html',
  styleUrls: ['./query-builder-condition-dialog.component.scss']
})
export class QueryBuilderConditionDialogComponent implements OnInit {

  fields: any[] = [];
  andOr: string;
  oper: string;
  conditionValue: string;
  value: number = 0;
  field: string;
  whereData: WhereData[];

  conditionForm: UntypedFormGroup;

  constructor(private dialogRef: MatDialogRef<QueryBuilderConditionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.fields = data.fields;
    this.whereData = data.whereData;
  }

  ngOnInit(): void {

    const group: any = {};
    group['fieldControlName'] = new UntypedFormControl(false, Validators.required);
    group['andOrControlName'] =  new UntypedFormControl(false, Validators.required);
    group['operControlName'] =  new UntypedFormControl(false, Validators.required);
    group['valueControlName'] =  new UntypedFormControl(false, Validators.required);
    this.conditionForm = new UntypedFormGroup(group);
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    var field = this.conditionForm.controls['fieldControlName'].value;
    this.whereData.push({andor: this.andOr, operator: this.oper,field:field,value:this.conditionValue});
    this.field = "";
    this.andOr = "";
    this.oper = "";
    this.conditionValue = "";
    this.dialogRef.close();
  }

}

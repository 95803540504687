<nav>
  <div (click)="securityAdminUIUsersClicked()" [style.background-color]="getSelectedNavBackgroundColor('security-admin-ui-users')">
    <div style="text-align: center; padding-top:15px;"><img src="assets/users.png" style="height: 24px;"></div>
    <div style="text-align: center; padding-top:3px; padding-bottom:15px; color: black; font-size: 8pt;">Users</div>
  </div>
  <div (click)="securityAuditLogsClicked()" [style.background-color]="getSelectedNavBackgroundColor('security-audit-logs')" >
    <div style="text-align: center; padding-top:15px;"><img src="assets/logs.png" style="height: 32px;"></div>
    <div style="text-align: center; padding-top:5px; padding-bottom:15px; color: black; font-size: 8pt;">Audit Logs</div>
  </div>
</nav>

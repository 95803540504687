<main style="text-align: center; background-color: white; height: 100%" [hidden]="!errorMessage">
  <div style="width: 50%; text-align: center; padding-top: 50px; margin: auto; font-size: 14pt;" >
    <img mat-card-image src="assets/error.png" alt="Add new adapter" style="width: 100px" >
    <div>
      Looks like there was an error connecting to the Adapter's Admin API.
    </div>
    <div style="padding-top: 10px;">
      {{errorMessage}}
    </div>
    <div style="padding-top: 10px;">
      Check the Adapter logs for details.
    </div>
  </div>
</main>

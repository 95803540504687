import {IntegrationSchemaEntity} from './integration-schema-entity';

export class IntegrationSchema {

  constructor(mapValues) {

    if (mapValues != null) {
      this.State = mapValues['State'];
      this.SchemaVersion = mapValues['SchemaVersion'];
      this.IntegrationId = mapValues['IntegrationId'];
      let entityArray = JSON.parse(JSON.stringify(mapValues['RawSchema']['EntityDefinitions'], undefined, 4));
      for (let i = 0; i < entityArray.length; i++) {
        this.EntityDefinitions.push(new IntegrationSchemaEntity(entityArray[i]));
      }
    }
  }

  State: string;
  SchemaVersion: number;
  IntegrationId: number;
  EntityDefinitions: IntegrationSchemaEntity[] = [];
}

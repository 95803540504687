import {Component, Injectable, Input, OnInit} from '@angular/core';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {BehaviorSubject} from 'rxjs';
import {NestedTreeControl} from '@angular/cdk/tree';
import {AdapterService} from '../../../services/adapter.service';
import {GlobalUiStateQuery} from '../../../state/global-ui-state.query';
import * as cloneDeep from 'lodash/cloneDeep';


/**
 * Json node data with nested structure. Each node has a filename and a value or a list of children
 */
export class FileNode {
  children: FileNode[];
  filename: string;
  type: any;
}

/**
 * The Json tree data in string. The data could be parsed into Json object
 */
var TREE_DATA = JSON.stringify({});
var orgData: FileNode[];
/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
@Injectable()
export class FileDatabase {
  dataChange = new BehaviorSubject<FileNode[]>([]);

  get data(): FileNode[] { return this.dataChange.value; }

  searchString: string;
  constructor() {
    this.initialize();
  }

  initialize() {
    // Parse the string to json object.
    var dataObject = JSON.parse(TREE_DATA);

    // Build the tree nodes from Json object. The result is a list of `FileNode` with nested
    //     file node as children.
    const data = this.buildFileTree(dataObject, 0);

    if (orgData === undefined || orgData.length == 0 )
      orgData = data;

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `FileNode`.
   */
  buildFileTree(obj: object, level: number): FileNode[] {
    return Object.keys(obj).reduce<FileNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new FileNode();
      node.filename = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.type = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  public filter(filterText: string) {
    let filteredTreeData;
    filteredTreeData =  cloneDeep(orgData);
    this.searchString = filterText;
    if (filterText.length > 0) {

      console.log(this.data);

      this.recursiveNodeEliminator(filteredTreeData);
      //filteredTreeData =  this.data.filter( fn => fn.);
     // filteredTreeData = this.dataObject.filter(d => d.text.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1);
  /*    Object.assign([], filteredTreeData).forEach(ftd => {
        let str = (<string>ftd.fileName);
        while (str.lastIndexOf('.') > -1) {
          const index = str.lastIndexOf('.');
          str = str.substring(0, index);
          if (filteredTreeData.findIndex(t => t.code === str) === -1) {
            const obj = this.data.find(d => d.filename === str);
            if (obj) {
              filteredTreeData.push(obj);
            }
          }
        }
      });*/
    }/* else {
      filteredTreeData = orgData;
    }*/

    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    // file node as children.
    //const data = this.buildFileTree(filteredTreeData, 0);
    // Notify the change.
    this.dataChange.next(filteredTreeData);
  }

  recursiveNodeEliminator(tree: Array<FileNode>): boolean {
    for (let index = tree.length - 1; index >= 0; index--) {
      const node = tree[index];
      if (node.children) {
        const parentCanBeEliminated = this.recursiveNodeEliminator(node.children);
        if (parentCanBeEliminated) {
          if (node.filename.toLocaleLowerCase().indexOf(this.searchString.toLocaleLowerCase()) === -1) {
            tree.splice(index, 1);
          }
        }
      } else {
        // Its a leaf node. No more branches.
        if (node.filename.toLocaleLowerCase().indexOf(this.searchString.toLocaleLowerCase()) === -1) {
          tree.splice(index, 1);
        }
      }
    }
    return tree.length === 0;
  }

}



@Component({
  selector: 'app-adapter-details-config',
  templateUrl: './adapter-details-config.component.html',
  styleUrls: ['./adapter-details-config.component.scss'],
  providers: [FileDatabase]
})
export class AdapterDetailsConfigComponent implements OnInit {

  errorMessage = '';
  filterText = '';

  nestedTreeControl: NestedTreeControl<FileNode>;
  nestedDataSource: MatTreeNestedDataSource<FileNode>;


  selectedAdapterDetails$: Object;

  constructor(private database: FileDatabase,
              private service: AdapterService,
              private globalStateQuery: GlobalUiStateQuery) {

    this.nestedTreeControl = new NestedTreeControl<FileNode>(this._getChildren);
    this.nestedDataSource = new MatTreeNestedDataSource();

    database.dataChange.subscribe(data => this.nestedDataSource.data = data);
  }

  hasNestedChild = (_: number, nodeData: FileNode) => !nodeData.type;

  private _getChildren = (node: FileNode) => node.children;

  ngOnInit(): void {
    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();

      this.service.getConfig(this.selectedAdapterDetails$['endpoint']).subscribe(
        result => {
          console.log(result.body)
          TREE_DATA = JSON.stringify(result.body);
          console.log(TREE_DATA)
          this.database.initialize();
          this.nestedTreeControl.dataNodes = this.database.data;
          //this.nestedTreeControl.expandAll();
        },
        error =>{
          this.errorMessage = JSON.stringify(error)
        },
        () =>{}
      );

  }

  filterChanged() {

    this.database.filter( this.filterText);
    this.nestedTreeControl.dataNodes = this.database.data;

    if(this.filterText.length > 0 ) {
        this.nestedTreeControl.expandAll();
    } else {
      this.nestedTreeControl.collapseAll();
      // this.treeControl.expandAll();
    }
  }

  clearSearch() {

    this.database.filter("");
    this.nestedTreeControl.dataNodes = this.database.data;

    if(this.filterText.length > 0 ) {
      this.nestedTreeControl.expandAll();
    } else {
      this.nestedTreeControl.collapseAll();
      // this.treeControl.expandAll();
    }
  }




}

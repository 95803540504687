<app-error-page [errorMessage]="errorMessage"></app-error-page>

<main style="text-align: center; background-color: white; height: 100%; position: relative;" [hidden]="errorMessage">

  <form>

    <div style="background-color: white; border-bottom: solid 1px gray; padding: 10px;">

      <div style="display: flex; justify-content: space-between;">

          <div style="flex: 1 1 auto;">
            <input matInput style="width: 100%; border: none; border-bottom: solid 1px;" placeholder="Search by Integration Name or ID" [value]="searchCriteria" (input)="onSearchChange($event.target.value)">
          </div>
          <div style="flex: 0 0 25px;">
            <img  src="assets/clear.png" alt="Search Integrations" style="height: 24px; width: 24px; padding-left: 5px;" (click)="clearSearch()">
          </div>

      </div>

    </div>

  </form>

  <div style="padding: 25px;">

      <mat-grid-list cols="{{numCols}}" rowHeight="3:4" [gutterSize]="colPadding" >

        <mat-grid-tile>
          <div  (click)="onboardIntegrationClicked()" style="text-align: center; vertical-align: middle; cursor: pointer;">
            <img  src="assets/addAdapter.png" alt="Add new adapter" style="height: 100px; width: 100px;" >
            <div style="height: 10px;"></div>
            Onboard Integration
          </div>
        </mat-grid-tile>

        <mat-grid-tile *ngFor="let integration of filteredResultList" [ngClass]="{'enabled-yes': integration.enabled,'enabled-no': !integration.enabled }">

          <app-integration-card [integration]="integration" (parentReloadPage)="parentReloadPage()"></app-integration-card>

        </mat-grid-tile>

      </mat-grid-list>

    </div>

  </main>

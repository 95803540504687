import { Injectable } from '@angular/core';
import {EntityState, EntityStore, EntityUIStore, StoreConfig} from '@datorama/akita';
import { EnvironmentModel, EnvironmentsState } from './environment.model';

export type EnvironmentsUI = {
};

export interface EnvironmentsUIState extends EntityState<EnvironmentsUI> {}

const initialState = {
  ui: {
  }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'environments' })
export class EnvironmentsStore extends EntityStore<EnvironmentsState, EnvironmentModel> {

  ui: EntityUIStore<EnvironmentsUIState>;

  constructor() {
    super(initialState);
  }

  updateUI( ui: EnvironmentsUIState['ui'] ) {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui
      }
    }));
  }

}

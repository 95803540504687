import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SchemaService} from '../../../../services/schema.service';
import {IntegrationDetails} from '../../../../models/integration-details';
import {GlobalUiStateQuery} from '../../../../state/global-ui-state.query';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import * as Validator from 'validatorjs';

@Component({
  selector: 'app-attribute-customizations-dialog-body',
  templateUrl: './attribute-customizations-dialog-body.component.html',
  styleUrls: ['./attribute-customizations-dialog-body.component.scss']
})
export class AttributeCustomizationsDialogBodyComponent implements OnInit {

  isEdit = false;
  jsondata = new Object();
  titleBase = 'Schema Entity Attribute Customizations';
  title = '';
  validJson = true;
  defaultOverride: string;
  form: UntypedFormGroup;
  errorMessage: string;

  selectedAdapterDetails$: Object;
  selectedIntegrationDetails$: IntegrationDetails = new IntegrationDetails(null);

  constructor(public dialogRef: MatDialogRef<AttributeCustomizationsDialogBodyComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private service: SchemaService,
              private globalStateQuery: GlobalUiStateQuery,
              public fb: UntypedFormBuilder,
              private spinner: NgxSpinnerService,) {

  }

  ngOnInit() {
    this.selectedAdapterDetails$ = this.globalStateQuery.getSelectedAdapterDetails();
    this.selectedIntegrationDetails$ = this.globalStateQuery.getSelectedAdapterIntegration();
    console.log(this.data);
    this.defaultOverride = this.data.EntityAttributeCustomizations != null ? this.data.EntityAttributeCustomizations["DefaultOverride"] : null;
    const group: any = {};
    group['defaultOverride'] = new UntypedFormControl(this.defaultOverride);
    this.form = new UntypedFormGroup(group);
  }

  close() {
    this.dialogRef.close(null);
  }


  async save() {

    if (!this.data.EntityAttributeCustomizations)
      this.data.EntityAttributeCustomizations = {};

    if (!this.form.controls['defaultOverride'].value || this.form.controls['defaultOverride'].value == "")  {

      this.data.EntityAttributeCustomizations["DefaultOverride"] = null;
    }
    else {
      // validate that the input value matches the type
      if (!this.validateValueIsOfType(this.form.controls['defaultOverride'].value, this.data["Attribute"]["Type"])) {
        this.errorMessage = "Provided value does not match required type ...";
        return;
      }

      this.data.EntityAttributeCustomizations["DefaultOverride"] = this.form.controls['defaultOverride'].value;
    }

    this.dialogRef.close(this.data);
  }

  validateValueIsOfType(value, type) {

    if (value.length == 0)
      return true;

    let typeForValidation = "string";
    if (type.toLowerCase() == "double")
      typeForValidation = "numeric";
    if (type.toLowerCase() == "string")
      typeForValidation = "string";
    if (type.toLowerCase() == "integer")
      typeForValidation = "integer";
    if (type.toLowerCase() == "datetime")
      typeForValidation = "date";

    let data = {
      defaultValue: value
    };

    let rules = {
      defaultValue: 'required|' + typeForValidation
    };

    let validation = new Validator(data, rules);

    return validation.passes();

  }
}

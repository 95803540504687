export class QueInfo {

  constructor(mapValues) {
    if( mapValues != null) {
      this.queueArn = mapValues["QueueArn"];
      this.numberOfMessages = mapValues["NumberOfMessages"];
    }
  }
  queueArn: string;
  numberOfMessages: number;
}

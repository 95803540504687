import {DynamoTableInfo} from "./dynamo-table-info";
import {QueInfo} from './que-info';

export class Healthcheck {

  constructor(mapValues) {
    if ( mapValues != null)
    {
      this.uptime = mapValues["AdapterUptime"];
      this.clientType = mapValues["ClientType"];
      this.operationMode = mapValues["Mode"];
      this.assemblyVersion = mapValues["AssemblyVersion"];
      this.baseUrl = mapValues["BaseUrl"];
      this.dynamo = mapValues["DynamoInfo"];
      this.topicCount = mapValues["TopicCount"];
      this.topics = mapValues["Topics"];
      this.topicSubscriptionsCount = mapValues["TopicSubscriptionsCount"];
      this.topicSubscriptions = mapValues["Subscriptions"];
      this.queInfo = mapValues["QueueInfo"];
      this.queueCount = mapValues["QueueCount"];
      this.logLevel = mapValues["LogLevel"];
    }
  }

  uptime: string;
  clientType: string;
  operationMode: string;
  assemblyVersion: string;
  baseUrl: string;
  dynamo: DynamoTableInfo[] = [];
  topicCount: number;
  topics: string[] = [];
  topicSubscriptionsCount: number;
  topicSubscriptions: string[] = [];
  queInfo: QueInfo[] =[];
  queueCount: number;
  logLevel: string;
}
